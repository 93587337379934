import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { Header } from "./headers/header";
import { InfiniteScroll } from "../";

const Wrapper = styled.div`
	&,
	&:focus {
		background-color: var(--grey-field);
		box-shadow: none !important;
		border-color: var(--grey-1);
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		${(props) =>
			props.hasborder
				? "border: 1px solid var(--grey-1);border-bottom: none;"
				: ""}
	}

	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: var(--grey-2);
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: var(--grey-2);
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: var(--grey-2);
	}
`;

const TableBody = styled.div`
	&,
	&:focus {
		display: flex;
		width: 100%;
		flex-direction: column;
		background-color: var(--white);
	}

`;

/**
 *
 * @param {} filter
 * @param {} columns
 * @param {} rowComponent
 * @param {} data
 * @param {} filterFunction
 * @returns
 */

export const Table = (props) => {
	const [ordering, setOrdering] = useState({});
	const [data, setData] = useState(props.data || []);

	const Row = props.rowComponent;
	const HeaderCustom = props.headerComponent;
	const infiniteScrollOptions = props.infiniteScrollOptions;

	useEffect(() => {
		setData(props.data || []);
	}, [props.data]);

	const getDataOrdered = (data) => {
		const key = ordering.key;
		const order = ordering.order;

		if (key) {
			if (order == "asc") {
				data = data.sort((a, b) => {
					return a[key].localeCompare(b[key]);
				});
			} else {
				data = data.sort((a, b) => {
					return b[key].localeCompare(a[key]);
				});
			}
		}

		return data;
	};

	const dataList = getDataOrdered(data).map((el, index) => (
			<Row
				key={el._id || index}
				data={el}
				materialeData={props.materialeData ? props.materialeData : {}}
				clienteData={props.clienteData ? props.clienteData : {}}
				onChange={(data) => {
					if (props.onChange) {
						props.onChange(data, index);
					}
				}}
				onDelete={(data) => {
					if (props.onDelete) {
						props.onDelete(data, index);
					}
				}}
			/>
		  ));

	return (
		<Wrapper
			className={props.className}
			key={props.key}
			hasborder={props.hasborder}
			style={props.style}
		>
			{HeaderCustom ? (
				<HeaderCustom
					className={props.classNameHeader}
					elements={props.columns}
					ordering={ordering}
					orderingListener={(orderingObj) => {
						setOrdering(orderingObj);
					}}
				/>
			) : (
				<Header
					className={props.classNameHeader}
					elements={props.columns}
					headerCellComponent={props.headerCellComponent}
					ordering={ordering}
					orderingListener={(orderingObj) => {
						setOrdering(orderingObj);
					}}
				/>
			)}
			<TableBody>
				{
					Row && data ?
					infiniteScrollOptions && infiniteScrollOptions.enabled ?
							<InfiniteScroll
								isLoading={infiniteScrollOptions.isLoading}
								hasMore={infiniteScrollOptions.hasMore}
								loaderContent={infiniteScrollOptions.loaderContent}
								onUpdate={infiniteScrollOptions.onUpdate}
								content={dataList}
							/>
						:
						dataList
					:
					""
				}
			</TableBody>
		</Wrapper>
	);
};
