import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, SelectField, Text, TextField, Checkbox } from "../../";
import {
	getClients,
	getListini,
	createCommessa,
	getClientsByType,
} from "../../../services";
import {
	COMMESSEDETAIL,
	PREVENTIVIDETAIL,
} from "../../../navigation/constants";
import { USER_ROLES } from "../../../utils/constants";
import { ToastContainer, Toast } from "react-bootstrap";
import { setToast } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";

export const NewOrderModal = (props) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user || {});
	const [isLoading, setIsLoading] = useState(true);
	const [clientiList, setClientiList] = useState([]);
	const [operatoriList, setOperatoriList] = useState([]);
	const [listiniList, setListiniList] = useState([]);
	const [newCommessa, setNewCommessa] = useState(props.defaultClientId ? {cliente: props.defaultClientId} : user.cd_privilege == USER_ROLES.CLIENT ? {cliente: user._id, attenzione_di: user.logged.email} : {});
	const [showToast, setShowToast] = useState({ show: false });
	const toast = useSelector((state) => state.toast || {});
	
	const navigate = useNavigate();
	let isPreventivo = props.isPreventivo || false;
	let selectedClient = {};
	let priorityCommessa = props.priorityCommessa || (clientiList.find((cliente) => cliente._id == newCommessa.cliente) || {priority: "NOI"}).priority;

	useEffect(() => {
		executeGetListini();
		//executeGetOperatori();
		executeGetClienti();
	}, []);

	// const executeGetOperatori = () => {
	// 	getClientsByType(USER_ROLES.OPERATOR).then(({ result, error }) => {
	// 		if (error) {
	// 		} else {
	// 			console.log(result);
	// 			setOperatoriList(result);
	// 		}
	// 	});
	// };


	const executeGetListini = () => {
		getListini().then(({ result, error }) => {
			if (error) {
			} else {
				
				setListiniList(result);
				if (!props.defaultClientId) {
					const listinoDefault = result.find((listino) => listino.is_default) || {_id: ""};
					setNewCommessa({...newCommessa, listino: listinoDefault._id});

				}
				/*if(user.cd_privilege == USER_ROLES.CLIENT && result && result.length > 0){
					setNewCommessa({...newCommessa, listino: result[0]._id})
				}*/
			}
		});
	};

	const executeGetClienti = () => {
		getClientsByType(USER_ROLES.CLIENT).then(({ result, error }) => {
			if (error) {
			} else {

				if(newCommessa && newCommessa.cliente){
					let clientSelected = result.find((item) => item._id == newCommessa.cliente);
					setOperatoriList( clientSelected && clientSelected.credentials ? clientSelected.credentials : []);
					setNewCommessa({...newCommessa, listino: clientSelected.listino});
				}
				/*
				TODO: check possible error.
				if(user && user.cd_privilege == USER_ROLES.CLIENT){
					let clientSelected = result.find((item) => item._id == user._id);
					setOperatoriList( clientSelected && clientSelected.credentials ? clientSelected.credentials : [])
				}
				 */
				setClientiList(result);
			}

			setIsLoading(false);
		});
	};

	const saveCommessa = () => {
		if(user.cd_privilege == USER_ROLES.CLIENT){
			let infoCliente = clientiList.find((e) => e._id === user._id);
			newCommessa.cliente = infoCliente._id;		
			newCommessa.attenzione_di = infoCliente.credentials.filter((item) => item._id === user.logged._id) ? infoCliente.credentials.filter((item) => item._id === user.logged._id)[0].email : "-";
		}
		if(newCommessa.cliente){
			newCommessa.priority = priorityCommessa;
			createCommessa(newCommessa).then(({ result, error }) => {
				if (error) {
					console.log(error);
					dispatch(setToast({
						show: true,
						title: "Errore",
						description: "Non è stato possibile creare il preventivo",
						variant: "danger"
					  }));
				} else {
					
					if (props.onCancel) {
						props.onCancel();
					}
					if (isPreventivo) {
						navigate(PREVENTIVIDETAIL+`?commessaId=${result._id}`);
					} else {
						navigate(COMMESSEDETAIL+`?commessaId=${result._id}`);
					}
				}
			});
		} else {
			dispatch(setToast({
				show: true,
				title: "Errore!",
				description: "Devi inserire un cliente per poter proseguire!",
				variant: "danger"
			  }));
		}
	};

	
	if (isLoading) {
		return <div></div>;
	} else {
		return (
			<Modal
				show={props.show}
				onHide={props.onHide}
				onCancel={props.onCancel}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				dialogClassName="modal-footer-no-border-top modal-padding"
			>
				<Modal.Body>
					<Text size="xxlarge" weight="medium" color="var(--black)">
						{isPreventivo ? "Nuovo preventivo" : "Nuova commessa"}
					</Text>
					<Text color="var(--darkgrey)">
						{isPreventivo
							? "Inserisci i seguenti dati per creare un nuovo preventivo"
							: "Inserisci i seguenti dati per creare una nuova commessa"}
					</Text>

					{user && user.cd_privilege == USER_ROLES.CLIENT ?
						<TextField
							id=""
							type="text"
							label={"CLIENTE NOME O RAGIONE SOCIALE"}
							defaultValue={
								clientiList.find((e) => e._id === user._id).ragione_sociale
							}
							readOnly
							lightbackground
							style={{
								marginTop: "40px",
							}}
						/>
					: 
					
						<SelectField
							id=""
							required
							type="text"
							label="CLIENTE NOME O RAGIONE SOCIALE"
							labelColor="var(--darkgrey)"
							style={{
								marginTop: "50px",
							}}
							value={newCommessa.cliente}
							options={[{ value: "-", text: "Seleziona..." }].concat(
								clientiList.map((item) => ({
									value: item._id,
									text: item.nomecompleto
										? item.nomecompleto
										: "-"
								}))
							)}
							onChange={(id, value) => {
								let clientSelected = clientiList.find((item) => item._id == value);
								newCommessa.cliente = value;
								if(clientSelected && clientSelected.listino != undefined && clientSelected.listino != null && clientSelected.listino != ""){
									newCommessa.listino = clientSelected.listino;
								}
								setNewCommessa({ ...newCommessa });
								setOperatoriList( clientSelected && clientSelected.credentials ? clientSelected.credentials : [])
							}}
						>
						</SelectField>
					}

					{user && user.cd_privilege != USER_ROLES.CLIENT ?

						<SelectField
							id=""
							required
							type="text"
							label="LISTINO LAVORAZIONI"
							labelColor="var(--darkgrey)"
							value={newCommessa.listino}
							style={{
								marginTop: "20px",
							}}
							options={[{ value: "-", text: "Seleziona..." }].concat(
								listiniList.map((item) => ({
									value: item._id,
									text: item.nome,
								}))
							)}
							onChange={(id, value) => {
								newCommessa.listino = value;
								setNewCommessa({ ...newCommessa });
							}}
						></SelectField>
						:
						""
					}

					<TextField
						id=""
						type="text"
						label="RIFERIMENTO"
						labelColor="var(--darkgrey)"
						placeholder="Inserisci..."
						style={{
							marginTop: "20px",
						}}
						onChange={(id, value) => {
							newCommessa.riferimento = value;

							setNewCommessa({ ...newCommessa });
						}}
					/>

					{
						user && user.cd_privilege != USER_ROLES.CLIENT ?
						<>
							<SelectField
								id=""
								required
								type="text"
								labelColor="var(--darkgrey)"
								label="ATTENZIONE DI"
								value={newCommessa.attenzione_di}
								style={{
									marginTop: "20px",
									marginBottom: "0px",
								}}
								options={[{ value: "-", text: "Seleziona..." }].concat(
									operatoriList.map((item) => ({
										value: item.email,
										text: item.fullname,
									}))
								)}
								onChange={(id, value) => {
									newCommessa.attenzione_di = value;
									setNewCommessa({ ...newCommessa });
								}}
							></SelectField>
							<Checkbox
								id="chk_visibile_cliente"
								checked={newCommessa.client_visible}
								label={"preventiviDetail.visibileCliente"}
								color="var(--black)"
								onChange={() => {
									newCommessa.client_visible = !newCommessa.client_visible;
									setNewCommessa({ ...newCommessa });
								}}
								style={{
									marginRight: "24px",
									marginTop: "12px",
								}}
							/>
						</>
						:
						""
					}
					
				</Modal.Body>
				<Modal.Footer>
					<Container>
						<Row
							style={{
								marginTop: "50px",
							}}
						>
							<Col
								style={{
									display: "flex",
									justifyContent: "end",
									flexWrap: "wrap",
									gap: "16px",
								}}
							>
								<Button variant="secondary" onClick={() => props.onCancel()}>
									<Text size="small" weight="medium">
										ANNULLA
									</Text>
								</Button>
								<Button variant="success" onClick={() => saveCommessa()}>
									<Text size="small" weight="medium">
										{isPreventivo ? "CREA PREVENTIVO" : "CREA COMMESSA"}
									</Text>
								</Button>
							</Col>
						</Row>
					</Container>
				</Modal.Footer>
			</Modal>
		);
	}
};
