import React, { useEffect, useState, useMemo } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Typeahead } from 'react-bootstrap-typeahead';
import { getAllAziende, getAllMateriali, searchMaterialiByAziendaId, getLavorazioni } from "../../services";
import { Text, SelectField, Row, TableCommesseDimensioni, TableCommesseLavorazioni, TableCommesseAltreLavorazioni, ProductImageModal } from "../index";
import {
    Delete,
	ImageSearch as SearchImgIcon, ImportExport,
} from "@material-ui/icons";
import { DEFAULT_IMB, USER_ROLES } from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { setToast } from "../../redux";
import { Add as AddIcon, Delete as DeleteIcon, Clear as ClearIcon } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

`;

const Div = styled.div`
	display: flex;
	align-items: center;
`;

const DivClick = styled(Div)`
	&,
	& > * {
		cursor: pointer;
	}
`;

const GRUPPO_LAVORAZIONE_ALZATINE = "ALZATINE";
const GRUPPO_LAVORAZIONE_COSTE = "COSTE";
const GRUPPO_LAVORAZIONE_FORO = "FORO";
const GRUPPO_LAVORAZIONE_VARIE = "VARIE";
const GRUPPO_LAVORAZIONE_ACCESSORI = "ACCESSORI";
const GRUPPO_LAVORAZIONE_VASCHE = "VASCHE";


export const BlockCommesseMateriali = withTranslation()((props) => {
    const { t } = props;

    let commessa_materiale = props.commessaMateriale || {};
    let commessa_cliente = props.commessaCliente || {};

    const [isLoading, setIsLoading] = useState(true);
    const [aziendaId, setAziendaId] = useState(commessa_materiale.materiale && commessa_materiale.materiale.azienda ? commessa_materiale.materiale.azienda._id : props.aziendaId || "")
    const [aziendeList, setAziendeList] = useState([]);
    const [materiale, setMateriale] = useState(commessa_materiale.materiale ? commessa_materiale.materiale : null);
    const [materialeName, setMaterialeName] = useState(commessa_materiale.materiale ? commessa_materiale.materiale.nome : props.materialeName || "");
    const [originalMaterialiList, setOriginalMaterialiList] = useState([]);
    const materialiList = useMemo(() => {
        return originalMaterialiList.filter((materiale)  => materiale.azienda._id == aziendaId && materiale.listino._id == props.listino);
    }, [aziendaId, originalMaterialiList]);
    const materialiNamesList = useMemo(() => {
        let uniqueMaterials = [];
        materialiList.forEach((_materiale) => {
            if (!uniqueMaterials.includes(_materiale.nome) && (!props.materialiSelected || props.materialiSelected.length == 0 || !props.materialiSelected.includes(_materiale._id) || (materiale || {})._id == _materiale._id)) {
                uniqueMaterials.push(_materiale.nome);
            }
        });
        //let materialNames = materialiList.filter((materiale, index) => (materialiList.indexOf(materiale) !== index));
        return uniqueMaterials;
    }, [aziendaId, originalMaterialiList]);
    const spessoriList = useMemo(() => {
        if(materialeName != "") {
            return materialiList.filter(
                (item) => (
                    item.nome == materialeName
                    &&
                    (!props.materialiSelected || props.materialiSelected.length == 0 || !props.materialiSelected.includes(item._id) || (materiale || {})._id == item._id)
                )
            );
        } else {
            return [];
        }
    }, [materialeName, aziendaId, originalMaterialiList]);
    const [addItemHover, setAddItemHover] = useState(false);
	const [deleteItemHover, setDeleteItemHover] = useState([]);
    const [lavorazioni, setLavorazioni] = useState([]);
    const [showModalProductImage, setShowModalProductImage] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user || {});
    const lavorazioniGroupByCodice = useMemo(() => {
        let obj = {};
        if (lavorazioni.length == 0) return obj;

        lavorazioni.forEach((lavorazione) => {
            if (!obj[lavorazione.codice] && lavorazione.attiva) {
                obj[lavorazione.codice] = {};
            }
            if (lavorazione.attiva) {
                obj[lavorazione.codice][lavorazione.gruppo_materiale.toLowerCase()] = lavorazione;
            }
        });

        return obj;

    }, [lavorazioni]);

    const imballo = useMemo(() => {
        const lav_imb = lavorazioni.filter((item) => item.codice == "IMB");
        return lav_imb.length > 0 ? lav_imb[0] : DEFAULT_IMB;
    }, [lavorazioni]);

    useEffect(() => {
        executeGetAziende();
        executeGetLavorazioni();
    }, [])

    useEffect(() => {
        if(aziendaId != "") {
            executeGetMateriali("")
        }
    }, [aziendaId]);

    /*useEffect(() => {
        if(materialiList && materialiList.length > 0) {
            createMaterialiNamesList(materialiList);
        }
    }, [props.materialiSelected])*/

    const executeGetAziende = () => {
		//setIsLoading(true);

		getAllAziende().then(({ result, error }) => {
			if (error) {
			} else {
                setAziendeList(result)
                if(materiale){
                    executeGetMateriali("");
                }
			}

			//setIsLoading(false);
		});
	};

    const executeGetLavorazioni = () => {

		getLavorazioni().then(({ result, error }) => {
			if (error) {
			} else {
                let lavorazioniFiltered = []
                if(user.cd_privilege == USER_ROLES.CLIENT) {
                    result.forEach((lavorazione, index) => {
                        if(lavorazione.privato != true && lavorazione.listino._id == props.listino){
                            lavorazioniFiltered.push(lavorazione);
                        }
                    });
                    setLavorazioni(lavorazioniFiltered);
                } else {
                    result.forEach((lavorazione, index) => {
                        if(lavorazione.listino._id == props.listino){
                            lavorazioniFiltered.push(lavorazione);
                        }
                    });
                    setLavorazioni(lavorazioniFiltered);
                }
				
			}

            setIsLoading(false);

		});
	};

    
    const executeGetMateriali = (nome) => {

		getAllMateriali(props.listino, aziendaId).then(({ result, error }) => {
			if (error) {
			} else {
                if(user.cd_privilege == USER_ROLES.CLIENT) {
                    let materialiFiltered = []
                    result.forEach((materiale, index) => {
                        if(materiale.privato != true && materiale.listino._id == props.listino){
                            materialiFiltered.push(materiale);
                        }
                    });
                    setOriginalMaterialiList(materialiFiltered);
                } else {
                    setOriginalMaterialiList(result);
                }
			}
        
		});
	};

    /*const executeGetMateriali = (nome) => {

		searchMaterialiByAziendaId(aziendaId, nome).then(({ result, error }) => {
			if (error) {
			} else {
				setMaterialiList(result);
                createMaterialiNamesList(result);
                if(materialeName != "") {
                    setSpessoriList(
                        result.filter(
                            (item) => (
                                item.nome == materialeName
                                &&
                                (!props.materialiSelected || props.materialiSelected.length == 0 || !props.materialiSelected.includes(item._id) || (materiale || {})._id == item._id)
                            )
                        )
                    )
                } else {
                    setSpessoriList([]);
                }
			}
        
		});
	};*/


    const getLavorazioniByGroup = (gruppoLavorazione) => {

		let list = []
        list = lavorazioni.filter((item) => (item.gruppo_lavorazione.toLowerCase() == gruppoLavorazione.toLowerCase()));
		
        return list;
	}

    const getLavorazioniByMaterial = (gruppoLavorazione, gruppoMateriale) => {

		let list = []
        list = lavorazioni.filter((item) => (item.gruppo_lavorazione.toLowerCase() == gruppoLavorazione.toLowerCase() && item.gruppo_materiale.toLowerCase() == gruppoMateriale.toLowerCase()));
        return list;
	}

    const getOptionsLavorazioni = (gruppo_lavorazione) => {
        let lavorazioni = materiale ? getLavorazioniByMaterial(gruppo_lavorazione, materiale.gruppo_materiale) : getLavorazioniByGroup(gruppo_lavorazione);
        if(user.cd_privilege == USER_ROLES.CLIENT) {
            let lavorazioniFiltered = []
            lavorazioni.forEach((lavorazione, index) => {
                if(lavorazione.privato != true){
                    lavorazioniFiltered.push(lavorazione);
                }
            });
            return lavorazioniFiltered;
        } else {
            return lavorazioni;
        }
    }


    const canChangeMateriale = (_materiale) => {

        let canChange = true;
        let list = [
            commessa_materiale.lavorazioni_alzatine,
            commessa_materiale.lavorazioni_profili,
            commessa_materiale.lavorazioni_fori,
            commessa_materiale.lavorazioni_varie,
            commessa_materiale.accessori,
            commessa_materiale.vasche_integrate
        ];

        list.forEach((lista_lavorazioni) => {
            lista_lavorazioni.forEach((item) => {
                if (item.lavorazione.gruppo_materiale.toLowerCase() != _materiale.gruppo_materiale.toLowerCase() && canChange) {
                    if(!lavorazioniGroupByCodice[item.lavorazione.codice] || !lavorazioniGroupByCodice[item.lavorazione.codice][_materiale.gruppo_materiale.toLowerCase()]) {
                        canChange = false;
                    }
                }
            });
        });

        return canChange;

    } 

    const onChangeCommessaMateriale = () => {
        // lavorazioniGroupByCodice[item.codice][item.gruppo_materiale]
        commessa_materiale.lavorazioni_alzatine = commessa_materiale.lavorazioni_alzatine.map(substituteLavorazione).filter((item) => (item.lavorazione.gruppo_materiale.toLowerCase() == commessa_materiale.materiale.gruppo_materiale.toLowerCase()));
        commessa_materiale.lavorazioni_profili = commessa_materiale.lavorazioni_profili.map(substituteLavorazione).filter((item) => (item.lavorazione.gruppo_materiale.toLowerCase() == commessa_materiale.materiale.gruppo_materiale.toLowerCase()));
        commessa_materiale.lavorazioni_fori = commessa_materiale.lavorazioni_fori.map(substituteLavorazione).filter((item) => (item.lavorazione.gruppo_materiale.toLowerCase() == commessa_materiale.materiale.gruppo_materiale.toLowerCase()));
        commessa_materiale.lavorazioni_varie = commessa_materiale.lavorazioni_varie.map(substituteLavorazione).filter((item) => (item.lavorazione.gruppo_materiale.toLowerCase() == commessa_materiale.materiale.gruppo_materiale.toLowerCase()));
        commessa_materiale.accessori = commessa_materiale.accessori.map(substituteLavorazione).filter((item) => (item.lavorazione.gruppo_materiale.toLowerCase() == commessa_materiale.materiale.gruppo_materiale.toLowerCase()));
        commessa_materiale.vasche_integrate = commessa_materiale.vasche_integrate.map(substituteLavorazione).filter((item) => (item.lavorazione.gruppo_materiale.toLowerCase() == commessa_materiale.materiale.gruppo_materiale.toLowerCase()));
        //commessa_materiale.altre_lavorazioni = [];

        if(props.onChange) {
            props.onChange(commessa_materiale);
        }

    }


    const substituteLavorazione = (item) => { 
        if(lavorazioniGroupByCodice[item.lavorazione.codice] && lavorazioniGroupByCodice[item.lavorazione.codice][commessa_materiale.materiale.gruppo_materiale.toLowerCase()]) {
            item.lavorazione = lavorazioniGroupByCodice[item.lavorazione.codice][commessa_materiale.materiale.gruppo_materiale.toLowerCase()];
        }
        return item;
    }

    if(isLoading){
        return (<div></div>);
    } else {
        return (
            <Card
                style={{
                    padding: "32px",
                    width: "100%",
                    marginTop: "40px",
                }}
            >
                <div
                    style={{
                        
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Text size="large" weight="bold">
                            {"blockCommesseMateriali.title"}
                        </Text>
                
                        <Text size="medium" weight="regular">
                            {"blockCommesseMateriali.description"}
                        </Text>
                    </div>
                    <DivClick
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                        onMouseEnter={() =>
                            setDeleteItemHover({ ...deleteItemHover, [0]: true })
                        }
                        onMouseLeave={() =>
                            setDeleteItemHover({ ...deleteItemHover, [0]: false })
                        }

                        onClick={(e) => {
                            if(props.onDelete) {
                                props.onDelete(commessa_materiale);
                            }
                        }}
                       
                    >
                        <ClearIcon
                            style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "8px",
                                marginBottom: "3px",
                            }}
                            color={
                                deleteItemHover[0] ? "var(--black)" : "var(--grey-2)"
                            }
                        />
                        {/* <Text
                            size="large"
                            weight="medium"
                            color={
                                deleteItemHover[0] ? "var(--black)" : "var(--black)"
                            }
                            style={{
                                textDecoration: deleteItemHover[0] ? "underline" : "",
                            }}
                        >
                            Elimina
                        </Text> */}
                    </DivClick>
                </div>
                {
                    materiale && materiale.nome != "" ?
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            width: "100%",
                            marginTop: "24px"
                        }}
                    >
                        <Text size="large" weight="bold">
                            {t("blockCommesseMateriali.materialName", {nome: materiale.nome, spessore: materiale.spessore})}
                        </Text>
                        <DivClick
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginLeft: "12px"
                            }}
                            onClick={(e) => {
                                commessa_materiale.materiale = null;
                                commessa_materiale.dimensioni = [];
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                                setMateriale(null);
                            }}
                        
                        >
                            <DeleteIcon
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "8px",
                                    marginBottom: "3px",
                                }}
                            />
                            {/* <Text
                                size="large"
                                weight="medium"
                                color={
                                    deleteItemHover[0] ? "var(--black)" : "var(--black)"
                                }
                                style={{
                                    textDecoration: deleteItemHover[0] ? "underline" : "",
                                }}
                            >
                                Elimina
                            </Text> */}
                        </DivClick>
                    </div>
                    :
                    ""
                }
                <Row
                    defaultWidth={40}
                    breakpoints={[
                        {
                            breakpoint: "10000",
                            defaultWidth: 40,
                            widthOverride: {
                                ".materiale":30,
                                ".spessore": 20,
                                ".searchImg": 10,
                            },
                        },
                    ]}
                    style={{
                        marginTop: "24px",
                    }}
                >
                    <SelectField
                        className="azienda"
                        label={"blockCommesseMateriali.aziendaLabel"}
                        value={aziendaId}
                        isClearable
                        isSearchable
                        options={
                            [{value:"-", text:"hint_select"}].concat(aziendeList.map((item) => ({
                                value: item._id,
                                text: item.nome
                            })))
                        }
                        onChange={(id, value) => {
                            setAziendaId(value);
                        }}
                        onClear={() => {
                            setAziendaId("");
                            setMaterialeName("");
                        }}
                        
                    />

                    <SelectField
                        className="materiale"
                        label={"blockCommesseMateriali.materialeLabel"}
                        value={materialeName}
                        isClearable
                        isSearchable
                        options={
                            [{value:"-", text:"hint_select"}].concat(materialiNamesList.map((item) => ({
                                value: item,
                                text: item
                            })))
                        }
                        onChange={(id, value) => {

                            if(props.onChange) {
                                props.onChange(commessa_materiale);
                            }
                            if(canChangeMateriale(materialiList.find((item) => item.nome == value))) {
                                setMaterialeName(value != "-" ? value : "");
                            } else {
                                dispatch(
                                    setToast({
                                        show: true,
                                        title: "blockCommesseMateriali.toast.cannotChangeMaterialTitle",
                                        description: "blockCommesseMateriali.toast.cannotChangeMaterialDescription",
                                        variant: "danger",
                                        delay: 7000
                                    })
                                );
                                setAziendaId(materiale.azienda._id);
                                setMaterialeName(materiale.nome);
                            }
                            
                        }}
                        onClear={() => {
                            setMaterialeName("");
                        }}
                        
                    />

                    <SelectField
                        className="spessore"
                        label={"blockCommesseMateriali.spessoreLabel"}
                        value={materiale && materiale.spessore && materiale.spessore != "" ? materiale._id : []}
                        options={
                            [{value:"-", text:"hint_select"}].concat(spessoriList.map((item) => ({
                                value: item._id,
                                text: item.spessore
                            })))
                        }
                        onChange={(id, value) => {

                            commessa_materiale.materiale = materialiList.find((item) => (
                                item._id == value
                            ));

                            onChangeCommessaMateriale();

                            setMateriale(commessa_materiale.materiale);
                        }}
                        filterOption={(option, inputValue) => {
                            return option.value != "-" && option.value != "";
                          }
                        }
                    />

                    <div
                        className="searchImg"
                        style={{
                            alignSelf: "flex-end",
                            marginBottom: "10px",
                        }}
                    >
                        {
                            materialeName && materialeName != "" ?
                                <SearchImgIcon
                                    style={{
                                        color: "var(--grey-2)",
                                        width: "28px",
                                        height: "28px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        if(materialeName && materialeName != "") {
                                            setShowModalProductImage(true);
                                        }
                                    }}
                                />
                            :
                                ""
                        }
                    </div>
                </Row>
                { materiale ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseDimensioni
                            title={"blockCommesseMateriali.dimensionTitle"}
                            tableData={commessa_materiale.dimensioni}
                            imballo ={parseFloat(imballo.prezzo)}
                            materialeData = {materiale}
                            clienteData = {commessa_cliente}
                            onChange={(newList) => {
                                commessa_materiale.dimensioni = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                {(materiale ? getLavorazioniByMaterial(GRUPPO_LAVORAZIONE_ALZATINE, materiale.gruppo_materiale) : getLavorazioniByGroup(GRUPPO_LAVORAZIONE_ALZATINE)).length > 0 ? 
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseLavorazioni
                            title={"blockCommesseMateriali.alzatineTitle"}
                            tableData={commessa_materiale.lavorazioni_alzatine}
                            lavorazioni={getOptionsLavorazioni(GRUPPO_LAVORAZIONE_ALZATINE)}
                            clienteData = {commessa_cliente}
                            labelTextfield={"QT"}
                            onChange={(newList) => {
                                commessa_materiale.lavorazioni_alzatine = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                {(materiale ? getLavorazioniByMaterial(GRUPPO_LAVORAZIONE_COSTE, materiale.gruppo_materiale) : getLavorazioniByGroup(GRUPPO_LAVORAZIONE_COSTE)).length > 0 ? 
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseLavorazioni
                            title={"blockCommesseMateriali.costeTitle"}
                            tableData={commessa_materiale.lavorazioni_profili}
                            lavorazioni={getOptionsLavorazioni(GRUPPO_LAVORAZIONE_COSTE)}
                            clienteData = {commessa_cliente}
                            labelTextfield={"QT"}
                            onChange={(newList) => {
                                commessa_materiale.lavorazioni_profili = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                {(materiale ? getLavorazioniByMaterial(GRUPPO_LAVORAZIONE_FORO, materiale.gruppo_materiale) : getLavorazioniByGroup(GRUPPO_LAVORAZIONE_FORO)).length > 0 ? 
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseLavorazioni
                            title={"blockCommesseMateriali.foroTitle"}
                            tableData={commessa_materiale.lavorazioni_fori}
                            lavorazioni={getOptionsLavorazioni(GRUPPO_LAVORAZIONE_FORO)}
                            clienteData = {commessa_cliente}
                            onChange={(newList) => {
                                commessa_materiale.lavorazioni_fori = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                {(materiale ? getLavorazioniByMaterial(GRUPPO_LAVORAZIONE_VARIE, materiale.gruppo_materiale) : getLavorazioniByGroup(GRUPPO_LAVORAZIONE_VARIE)).length > 0 ? 
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseLavorazioni
                            title={"blockCommesseMateriali.varieTitle"}
                            tableData={commessa_materiale.lavorazioni_varie}
                            lavorazioni={getOptionsLavorazioni(GRUPPO_LAVORAZIONE_VARIE)}
                            clienteData = {commessa_cliente}
                            onChange={(newList) => {
                                commessa_materiale.lavorazioni_varie = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                {(materiale ? getLavorazioniByMaterial(GRUPPO_LAVORAZIONE_ACCESSORI, materiale.gruppo_materiale) : getLavorazioniByGroup(GRUPPO_LAVORAZIONE_ACCESSORI)).length > 0 ? 
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseLavorazioni
                            title={"blockCommesseMateriali.accessoriTitle"}
                            tableData={commessa_materiale.accessori}
                            lavorazioni={getOptionsLavorazioni(GRUPPO_LAVORAZIONE_ACCESSORI)}
                            clienteData = {commessa_cliente}
                            onChange={(newList) => {
                                commessa_materiale.accessori = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                {(materiale ? getLavorazioniByMaterial(GRUPPO_LAVORAZIONE_VASCHE, materiale.gruppo_materiale) : getLavorazioniByGroup(GRUPPO_LAVORAZIONE_VASCHE)).length > 0 ? 
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                        }}
                    >
                        <TableCommesseLavorazioni
                            title={"blockCommesseMateriali.vascheIntegrate"}
                            tableData={commessa_materiale.vasche_integrate}
                            lavorazioni={getOptionsLavorazioni(GRUPPO_LAVORAZIONE_VASCHE)}
                            clienteData = {commessa_cliente}
                            onChange={(newList) => {
                                commessa_materiale.vasche_integrate = newList;
                                if(props.onChange) {
                                    props.onChange(commessa_materiale);
                                }
                            }}
                        />
                    </div>
                    :
                    <div></div>
                }
                
                <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "40px",
                    }}
                >
                    <TableCommesseAltreLavorazioni
                        title={"blockCommesseMateriali.altreLavorazioniTitle"}
                        tableData={commessa_materiale.altre_lavorazioni}
                        clienteData = {commessa_cliente}
                        onChange={(newList) => {
                            commessa_materiale.altre_lavorazioni = newList;
                            if(props.onChange) {
                                props.onChange(commessa_materiale);
                            }
                        }}
                    />
                </div>
                </>
                {
                    aziendeList && aziendeList.length > 0 && aziendaId != "" && materialeName && materialeName != "" ?
                        <ProductImageModal
                            show={showModalProductImage}
                            azienda={(aziendeList.find((azienda) => azienda._id == aziendaId) || {}).nome}
                            materiale={materialeName}
                            onHide={() => setShowModalProductImage(false)}
                            onCancel={() => setShowModalProductImage(false)}
                        />
                    :
                        ""
                }
            </Card>
        );
    };
});