import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ROOT } from "./constants";

export const NotFound = () => {
  return (
    <Fragment>
      {/* Page Not Found! */}
      <Link to={ROOT}>Home</Link>
      <Typography variant="h2">404: page not found!</Typography>
    </Fragment>
  );
};
