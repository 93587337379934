import React, { useEffect, useState, useMemo } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Add as AddIcon } from "@material-ui/icons";
import { Text, TextField, SelectField, Button, Table, RowCommesseLavorazioni, HeaderCellCommesse } from "../index";
import { calcolaTotaleLavorazioni } from "../../utils/functionsUtils";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: column;
    }

`;


const Footer = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        background-color: var(--grey-field);

        border-left: 1px solid var(--grey-1);
        border-right: 1px solid var(--grey-1);
        border-bottom: 1px solid var(--grey-1);

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        padding: 12px 20px 12px 20px;
        
    }

`;


const COLUMNS = [
  {
      key: "ua",
      text: "tableCommesseLavorazioni.column1",
      width: "10%",
    },
  {
    key: "data1",
    text: "tableCommesseLavorazioni.column2",
    width: "10%",
  },
  {
    key: "data2",
    text: "tableCommesseLavorazioni.column3",
    width: "10%",
  },
  {
    key: "data3",
    text: "tableCommesseLavorazioni.column4",
    width: "35%",
  },
  {
    key: "data4",
    text: "tableCommesseLavorazioni.column5",
    width: "10%",
  },
  {
      key: "data1",
      text: "tableCommesseLavorazioni.column6",
      width: "10%",
    },
    {
      key: "data2",
      text: "tableCommesseLavorazioni.column7",
      width: "10%",
    },
    {
      key: "data3",
      text: "tableCommesseLavorazioni.column8",
      width: "5%",
    }
];


export const TableCommesseLavorazioni = withTranslation()((props) => {

  const { t } = props;

    let tableData = props.tableData || [];
    let clienteData = props.clienteData || [];
    const lavorazioni = props.lavorazioni || [];
    const DEFAULT_LAVORAZIONE = useMemo(() => lavorazioni.find((item) => item.std == true) || "-", [lavorazioni]);
    const [lavorazioneSelected, setLavorazioneSelected] = useState(DEFAULT_LAVORAZIONE);
    const [uaValue, setUaValue] = useState("");

    const areFieldsValid = uaValue != "" && lavorazioneSelected != "-";
  
    useEffect(() => {
      setLavorazioneSelected(DEFAULT_LAVORAZIONE);
    }, [lavorazioni]);

    const addRowItem = () => {

      if(areFieldsValid){
        let itemToAdd = {
          ua_value: uaValue,
          lavorazione: lavorazioneSelected
        };

        tableData.push(itemToAdd);

        setUaValue("");
        setLavorazioneSelected(DEFAULT_LAVORAZIONE);

        if(props.onChange) {
          props.onChange(tableData);
        }
      }

    }

    const deleteRowItem = (index) => {

      tableData = tableData.filter((item, itemIndex) => itemIndex != index);

      if(props.onChange) {
        props.onChange(tableData);
      }

    }


    const getOptionsLavorazioni = (gruppo_lavorazione) => {
      let list = lavorazioni.map((item) => ({
          value: item._id,
          text: item.codice + " - " /*+ item.gruppo_materiale + ": "*/ + item.descrizione + " - " + item.prezzo + " €/" + item.ua
      }));
      return [{value:"-", text: "tableCommesseLavorazioni.selectLavorazione"}].concat(list);
  }

  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >
            
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <Text
                    size="medium"
                    weight="medium"
                    color="var(--red)"
                    style={{
                      marginRight: "12px"
                    }}
                >
                    {props.title}
                </Text>
                <Text
                    size="xsmall"
                    weight="regular"
                    color="var(--grey-2)"
                >
                    {"tableCommesseLavorazioni.description"}
                </Text>
            </div>
            
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "24px"
              }}
            >
              <SelectField
                id={"lavorazione"}
                value={lavorazioneSelected && lavorazioneSelected._id ? lavorazioneSelected._id : "-"}
                options={getOptionsLavorazioni()}
                label={"tableCommesseLavorazioni.tipologiaLabel"}
                placeholder={"hint_insert"}
                onChange={(id, value) => {
                  if (value != "-") {
                    let obj = lavorazioni.find((item) => item._id == value);
                    setLavorazioneSelected(obj);
                  } else {
                    setLavorazioneSelected("-");
                  }
                }}
                style={{
                  minWidth: "150px",
                  maxWidth: "600px",
                  width: "100%",
                  marginRight: "20px"
                }}
              />
              <TextField
                id={"ua_value"}
                value={uaValue}
                type={"text"}
                label={props.labelTextfield ? props.labelTextfield : "UA"}
                placeholder={"hint_insert"}
                style={{
                  maxWidth: "150px",
                  marginRight: "20px"
                }}
                onChange={(id, value) => {
                  setUaValue(value.replace(",", "."));
                }}
                onKeyPress={(event) => {
                  if (event.charCode === 13) {
                    if(areFieldsValid) {
                      addRowItem();
                    }
                  }
                }}
              />
              <Button
                variant="outlined-darkgrey"
                disabled={!areFieldsValid}
                style={{
                  alignSelf: "flex-end",
                  padding: "8px 16px 8px 16px"
                }}
                onClick={() => {
                  addRowItem();
                }}
              >
                <AddIcon/>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
                overflowX: "scroll"
              }}
            >
              <Table
                className="min-width-742"
                columns={COLUMNS}
                headerCellComponent={HeaderCellCommesse}
                data={tableData}
                clienteData={clienteData}
                hasborder
                rowComponent={RowCommesseLavorazioni}
                onChange={(newItem, index) => {
                  tableData = tableData.map((item, itemIndex) => itemIndex != index ? item : newItem);

                  if(props.onChange) {
                    props.onChange(tableData);
                  }
                }}
                onDelete={(item, index) => {
                  deleteRowItem(index);
                }}
              />
              <Footer
                className="min-width-742"
              >
                <Text
                  size="xsmall"
                  weight="medium"
                  color={"var(--darkgrey)"}
                >
                  {t("tableCommesseLavorazioni.tot", {tot:calcolaTotaleLavorazioni(tableData, clienteData)})}
                </Text>
              </Footer>
            </div>
            

        </Wrapper>
  );
});
