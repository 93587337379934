import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { ImgCell } from "../cells/imgCell";
import { TextCell } from "../cells/textCell";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ProgressBar } from "../..";
import { ZONE, COMMESSA_TYPES, COMMESSA_STATUS_COLOR, PUNTEGGI_LAVORAZIONI_STATUS, STATUS } from "../../../utils/constants"
import {
    Label
} from "@material-ui/icons";
import {
	mountMaterialNames
} from "../../../utils/";
import { DOWNLOAD_DOCUMENTI } from "../../../services/constants";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;
        min-width: 1192px;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--white);
        border-bottom: 1px solid var(--grey-1);
    }

    & > .cell-padding {
        padding-left: 8px;
        padding-right: 8px;
    }

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowOrdini = (props) => {

    const [data, setData] = useState(props.data);

    const [documentsState, setDocumentsState] = useState({
		pdf_esecutivo: {
			exist: false,
			filename: ""
		},
		pdf_cliente: {
			exist: false,
			filename: ""
		},
		pdf_conferma: {
			exist: false,
			filename: ""
		}
	});

    useEffect(() => {
        const anno_commessa = moment(data.dt_creazione_commessa).format("YYYY"); //2022
        const numero_commessa = data.numero_commessa; // "76D"

		if	(data.pdf_cliente_exist) {
            documentsState.pdf_cliente.exist = true;
            documentsState.pdf_cliente.filename = `${numero_commessa}-${anno_commessa}-E.pdf`;
		}

        if	(data.pdf_esec_exist) {
            documentsState.pdf_esecutivo.exist = true;
            documentsState.pdf_esecutivo.filename = `${numero_commessa}-${anno_commessa}.pdf`;
		}

        if	(data.pdf_conf_exist) {
            documentsState.pdf_conferma.exist = true;
            documentsState.pdf_conferma.filename = `${numero_commessa}-${anno_commessa}-D.pdf`;
		}

        if(data.pdf_cliente_exist || data.pdf_esec_exist || data.pdf_conf_exist) {
            setDocumentsState({...documentsState});
        }

	}, []);

    const getColorFromCommessa = (show_anticipo) => {
        if(data.cd_type != COMMESSA_TYPES.ATTIVITA) {
            if(moment() < moment(data.dt_consegna) && show_anticipo) {
                return "var(--green)";
            } else {
                return COMMESSA_STATUS_COLOR[data.cd_status];
            }
        } else {
            return "";
        }
    }

    const getSumPunteggiLavorazione = (only_green) => {
        let sum = 0;

        Object.keys(data.punteggi_lavorazioni).forEach(key => {
            if (only_green) {
                if(data.punteggi_lavorazioni[key].status == PUNTEGGI_LAVORAZIONI_STATUS.GREEN) {
                    sum += parseFloat(data.punteggi_lavorazioni[key].initial_value);
                }
            } else {
                sum += parseFloat(data.punteggi_lavorazioni[key].initial_value);
            }
        });

        return sum.toFixed(2);
    }

    const getPercentage = () => {
        const value = ((getSumPunteggiLavorazione(true)*100)/getSumPunteggiLavorazione(false)).toFixed(2);
        return !isNaN(value) ? value + "%" : "0.00%";
    };

  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >

            <div
                className="cell-padding"
                style={{
                    width: "3%"
                }}
            >
                <Label
                    style={{
                        color: getColorFromCommessa(true)
                    }}
                />
            </div>
            <TextCell
                className="cell-padding"
                primaryText={data.priority}
                style={{
                    width: "5%"
                }}
            />

            <TextCell
                className="cell-padding"
                primaryText={(ZONE.find((zona) => zona.value == (data.cliente || {zona_consegna:"-"}).zona_consegna) || {}).text || "-"}
                style={{
                    width: "7%"
                }}
            />

            <TextCell
                className="cell-padding"
                primaryText={data.cd_type != COMMESSA_TYPES.ATTIVITA ? STATUS[data.cd_status] : "commesse.attivitaLabel"}
                color={data.cd_type != COMMESSA_TYPES.ATTIVITA ? COMMESSA_STATUS_COLOR[data.cd_status] : "#000000"}
                style={{
                    width: "10%"
                }}
            />

            <TextCell
                className="cell-padding"
                primaryText={data.cliente ? data.cliente.ragione_sociale : ""}
                secondaryText={`${data.riferimento}`}
                primaryTextSize = "large"
                primaryTextWeight = "bold"
                style={{
                    width: "15%"
                }}
            />

            
            
            <TextCell
                primaryText={mountMaterialNames(data)}
                style={{
                    width: "30%"
                }}
            />

            <TextCell
                className="cell-padding"
                primaryText={data.hour_consegna && data.hour_consegna != "" ? moment(data.dt_consegna).set({hour:data.hour_consegna.split(":")[0],minute:data.hour_consegna.split(":")[1],second:0,millisecond:0}).format("DD/MM/YYYY - HH:mm") : moment(data.dt_consegna).format("DD/MM/YYYY - HH:mm")}
                style={{
                    width: "15%"
                }}
            />

            <TextCell
                className="cell-padding"
                primaryText={data.numero_commessa}
                style={{
                    width: "10%"
                }}
            />


            <div
                className="cell-padding"
                style={{
                    width: "15%",
                    display: "flex",
                    flexDirection: "column"
                }}
            >

                <div
                    style={{
                        display: "flex"
                    }}
                >

                    <div
                        className="cell-padding"
                        style={{
                            width: "33.3%"
                        }}
                    >
                        {
                            documentsState.pdf_esecutivo.exist ?
                                <a
                                    href={documentsState.pdf_esecutivo.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("pdf", data.numero_commessa, moment(data.dt_creazione_commessa).format("YYYY"))}` : null}
                                    target="_blank"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <FileDownloadOutlinedIcon
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </a>
                            :
                                ""
                        }
                    </div>
                    <div
                        className="cell-padding"
                        style={{
                            width: "33.3%"
                        }}
                    >
                        {
                            documentsState.pdf_cliente.exist ?
                                <a
                                    href={documentsState.pdf_cliente.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("pdf", data.numero_commessa, `${moment(data.dt_creazione_commessa).format("YYYY")}-E`)}` : null}
                                    target="_blank"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <FileDownloadOutlinedIcon
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </a>
                            :
                                ""
                        }
                    </div>
                    <div
                        className="cell-padding"
                        style={{
                            width: "33.3%"
                        }}
                    >
                        {
                            documentsState.pdf_conferma.exist ?
                                <a
                                    href={documentsState.pdf_conferma.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("pdf", data.numero_commessa, `${moment(data.dt_creazione_commessa).format("YYYY")}-D`)}` : null}
                                    target="_blank"
                                    style={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <FileDownloadOutlinedIcon
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    />
                                </a>
                            :
                                ""
                        }
                    </div>
                </div>
                <ProgressBar
                    now={((getSumPunteggiLavorazione(true)*100)/getSumPunteggiLavorazione(false)).toFixed(2)}
                    className={"small"}
                    color="var(--green)"
                    label={getPercentage()}
                    style={{
                        maxWidth: "100%",
                        width: "100%",
                        marginTop: "12px"
                    }}
                />

            </div>

        </Wrapper>
  );
};
