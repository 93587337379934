import React, { useState, useEffect } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Text, TextField, SelectField } from "../..";

const SendQuotationModal = (props) => {
	const { t } = props;
	let preventivo = props.data ? props.data : {};
	let mailToSend = "";
	const [mailSelected, setMailSelected] = useState(preventivo.cliente.email_invio_documenti);

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Container>
					<Text size="xxlarge" weight="medium" color="var(--black)">
						{"send_quotation_modal.title"}
					</Text>
					<Text color="var(--darkgrey)">
						{"send_quotation_modal.subtitle"}
					</Text>

					<TextField
						id=""
						type="email"
						
						value={mailSelected}
						label={"send_quotation_modal.send_email_title"}
						labelColor="var(--darkgrey)"
						placeholder={"hint_insert"}
						style={{
							marginTop: "20px",
						}}
						onChange={(id, value) => {
							//mailToSend = value
							setMailSelected(value)
						}}
					/>

					{/* <SelectField
						id={preventivo._id}
						label={"send_quotation_modal_send_email_title"}
						value={mailSelected}
						options={preventivo.cliente.credentials
							.map((credential) => {
								return {
									value: credential._id,
									text: credential.email || "",
								};
							})}
						onChange={(id, value) => {
							mailToSend = value
							setMailSelected(value)
						}}
						style={{
							marginTop: "20px",
						}}
					/> */}
					{/* <Form>
						<Row>
							<Col sx={12} md={6}>
								<Checkbox
									label="PREVENTIVO COMPLETO"
									name="sendingType"
									type="radio"
									id="radio-complete-quotation"
									defaultChecked
									style={{
										marginTop: "20px",
									}}
								/>
							</Col>
							<Col sx={12} md={6}>
								<Checkbox
									label="CONTEGGI"
									name="sendingType"
									type="radio"
									id="radio-counting"
									style={{
										marginTop: "20px",
									}}
								/>
							</Col>
						</Row>
					</Form> */}
					{/* <div
						style={{
							height: "50px",
						}}
					/> */}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row
						style={{
							marginTop: "50px",
						}}
					>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={() => props.onCancel()}>
								<Text size="small" weight="medium">
									{"btn_cancel"}
								</Text>
							</Button>
							<Button variant="success" onClick={() => props.onSuccess(mailSelected)}>
								<Text size="small" weight="medium">
									{"send_quotation_modal.send_email_btn"}
								</Text>
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};


SendQuotationModal.defaultProps = {
	onSuccess: () => {},
	onCancel: () => {},
};

export default SendQuotationModal;
