import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row, Stack } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
	Button,
	DatePickerComponent,
	SelectField,
	Text,
	TextAreaField,
	TextField,
} from "../../";
import { getListini, createCommessa, getClientsByType, updateCommessa, deleteCommessa } from "../../../services";
import { PRIORITA, USER_ROLES, COMMESSA_TYPES } from "../../../utils/constants";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddDocument from "./components/addDocument";
import "./NewActivityModal.css";
import moment from "moment";

export const NewActivityModal = (props) => {
	const [startDate, setStartDate] = useState(new Date());
	const [isLoading, setIsLoading] = useState(true);
	const [clientiList, setClientiList] = useState([]);
	const [commessa, setCommessa] = useState(
		props.commessa ?
			props.commessa
		:
			{
				cd_type: COMMESSA_TYPES.ATTIVITA
			}
	);


	useEffect(() => {
		executeGetClienti();
	}, []);

	const executeGetClienti = () => {
		getClientsByType(USER_ROLES.CLIENT).then(({ result, error }) => {
			if (error) {
			} else {
				setClientiList(result);
			}

			setIsLoading(false);
		});
	};

	const saveCommessa = () => {
		console.log(commessa);
		if(commessa._id) {
			updateCommessa(commessa._id, commessa).then(({ result, error }) => {
				if (error) {
					console.log(error);
				} else {
					if(props.onSuccess) {
						props.onSuccess(commessa);
					} else if(props.onCancel) {
						props.onCancel();
					}
					setCommessa({
						cd_type: COMMESSA_TYPES.ATTIVITA
					});
				}
			});
		} else {
			createCommessa(commessa).then(({ result, error }) => {
				if (error) {
					console.log(error);
				} else {
					if(props.onSuccess && result.result) {
						props.onSuccess(result.result);
					} else if(props.onCancel) {
						props.onCancel();
					}
					setCommessa({
						cd_type: COMMESSA_TYPES.ATTIVITA
					});
				}
			});
		}
	};

	const executeDeleteCommessa = () => {
		deleteCommessa(commessa._id).then(({ result, error }) => {
			if (error) {
				console.log(error);
			} else {
				if(props.onSuccess) {
					props.onDelete(commessa);
				} else if(props.onCancel) {
					props.onCancel();
				}
				setCommessa({
					cd_type: COMMESSA_TYPES.ATTIVITA
				});
			}
		});
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Container>
					<Text size="xxlarge" weight="medium" color="var(--black)">
						Nuova attività
					</Text>
					<Text color="var(--darkgrey)">
						Inserisci i seguenti dati per creare una nuova attività
					</Text>

					<SelectField
						id=""
						required
						type="text"
						label="CLIENTE NOME O RAGIONE SOCIALE"
						labelColor="var(--darkgrey)"
						style={{
							marginTop: "50px",
						}}
						value={commessa.cliente && commessa.cliente._id ? commessa.cliente._id : commessa.cliente}
						options={[{ value: "-", text: "Seleziona..." }].concat(clientiList.map((item) => ({
							value: item._id,
							text: item.nomecompleto ? item.nomecompleto : item.ragione_sociale
						})))}
						onChange={(id, value) => {
							if(commessa.cliente && commessa.cliente._id) {
								commessa.cliente = clientiList.find((cliente, index) => cliente._id == value);
							} else {
								commessa.cliente = value;
							}
							commessa.priority = (clientiList.find((cliente) => cliente._id == commessa.cliente) || {priority: "NOI"}).priority;
							setCommessa({...commessa});
						}}
					></SelectField>

					<TextField
						id=""
						type="text"
						label="RIFERIMENTO"
						labelColor="var(--darkgrey)"
						placeholder="Inserisci..."
						style={{
							marginTop: "20px",
						}}
						value={commessa.riferimento}
						onChange={(id, value) => {
							commessa.riferimento = value;
							setCommessa({...commessa});
						}}
					/>

					<Row>
						<Col sx={12} md={6}>
							<SelectField
								id=""
								required
								type="text"
								label="PRIORITÀ"
								labelColor="var(--darkgrey)"
								style={{
									marginTop: "20px",
								}}
								value={commessa.priority}
								options={PRIORITA}
								onChange={(id, value) => {
									commessa.priority = value;
									setCommessa({...commessa});
								}}
							></SelectField>
						</Col>
						<Col sx={12} md={6}>
							<DatePickerComponent
								label="DATA CONSEGNA"
								labelColor="var(--darkgrey)"
								selectedDate={commessa.dt_consegna}
								backgroundColor="var(--white)"
								style={{
									marginTop: "20px",
								}}
								onChange={(id, value) => {
									commessa.dt_consegna = moment(value).set({hour:0,minute:0,second:0,millisecond:0});
									commessa.dt_lavorazione = commessa.dt_consegna;
									setCommessa({ ...commessa });
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<TextAreaField
								label="DESCRIZIONE"
								labelColor="var(--darkgrey)"
								value={commessa.descrizione_attivita}
								style={{
									marginTop: "20px",
								}}
								onChange={(value) => {
									commessa.descrizione_attivita = value;
									setCommessa({...commessa});
								}}
							/>
						</Col>
					</Row>
					<div
						style={{
							marginBottom: "20px",
						}}
					></div>
					<Row>
						<Col xs={12}>
							<Text size="small" weight={"medium"} color="var(--darkgrey)">
								DOCUMENTI
							</Text>
						</Col>
					</Row>

					<AddDocument
						list={[
							{
								id: 0,
								document: "",
							},
						]}
					/>
					<div
						style={{
							marginBottom: "50px",
						}}
					></div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Container fluid>
					<Row className="d-flex justify-content-between">
						<Col
							xs={{ span: 12, order: 2 }}
							sm={{ span: 12, order: 2 }}
							md={{ span: 3, order: 1 }}
						>
							{
								commessa._id ?
								<Button
									variant="outline-secondary"
									onClick={() => executeDeleteCommessa()}
									style={{
										marginRight: "16px",
									}}
								>
									<DeleteOutlineOutlinedIcon
										className="align-baseline"
										style={{
											color: "var(--grey-2)",
											marginRight: "6px",
										}}
									/>
									<Text>Elimina</Text>
								</Button>
								:
								""
							}
						</Col>
						<Col
							xs={{ span: 12, order: 1 }}
							sm={{ span: 12, order: 1 }}
							md={{ span: 9, order: 2 }}
							className="d-flex ctm-sm-buttons"
						>
							<Button
								variant="secondary"
								onClick={() => props.onCancel ? props.onCancel() : null}
								style={{
									marginRight: "16px",
								}}
							>
								ANNULLA
							</Button>
							<Button variant="success" onClick={() => saveCommessa()}>
								{commessa._id ? "SALVA ATTIVITA'" : "CREA ATTIVITA'"}
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};
