import React, { useState } from "react";
import styled from "styled-components";
import { EditorCell } from "../cells/editorCell";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--white);
        border-bottom: 1px solid var(--grey-1);
        padding-left: 8px;
        padding-right: 8px;
    }


    & > div {
        width: 25%;
        max-width: 25%;
    }

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowTest = (props) => {

    const [data, setData] = useState(props.data);

    const onChange = (id, value, isValid) =>{

        if (isValid) {
            const obj = data;

            obj[id] = value;

            setData(obj);

            if (props.onValidValueChange) {

                props.onValidValueChange(obj, props.rowKey);

            }

        }

    };

  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >
            <EditorCell
                key={"data1"}
                keyCell={"data1"}
                data={data.data1}
                type="text"
                onChange={onChange}
                required
            />

            <EditorCell
                key={"data2"}
                keyCell={"data2"}
                data={data.data2}
                type="text"
                onChange={onChange}
                required
            />

            <EditorCell
                key={"data3"}
                keyCell={"data3"}
                data={data.data3}
                type="text"
                onChange={onChange}
                required
            />

            <EditorCell
                key={"data4"}
                keyCell={"data4"}
                data={data.data4}
                type="text"
                onChange={onChange}
                required
            />

        </Wrapper>
  );
};
