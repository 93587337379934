import { Add as AddIcon } from "@material-ui/icons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Text, TextField, FilePondField } from "../../..";
import { postUploadFile } from "../../../../services";
import { setToast } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from 'react-dom'

const AddDocument = (props) => {

	const isBlock = props.block;

	const list = props.list && props.list.length > 0 ?
					props.list 
				: 
					[
						{}	
					];

	const addEmptyItem = () => {
		if(!list.find((item) => !item._id)) {
			const listUpdated = list.concat({});
			
			if(props.onChange) {
				props.onChange(props.id, listUpdated)
			}
		}
	};

	const removeItem = (_id) => {
		const listUpdated = list.filter((item) => item._id !== _id);
		if(props.onChange) {
			props.onChange(props.id, listUpdated)
		}
	};
	

	return (
		<div>
			{list.map((item, item_index) => (
				<Row className="mb-3" key={item._id}>
					<Col xs={10}>
						<FilePondField
							block={isBlock}
							files={
								item._id ?
								[
									{
										"source": 'campramarmi/' + item._id,
										"options": {
											"type": 'local',
											"file": {
												"name": item.filename,
                    							"type": 'image/png',
											}
										}
									}
								]
								  :
								null
							}
							allowMultiple={false}
							maxFiles={1}
							id={item._id}
							onSuccess={(result) => {
								const listUpdated = list.map((actual_item, actual_index) => actual_index == item_index ? result : actual_item)
								if(props.onChange) {
									props.onChange(props.id, listUpdated);
								}
							}}
							onDelete={() => {
								const listUpdated = list.filter((actual_item, actual_index) => actual_index != item_index)
								if(props.onChange) {
									props.onChange(props.id, listUpdated);
								}
							}}
							style={{
								marginBottom: "0px"
							}}
						/>
					</Col>
					<Col
						xs={2}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						className="mt-sm-2 mt-md-0"
					>
						<Stack
							direction="horizontal"
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-evenly",
								alignItems: "center"
							}}
						>
							<a
								href={item.file_url}
								target="download"
								style={{
									color: "initial"
								}}
							>
								<FileDownloadOutlinedIcon
									style={{
										width: "28px",
										height: "28px",
										cursor: "pointer"
									}}
								/>
							</a>
							{
								item_index > 0 ?
									<DeleteIcon
										onClick={() => removeItem(item._id)}
										style={{
											width: "28px",
											height: "28px",
											cursor: "pointer"
										}}
									/>
								:
									""
							}
						</Stack>
					</Col>
				</Row>
			))}
			{
				props.multiField?
				(
					<div
						onClick={() => addEmptyItem()}
					>
						<AddIcon
							style={{
								color: "var(--grey-2)",
								width: "20px",
								height: "20px",
								marginRight: "6px",
								cursor: "pointer"
							}}
						/>
						<Text
							size="large"
							weight="medium"
							color="var(--grey-2)"
							style={{
								cursor: "pointer"
							}}
						>
							Aggiungi documento
						</Text>
					</div>
				)
				:
				""
			}
		</div>
	);
};

export default AddDocument;
