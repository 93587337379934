import {
	ANAGRAFICHE,
	ANAGRAFICHE_DETAIL,
	ANAGRAFICHE_TYPE,
	CREATE_CLIENT,
	DELETE_CLIENT,
	CREATE_OPERATOR,
	UPDATE_CLIENT,
	SEND_CLIENT_CREDENTIALS,
	SEND_OPERATOR_CREDENTIALS,
	ANAGRAFICHE_FILTERED
} from "./constants";
import { get, post, del, put } from "./service";

export const getClients = async () => {
	return get(ANAGRAFICHE());
};

export const getClientsFiltered = async (cd_privilege, page_number, elements_count, filters) => {
	return post(ANAGRAFICHE_FILTERED(cd_privilege, page_number, elements_count), filters);
};

export const getClientsByType = async (cd_privilege) => {
	return get(ANAGRAFICHE_TYPE(cd_privilege));
};

export const getClientDetails = async (id) => {
	return get(ANAGRAFICHE_DETAIL(id));
};

export const createClient = async (client) => {
	return post(CREATE_CLIENT(), client);
};

export const createOperator = async (operator) => {
	return post(CREATE_OPERATOR(), operator);
};

export const deleteClient = async (id) => {
	return del(DELETE_CLIENT(id));
};

export const updateClient = async (id, client) => {
	return put(UPDATE_CLIENT(id), client);
};

export const sendClientCredentials = async (credentials) => {
	return post(SEND_CLIENT_CREDENTIALS(), credentials);
};

export const sendOperatorCredentials = async (body) => {
	return post(SEND_OPERATOR_CREDENTIALS(), body);
};