import React from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Text, TextField } from "../..";

const ConfirmAlertModal = withTranslation()((props) => {

  const { t } = props;

	return (
		<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
	  dialogClassName="modal-footer-no-border-top modal-padding"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" style={{padding : "0px 16px 0px 16px"}}>
          {props.headerTitle ? t(props.headerTitle) : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.mainTitle ? t(props.mainTitle) : ""}</h4>
        <p>
          {props.subtitle ? t(props.subtitle) : ""} 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>
          ANNULLA
        </Button>
        <Button variant="primary" onClick={props.onSuccess}>
            {props.actionButtonTitle ? t(props.actionButtonTitle) : "ELIMINA"}
        </Button>
      </Modal.Footer>
    </Modal>
	);
});

ConfirmAlertModal.defaultProps = {
	onSuccess: () => {},
	onCancel: () => {},
};

export default ConfirmAlertModal;
