import classNames from "classnames";
import React, { Fragment, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import styled from "styled-components";
import { Text } from "../";

const Container = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;

`;

const ContainerLink = styled(Link)`
  
  width: 100%;

  background: transparent;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 12px;
  padding-left: 40px;
  padding-right: 24px;
  padding-bottom: 12px;

  text-decoration: none;

  &.selected, &:hover {
      cursor: pointer;
      background-color: var(--darkgrey);

      & > * {
        color: var(--white) !important;
      }

      & > * {
        cursor: pointer;
      }

  }

  svg {
    color: var(--grey-2);
  }

`;

export const MenuItem = (props) => {

    const t = props.t;

    const item = props.item;

    const Icon = item.img;

  return (
    <Container
        style={props.style}
    >
      <ContainerLink
        className={classNames({
          "selected": props.selected
        })}
        to={item.link}
        onClick={() => {
          props.onMenuItemSelected();
        }}
      >
        <Icon
          style={{
              marginRight: "16px"
          }}
        />
        <Text
          size="xlarge"
          weigth="medium"
          className={classNames("menu-text")}
          color="var(--grey-2)"
        >
          {item.text}
        </Text>
      </ContainerLink>
    </Container>
  );
};