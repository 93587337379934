import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import 'moment/locale/it';
import { Text, RowCommesse, Table, ProgressBar } from "../index";
import { PUNTEGGI_LAVORAZIONI_STATUS, OBIETTIVO_LAVORAZIONE_GIORNALIERA } from "../../utils/constants";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

`;


const COLUMNS = [
    {
        key: "data1",
        text: "",
        isOrderable: true,
        width: "3%",
      },
    {
      key: "data1",
      text: "PRI.",
      isOrderable: true,
      width: "5%",
    },
    {
      key: "data2",
      text: "CLIENTE/RIFERIMENTO",
      isOrderable: true,
      width: "10%",
    },
    {
      key: "data3",
      text: "MATERIALE/SP",
      width: "10%",
    },
    {
      key: "data4",
      text: "COMMENTI",
      isOrderable: true,
      width: "10%",
    },
    {
        key: "data1",
        text: "COMMENTI TAGLIO",
        isOrderable: true,
        width: "10%",
      },
      {
        key: "data2",
        text: "N.",
        isOrderable: true,
        width: "4%",
      },
      {
        key: "data3",
        text: "DWG",
        width: "3%",
      },
      {
        key: "data4",
        text: "LAVORAZIONE",
        isOrderable: true,
        width: "25%",
      },
      {
        key: "data4",
        text: "CONTROLLO",
        isOrderable: true,
        width: "10%",
      },
      {
        key: "data4",
        text: "CNC 4.0",
        isOrderable: true,
        width: "10%",
      },
  ];

  



export const BlockCommesse = withTranslation()((props) => {

    const { t } = props;

    const data = props.data;
    let greenBarProgress = 0;
    let maxGreenBarProgress = 0;
    let punteggioMassimo = 0;
    let progressDot = 0;

    useEffect(() => {
      moment.locale("it");
    }, [])


    const calculateBars = () => {

      if(data.list) {

        data.list.forEach((commessa, index) => {

          const punteggio = parseFloat(getSumPunteggiLavorazione(commessa, false));

          if(punteggioMassimo < punteggio) {
            punteggioMassimo = punteggio;
          }

          greenBarProgress += parseFloat(getSumPunteggiLavorazione(commessa, true));
          maxGreenBarProgress += punteggio;

        });

        data.list.forEach((commessa, index) => {
          const punteggio = parseFloat(getSumPunteggiLavorazione(commessa, false));
          commessa.bar_width = (punteggio / punteggioMassimo) * 100;
        });

      }

      progressDot = 100 - (OBIETTIVO_LAVORAZIONE_GIORNALIERA / maxGreenBarProgress) * 100;

    }

    const getSumPunteggiLavorazione = (commessa, only_green) => {
      let sum = 0;

      Object.keys(commessa.punteggi_lavorazioni).forEach(key => {
          if (only_green && commessa.punteggi_lavorazioni[key].status == PUNTEGGI_LAVORAZIONI_STATUS.GREEN) {
            sum += parseFloat(commessa.punteggi_lavorazioni[key].initial_value);
          } else if(!only_green) {
            sum += parseFloat(commessa.punteggi_lavorazioni[key].initial_value);
          }
      });

      return sum.toFixed(2);
    }

    calculateBars();

  return (
        <Wrapper
          className={props.className}
          key={props.keyCell}
          keyCell={props.keyCell}
          style={props.style}
        >
            
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Text
                    size="xxlarge"
                    weight="medium"
                >
                    {
                      t(
                        "blockCommesse.title",
                        {
                          weekDay: moment(data.dt_lavorazione).format("dddd"),
                          date: moment(data.dt_lavorazione).format("DD/MM/YYYY")
                        }
                      )
                    }
                </Text>
                {
                  maxGreenBarProgress > 0 ?
                    <ProgressBar
                      now={100 - ((greenBarProgress * 100) / maxGreenBarProgress)}
                      dotPercentage={progressDot < 0 ? 0 : progressDot}
                      className={"large"}
                      color="var(--green)"
                      label={`${greenBarProgress.toFixed(2)}/${maxGreenBarProgress.toFixed(2)} | L: ${OBIETTIVO_LAVORAZIONE_GIORNALIERA}`}
                      style={{
                          maxWidth: "35%",
                          width: "35%",
                          display: "flex",
                          flexDirection: "column-reverse",
                          alignItems: "end"
                      }}
                    />
                  :
                    ""
                }
            </div>
            <Card
                style={{
                    width: "100%",
                    marginTop: "20px",
                    padding: "32px 20px 32px 20px"
                }}
            >
                <Table
                  className={"scroll-1330"}
                  classNameHeader={"min-width-1192"}
                  columns={COLUMNS}
                  data={data.list}
                  aziende={props.aziende}
                  onChange={(data, index) => {
                    if(props.onChange) {
                      props.onChange(data);
                    }
                  }}
                  onDelete={(data, index) => {
                    if(props.onDelete) {
                      props.onDelete(data);
                    }
                  }}
                  rowComponent={RowCommesse}
                />
            </Card>

        </Wrapper>
  );
});
