import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
	BlockFilterAnagrafiche,
	Breadcrumb,
	Card,
	RowAnagrafiche,
	Table,
	ConfirmAlertModal,
	Loader,
	InfiniteScroll
} from "../../components";
import { ANAGRAFICHE } from "../../navigation/constants";
import { getClientsFiltered, deleteClient } from "../../services";
import {
	AGENTI,
	GRUPPI_ANAGRAFICHE,
	USER_ROLES,
	ZONE,
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux";
import { useDebounce } from "../../hooks";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;

const DEFAULTS = {
	TYPOLOGIES: "Tutte le tipologie",
	GROUPS: "Tutti i gruppi",
	AGENTS: "Tutti gli agenti",
	SHOP_ZONE: "Tutte le zone neg.",
	DELIVERY_ZONE: "Tutte le zone cons.",
	SEARCH: "",
};

const COLUMNS = [
	{
		key: "data1",
		text: "anagrafiche.table.column1",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data2",
		text: "anagrafiche.table.column2",
		isOrderable: true,
		width: "25%",
	},
	{
		key: "data3",
		text: "anagrafiche.table.column3",
		width: "25%",
	},
	{
		key: "data4",
		text: "anagrafiche.table.column4",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data1",
		text: "anagrafiche.table.column5",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data2",
		text: "anagrafiche.table.column6",
		isOrderable: true,
		width: "20%",
	},
	{
		key: "data3",
		text: "anagrafiche.table.column7",
		width: "5%",
	}
];

export function Anagrafiche() {
	const [isLoading, setIsLoading] = useState(true);
	const [anagraficheList, setAnagraficheList] = useState([]);

	const [pageNumber, setPageNumber] = useState(0);
	const ELEMENTS_COUNT = 50;
	const [updateList, setUpdateList] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const debounce = useDebounce();

	const [typologies, setTypologies] = useState([{ text: "", value: "" }]);
	const [groups, setGroups] = useState([{ text: "", value: "" }]);
	const [agents, setAgents] = useState([{ text: "", value: "" }]);
	const [shopZones, setShopZones] = useState([{ text: "", value: "" }]);
	const [deliveryZones, setDeliveryZones] = useState([{ text: "", value: "" }]);
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);
	const [filters, setFilters] = useState({
		typology: "-",
		group: "-",
		agent: "-",
		shopZone: "-",
		deliveryZone: "-",
		search: "",
	});
	const [operator, setOperator] = useState();
	const toast = useSelector((state) => state.toast || {});
	const dispatch = useDispatch();

	// ====================================================================================
	// Init
	// ====================================================================================
	useEffect(() => {
		resetFilters();
	}, []);

	useEffect(() => {
		setPageNumber(0);
		executeGetAnagrafiche(0);
	}, [filters]);

	useEffect(() => {
		if	(updateList && hasMore) {
			executeGetAnagrafiche(pageNumber+1);
			setPageNumber(pageNumber+1);
		}
	}, [updateList])

	const resetFilters = () => {
		// Tipologie
		let newTypologies = [];
		newTypologies.push({ text: DEFAULTS.TYPOLOGIES, value: "-" });
		for (let enumValues in USER_ROLES) {
			let name = "";
			switch (USER_ROLES[enumValues]) {
				case "CMS-ACCESS-SUPERADMIN":
					name = "anagrafiche.admin";
					break;

				case "CMS-ACCESS-OPERATOR":
					name = "anagrafiche.operator";
					break;

				case "CMS-ACCESS-CLIENT":
					name = "anagrafiche.client";
					break;
			}

			newTypologies.push({
				text: name,
				value: USER_ROLES[enumValues]
			});
		}
		setTypologies(newTypologies);

		// Gruppi
		let newGroups = [];
		GRUPPI_ANAGRAFICHE.forEach((item) => {
			if (item.value === "-") {
				newGroups.push({
					text: DEFAULTS.GROUPS,
					value: item.value
				});
			} else {
				newGroups.push({
					text: item.text,
					value: item.value
				});
			}
		});
		setGroups(newGroups);

		// Agenti
		let newAgents = [];
		AGENTI.forEach((item) => {
			if (item.value === "-") {
				newAgents.push({
					text: DEFAULTS.AGENTS,
					value: item.value
				});
			} else {
				newAgents.push({
					text: item.text,
					value: item.value
				});
			}
		});
		setAgents(newAgents);

		// Zone negozio
		let shopZones = [];
		ZONE.forEach((item) => {
			if (item.value === "-") {
				shopZones.push({
					text: DEFAULTS.SHOP_ZONE,
					value: item.value
				});
			} else {
				shopZones.push({
					text: item.text,
					value: item.value
				});
			}
		});
		setShopZones(shopZones);

		// Zone consegna
		let newDeliveryZones = [];
		ZONE.forEach((item) => {
			if (item.value === "-") {
				newDeliveryZones.push({
					text: DEFAULTS.DELIVERY_ZONE,
					value: item.value
				});
			} else {
				newDeliveryZones.push({
					text: item.text,
					value: item.value
				});
			}
		});
		setDeliveryZones(newDeliveryZones);

		// filtri
		clearFilters();
	};

	const executeGetAnagrafiche = (_pageNumber = pageNumber) => {
		debounce(() => {
			setIsLoading(true);
			getClientsFiltered("", _pageNumber, ELEMENTS_COUNT, filters).then(({ result, error }) => {
				if (error) {
				} else {
					console.log(result)
					setHasMore(result.length == ELEMENTS_COUNT);

					if (_pageNumber > 0) {
						setAnagraficheList([...anagraficheList, ...result]);
					} else {
						setAnagraficheList([...result]);
					}
				}
				setUpdateList(false);
				setIsLoading(false);
			});
		});
	};

	// ====================================================================================
	// Filters
	// ====================================================================================
	const setFilter = (type, value) => {
		setFilters({ ...filters, [type]: value });
	};

	const clearFilters = () => {
		setFilters({
			typology: "-",
			group: "-",
			agent: "-",
			shopZone: "-",
			deliveryZone: "-",
			search: "",
		});
	};

	const deleteOperator = () => {
		if (operator) {
			deleteClient(operator).then(({ result, errors }) => {
				if (errors) {
					console.log(errors);
					setConfirmAlertModalShow(false)
					dispatch(
						setToast({
							show: true,
							title: "anagrafiche.toast.error.title",
							description: "anagrafiche.toast.error.description",
							variant: "danger",
						})
					);
					
				} else {
					setConfirmAlertModalShow(false)
					dispatch(
						setToast({
							show: true,
							title: "anagrafiche.toast.success.title",
							description: `anagrafiche.toast.success.description`,
							variant: "success",
						})
					);
					let newAnagrafiche = anagraficheList.filter(
						(item) => operator !== item._id
					);
					setAnagraficheList(newAnagrafiche);
					setOperator("")
				}
			});
		}
	};

	return (
		<Wrapper>
			<Row className="d-flex align-items-center">
				<Col md={8}>
					<Breadcrumb levels={[{ text: "anagrafiche.breadcrumbTitle", link: ANAGRAFICHE }]} />
				</Col>
			</Row>

			<BlockFilterAnagrafiche
				typologies={typologies}
				groups={groups}
				agents={agents}
				shopZones={shopZones}
				deliveryZones={deliveryZones}
				selected={filters}
				setFilter={setFilter}
				clearFilters={clearFilters}
				style={{
					marginTop: "32px",
				}}
				onCreateOperatorSuccess={executeGetAnagrafiche}
			/>

			<Card
				style={{
					marginTop: "32px",
					paddingLeft: "20px",
					paddingRight: "20px",
				}}
			>
				<Table
					infiniteScrollOptions={{
						enabled: true,
						onUpdate: () => {
							setUpdateList(true);
						},
						hasMore,
						isLoading,
						loaderContent: (
							<div
								style={
									{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										marginTop: "56px"
									}
								}
							>
								<Loader
									layer={3}
									color="var(--red)"
								/>
							</div>
						)
					}}
					className="scroll-1440"
					classNameHeader={"min-width-1318"}
					columns={COLUMNS}
					data={anagraficheList}
					rowComponent={RowAnagrafiche}
					onDelete={(data, index) => {
						setOperator(data)
						setConfirmAlertModalShow(true);
						
						// let newAnagrafiche = anagraficheList.filter(
						// 	(item) => data !== item._id
						// );
						// setAnagraficheList(newAnagrafiche);
					}}
					onChange={(data, index) => {
						anagraficheList[index] = data;
						setAnagraficheList([...anagraficheList]);
					}}
				/>
				<ConfirmAlertModal
					show={confirmAlertModalShow}
					headerTitle="Conferma operazione"
					mainTitle="Attenzione!"
					subtitle="Sei sicuro di voler cancellare questo utente?"
					onHide={() => setConfirmAlertModalShow(false)}
					onCancel={() => setConfirmAlertModalShow(false)}
					onSuccess={() =>
						deleteOperator()
					}
				/>
			</Card>
		</Wrapper>
	);
}
