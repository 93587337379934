import { Add as AddIcon } from "@material-ui/icons";
import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "../";
import { UtenzeCliente } from "./utenzeCliente";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const Div = styled.div`
	width: 190px;
	display: flex;
	align-items: center;

	&,
	& > * {
		cursor: pointer;
	}
`;

export const AddUtenzeCliente = (props) => {
	let list = props.list || [];
	let clientId = props.clientId || ""
	const [addItemHover, setAddItemHover] = useState(false);

	const addItem = () => {
		if (
			list.filter(
				(item) =>
					item.fullname === "" && item.telefono === "" && item.email === ""
			).length === 0
		) {
			list = list.concat([
				{
					fullname: "",
					telefono: "",
					email: "",
				},
			]);

			if (props.onListChange) {
				props.onListChange(list);
			}
		}
	};

	const updateItem = (index, newItem) => {
		list[index] = newItem;

		if (props.onListChange) {
			props.onListChange(list);
		}
	};

	const removeItem = (index) => {
		list = list.filter((item, item_index) => item_index != index);

		if (props.onListChange) {
			props.onListChange(list);
		}
	};

	return (
		<Wrapper style={props.style}>
			{list.map((el, index) => (
				<UtenzeCliente
					key={index}
					data={el}
					disabledSendMail={props.disabledSendMail}
					style={{
						marginTop: index > 0 ? "16px" : "0px",
					}}
					client={clientId}
					onChange={(newItem) => {
						updateItem(index, newItem);
					}}
					onDelete={(id) => {
						removeItem(index);
					}}
				/>
			))}
			<Div
				onClick={(e) => {
					addItem();
				}}
				style={{
					marginTop: "20px",
					userSelect: "none",
				}}
				onMouseEnter={() => setAddItemHover(true)}
				onMouseLeave={() => setAddItemHover(false)}
			>
				<AddIcon
					style={{
						color: addItemHover ? "var(--darkgrey)" : "var(--grey-2)",
						width: "20px",
						height: "20px",
						marginRight: "8px",
						marginBottom: "2px",
					}}
				/>
				<Text
					size="large"
					weight="medium"
					color={addItemHover ? "var(--darkgrey)" : "var(--grey-2)"}
					style={{
						textDecoration: addItemHover ? "underline" : "",
					}}
				>
					{"addUtenzeCliente.add"}
				</Text>
			</Div>
		</Wrapper>
	);
};
