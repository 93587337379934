import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BlockLavorazioniNote, Button, Text } from "../..";
import { updateCommessa } from "../../../services";
import { setToast } from "../../../redux";
import { useSelector } from "react-redux";
import { canEdit } from "../../../utils/functionsUtils";

const ProcessingNotesModal = (props) => {
	const user = useSelector((state) => state.user || {});
	const dispatch = useDispatch();
	const [commessa, setCommessa ] = useState(props.commessa);

	const saveCommessa = () => {
		
		updateCommessa(commessa._id, commessa).then(({ result, error }) => {
			if (error) {
				console.log(error);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "Fatto!",
						description: "Le note sono state aggiornate!",
						variant: "success",
					})
				);
				if(props.onSuccess) {
					props.onSuccess(commessa);
				}
				if(props.onCancel) {
					props.onCancel();
				}
			}
		});
		
	};

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Container>
					<Text size="xxlarge" weight="medium" color="var(--black)">
						Commessa {commessa.numero_commessa} / {moment(commessa.dt_creazione_commessa).format("YYYY")} - Note lavorazioni
					</Text>
					<Text
						color="var(--darkgrey)"
						style={{
							marginBottom: "10px",
						}}
					>
						Inserisci i dati nei seguenti campi per modificare le note
						lavorazioni della commessa
					</Text>

					<BlockLavorazioniNote
						commessa={commessa}
						onChange={(newCommessa) => {
							setCommessa({...newCommessa});
						}}
					/>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row
						style={{
							marginTop: "50px",
						}}
					>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={() => props.onCancel()}>
								<Text size="small" weight="medium">
									ANNULLA
								</Text>
							</Button>
							{canEdit(user) ? (
								<Button variant="success" onClick={saveCommessa}>
									<Text size="small" weight="medium">
										SALVA MODIFICHE
									</Text>
								</Button>
								) : (
									<div></div>
								)
							}
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};

export default ProcessingNotesModal;
