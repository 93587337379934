import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
	BlockFilterSpedizioni,
	Breadcrumb,
	Button,
	Card,
	CommentListModal,
	RowSpedizioni,
	Spacer,
	Table,
	Text,
} from "../../components";
import { COMMESSE, LISTINI_MATERIALI, SPEDIZIONI } from "../../navigation/constants";
import { getOrdini } from "../../services";
import { COMMESSA_STATUS, COMMESSA_TYPES } from "../../utils/constants";
import "./listini_materiali.css";
import { getMaterialiByListinoId, getListini } from "../../services";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;

const COLUMNS = [
	{
		key: "data1",
		text: "",
		isOrderable: true,
		width: "3%",
	},
	{
		key: "data2",
		text: "PRI.",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data2",
		text: "H",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data3",
		text: "ZONA",
		isOrderable: true,
		width: "7%",
	},
	{
		key: "data4",
		text: "CLIENTE / RIFERIMENTO",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data4",
		text: "MATERIALE / SP",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data1",
		text: "COMMENTI",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data2",
		text: "COMMENTI CONSEGNA",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data3",
		text: "COMMENTI CONSEGNA CLIENTE",
		width: "10%",
	},
	{
		key: "data3",
		text: "NOTE ACCESSORI",
		width: "10%",
	},
	{
		key: "data3",
		text: "N.",
		width: "5%",
	},
	{
		key: "data3",
		text: "DWG",
		width: "5%",
	},
	{
		key: "data3",
		text: "CONTROLLO",
		width: "10%",
	},
];

const DEFAULTS = {
	prenotazione: false,
	preordine: true,
	in_attesa: true,
	ordine: true,
	finito: true,
	anticipo: false,
	gold: false,
	attivita: true,
	search: "",
	priorita: "-",
	zona: "-",
	da: "",
	al: "",
};

export function ListiniMateriali() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [materiali, setMateriali] = useState([]);
	const [listini, setListini] = useState([]);
	const [listinoSelected, setListinoSelected] = useState({});
	
	const [showModal, setShowModal] = useState({ show: false, value: "" });
	const navigate = useNavigate();
	const toast = useSelector((state) => state.toast || {});

	const [filters, setFilters] = useState({
		...DEFAULTS,
	});

	useEffect(() => {
		executeGetListini();
	}, []);

	useEffect(() => {
		executeGetMaterialiByListino();
	}, [listinoSelected]);

	const executeGetListini = () => {
		getListini().then(({ result, error }) => {
			if (error) {
				console.error(error);
				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description:
							"Non è stato possibile recuperare i materiali dal server.",
						variant: "danger",
					})
				);
			} else {
				setListini(result);
			}
		});
	}

	const executeGetMaterialiByListino = () => {
		if(listinoSelected._id) {
			getMaterialiByListinoId(listinoSelected._id).then(({ result, error }) => {
				if (error) {
					console.error(error);
					dispatch(
						setToast({
							show: true,
							title: "Errore",
							description:
								"Non è stato possibile recuperare i materiali dal server.",
							variant: "danger",
						})
					);
				} else {
					setMateriali(result);
				}

				setIsLoading(false);
			});
		}
	};


	return (
		<Wrapper>
			<CommentListModal
				show={showModal.show}
				onHide={() => setShowModal({ ...showModal, show: false })}
				onCancel={() => setShowModal({ ...showModal, show: false })}
				value={showModal.value}
				onChange={(text) => setShowModal({ ...showModal, value: text })}
				onSuccess={() => {}}
			/>

			<Row className="d-flex align-items-center">
				<Col md={8}>
					<Breadcrumb
						levels={[
							{
								text: "Listini Materiali",
								link: LISTINI_MATERIALI,
							},
						]}
					/>
				</Col>
				<Col md={4} className="mt-3 mt-md-0 d-flex justify-content-md-end">
					<Button
						variant="primary"
						onClick={() => {
							
						}}
					>
						<Text size="small" weight="medium">
							Importa dati listino
						</Text>
						<ArrowForwardIcon
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Button>
				</Col>
			</Row>

			{
				isLoading ?
					""
				:
				<Card
					style={{
						marginTop: "20px",
						padding: "32px 20px 32px 20px",
					}}
				>
					<Table
						className="scroll-1440"
						classNameHeader={"min-width-1301"}
						columns={COLUMNS}
						data={materiali || []}
					/>
				</Card> 
			}
		</Wrapper>
	);
}
