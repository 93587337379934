import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
	BlockFilterSpedizioni,
	Breadcrumb,
	Button,
	Card,
	CommentListModal,
	RowSpedizioni,
	Spacer,
	Table,
	Text,
} from "../../components";
import { COMMESSE, SPEDIZIONI } from "../../navigation/constants";
import { getOrdini } from "../../services";
import { COMMESSA_STATUS, COMMESSA_TYPES } from "../../utils/constants";
import "./listini_lavorazioni.css";
import { getSettings, setSettings } from "../../services";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;

const COLUMNS = [
	{
		key: "data1",
		text: "",
		isOrderable: true,
		width: "3%",
	},
	{
		key: "data2",
		text: "PRI.",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data2",
		text: "H",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data3",
		text: "ZONA",
		isOrderable: true,
		width: "7%",
	},
	{
		key: "data4",
		text: "CLIENTE / RIFERIMENTO",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data4",
		text: "MATERIALE / SP",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data1",
		text: "COMMENTI",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data2",
		text: "COMMENTI CONSEGNA",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data3",
		text: "COMMENTI CONSEGNA CLIENTE",
		width: "10%",
	},
	{
		key: "data3",
		text: "NOTE ACCESSORI",
		width: "10%",
	},
	{
		key: "data3",
		text: "N.",
		width: "5%",
	},
	{
		key: "data3",
		text: "DWG",
		width: "5%",
	},
	{
		key: "data3",
		text: "CONTROLLO",
		width: "10%",
	},
];

const DEFAULTS = {
	prenotazione: false,
	preordine: true,
	in_attesa: true,
	ordine: true,
	finito: true,
	anticipo: false,
	gold: false,
	attivita: true,
	search: "",
	priorita: "-",
	zona: "-",
	da: "",
	al: "",
};

export function ListiniLavorazioni() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [original, setOriginal] = useState({});
	const [spedizioniList, setSpedizioniList] = useState({});
	const [showModal, setShowModal] = useState({ show: false, value: "" });
	const [localSettings, setLocalSettings] = useState({});
	const navigate = useNavigate();
	const toast = useSelector((state) => state.toast || {});

	const [filters, setFilters] = useState({
		...DEFAULTS,
	});

	useEffect(() => {
		executeGetCommesse();

		getSettings().then(({ result, error }) => {
			if (error) {
				console.error(error);
			} else {
				const settings = result && result.length > 0 ? result[0] : {};
				setLocalSettings(settings);
				setShowModal({ ...showModal, value: settings.commento_spedizioni });
			}
		});
	}, []);

	const executeGetCommesse = () => {
		getOrdini().then(({ result, error }) => {
			if (error) {
				console.error(error);
				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description:
							"Non è stato possibile recuperare le spedizioni dal server.",
						variant: "danger",
					})
				);
			} else {
				let sorted = sortDeliveries(result) || {};
				sorted = groupByDay(sorted);

				setOriginal(sorted);
				setSpedizioniList(sorted);
				setFilters({ ...DEFAULTS });
			}

			setIsLoading(false);
		});
	};

	// ============================================================================================
	// Filters
	// ============================================================================================
	useEffect(() => {
		let spedizioni = {};
		let today = moment();

		Object.keys(original).forEach((day) => {
			spedizioni[day] = original[day].filter((spedizione) => {
				console.log(spedizione);
				console.log(
					!filters.attivita && spedizione.cd_type !== COMMESSA_TYPES.ATTIVITA
				);
				return (
					(filters.attivita ||
						spedizione.cd_type !== COMMESSA_TYPES.ATTIVITA) &&
					((filters.prenotazione &&
						spedizione.cd_status == COMMESSA_STATUS.PRENOTAZIONE) ||
						(filters.preordine &&
							spedizione.cd_status == COMMESSA_STATUS.PREORDINE) ||
						(filters.in_attesa &&
							spedizione.cd_status == COMMESSA_STATUS.IN_ATTESA) ||
						(filters.ordine && spedizione.cd_type === COMMESSA_TYPES.ORDINE) ||
						(filters.finito && spedizione.cd_status != COMMESSA_TYPES.FINITO) ||
						(filters.anticipo && moment(spedizione.dt_consegna) >= today) ||
						(filters.gold && spedizione.is_gold) ||
						spedizione.cd_status == COMMESSA_STATUS.CONFERMATO) &&
					(filters.priorita === "-" ||
						spedizione.priority === filters.priorita) &&
					(filters.zona === "-" ||
						(spedizione.cliente &&
							spedizione.cliente.zona_consegna === filters.zona)) &&
					(filters.search === "" ||
						includesText(spedizione, filters.search.trim())) &&
					(filters.da === "" ||
						moment(spedizione.dt_consegna) >=
							moment(filters.da).startOf("day")) &&
					(filters.al === "" ||
						moment(spedizione.dt_consegna) <= moment(filters.al).endOf("day"))
				);
			});
		});

		setSpedizioniList(spedizioni);
		console.log(spedizioni);
	}, [filters]);

	/**
	 * Ritorna true se include il testo text, altrimenti false
	 */
	const includesText = (rowData, text) => {
		if (text === "") return true;

		let value = new RegExp(text, "ig");

		return (
			(rowData.priority !== "" && value.test(rowData.priority)) ||
			(rowData.dt_consegna !== "" &&
				value.test(moment(rowData.dt_consegna).format("HH:mm"))) ||
			(rowData.cliente &&
				rowData.cliente.zona !== "" &&
				value.test(rowData.cliente.zona)) ||
			(rowData.cliente &&
				rowData.cliente.ragione_sociale !== "" &&
				value.test(rowData.cliente.ragione_sociale)) ||
			(rowData.cliente &&
				rowData.cliente.agente !== "" &&
				value.test(rowData.cliente.agente)) ||
			(rowData.materiali &&
				rowData.materiali.length > 0 &&
				rowData.materiali[0].materiale &&
				rowData.materiali[0].materiale.nome !== "" &&
				value.test(rowData.materiali[0].materiale.nome)) ||
			(rowData.note_consegna !== "" && value.test(rowData.note_consegna)) ||
			(rowData.cliente &&
				rowData.cliente.note_consegna !== "" &&
				value.test(rowData.cliente.note_consegna)) ||
			(rowData.commento !== "" && value.test(rowData.commento)) ||
			(rowData.numero_commessa !== "" && value.test(rowData.numero_commessa))
		);
	};

	const clearFilters = () => {
		setSpedizioniList(original);
		setFilters({
			...DEFAULTS,
		});
	};

	// ============================================================================================
	// Sort
	// ============================================================================================
	const sortDeliveries = (list) => {
		const preordini = [];
		const inAttesa = [];
		const ordini = [];
		const finiti = [];
		const attivita = [];
		const altro = [];

		for (let spedizione of list) {
			switch (spedizione.cd_status) {
				case COMMESSA_TYPES.PREORDINE:
					preordini.push(spedizione);
					break;

				case COMMESSA_TYPES.IN_ATTESA:
					inAttesa.push(spedizione);
					break;

				case COMMESSA_TYPES.ORDINE:
					ordini.push(spedizione);
					break;

				case COMMESSA_TYPES.FINITO:
					finiti.push(spedizione);
					break;

				case COMMESSA_TYPES.ATTIVITA:
					attivita.push(spedizione);
					break;

				default:
					altro.push(spedizione);
			}
		}

		const sorted = [];

		preordini.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		inAttesa.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		ordini.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		finiti.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		attivita.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		altro.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));

		sorted.pushAll(preordini);
		sorted.pushAll(inAttesa);
		sorted.pushAll(ordini);
		sorted.pushAll(finiti);
		sorted.pushAll(attivita);
		sorted.pushAll(altro);

		return sorted;
	};

	/**
	 * Raggruppa per giorni in formato
	 * {
	 * 	"giorno1": [item1_giorno1, item2_giorno1],
	 * 	"giorno2": [item1_giorno2, item2_giorno2]
	 * }
	 */
	const groupByDay = (list) => {
		const byDay = {};

		for (let item of list) {
			let day = moment(item.dt_consegna).format("DD/MM/YYYY");
			if (byDay[day]) {
				byDay[day].push(item);
			} else {
				byDay[day] = [];
				byDay[day].push(item);
			}
		}

		return byDay;
	};

	// ==============================================================================
	// Modal
	// ==============================================================================
	const saveComments = () => {
		setSettings(localSettings._id, {
			...localSettings,
			commento_spedizioni: showModal.value,
		}).then(({ result, error }) => {
			if (error) {
				console.error(error);
				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description: "I commenti non sono stati aggiornati.",
						variant: "danger",
					})
				);
			} else {
				setLocalSettings({
					...localSettings,
					commento_spedizioni: showModal.value,
				});
				dispatch(
					setToast({
						show: true,
						title: "Aggiornato!",
						description: "I commenti sono stati aggiornati!",
						variant: "success",
					})
				);
				setShowModal({ ...showModal, show: false });
			}
		});
	};

	return (
		<Wrapper>
			<CommentListModal
				show={showModal.show}
				onHide={() => setShowModal({ ...showModal, show: false })}
				onCancel={() => setShowModal({ ...showModal, show: false })}
				value={showModal.value}
				onChange={(text) => setShowModal({ ...showModal, value: text })}
				onSuccess={saveComments}
			/>

			<Row className="d-flex align-items-center">
				<Col md={8}>
					<Breadcrumb
						levels={[
							{
								text: "Spedizioni",
								link: SPEDIZIONI,
							},
						]}
					/>
				</Col>
				<Col md={4} className="mt-3 mt-md-0 d-flex justify-content-md-end">
					<Button
						variant="secondary"
						onClick={() => {
							navigate(COMMESSE);
						}}
					>
						<Text size="small" weight="medium">
							VAI ALLE COMMESSE
						</Text>
						<ArrowForwardIcon
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Button>
				</Col>
			</Row>

			<BlockFilterSpedizioni
				style={{
					marginTop: "32px",
				}}
				onCommentListOpen={() => setShowModal({ ...showModal, show: true })}
				filters={filters}
				setFilters={setFilters}
				clearFilters={clearFilters}
			/>

			{isLoading
				? ""
				: Object.keys(spedizioniList).map((day) => {
						if (spedizioniList[day].length > 0) {
							return (
								<>
									<Spacer height="36px" />
									<Text size="xxlarge" weight="medium">
										Consegna {day}
									</Text>
									<Card
										style={{
											marginTop: "20px",
											padding: "32px 20px 32px 20px",
										}}
									>
										<Table
											className="scroll-1440"
											classNameHeader={"min-width-1301"}
											columns={COLUMNS}
											data={spedizioniList[day] || []}
											rowComponent={RowSpedizioni}
										/>
									</Card>
								</>
							);
						} else {
							return "";
						}
				  })}
			<Spacer height="120px" />
		</Wrapper>
	);
}
