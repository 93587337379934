import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 100%;

  background: var(--white);

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.centerChildren ? "center" : "")};

  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;

  border: 1px solid var(--grey-1);

  border-radius: 6px;
`;

export const Card = (props) => {
  return (
    <Container className={props.className} centerChildren={props.centerChildren} onClick={props.onClick} style={props.style}>
      {props.children}
    </Container>
  );
};
