import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Add as AddIcon } from "@material-ui/icons";
import { Text, TextField, SelectField, Button, Table, RowCommesseLavorazioni, HeaderCellCommesse, RowCommessePunteggi } from "../index";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: column;
    }

`;


const Footer = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        background-color: var(--grey-field);

        border-left: 1px solid var(--grey-1);
        border-right: 1px solid var(--grey-1);
        border-bottom: 1px solid var(--grey-1);

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        padding: 12px 20px 12px 20px;
        
    }

`;


const COLUMNS = [
  {
      key: "tgM",
      text: "tableCommessePunteggi.column1",
      width: "10%",
    },
  {
    key: "data1",
    text: "tableCommessePunteggi.column2",
    width: "10%",
  },
  {
    key: "data2",
    text: "tableCommessePunteggi.column3",
    width: "10%",
  },
  {
    key: "data3",
    text: "tableCommessePunteggi.column4",
    width: "10%",
  },
  {
    key: "data4",
    text: "tableCommessePunteggi.column5",
    width: "10%",
  },
  {
      key: "data1",
      text: "tableCommessePunteggi.column6",
      width: "10%",
    },
    {
      key: "data2",
      text: "tableCommessePunteggi.column7",
      width: "10%",
    },
    {
      key: "data3",
      text: "tableCommessePunteggi.column8",
      width: "10%",
    },
    {
      key: "data3",
      text: "tableCommessePunteggi.column9",
      width: "10%",
    },
    {
      key: "data3",
      text: "tableCommessePunteggi.column10",
      width: "10%",
    }
];

  

let idCounter = 0;

export const TableCommessePunteggi = (props) => {

    const [tableData, setTableData] = useState(props.tableData);
    const [fields, setFields] = useState(props.fields);

    useEffect(() => {

      if(props.tableData) {
        const dataUpdated = props.tableData.map((item) => {
          if(item._id) {
            return item;
          } else {
            idCounter++;
            return {...item, _id: idCounter};
          }
        })
        setTableData(dataUpdated);
      }

    }, [props.tableData]);

    const checkFieldValue = () => {
      let valid = true;

      fields.forEach((item, index) => {
        if(item.value == "") {
          valid = false;
        }
      });

      return valid;
    }

    const updateField = (itemToUpdate) => {

      const fieldsUpdated = fields.map((item, index) => (
        item.id === itemToUpdate.id ? itemToUpdate : item
      ));

      setFields(fieldsUpdated);

    }


    const addRowItem = () => {

      let itemToAdd = {};
      idCounter++;

      fields.forEach((item, index) => {
        itemToAdd[item.id] = item.value;
      });

      itemToAdd._id = idCounter;

      const dataUpdated = tableData.concat([itemToAdd]);

      setTableData(dataUpdated);

      const fieldsUpdated = fields.map((item, index) => (
        {...item, value: ""}
      ));

      setFields(fieldsUpdated);

    }

    const deleteRowItem = (_id) => {

      idCounter++;

      const dataUpdated = tableData.filter((item, index) => item._id !== _id);

      setTableData(dataUpdated);

    }

  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >
            
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <Text
                    size="medium"
                    weight="medium"
                    color="var(--red)"
                    style={{
                      marginRight: "12px"
                    }}
                >
                    {props.title}
                </Text>
                <Text
                    size="xsmall"
                    weight="regular"
                    color="var(--grey-2)"
                >
                    {"tableCommessePunteggi.description"}
                </Text>
            </div>
            
            
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px"
              }}
            >
              <Table
                columns={COLUMNS}
                headerCellComponent={HeaderCellCommesse}
                data={tableData}
                hasborder
                rowComponent={RowCommessePunteggi}
                onDelete={(item) => {
                  deleteRowItem(item._id);
                }}
              />
              <Footer>
                <Text
                  size="xsmall"
                  weight="medium"
                  color={"var(--darkgrey)"}
                >
                  {`tableCommessePunteggi.totalPoint`}
                </Text>
              </Footer>
            </div>
            

        </Wrapper>
  );
};
