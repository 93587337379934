import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

const CheckboxWrapperCustom = styled(Form.Check)`
	cursor: pointer;
	label {
		font-family: Roboto, sans-serif;
		font-size: 16px;
		font-weight: 400;
		cursor: pointer;
		user-select: none;
		color: ${(props) => (props.labelColor ? props.labelColor : "var(--black)")};
	}

	input {
		${(props) => (props.label ? "" : "margin-top: 0px;")}
		box-shadow: none !important;
		cursor: pointer;
		${(props) =>
			props.large
				? `
          width: 24px;
          height: 24px;
        `
				: ""}
	}

	input[type="checkbox"] {
		border-radius: ${(props) => (props.isCircular ? "50%" : "0px")} !important;
	}

	input[type="radio"] {
		cursor: pointer;
		border: 1px solid var(--grey-2);
	}

	input[type="radio"]:checked {
		background-color: var(--white);
	}

	input:focus,
	input:checked {
		border-color: ${(props) =>
			props.color ? props.color : "var(--black)"} !important;
	}

	input[type="radio"]:before {
		content: "";
		display: block;
		width: 8px;
		height: 8px;
		margin: 3px;
		border-radius: 50%;
	}
	input[type="radio"]:checked:before {
		background-color: ${(props) =>
			props.color ? props.color : "var(--black)"};
	}

	input[type="checkbox"]:checked {
		background-color: ${(props) =>
			props.color ? props.color : "var(--black)"};
	}
`;

export const CheckboxWrapper = withTranslation()((props) => {
	const { t } = props;
	return <CheckboxWrapperCustom
				{...props}
				label={t(props.label)}
			/>
});