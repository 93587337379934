import moment from "moment-business-time";
import { ZONE, PRIORITA, PUNTEGGI_LAVORAZIONI_STATUS } from "./constants";
import { USER_ROLES } from "../utils/constants";

export const getToken = () => {
	const tokenString = localStorage.getItem("token");
	let userToken = JSON.parse(tokenString);
	if(!userToken || userToken == "") {
		const reduxStoreString = sessionStorage.getItem("persist:campra");
		userToken = (JSON.parse(reduxStoreString) || {}).token;
	}
	return userToken && userToken != "null" ? userToken.replaceAll("\"", "") : "";
};

export const saveToken = (userToken) => {
	localStorage.setItem("token", JSON.stringify(userToken));
};

export const getDescriptionNewsWithoutHtml = (description) => {
	return description.replaceAll(/(<([^>]+)>)/ig, "");
}

export const getEasterSunday = () => {
	const year = moment().year();
	const a = parseInt(year % 19);
	const b = parseInt(year / 100);
	const c = parseInt(year % 100);
	const d = parseInt(b / 4);
	const e = parseInt(b % 4);
	const g = parseInt((8 * b + 13) / 25);
	const h = parseInt((19 * a + b - d - g + 15) % 30);
	const j = parseInt(c / 4);
	const k = parseInt(c % 4);
	const m = parseInt((a + 11 * h) / 319);
	const r = parseInt((2 * e + 2 * j - k - h + m + 32) % 7);
	const n = parseInt((h - m + r + 90) / 25);
	const p = parseInt((h - m + r + n + 19) % 32);

	return `${year}-${n > 9 ? n : "0" + n}-${p > 9 ? p : "0" + p}`;
};

export const getEasterMonday = () => {
	let day = moment(getEasterSunday()).add(1, "days");

	return moment(day).format("YYYY-MM-DD");
};

moment.locale("it", {
	workinghours: {
		0: null,
		1: ["08:30:00", "16:30:00"],
		2: ["08:30:00", "16:30:00"],
		3: ["08:30:00", "16:30:00"],
		4: ["08:30:00", "16:30:00"],
		5: ["08:30:00", "16:30:00"],
		6: null,
	},
	holidays: [
		"*-01-01",
		"*-01-06",
		getEasterSunday(),
		getEasterMonday(),
		"*-04-25",
		"*-05-01",
		"*-06-02",
		"*-08-15",
		"*-11-01",
		"*-12-08",
		"*-12-25",
		"*-12-26",
	],
});

export const getZona = (zona_id) => {
	let zonaStr = "-";
	ZONE.map((zona) => {
		if (zona.value == zona_id) {
			zonaStr = zona.text;
		}
	});
	return zonaStr;
};

export const getPriorita = (priority_id) => {
	let prioritaStr = "-";
	PRIORITA.map((priority) => {
		if (priority.value == priority_id) {
			prioritaStr = priority.text;
		}
	});
	return prioritaStr;
};

export const getOptionsForSelectMat = () => {
	return [
		{
			text: "MAT",
			value: "-",
		},
		{
			text: "MAT",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "MAT",
			value: PUNTEGGI_LAVORAZIONI_STATUS.RED,
			color: "var(--red)",
		},
		{
			text: "MAT",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		{
			text: "MAT G-R",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWRED,
			color: "var(--purple)",
			labelStyle:{
			}
		},
		{
			text: "MAT",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectTg = () => {
	return [
		{
			text: "TG",
			value: "-",
		},/*
		{
			text: "TGM",
			value: `tgm_${PUNTEGGI_LAVORAZIONI_STATUS.GREY}`,
			color: "var(--grey-2)",
		},
		{
			text: "TGM",
			value: `tgm_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOW}`,
			color: "var(--yellow)",
		},
		{
			text: "TGM",
			value: `tgm_${PUNTEGGI_LAVORAZIONI_STATUS.GREEN}`,
			color: "var(--green)",
		},*/
		{
			text: "TGA",
			value: `tga_${PUNTEGGI_LAVORAZIONI_STATUS.GREY}`,
			color: "var(--grey-2)",
		},
		{
			text: "TGA",
			value: `tga_${PUNTEGGI_LAVORAZIONI_STATUS.RED}`,
			color: "var(--red)",
		},
		{
			text: "TGA",
			value: `tga_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOW}`,
			color: "var(--yellow)",
		},
		{
			text: "TGA G-R",
			value: `tga_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOWRED}`,
			color: "var(--purple)",
		},
		/*{
            text: "TGA",
            value: `tga_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN}`
        },*/
		{
			text: "TGA",
			value: `tga_${PUNTEGGI_LAVORAZIONI_STATUS.GREEN}`,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectCoste = () => {
	return [
		{
			text: "COSTE",
			value: "-",
		},
		{
			text: "COSTE",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "COSTE",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		/*{
            text: "COSTE",
            value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN
        },*/
		{
			text: "COSTE",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectWj = () => {
	return [
		{
			text: "WJ",
			value: "-",
		},/*
		{
			text: "WJM",
			value: `wjm_${PUNTEGGI_LAVORAZIONI_STATUS.GREY}`,
			color: "var(--grey-2)",
		},
		{
			text: "WJM",
			value: `wjm_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOW}`,
			color: "var(--yellow)",
		},
		{
            text: "WJM",
            value: `wjm_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN}`
        },
		{
			text: "WJM",
			value: `wjm_${PUNTEGGI_LAVORAZIONI_STATUS.GREEN}`,
			color: "var(--green)",
		},
		*/
		{
			text: "WJA",
			value: `wja_${PUNTEGGI_LAVORAZIONI_STATUS.GREY}`,
			color: "var(--grey-2)",
		},
		{
			text: "WJA",
			value: `wja_${PUNTEGGI_LAVORAZIONI_STATUS.RED}`,
			color: "var(--red)",
		},
		{
			text: "WJA",
			value: `wja_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOW}`,
			color: "var(--yellow)",
		},
		{
			text: "WJA G-R",
			value: `wja_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOWRED}`,
			color: "var(--purple)",
		},
		/*{
            text: "WJA",
            value: `wja_${PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN}`
        },*/
		{
			text: "WJA",
			value: `wja_${PUNTEGGI_LAVORAZIONI_STATUS.GREEN}`,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectCnc = () => {
	return [
		{
			text: "CNC",
			value: "-",
		},
		{
			text: "CNC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "CNC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.RED,
			color: "var(--red)",
		},
		{
			text: "CNC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		{
            text: "CNC G-R",
            value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWRED,
			color: "var(--purple)"
        },
		{
			text: "CNC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectAlz = () => {
	return [
		{
			text: "ALZ",
			value: "-",
		},
		{
			text: "ALZ",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "ALZ",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		/*{
            text: "ALZ",
            value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWGREEN
        },*/
		{
			text: "ALZ",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectInc = () => {
	return [
		{
			text: "INC",
			value: "-",
		},
		{
			text: "INC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "INC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		{
			text: "INC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectFin = () => {
	return [
		{
			text: "FIN",
			value: "-",
		},
		{
			text: "FIN",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "FIN",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		{
			text: "FIN",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectImb = () => {
	return [
		{
			text: "IMB",
			value: "-",
		},
		{
			text: "IMB",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "IMB",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		{
			text: "IMB",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

export const getOptionsForSelectAcc = () => {
	return [
		{
			text: "ACC",
			value: "-",
		},
		{
			text: "ACC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREY,
			color: "var(--grey-2)",
		},
		{
			text: "ACC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.RED,
			color: "var(--red)",
		},
		{
			text: "ACC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOW,
			color: "var(--yellow)",
		},
		{
			text: "ACC G-R",
			value: PUNTEGGI_LAVORAZIONI_STATUS.YELLOWRED,
			color: "var(--purple)",
		},
		{
			text: "ACC",
			value: PUNTEGGI_LAVORAZIONI_STATUS.GREEN,
			color: "var(--green)",
		},
	];
};

//  !!!!!!!!! ATTENZONE !!!!!!!!! QUESTA FUNZIONE E' ANCHE SUL SERVIZIO. SE LA MODIFICHI QUA DEVI MODIFICARLA ANCHE LA
export const calculateDtLavoration = (punteggi, dt_consegna) => {
	const POINTS_HOUR_RATIO = 10;
	const POINTS_DAY_RATIO = 80;
	let dt_lavoration = "";
	let points = 0;

	const keys_list = Object.keys(punteggi);

	keys_list.forEach((key) => {
		if(punteggi[key].status != "GREEN") {
			points += parseFloat(punteggi[key].initial_value);
		}
	});

	points = points * 2;

	if(points % POINTS_DAY_RATIO != 0) {
		points = points + (POINTS_DAY_RATIO - points % POINTS_DAY_RATIO);
	}

	dt_lavoration = moment(dt_consegna).set({hour:0,minute:0,second:0,millisecond:0}).subtractWorkingTime(
		parseInt(points / POINTS_HOUR_RATIO),
		"hours",
		((points % POINTS_HOUR_RATIO) / POINTS_HOUR_RATIO) * 60,
		"minutes"
	).set({hour:0,minute:0,second:0,millisecond:0});

	if(moment(dt_lavoration) < moment()) {
		dt_lavoration = moment().set({hour:0,minute:0,second:0,millisecond:0});
	}

	if(moment(dt_lavoration) > moment(dt_consegna)) {
		dt_lavoration = moment(dt_consegna).set({hour:0,minute:0,second:0,millisecond:0});
	}

	return dt_lavoration;
};

export const calcolaScontoTabellaDimensioni = (cliente, materiale) => {
	let sconto = cliente.sconto_generale_materiali
		? parseInt(cliente.sconto_generale_materiali)
		: 0;
	cliente.sconto_azienda.forEach((item) => {
		if (item.azienda == materiale.azienda._id) {
			sconto = sconto + parseInt(item.sconto);
		}
	});
    cliente.materiali_scontati.forEach((item) => {
		if (item.materiale == materiale.codice) {
			sconto = sconto + parseInt(item.sconto);
		}
	});
	return sconto;
};

export const calcolaScontoTabellaLavorazioni = (cliente, lavorazione) => {
	let sconto = cliente.sconto_generale_lavorazioni
		? parseInt(cliente.sconto_generale_lavorazioni)
		: 0;
	cliente.lavorazioni_scontate.forEach((item) => {
		if (item.lavorazione == lavorazione.codice) {
			sconto = sconto + parseInt(item.sconto);
		}
	});
	return sconto;
};
export const calcolaMetroQuadro = (dimensioni) => {
	return (
		(parseFloat(dimensioni.lunghezza) / 100) *
		(parseFloat(dimensioni.larghezza) / 100)
	).toFixed(2);
};

export const calcolaPrezzoMateriale = (materiale, dimensioni) => {
	if (materiale) {
		let prezzo = materiale.prezzo
			? parseFloat(materiale.prezzo).toFixed(2)
			: 0.0;
		let misuraMinore = parseFloat(dimensioni.lunghezza) < parseFloat(dimensioni.larghezza) ? parseFloat(dimensioni.lunghezza) : parseFloat(dimensioni.larghezza)

		switch (materiale.regola.toLowerCase()) {
			case "std":
				if (
					parseFloat(misuraMinore) > materiale.listino.rules.std
				) {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni) * 1.2;
				} else {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni);
				}
				break;
			case "corian":
				if (
					parseFloat(misuraMinore) > materiale.listino.rules.corian
				) {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni) * 1.2;
				} else {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni);
				}
				break;
			case "mtex":
				if (
					parseFloat(misuraMinore) > materiale.listino.rules.mitex
				) {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni) * 1.25;
				} else {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni);
				}
				break;
			case "arredanet":
				if (parseFloat(misuraMinore) == parseFloat(dimensioni.lunghezza) && parseFloat(dimensioni.lunghezza) < materiale.listino.rules.arredanet.lunghezza_1) {
					prezzo = parseFloat(dimensioni.larghezza) / 100 * prezzo * 0.65;
				} else if (parseFloat(misuraMinore) == parseFloat(dimensioni.larghezza) && parseFloat(dimensioni.larghezza) < materiale.listino.rules.arredanet.larghezza_1) {
					prezzo = parseFloat(dimensioni.lunghezza) / 100 * prezzo * 0.65;
				} else if (parseFloat(misuraMinore) == parseFloat(dimensioni.lunghezza) && parseFloat(dimensioni.lunghezza) <= materiale.listino.rules.arredanet.lunghezza_2) {
					prezzo = parseFloat(dimensioni.larghezza) / 100 * prezzo * 0.9;
				} else if (parseFloat(misuraMinore) == parseFloat(dimensioni.lunghezza) && parseFloat(dimensioni.larghezza) <= materiale.listino.rules.arredanet.larghezza_2) {
					prezzo = parseFloat(dimensioni.lunghezza) / 100 * prezzo * 0.9;
				} else {
					prezzo = prezzo * calcolaMetroQuadro(dimensioni);
				}
				break;
			default:
				prezzo = prezzo * calcolaMetroQuadro(dimensioni);
				break;
		}

		return prezzo.toFixed(2);
	} else {
		return 0.0;
	}
};

export const calcolaPrezzoLavorazione = (prezzo, uaValue) => {
	return (prezzo * uaValue).toFixed(2);
};

export const calcolaTotParziale = (prezzo, sconto) => {
	return (prezzo - (prezzo * parseInt(sconto)) / 100).toFixed(2);
};

export const calcolaTotaleDimensioni = (dimensioni, cliente, materiale) => {
	let tot = 0.0;
	dimensioni.forEach((item) => {
		let sconto = calcolaScontoTabellaDimensioni(cliente, materiale);
		if (item.imballaggio == true || item.imballaggio == "true") {
			let prezzoImballaggio = calcolaImballaggioPrice(item, cliente);
			tot =
				parseFloat(tot) +
				parseFloat(
					parseFloat(
						calcolaTotParziale(calcolaPrezzoMateriale(materiale, item), sconto)
					) + parseFloat(prezzoImballaggio)
				);
		} else {
			tot =
				parseFloat(tot) +
				parseFloat(
					calcolaTotParziale(calcolaPrezzoMateriale(materiale, item), sconto)
				);
		}
	});
	return tot.toFixed(2);
};

export const calcolaImballaggioPrice = (dimensione, cliente) => {
	let maxDimension = parseFloat(dimensione.lunghezza) > parseFloat(dimensione.larghezza) ? parseFloat(dimensione.lunghezza) : parseFloat(dimensione.larghezza)
	let prezzoImballaggio = (parseFloat(dimensione.imballaggio_price) * (parseFloat(maxDimension) / 100)).toFixed(2);
	return parseFloat(prezzoImballaggio) - parseFloat(prezzoImballaggio) * parseInt(cliente.sconto_imballo && cliente.sconto_imballo != "" ? cliente.sconto_imballo : 0) / 100;
};

export const calcolaTotaleLavorazioni = (lavorazioni, cliente) => {
	let tot = 0.0;
	lavorazioni.forEach((item) => {
		if (
			item.lavorazione &&
			item.lavorazione.prezzo != "" &&
			item.ua_value != ""
		) {
			let sconto = calcolaScontoTabellaLavorazioni(cliente, item.lavorazione);
			tot =
				parseFloat(tot) +
				parseFloat(
					calcolaTotParziale(
						calcolaPrezzoLavorazione(item.lavorazione.prezzo, item.ua_value),
						sconto
					)
				);
		} else if (item.sc != "" && item.prezzo != "" && item.ua_value) {
			tot =
				parseFloat(tot) +
				parseFloat(
					calcolaTotParziale(
						calcolaPrezzoLavorazione(item.prezzo, item.ua_value),
						item.sc
					)
				);
		}
	});
	return tot.toFixed(2);
};

export const calcolaTotalePreventivo = (preventivo) => {
	let tot = 0.0;
	preventivo.materiali.forEach((item) => {
		tot =
			parseFloat(tot) +
			parseFloat(
				calcolaTotaleDimensioni(
					item.dimensioni,
					preventivo.cliente,
					item.materiale
				)
			) +
			parseFloat(calcolaTotaleLavorazioni(item.accessori, preventivo.cliente)) +
			parseFloat(
				calcolaTotaleLavorazioni(item.altre_lavorazioni, preventivo.cliente)
			) +
			parseFloat(
				calcolaTotaleLavorazioni(item.lavorazioni_alzatine, preventivo.cliente)
			) +
			parseFloat(
				calcolaTotaleLavorazioni(item.lavorazioni_fori, preventivo.cliente)
			) +
			parseFloat(
				calcolaTotaleLavorazioni(item.lavorazioni_profili, preventivo.cliente)
			) +
			parseFloat(
				calcolaTotaleLavorazioni(item.lavorazioni_varie, preventivo.cliente)
			) +
			parseFloat(
				calcolaTotaleLavorazioni(item.vasche_integrate, preventivo.cliente)
			);
	});

	if(preventivo.cliente.costo_ordine && preventivo.cliente.costo_ordine.prezzo>0){
		tot += parseFloat(preventivo.cliente.costo_ordine.prezzo);
	}

	return tot.toFixed(2);
};

Array.prototype.pushAll = function (array) {
	array.forEach(function (x) {
		this.push(x);
	}, this);
};


export const mountMaterialNames = (data) => {
	if (data && data.materiali) {
		let names = [];
		for (let materiale of data.materiali) {
			if (materiale.materiale && materiale.materiale.nome !== "")
				names.push(`${materiale.materiale.nome} / ${materiale.materiale.spessore}`);
		}
		if(names.length > 0) {
			return names.join(", ");
		} else {
			return "-";
		}
	} else {
		return "-";
	}
};

export const mountMaterialNamesWithAzienda = (data, aziende) => {
	if (data && data.materiali) {
		let names = [];

		for (let materiale of data.materiali) {
			if (materiale.materiale && materiale.materiale.nome !== ""){
				let azienda = aziende.filter((azienda) => azienda._id === materiale.materiale.azienda);
				if(azienda && azienda.length > 0){
					names.push(`${azienda[0]} - ${materiale.materiale.nome} / ${materiale.materiale.spessore}`);
				} else {
					names.push(`${materiale.materiale.nome} / ${materiale.materiale.spessore}`);
				}
			}
		}
		if(names.length > 0) {
			return names.join(", ");
		} else {
			return "-";
		}
	} else {
		return "-";
	}
};


export const canEdit = (user) => {
    return user.cd_privilege != USER_ROLES.CLIENT
}

export const canEditLavorazioni = (user) => {
    return user.cd_privilege != USER_ROLES.CLIENT && user.cd_privilege != USER_ROLES.OPERATOR
}


export const getCommentiLavorazioniFormatted = (data) => {
	const gruppi_lavorazioni_key_list = ["lavorazioni_alzatine", "lavorazioni_profili", "lavorazioni_fori", "lavorazioni_varie", "accessori", "vasche_integrate"];
	const obj = {
		"tgm": "",
		"tga": "",
		"coste": "",
		"wjm": "",
		"wja": "",
		"cnc": "",
		"alz": "",
		"acc": "",
		"inc": "",
		"fin": ""
	}
	if(data && data.materiali) {
		data.materiali.forEach((materiale) => {
			gruppi_lavorazioni_key_list.forEach((gruppo_lavorazione_key) => {
				materiale[gruppo_lavorazione_key].forEach((lavorazione) => {
					obj.tgm = obj.tgm + (lavorazione.lavorazione.tgm > 0 && lavorazione.lavorazione.descrizione != ""? lavorazione.lavorazione.descrizione +";" : "");
					obj.tga = obj.tga + (lavorazione.lavorazione.tga > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.coste = obj.coste + (lavorazione.lavorazione.coste > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.wjm = obj.wjm + (lavorazione.lavorazione.wjm > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.wja = obj.wja + (lavorazione.lavorazione.wja > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.nodescrizione +";" : "");
					obj.cnc = obj.cnc + (lavorazione.lavorazione.cnc > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.alz = obj.alz + (lavorazione.lavorazione.alz > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.acc = obj.acc + (lavorazione.lavorazione.acc > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.inc = obj.inc + (lavorazione.lavorazione.inc > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
					obj.fin = obj.fin + (lavorazione.lavorazione.fin > 0 && lavorazione.lavorazione.descrizione != "" ? lavorazione.lavorazione.descrizione +";" : "");
				});
			})
		});
	}
	return obj;
}


export const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});
