import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Text, TextAreaField } from "../../";

const CommentListModal = (props) => {
	const { t } = props;

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Container>
					<Text size="xxlarge" weight="medium" color="var(--black)">
						{"comment_list_modal.title"}
					</Text>
					<Text color="var(--darkgrey)">
						{"comment_list_modal.subtitle"}
					</Text>

					<TextAreaField
						label={"comment_list_modal_list.title"}
						labelColor="var(--darkgrey)"
						rows={16}
						value={props.value}
						onChange={props.onChange}
						style={{
							marginTop: "50px",
							marginBottom: "50px",
						}}
					/>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={() => props.onCancel()}>
								<Text size="small" weight="medium">
									{"btn_cancel"}
								</Text>
							</Button>
							<Button variant="success" onClick={() => props.onSuccess()}>
								<Text size="small" weight="medium">
									{"btn_save_modifies"}
								</Text>
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};

export default CommentListModal;
