import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EditorCell } from "../cells/editorCell";
import { ImgCell } from "../cells/imgCell";
import { TextCell } from "../cells/textCell";
import { Close as CloseIcon } from '@material-ui/icons';

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        align-items: normal;

        background-color: var(--white);
        border-bottom: 1px solid var(--grey-1);
        
        
    }

    & > div {
        display: flex;
        justify-content: center;
        border-right: 1px solid var(--grey-1);
        padding-top: 0px;
        padding-bottom: 0px;
    }

    & > .cell-padding {
        padding-left: 16px;
        padding-right: 16px;
    }

    & > div:nth-last-child(1) {
        border-right: 0px;
    }

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowCommessePunteggi = (props) => {

    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const onChange = (id, value, isValid) => {

    }

  return (
        <Wrapper
            className={props.className}
            style={props.style}
        >
            <TextCell
                primaryText={"2.35"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

            <TextCell
                primaryText={"2.35"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

            <TextCell
                primaryText={"Ls"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

            <TextCell
                primaryText={"2.35"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

            <TextCell
                primaryText={"28.00"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

            <TextCell
                primaryText={"0"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

            <TextCell
                primaryText={"65.8"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />
            <TextCell
                primaryText={"2.35"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />
            <TextCell
                primaryText={"2.35"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />
            <TextCell
                primaryText={"2.35"}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%",
                    paddingTop:"10px",
                    paddingBottom:"10px"
                }}
            />

        </Wrapper>
  );
};
