import React from "react";
import styled from "styled-components";
import { Card, Text } from "../index";

const Row = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardCounter = (props) => {
  
  return (
    <Card
      className={props.className}
      style={{
        ...props.style,
        paddingTop: "16px",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "16px",
      }}
    >
      <Row>
        <Text
          size="large"
          weight="medium"
          color={props.color}
        >
          {props.primaryText}
        </Text>
        <Text
          size="xlarge"
          weight="bold"
          color={props.color}
        >
          {props.primaryCounter}
        </Text>
      </Row>
      <Row>
        <Text
          size="medium"
        >
          {props.secondaryText}
        </Text>
        <Text
          size="large"
          weight="medium"
          color="var(--grey-2)"
        >
          {props.secondaryCounter}
        </Text>
      </Row>
    </Card>
  );
};
