import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Text } from "../index";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const MEDIA_BASE_URL = `${process.env.REACT_APP_WS_LINK}/statics/uploads`;

const FilePondCustom = styled(FilePond)`
	& {
		margin-bottom: 0px !important;
	}
	.filepond--credits {
		display: none;
	}
	.filepond--drop-label {
		justify-content: normal;
		align-items: left;
		min-height: 40px;
		height: initial;
		background-color: white;
		border-radius: 6px;
		border: 1px solid var(--grey-1);
		& > label {
			color: var(--grey-2);
		}
	}
	.filepond--list-scroller {
		margin-top: 0px;
		margin-bottom: 0px;

		& > .filepond--list {
			border-radius: 6px;
			position: initial;

			& > .filepond--item {
				border-radius: 6px;
				margin: 0px;

				& > .filepond--file-wrapper {
					border-radius: 6px;
					background-color: var(--green);
				}

			}

		}

	}
	.filepond--file-info {
		align-self: center;
	}
	.filepond--file-info-sub {
		display: none;
	}
	
`;

export const FilePondField = (props) => {
	const fieldRef = useRef();

	return (
		<div>
			<FilePondCustom
				files={props.files}
				onremovefile={(error, file) => {
					if(file.filename && props.onDelete && props.files && props.files[0] && props.files[0].options) {
						props.onDelete();
					}
				}}
				allowMultiple={props.allowMultiple}
				maxFiles={props.maxFiles}
				allowRemove={props.block === true ? false : true}
				server={{
					load: 'https://media.etihub.it/',
					process: {
					  url: props.mediaBaseUrl || MEDIA_BASE_URL,
					  method: 'POST',
					  onload: (param) => {
						  let json = null;
						  try{
							json = JSON.parse(param);
							if(props.onSuccess && json.file_url.lastIndexOf(".") >= 0) {
								props.onSuccess(json);
							}
						  }catch (e){
							console.log(e);
						  }
					
						}
					  }
					}
				}
				name={props.id || "files"} 
				labelIdle='Carica qui il tuo file'
				style={props.style}
			/>
		</div>
	);
};

