import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Text,
  TextField
} from "../../components";
import { COMMESSE, ORDINI } from "../../navigation/constants";
import { authenticate } from "../../services";
import { setUser, setToken } from "../../redux";
import { LogoLogin2X } from "../../assets/images/png/index";
import { saveToken } from "../../utils";
import { USER_ROLES } from "../../utils/constants";
import { setToast } from "../../redux";

const Login = (props) => {

  const dispatch = useDispatch();
  const { state } = useLocation();
  const previous_link = state && state.previous_link ? state.previous_link : "";
 
  const user = useSelector((state) => state.user || {});
  const token = useSelector((state) => state.token || "");

  const { t } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [memorizeToken, setMemorizeToken] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isAuthenticated = (token && token != "") || (user && user._id);

  const isFormValid = !(email != "" && password != "");

  const executeLogin = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    let current_email = document.getElementById('email').value;
    let current_password = document.getElementById('password').value;

    setEmail(current_email);
    setPassword(current_password);

    let { result, error } = await authenticate({
        email: current_email,
        password: current_password,
    });

    if (error) {
      dispatch(
        setToast({
          show: true,
          title: "login.error.title",
          description: "login.error.description",
          variant: "danger",
        })
      );
    } else {
      if (result && result.token) {
        dispatch(setToken(result.token));
        saveToken(result.token);
      }

      if (result && result.user) {
        dispatch(setUser(result.user));
      }
    }

    setIsLoading(false);
  };
  
  return isAuthenticated && user && user.cd_privilege ? (
    <Navigate to={user.cd_privilege == USER_ROLES.CLIENT ? ORDINI :  COMMESSE} replace />
  ) : (
    <Card
      centerChildren
      style={{
        width: "100%",
        maxWidth: "500px",
        marginTop: "140px",
      }}
    >
      <img
        src={LogoLogin2X}
        style={{
          maxWidth: "177px",
          maxHeight: "90px"
        }}
      />

      <Divider
        style={{
          marginTop: "40px",
          marginBottom: "40px",
        }}
      />

      <Text size="xxlarge" weight="medium">{"login.title"}</Text>

      <Text
        size="large"
        weight="regular"
        style={{
          textAlign: "center",
          maxWidth: "280px",
          marginTop: "10px",
        }}
      >
        {"login.description"}
      </Text>

      <Form
        onSubmit={executeLogin}
        style={{
          width: "100%",
        }}
      >
        <TextField
          id="email"
          required
          type="email"
          placeholder={"login.email_placeholder"}
          onChange={(id, value, isValid) => {
            if (isValid) {
              setEmail(value);
            } else {
              setEmail("");
            }
          }}
          style={{
            marginTop: "38px",
            marginBottom: "16px",
          }}
        />

        <TextField
          id="password"
          required
          type="password"
          placeholder={"login.password_placeholder"}
          onChange={(id, value, isValid) => {
            if (isValid) {
              setPassword(value);
            } else {
              setPassword("");
            }
          }}
        />

        <Divider
          style={{
            marginTop: "40px",
          }}
        />

        <Button
          variant="dark"
          type="submit"
          isLoading={isLoading}
          centerchildren
          style={{
            width: "100%",
            marginTop: "40px",
          }}
        >
          <Text
              size="small"
              weight="medium"
          >
              {"login.btn_submit"}
          </Text>
        </Button>
      </Form>
    </Card>
  );
};

export default (Login);
