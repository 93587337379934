import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import moment from "moment";
import 'moment/locale/it';
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useWindowSize } from "../../hooks";
import { getPdfSpedizioniList } from "../../services";
import { toBase64 } from "../../utils";
import { PRIORITA, ZONE, COMMESSA_STATUS } from "../../utils/constants";
import {
	Button,
	Checkbox,
	DatePickerComponent,
	SelectField,
	Spacer,
	Text,
	TextFieldWithIcon,
} from "../index";

export const BlockFilterSpedizioni = (props) => {
	const { width } = useWindowSize();
	const filters = props.filters;

	const onChange = (id, value) => {
		props.setFilters({ ...props.filters, [id]: value });
	};

	const onCheckboxChange = (e) => {
		props.setFilters({
			...props.filters,
			[e.target.id]: !props.filters[e.target.id],
		});
	};

	const setValue = (value) => {
		props.setFilters({ ...props.filters, search: value });
	};

	const setDate = (id, value) => {
		props.setFilters({
			...props.filters,
			[id]: value,
		});
	};


	const openPdfSpedizioni = () => {
		getPdfSpedizioniList(filters).then(({ result, error }) => {
			if(result && result.buffer && result.buffer.data) {
				const file = new Blob([new Uint8Array(result.buffer.data)], { type: "application/pdf" });
				const url = window.URL.createObjectURL(file);
				window.open(url, "_blank");
			}
			/*toBase64(file).then((base64) => {

				if(base64 instanceof Error) {
					console.log('Error: ', base64.message);
					return;
				}
				const newWindow = window.open()
				newWindow.document.write(
					"<html><head><title>your title in here</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +  url + "'></iframe></body></html>"
				)

			}).catch(e => console.log(e));*/
		});
	}


	return (
		<>
			<Row
				className="w-100 no-gutters"
				style={{
					margin: "32px 0px 0px 0px",
				}}
			>
				<Col
					lg={8}
					className="d-flex justify-content-start align-items-center flex-wrap"
					style={{
						paddingLeft: "0",
					}}
				>
					<Checkbox
						id="prenotazione"
						label="blockFilterSpedizioni.prenotazioneLabel"
						color="var(--purple)"
						checked={filters.statusList.some((status) => status == COMMESSA_STATUS.PRENOTAZIONE)}
						onChange={(e) => {
							const value = e.target.checked;
							if (value) {
								filters.statusList.push(COMMESSA_STATUS.PRENOTAZIONE);
							} else {
								filters.statusList = filters.statusList.filter(
									(item) => item != COMMESSA_STATUS.PRENOTAZIONE
								);
							}
							props.setFilters({ ...filters });
						}}
						style={{
							marginRight: "24px",
						}}
					/>

					<Checkbox
						id="preordine"
						label="blockFilterSpedizioni.preordineLabel"
						color="var(--blue)"
						checked={filters.statusList.some((status) => status == COMMESSA_STATUS.PREORDINE)}
						onChange={(e) => {
							const value = e.target.checked;
							if (value) {
								filters.statusList.push(COMMESSA_STATUS.PREORDINE);
							} else {
								filters.statusList = filters.statusList.filter(
									(item) => item != COMMESSA_STATUS.PREORDINE
								);
							}
							props.setFilters({ ...filters });
						}}
						style={{
							marginRight: "24px",
						}}
					/>

					<Checkbox
						id="in_attesa"
						label="blockFilterSpedizioni.attesaLabel"
						color="var(--red)"
						checked={filters.statusList.some((status) => status == COMMESSA_STATUS.IN_ATTESA)}
						onChange={(e) => {
							const value = e.target.checked;
							if (value) {
								filters.statusList.push(COMMESSA_STATUS.IN_ATTESA);
							} else {
								filters.statusList = filters.statusList.filter(
									(item) => item != COMMESSA_STATUS.IN_ATTESA
								);
							}
							props.setFilters({ ...filters });
						}}
						style={{
							marginRight: "24px",
						}}
					/>

					<Checkbox
						id="ordine"
						label="blockFilterSpedizioni.ordineLabel"
						checked={filters.statusList.some((status) => status == COMMESSA_STATUS.CONFERMATO)}
						onChange={(e) => {
							const value = e.target.checked;
							if (value) {
								filters.statusList.push(COMMESSA_STATUS.CONFERMATO);
							} else {
								filters.statusList = filters.statusList.filter(
									(item) => item != COMMESSA_STATUS.CONFERMATO
								);
							}
							props.setFilters({ ...filters });
						}}
						style={{
							marginRight: "24px",
						}}
					/>

					<Checkbox
						id="finito"
						label="blockFilterSpedizioni.finitoLabel"
						color="var(--darkgrey)"
						checked={filters.statusList.some((status) => status == COMMESSA_STATUS.FINITO)}
						onChange={(e) => {
							const value = e.target.checked;
							if (value) {
								filters.statusList.push(COMMESSA_STATUS.FINITO);
							} else {
								filters.statusList = filters.statusList.filter(
									(item) => item != COMMESSA_STATUS.FINITO
								);
							}
							props.setFilters({ ...filters });
						}}
						style={{
							marginRight: "24px",
						}}
					/>

					<Checkbox
						id="is_gold"
						label="blockFilterSpedizioni.goldLabel"
						color="var(--yellow)"
						checked={filters.is_gold}
						onChange={onCheckboxChange}
						style={{
							marginRight: "24px",
						}}
					/>

					<Checkbox
						id="is_attivita"
						label="blockFilterSpedizioni.attivitaLabel"
						color="var(--black)"
						checked={filters.is_attivita}
						onChange={onCheckboxChange}
						style={{
							marginRight: "24px",
						}}
					/>
				</Col>
				<Col
					lg={4}
					className="mt-2 mt-lg-0 d-flex justify-content-lg-end align-items-center"
					style={{
						paddingLeft: "0",
						paddingRight: "0",
					}}
				>
					<TextFieldWithIcon
						id="search"
						className="textfield-md"
						placeholder="blockFilterSpedizioni.searchSpedizioni"
						value={filters.search || ""}
						onChange={(id, value) => { setValue(value) }}
						style={{
							marginRight: "16px",
						}}
					/>

					<Button
						variant="outline-primary"
						onClick={() => {
							openPdfSpedizioni();
						}}
					>
						<PrintOutlinedIcon
							style={{
								width: "20px",
								height: "20px",
								marginRight: "8px",
							}}
						/>
						<Text size="small" weight="medium">
							{"blockFilterSpedizioni.pdf"}
						</Text>
					</Button>
				</Col>
			</Row>

			<Row
				className="w-100 mx-0"
				style={{
					marginTop: "32px",
				}}
			>
				<Col lg={9} className="mx-0 px-0">
					<Row className="w-100 mx-0 d-flex align-items-center flex-wrap justify-content-between justify-content-lg-start">
						<Col
							lg={4}
							className={`d-flex ${width < 992 ? "px-0" : "padding-left-0"}`}
						>
							<SelectField
								id="priority"
								lightbackground
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={PRIORITA}
								value={filters.priority || "NOI"}
								
								onChange={onChange}
							/>
							<Spacer width="1rem" />
							<SelectField
								id="zona"
								lightbackground
								value={filters.zona || "-"}
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={ZONE}
								onChange={onChange}
							/>
						</Col>
						<Col
							lg={4}
							className={`d-flex ${
								width < 992 ? "px-0" : "padding-left-0"
							} justify-content-between justify-content-lg-start mt-2 mt-lg-0`}
						>
							<DatePickerComponent
								id="dt_inizio_consegna"
								placeholderText="blockFilterSpedizioni.dtFrom"
								style={{ width: "100%" }}
								backgroundColor="var(--white)"
								selectedDate={filters.dt_inizio_consegna}
								removeMaxDate={filters.dt_fine_consegna == ""}
								maxDate={filters.dt_fine_consegna}
								onChange={setDate}
							/>
							<Spacer width="1rem" />
							<DatePickerComponent
								id="dt_fine_consegna"
								placeholderText="blockFilterSpedizioni.dtAt"
								style={{ width: "100%" }}
								backgroundColor="var(--white)"
								selectedDate={filters.dt_fine_consegna}
								removeMinDate={filters.dt_inizio_consegna == ""}
								minDate={filters.dt_inizio_consegna}
								onChange={setDate}
							/>
						</Col>
						<Col lg={4} className="px-0 mt-2 mt-lg-0">
							<Button
								variant="outline-secondary"
								onClick={props.clearFilters}
								style={{
									marginRight: "16px",
								}}
							>
								<AutorenewOutlinedIcon
									style={{
										marginRight: "8px",
									}}
								/>
								<Text size="small" weight="medium">
									{"blockFilterSpedizioni.clearFilter"}
								</Text>
							</Button>
						</Col>
					</Row>
				</Col>

				<Col
					lg={3}
					className="mx-0 px-0 mt-3 mt-lg-0 d-flex justify-content-lg-end"
				>
					<Button variant="outline-darkgrey" onClick={props.onCommentListOpen}>
						<EventNoteOutlinedIcon
							style={{
								width: "20px",
								height: "20px",
								marginRight: "8px",
							}}
						/>
						<Text size="small" weight="medium">
							{"blockFilterSpedizioni.openComment"}
						</Text>
					</Button>
				</Col>
			</Row>
		</>
	);
};
