import {
	FormatListBulleted as FormatListBulletedIcon,
	NoteAdd as NoteAddIcon,
} from "@material-ui/icons";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../../hooks";
import { ANAGRAFICHEDETAIL } from "../../../navigation/constants";
import { USER_ROLES } from "../../../utils/constants";
import {
	Dropdown,
	NewOrderModal,
	OperatorModal,
	Spacer,
	Text,
} from "../../index";
import { TextCell } from "../cells/textCell";

const Wrapper = styled.div`
	& {
		width: 100%;
		max-width: 100%;
		min-width: 1318px;

		display: flex;
		flex-direction: column;
		align-items: center;

		background-color: var(--white);
		border-bottom: 1px solid var(--grey-1);
	}

	& > .cell-padding {
		padding-left: 8px;
		padding-right: 8px;
	}
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowAnagrafiche = (props) => {
	
	const navigate = useNavigate();
	const { width } = useWindowSize();

	const data = props.data;
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [emails, setEmails] = useState([]);
	const [rotateClass, setRotateClass] = useState("");
	const [showAccordion, setShowAccordion] = useState(false);
	const [showModals, setShowModals] = useState({
		commessa: false,
		preventivo: false,
	});
	const [showOperatorModal, setShowOperatorModal] = useState(false);

	useEffect(() => {
		let newName = "";
		if (data.cd_privilege === USER_ROLES.OPERATOR) {
			newName = `${data.nome_operatore} ${data.cognome_operatore}`.trim();
		} else {
			newName = data.ragione_sociale || "";
			if (newName !== "" && data.nomecompleto) {
				newName += ` - ${data.nomecompleto}`;
			} else {
				newName = data.nomecompleto || "";
			}
		}
		setName(newName);

		let newAddress = data.indirizzo || "";
		if (newAddress !== "" && data.zipcode && data.zipcode !== "") {
			newAddress += `, ${data.zipcode}`;
		}
		if (newAddress !== "" && data.citta && data.citta !== "") {
			newAddress += `, ${data.citta}`;
		} else {
			newAddress = data.citta || "";
		}
		setAddress(newAddress);

		let newEmails = [];
		if (data.emails) {
			data.emails.forEach((email) => newEmails.push(email.email));
		} else if (data.email_operatore) {
			newEmails.push(data.email_operatore);
		}
		setEmails(newEmails);
	}, [data]);

	const operator =
		data.cd_privilege === USER_ROLES.OPERATOR
			? {
					_id: data._id,
					nome_operatore: data.nome_operatore,
					cognome_operatore: data.cognome_operatore,
					email_operatore: data.email_operatore,
			  }
			: {};

	const phonePadding = () => {
		if (width >= 1440) {
			return `${16 - Math.floor(width - 1440) / 80}px`;
		} else {
			return "";
		}
	};

	const emailPadding = () => {
		if (width >= 1440) {
			return `${(width - 1440) / 16 + 16}px`;
		} else {
			return "";
		}
	};

	return (
		<Wrapper
			className={props.className}
			key={props.keyCell}
			keyCell={props.keyCell}
			style={props.style}
		>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					background: data.contenzioso === true ? "rgba(210, 42, 22, .1)" : "rgba(255, 255, 255, 1)"
					
				}}
			>
				<div
					className="cell-padding"
					style={{
						width: "5%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					{data.cd_privilege !== USER_ROLES.OPERATOR && (
						<ArrowDropDownCircleOutlinedIcon
							className={rotateClass + " cursor-pointer hover-black"}
							onClick={() => {
								if (rotateClass !== "") {
									setRotateClass("");
								} else {
									setRotateClass("rotate-180");
								}
								setShowAccordion(!showAccordion);
							}}
							style={{
								color: rotateClass === "" ? "var(--grey-2)" : "var(--black)",
							}}
						/>
					)}
				</div>

				<TextCell
					className="cell-padding"
					primaryText={name}
					primaryTextWeight="bold"
					primaryTextClassName="cursor-pointer"
					onClick={() => {
						if (data.cd_privilege !== USER_ROLES.OPERATOR) {
							navigate(ANAGRAFICHEDETAIL+`?anagraficaId=${data._id}`);
						} else {
							setShowOperatorModal(true);
						}
					}}
					style={{
						width: "25%",
						cursor: "pointer",
					}}
				/>

				<TextCell
					className="cell-padding"
					primaryText={address}
					style={{
						width: "25%",
					}}
				/>

				<a
					href={`tel:${data.telefono || ""}`}
					className="cell-padding"
					style={{
						width: "10%",
						cursor: "pointer",
						textDecoration: "none",
					}}
				>
					<TextCell
						className="cell-padding"
						primaryText={data.telefono || ""}
					/>
				</a>

				<a
					href={`tel:${data.cellulare || ""}`}
					className="cell-padding"
					style={{
						width: "10%",
						cursor: "pointer",
						textDecoration: "none",
					}}
				>
					<TextCell primaryText={data.cellulare || ""} />
				</a>

				<a
					href={`mailto:${emails[0] || data.email_operatore || ""}`}
					className="cell-padding"
					style={{
						width: "20%",
						cursor: "pointer",
						textDecoration: "none",
					}}
				>
					<Text color="var(--black)">
						{emails[0] || data.email_operatore || ""}
					</Text>
				</a>

				<div
					className="cell-padding"
					style={{
						width: "5%",
					}}
				>
					{data.cd_privilege !== USER_ROLES.OPERATOR && (
						<Dropdown
							toggle={
								<FormatListBulletedIcon
									style={{
										color: "var(--darkgrey)",
									}}
								/>
							}
							menu={[
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
									}}
									onClick={() =>
										setShowModals({ ...showModals, commessa: true })
									}
								>
									<NoteAddIcon
										style={{
											color: "var(--darkgrey)",
											marginRight: "16px",
										}}
									/>
									<Text size="large" weight="medium">
										{"rowAnagrafiche.createCommessa"}
									</Text>
								</div>,
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
									}}
									onClick={() =>
										setShowModals({ ...showModals, preventivo: true })
									}
								>
									<NoteAddIcon
										style={{
											color: "var(--darkgrey)",
											marginRight: "16px",
										}}
									/>
									<Text size="large" weight="medium">
									{"rowAnagrafiche.createPreventivo"}
									</Text>
								</div>,
							]}
						/>
					)}
				</div>
			</div>

			{showAccordion && data.cd_privilege !== USER_ROLES.OPERATOR && (
				<div style={{ width: "100%" }}>
					<Row className="justify-content-center">
						<Col md={11}>
							<div
								style={{
									border: "1px solid #CFCFCF",
									marginLeft: "20px",
									marginRight: "20px",
									padding: "12px",
									
								}}
							>
								{data.credentials &&
									data.credentials.map((credential) => {
										return (
											<Row>
												<Col xs={12} lg={8} style={{}}>
													<Text color="var(--black)" weight="bold">
														{credential.fullname}
													</Text>
												</Col>
												<Col
													xs={6}
													lg={1}
													style={{
														minWidth: "120px",
														paddingLeft: phonePadding(),
														paddingRight: "0",
													}}
												>
													<a
														href={`tel:${credential.telefono || ""}`}
														style={{ textDecoration: "none" }}
													>
														<Text
															color="var(--black)"
															className="cursor-pointer"
														>
															{credential.telefono}
														</Text>
													</a>
												</Col>
												<Col
													xs={6}
													lg={2}
													style={{
														paddingLeft: emailPadding(),
													}}
												>
													<a
														href={`mailto:${credential.email}`}
														style={{ textDecoration: "none" }}
													>
														<Text
															className="cursor-pointer"
															color="var(--black)"
														>
															{credential.email}
														</Text>
													</a>
												</Col>
											</Row>
										);
									})}
							</div>
						</Col>
					</Row>
					<Spacer height="12px" />
				</div>
			)}

			<OperatorModal
				show={showOperatorModal}
				operatorId={operator._id}
				operator={operator}
				onHide={() => setShowOperatorModal(false)}
				onCancel={() => setShowOperatorModal(false)}
				onDelete={props.onDelete}
				onChange={props.onChange}
				onSuccess={props.onChange}
			/>

			

			{
				showModals.preventivo ?
					<NewOrderModal
						show={showModals.preventivo}
						isPreventivo={true}
						defaultClientId={data._id}
						priorityCommessa={data.priority}
						onHide={() => setShowModals({ ...showModals, preventivo: false })}
						onCancel={() => setShowModals({ ...showModals, preventivo: false })}
						onSuccess={undefined}
					/>
				:
					""
			}

			{
				showModals.commessa ?
					<NewOrderModal
						show={showModals.commessa}
						isPreventivo={false}
						defaultClientId={data._id}
						priorityCommessa={data.priority}
						onHide={() => setShowModals({ ...showModals, commessa: false })}
						onCancel={() => setShowModals({ ...showModals, commessa: false })}
					/>
				:
					""
			}
		</Wrapper>
	);
};
