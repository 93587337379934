import React from "react";
import styled from "styled-components";
import { Text } from "../../";

const Wrapper = styled.div`
  & {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: var(--grey-field);

    padding: 12px 8px 12px 8px;

    ${(props) => (props.isOrderable ? "cursor: pointer;" : "")}
  }
  & > div {
    display: flex;
    justify-content: center;
  }
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */

export const HeaderCell = (props) => {
  const onClick = () => {
    if (props.orderingListener && props.isOrderable) {
      props.orderingListener(props.keyCell);
    }
  };

  return (
    <Wrapper
      keyCell={props.keyCell}
      className={props.className}
      isOrderable={props.isOrderable}
      style={props.style}
      onClick={onClick}
    >
      <Text size="xsmall" weight="medium" style={props.textStyle}>
        {props.text ? props.text : ""}
      </Text>
    </Wrapper>
  );
};
