import {
	Add as AddIcon,
	ArrowForward as ArrowForwardIcon,
	AutorenewOutlined as AutorenewIcon,
	PrintOutlined as PrintIcon,
	Save as SaveIcon
} from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
//import { Link, useLocation } from "react-router-dom";
import {
	BlockCommesse,
	Breadcrumb,
	Button,
	Checkbox,
	DatePickerComponent,
	Dropdown,
	Loader,
	Row as RowWrapper,
	SelectField,
	Text,
	TextFieldWithIcon,
    Table,
    RowListini,
    ListinoDefaultModal,
	ImportListinoModal,
    Card
} from "../../components";
import { LISTINI } from "../../navigation/constants";
import {
	getListini
} from "../../services";
import {
	COMMESSA_STATUS,
	COMMESSA_TYPES,
	PRIORITA as PRIORITA_LIST,
	USER_ROLES
} from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import { PDF_COMMESSE_LIST } from "../../services/constants";
import { useDebounce } from "../../hooks";
import { setToast } from "../../redux";
import { withTranslation } from "react-i18next";
import { Fragment } from "react";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;


const COLUMNS = [
	{
		key: "data1",
		text: "listini.table.column1",
		isOrderable: false,
		width: "20%",
	},
	{
		key: "data2",
		text: "listini.table.column2",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data3",
		text: "listini.table.column3",
        isOrderable: false,
		width: "35%",
	},
	{
		key: "data4",
		text: "listini.table.column4",
		isOrderable: false,
		width: "5%",
	},
	/*
	{
		key: "data4",
		text: "listini.table.sdt_rule",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data4",
		text: "listini.table.corian_rule",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data4",
		text: "listini.table.mitex_rule",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data4",
		text: "listini.table.arreda_1_rule",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data4",
		text: "listini.table.arreda_2_rule",
		isOrderable: false,
		width: "5%",
	},
	{
		key: "data4",
		text: "listini.table.arreda_3_rule",
		isOrderable: false,
		width: "5%",
	},*/
	{
		key: "data4",
		text: "listini.table.arreda_4_rule",
		isOrderable: false,
		width: "35%",
	}
];


export const Listini = withTranslation()((props) => {
	const { t } = props;

	const dispatch = useDispatch();
	const [listiniList, setListiniList] = useState([]);
    const [showListinoDefaultModal, setShowListinoDefaultModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const user = useSelector((state) => state.user || {});
	const [reloadData, setReloadData] = useState(false);
	const [showImportListinoModal, setShowImportListinoModal] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		executeGetListini();
	}, []);


	const executeGetListini = () => {
		setIsLoading(true);

        getListini().then(({ result, error }) => {
            if (error) {
            } else {
                setListiniList(result);
                console.log(result);
            }
            setIsLoading(false);
        });
	};


	const saveListino = () => {
		
	};

	return (
		<Wrapper>
            <Breadcrumb
                levels={[
                    {
                        text: "listini.breadcrumbTitle",
                        link: LISTINI
                    }
                ]}
            />
            <div
                style={{
                    display: "flex",
					float: "right",
                    marginBottom: "32px",
                    marginTop: "32px"
                }}
            >
				{
					<Button
						variant="primary"
						onClick={() => {
							setShowImportListinoModal(true);
						}}
						style={{
							marginRight: "16px",
						}}
					>
						<Text size="small" weight="medium">
							{"commesse.importExternalData"}
						</Text>
					</Button>
				}
				{
					<Button
						variant="primary"
						onClick={() => {
							setShowListinoDefaultModal(true);
						}}
						style={{
							marginRight: "16px",
						}}
					>
						<Text size="small" weight="medium">
							{"listini.setDefaultListino"}
						</Text>
					</Button>
				}

            </div>
            <Card
				style={{
					marginTop: "16px",
					paddingLeft: "20px",
					paddingRight: "20px",
				}}
			>

                <Table
					className="scroll-1440"
					classNameHeader={"min-width-1318"}
					columns={COLUMNS}
					data={listiniList}
					rowComponent={RowListini}
					onDelete={(data, index) => {}}
					onChange={(data, index) => {}}
				/>

            </Card>
            <ListinoDefaultModal
				show={showListinoDefaultModal}
				onHide={() => setShowListinoDefaultModal(false)}
				onCancel={() => setShowListinoDefaultModal(false)}
				onSuccess={() => {
					setShowListinoDefaultModal(false);
					executeGetListini();
				}}
			/>
			<ImportListinoModal
				show={showImportListinoModal}
				onHide={() => setShowImportListinoModal(false)}
				onCancel={() => setShowImportListinoModal(false)}
				onSuccess={() => {
					setShowImportListinoModal(false)
					setReloadData(true);
				}}
			/>
		</Wrapper>
	);
});
