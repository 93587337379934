import { Email as EmailIcon } from "@material-ui/icons";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Text, TextField } from "../..";
import { useWindowSize } from "../../../hooks";
import { setToast } from "../../../redux";
import { createOperator, deleteClient, updateClient, sendOperatorCredentials } from "../../../services";
import { USER_ROLES } from "../../../utils/constants";

const OperatorModal = (props) => {
	
	const { width } = useWindowSize();
	const [operator, setOperator] = useState({});
	const [sendHover, setSendHover] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.operator) {
			setOperator({ ...props.operator });
		}
	}, []);

	const onChange = (id, value) => setOperator({ ...operator, [id]: value });

	const onCreate = () => {
		if (
			operator.nome_operatore &&
			operator.cognome_operatore &&
			operator.email_operatore
		) {
			createOperator({ ...operator, cd_privilege: USER_ROLES.OPERATOR }).then(
				({ result, errors }) => {
					if (errors || !result || !result.success) {
						console.log(errors);
						dispatch(
							setToast({
								show: true,
								title: "Errore!",
								description: "C'è stato un errore al momento della creazione dell'operatore",
								variant: "danger",
							})
						);
					} else {
						props.onCancel();
						if (props.onSuccess) {
							props.onSuccess();
						}
						setOperator({});
					}
				}
			);
		}
	};

	const onSave = () => {
		if (
			operator.nome_operatore &&
			operator.cognome_operatore &&
			operator.email_operatore
		) {
			let operatore = {
				...operator,
				cd_privilege: USER_ROLES.OPERATOR,
			};
			updateClient(operator._id, operatore).then(({ result, errors }) => {
				if (errors) {
					console.log(errors);
					dispatch(
						setToast({
							show: true,
							title: "Errore!",
							description:
								"C'è stato un errore al momento della creazione dell'operatore",
						})
					);
				} else {
					props.onCancel();
					if (props.onChange) {
						props.onChange(operatore);
					}
				}
			});
		}
	};

	const deleteOperator = () => {
		if (props.operator) {
			props.onCancel();
			if (props.onDelete) {
				props.onDelete(props.operator._id);
			}
		}
		// 	deleteClient(props.operator._id).then(({ result, errors }) => {
		// 		if (errors) {
		// 			console.log(errors);
		// 			dispatch(
		// 				setToast({
		// 					show: true,
		// 					title: "Errore!",
		// 					description: "C'è stato un errore durante l'eliminazione",
		// 					variant: "danger",
		// 				})
		// 			);
		// 		} else {
		// 			props.onCancel();
		// 			if (props.onDelete) {
		// 				props.onDelete(props.operator._id);
		// 				dispatch(
		// 					setToast({
		// 						show: true,
		// 						title: "Eliminato",
		// 						description: `L'operatore ${operator.nome_operatore} è stato eliminato`,
		// 						variant: "success",
		// 					})
		// 				);
		// 				setOperator({});
		// 			}
		// 		}
		// 	});
		// }
	};

	const sendCredential = () => {
		let credentials = {
			operator_id : props.operator._id
		}
		
		sendOperatorCredentials(credentials).then(({ result, errors }) => {
			if (errors) {
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description: "Non è stato possibile inviare le credenziali",
						variant: "danger",
					})
				);
			} else if (result === undefined) {
				console.error("Qualcosa è andato storto");
				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description: "Non è stato possibile inviare le credenziali",
						variant: "danger",
					})
				);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "Successo!",
						description: "Le credenziali sono state inviate!",
						variant: "success",
					})
				);
			}
		});
	};

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Container>
					<Row>
						<Col>
							<Text size="xxlarge" weight="medium" color="var(--black)">
								{"operator_model.title"}
							</Text>
							<Text color="var(--darkgrey)">
								{"operator_model.description"}
							</Text>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextField
								id="nome_operatore"
								type="text"
								required
								label={"name"}
								labelColor="var(--darkgrey)"
								placeholder={"hint_insert"}
								style={{
									marginTop: "20px",
								}}
								value={operator.nome_operatore || ""}
								onChange={onChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextField
								id="cognome_operatore"
								type="text"
								required
								label={"surname"}
								labelColor="var(--darkgrey)"
								placeholder={"hint_insert"}
								style={{
									marginTop: "20px",
								}}
								value={operator.cognome_operatore || ""}
								onChange={onChange}
							/>
						</Col>
					</Row>

					<Row>
						<Col xs={props.operatorId ? 9 : 12}>
							<TextField
								id="email_operatore"
								type="email"
								required
								label={"email"}
								labelColor="var(--darkgrey)"
								placeholder={"hint_insert"}
								style={{
									marginTop: "20px",
								}}
								value={operator.email_operatore || ""}
								onChange={onChange}
							/>
						</Col>
						{props.operatorId && (
							<Col
								xs={3}
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "flex-end",
									paddingBottom: "6px",
									cursor: "pointer",
								}}
								onClick={sendCredential}
								onMouseEnter={() => setSendHover(true)}
								onMouseLeave={() => setSendHover(false)}
							>
								<EmailIcon
									style={{
										color: sendHover ? "var(--black)" : "var(--red)",
										width: "20px",
										height: "20px",
										marginRight: "8px",
										marginBottom: "3px",
									}}
								/>
								<Text
									size="medium"
									weight="medium"
									color={sendHover ? "var(--black)" : "var(--red)"}
									style={{
										textDecoration: sendHover ? "underline" : "",
										cursor: "pointer",
									}}
								>
									{width > 1000 ? "Invia credenziali" : "Invia"}
								</Text>
							</Col>
						)}
					</Row>

					<div
						style={{
							height: "50px",
						}}
					/>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row>
						<Col>
							{props.operatorId ? (
								<Button
									variant="outline-darkgrey"
									className="bottombar-btn-resizable-left d-flex justify-content-center"
									onClick={deleteOperator}
								>
									<DeleteOutlineOutlinedIcon
										style={{
											height: "20px",
											width: "20px",
											marginRight: "8px",
											marginBottom: "3px",
										}}
									/>
									<Text size="small" weight="medium">
										Elimina
									</Text>
								</Button>
							) : (
								""
							)}
						</Col>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={props.onCancel}>
								<Text size="small" weight="medium">
									{"btn_cancel"}
								</Text>
							</Button>
							{props.operatorId ? (
								<Button variant="success" onClick={onSave}>
									<Text size="small" weight="medium">
										{"btn_save_modifies"}
									</Text>
								</Button>
							) : (
								<Button variant="success" onClick={onCreate}>
									<Text size="small" weight="medium">
										{"btn_create"}
									</Text>
								</Button>
							)}
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};

OperatorModal.defaultProps = {
	onSuccess: () => {},
	onCancel: () => {},
};

export default OperatorModal;
