import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Select from 'react-select';
import styled from "styled-components";
import { Text } from "../index";

const SelectCustom = styled(Select)`
	&,
	&:focus {
		cursor: pointer;
		border: ${(props) => (props.innerBorderHidden ? "none !important" : "")};
		border-radius: 6px;
		background-color: ${(props) =>
			props.lightbackground ? "var(--white)" : "var(--grey-field)"};
		box-shadow: none !important;
		border-color: var(--grey-1);
		border-radius: 6px;
	}

	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: var(--grey-2);
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: var(--grey-2);
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: var(--grey-2);
	}
`;

export const SelectField = withTranslation()((props) => {

	const { t } = props;

	const fieldRef = useRef();

	const [hasFocus, setHasFocus] = useState(false);

	const [canShowError, setCanShowError] = useState(false);

	const [isValueValid, setIsValueValid] = useState(!props.required);


	const options = props.options ?
		props.options.map((item) => ({
			value: item.value,
			label: item.label ? t(item.label) : (t(item.text) || ""),
			color: item.color || undefined,
			labelStyle:item.labelStyle||{}
		}))
	: [];

	let value = props.value ? options.find((item) => item.value == props.value) : options[0] || {};

	if(!value || !value.value) {
		value = options[0] || {}
	}

	useEffect(() => {
		launchValidate();
	}, [hasFocus]);

	useEffect(() => {
		if (!isValueValid && hasFocus && !canShowError) {
			setCanShowError(true);
		}
	}, [isValueValid]);

	const launchValidate = () => {
		if (props.required) {
			setIsValueValid(returnIfValueIsValid(fieldRef.current.value));
		}
	};

	const returnIfValueIsValid = (value) => {
		let isValid = true;

		isValid = value != "";

		return isValid;
	};

	const onChangeListener = (option) => {
		launchValidate();
		if (props.onChange && option) {
			props.onChange(
				props.id,
				option.value || "",
				returnIfValueIsValid(option.value)
			);
		}

		if(props.onClear && !option) {
			props.onClear();
		}
	};

	const onFocusListener = (e) => {
		if (props.onFocus) {
			props.onFocus(props.id, true);
		}

		setHasFocus(true);
	};

	const onBlurListener = (e) => {
		if (props.onFocus) {
			props.onFocus(props.id, false);
		}

		setCanShowError(true);
		setHasFocus(false);
	};

	return (
		<Form.Group className={props.className} style={props.style}>
			{props.label ? (
				<Text
					size="small"
					weight={"medium"}
					color={props.labelColor || "var(--darkgrey)"}
				>
					{t(props.label)}
				</Text>
			) : (
				""
			)}

			<Select
				ref={fieldRef}
				id={props.id}
				value={value}
				lightbackground={props.lightbackground}
				innerBorderHidden={props.innerBorderHidden || undefined}
				isDisabled={props.disabled}
				readOnly={props.readOnly}
				isInvalid={canShowError && !isValueValid}
				isClearable={props.isClearable || undefined}
				isSearchable={props.isSearchable || undefined}
				onChange={onChangeListener}
				onFocus={onFocusListener}
				onBlur={onBlurListener}
				options={options}
				filterOption={props.filterOption}
				styles={
					{
						singleValue: (provided, state, )=> ({
							...provided,
							color: state.data?.color
						}),
						option: (provided, state) => ({
								...provided,
								color: state.isSelected ? "white" : (options.find((item) => item.value == state.value) || {}).color,
								...(options.find((item) => item.value == state.value) || {}).labelStyle,
							}
						)
					}
				}
				style={props.selectStyle}
			/>

			{props.suggestion ? <Form.Text muted>{t(props.suggestion)}</Form.Text> : ""}
		</Form.Group>
	);
});

SelectField.defaultProps = {
	defaultValue: "",
	disabled: false,
	readOnly: false,
	required: false,
	options: [],
};
