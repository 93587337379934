import { PREVENTIVI, PREVENTIVI_FILTERED, DELETE_PREVENTIVO, SEND_MAIL_PREVENTIVO } from "./constants";
import { get, post, put, del } from "./service";

/**
 * Function to fetch all the users.
 */
export const getPreventivi = async () => {
  return get(PREVENTIVI());
};

export const getPreventiviFiltered = async (filters, page_number, elements_count) => {
  return post(PREVENTIVI_FILTERED(page_number, elements_count), filters);
};


export const deletePreventivo = async (id) => {
  return del(DELETE_PREVENTIVO(id));
};

export const sendMailPreventivo = async (mailPreventivo) => {
	return post(SEND_MAIL_PREVENTIVO(), mailPreventivo);
};