import {
	CloudUpload as UploadIcon,
	DeleteOutline as DeleteIcon,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
	BottomBar,
	Breadcrumb,
	Card,
	Row,
	Text,
	TextField,
	DatePickerComponent,
	TimePickerComponent,
	ConfirmAlertModal,
	Loader
} from "../../components";
import { NEWS } from "../../navigation/constants";
import { useLocation } from "react-router-dom";
import {
	getNewsById,
	createNews,
	updateNews,
	deleteNews
} from "../../services";
import * as moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { create } from "draft-js/lib/CharacterMetadata";
import { Col, Row as RowB } from "react-bootstrap";
import AddDocument from "../../components/modals/commesse/components/addDocument";
import { setToast } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

export const NewsDetail = withTranslation()((props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [news, setNews] = useState({});
	const location = useLocation();
	const toast = useSelector((state) => state.toast || {});
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [newsId, setNewsId] = useState();
	const navigate = useNavigate();
	const createState = (text) => {
		return EditorState.createWithContent(ContentState.createFromText(text));
	};
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);

	const onEditorStateChange = (editorState) => {
		const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		setNews({...news, descrizione: text});
		setEditorState(editorState);
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setNewsId(queryParams.get("newsId"));
	}, []);

	useEffect(() => {
		if(newsId) {
			executeGetNewsDetail();
		} else {
			news.data_pubblicazione = new Date().toISOString();
			setNews({ ...news });
			setIsLoading(false);
		}
	}, [newsId]);

	const executeGetNewsDetail = () => {
		if (newsId) {
			getNewsById(newsId).then(({ result, error }) => {
				if (error) {
					console.lof(error)
				} else {
					setNews({...result});
					const html = result.descrizione;
					const contentBlock = htmlToDraft(html);
					if (contentBlock) {
						const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
						const editorState = EditorState.createWithContent(contentState);
						setEditorState(result.descrizione ? editorState : createState(""));
					}
					
					console.log(result);
				}
				setIsLoading(false);
			});
		};
	};

	const saveNews = () => {
		updateNews(news._id, news).then(({ result, error }) => {
			if (error) {

				dispatch(setToast({
					show: true,
					title: "newsDetail.toastUpdate.error.title",
					description: "newsDetail.toastUpdate.error.description",
					variant: "danger"
				}));
				
			} else {
				
				dispatch(setToast({
                    show: true,
                    title: "newsDetail.toastUpdate.success.title",
                    description: "newsDetail.toastUpdate.success.description",
                    variant: "success"
				}));
				
			}
		});
	};

	const creaNews = () => {
		createNews(news).then(({ result, errors }) => {
			if (errors) {
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "newsDetail.toastCreate.error.title",
						description: "newsDetail.toastCreate.error.description",
						variant: "danger",
					})
				);
			} else if (result === undefined) {
				console.error("Qualcosa è andato storto");
				dispatch(
					setToast({
						show: true,
						title: "newsDetail.toastCreate.error.title",
						description: "newsDetail.toastCreate.error.description",
						variant: "danger",
					})
				);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "newsDetail.toastCreate.success.title",
						description: "newsDetail.toastCreate.success.description",
						variant: "success",
					})
				);
				setIsLoading(true);
				setNewsId(result._id);
				//executeGetNewsDetail();
				
			}
		});
	};

	const confermaEliminazione = () => {
		setConfirmAlertModalShow(true);
	}

	const eliminaArticolo = () => {
		deleteNews(news._id).then(({ result, errors }) => {
			if (errors) {
				console.error(errors);
				setConfirmAlertModalShow(false)
				dispatch(
					setToast({
						show: true,
						title: "newsDetail.toastDelete.error.title",
						description: "newsDetail.toastDelete.error.description",
						variant: "danger",
					})
				);
			} else {
				setConfirmAlertModalShow(false)
				dispatch(
					setToast({
						show: true,
						title: "newsDetail.toastDelete.success.title",
						description: "newsDetail.toastDelete.success.description",
						variant: "success",
					})
				);
				navigate(NEWS);
			}
		});
	};

	console.log(news)

	return (
		isLoading ?
			<div
				style={
					{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: "56px"
					}
				}
			>
				<Loader
					layer={3}
					color="var(--red)"
				/>
			</div>
		:
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				padding: "20px",
			}}
		>
			<Breadcrumb
				levels={[
					{
						text: "newsDetail.breadcrumbTitle",
						link: NEWS
					},
					{
						text: "newsDetail.breadcrumbSubtitle"
					}
				]}
				style={{
					marginTop: "40px",
				}}
			/>
			<Row
				style={{
					marginTop: "32px",
					marginBottom: "100px",
					alignItems: "flex-start",
				}}
				breakpoints={[
					{
						breakpoint: "10000",
						defaultWidth: 100,
						widthOverride: {
							".first-card": 60,
							".second-card": 40,
						},
					},
					{
						breakpoint: "1000",
						defaultWidth: 100,
					},
				]}
			>
				<div
					className="first-card"
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{news._id ? (
						<Card
							style={{
								padding: "32px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexDirection: "row",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="xxlarge" weight="bold" color="var(--black)">
									{"newsDetail.title"}
								</Text>

								<Text size="large" weight="regular" color="var(--darkgrey)">
									{t("newsDetail.creationDate", {date: news.data_pubblicazione && news.data_pubblicazione ? moment(news.data_pubblicazione).format("DD/MM/YYYY") : ""})}
								</Text>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="large" weight="regular" color="var(--grey-2)">
									{"newsDetail.lastUpdate"}
								</Text>
								<Text
									size="large"
									weight="regular"
									color="var(--grey-2)"
									style={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									{news.updatedAt ? moment(news.updatedAt).format("DD/MM/YYYY, HH:mm") : ""}
								</Text>
							</div>
						</Card>
					) : (
						<div></div>
					)
					}
					<Card
						style={{
							padding: "32px",
							width: "100%",
							marginTop: news._id ? "40px" : "0px",
						}}
					>
						<Text size="large" weight="bold">
							{"newsDetail.detail.title"}
						</Text>

						<Text size="medium" weight="regular">
							{"newsDetail.detail.description"}
						</Text>
						<Row
							style={{
								marginTop: "40px",
							}}
						>
							<TextField
								id=""
								required
								type="text"
								label={"newsDetail.titleLabel"}
								placeholder={"hint_insert"}
								value={news.titolo ? news.titolo : ""}
								onChange={(id, value) => {
									news.titolo = value;
									setNews({ ...news });
								}}
							/>
						</Row>
						<Row
							style={{
								marginTop: "40px",
							}}
						>
							<Text size="small" weight={"medium"} color="var(--darkgrey)">
								{"newsDetail.bodyText"}
							</Text>
							<Editor
								editorState={editorState}
								onChange={(value) => {
									//onChangeEditor(value);
									/*console.log(value);
									news.descrizione = value.blocks[0].text;
									setNews({ ...news });*/
									
								}}
								onEditorStateChange={onEditorStateChange}
								wrapperClassName="wrapper"
								editorClassName="editor"
								toolbarClassName="toolbar"
								wrapperStyle={{
									border: "1px solid var(--grey-1)",
									borderRadius: "6px",
									minWidth: "100%",
									width: "100%"
								}}
								toolbarStyle={{
									marginBottom: "0px",
									paddingBottom: "5px",
									borderBottom: "1px solid var(--grey-1)",
									borderRadius: "6px 6px 0px 0px",
								}}
								editorStyle={{
									backgroundColor: "var(--grey-field)",
									padding: "20px",
									borderRadius: "0px 0px 6px 6px"
								}}
								toolbar={{
									options: [
										"inline",
										"list",
										"textAlign",
										"colorPicker",
										"remove",
									],
									inline: {
										inDropdown: false,
										className: undefined,
										component: undefined,
										dropdownClassName: undefined,
										options: ["bold", "italic", "underline"],
									},

									list: {
										inDropdown: true,
										className: undefined,
										component: undefined,
										dropdownClassName: undefined,
										options: ["unordered", "ordered"],
									},
									textAlign: {
										inDropdown: true,
										className: undefined,
										component: undefined,
										dropdownClassName: undefined,
										options: ["left", "center", "right", "justify"],
									},
									colorPicker: {
										className: undefined,
										component: undefined,
										popupClassName: undefined,
										colors: [
											"rgb(97,189,109)",
											"rgb(26,188,156)",
											"rgb(84,172,210)",
											"rgb(44,130,201)",
											"rgb(147,101,184)",
											"rgb(71,85,119)",
											"rgb(204,204,204)",
											"rgb(65,168,95)",
											"rgb(0,168,133)",
											"rgb(61,142,185)",
											"rgb(41,105,176)",
											"rgb(85,57,130)",
											"rgb(40,50,78)",
											"rgb(0,0,0)",
											"rgb(247,218,100)",
											"rgb(251,160,38)",
											"rgb(235,107,86)",
											"rgb(226,80,65)",
											"rgb(163,143,132)",
											"rgb(239,239,239)",
											"rgb(255,255,255)",
											"rgb(250,197,28)",
											"rgb(243,121,52)",
											"rgb(209,72,65)",
											"rgb(184,49,47)",
											"rgb(124,112,107)",
											"rgb(209,213,216)",
										],
									},

									remove: { className: undefined, component: undefined },
								}}
							/>
						</Row>
						<Row
							defaultWidth={50}
							style={{
								marginTop: "40px",
							}}
						>
							<TextField
								id=""
								required
								type="text"
								label={"newsDetail.buttonTextLabel"}
								placeholder={"hint_insert"}
								value={news.testo_bottone ? news.testo_bottone : ""}
								onChange={(id, value) => {
									news.testo_bottone = value;
									setNews({ ...news });
								}}
							/>

							<TextField
								id=""
								required
								type="text"
								label={"newsDetail.buttonLinkLabel"}
								placeholder={"hint_insert"}
								value={news.link_bottone ? news.link_bottone : ""}
								onChange={(id, value) => {
									news.link_bottone = value;
									setNews({ ...news });
								}}
							/>
						</Row>
						<RowB>
							<Col lg={6} xs={12} style={{ marginTop: "40px"}}>
								<Text size="small" weight={"medium"} color="var(--darkgrey)">
									{"newsDetail.download"}
								</Text>
								<AddDocument
									id="documento_logo_cliente"
									list={news.allegato}
									onChange={(id, list) => {
										news.allegato = list;
										setNews({...news});
									}}
								/>
							</Col>
						</RowB>
						{/* <Row
							defaultWidth={50}
							breakpoints={[
								{
									breakpoint: "10000",
									defaultWidth: 50,
									widthOverride: {
										".upload": 15,
									},
								},
							]}
							style={{
								marginTop: "20px",
							}}
						>
							<TextField
								id=""
								required
								type="text"
								label={"ALLEGATO SCARICABILE"}
								placeholder={"Clicca sull'icona per caricare l'immagine..."}
								disabled={true}
								lightbackground
								style={{
									alignSelf: "flex-end",
								}}
							/>
							<div
								className="upload"
								style={{
									alignSelf: "flex-end",
									marginBottom: "10px",
								}}
							>
								<UploadIcon
									style={{
										color: "var(--red)",
										width: "20px",
										height: "20px",
										marginRight: "16px",
									}}
									onClick={() => {
										alert("Click");
									}}
								/>
								<DeleteIcon
									style={{
										color: "var(--grey-2)",
										width: "20px",
										height: "20px",
									}}
									onClick={() => {
										alert("Click");
									}}
								/>
							</div>
						</Row> 
						<Row
							defaultWidth={25}
							style={{
								marginTop: "20px",
							}}
						>
							<DatePickerComponent
								id="dt_consegna"
								label="newsDetail.pubblicationDate"
								labelColor="var(--darkgrey)"
								placeholderText="newsDetail.hintDate"
								backgroundColor="var(--grey-field)"
								selectedDate={
									news.data_pubblicazione ? news.data_pubblicazione : null
								}
								onChange={(id, value) => {
									news.data_pubblicazione = value;
									
									setNews({ ...news });
								}}
							/>

							<TimePickerComponent
								id="dt_time"
								label="newsDetail.pubblicationHour"
								labelColor="var(--darkgrey)"
								backgroundColor="var(--grey-field)"
								selectedDate={
									news.data_pubblicazione ? news.data_pubblicazione : null
								}
								onChange={(id, value) => {
									news.data_pubblicazione = value;
									
									setNews({ ...news });
								}}
							/>
						</Row>*/}
					</Card>
				</div>
				<div
					className="second-card"
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Card
						style={{
							padding: "32px",
							width: "100%",
						}}
					>
						<Text size="large" weight="bold">
							{"newsDetail.images.title"}
						</Text>

						<Text size="medium" weight="regular">
							{"newsDetail.images.description"}
						</Text>

						<Text
							size="medium"
							weight="regular"
							color="var(--darkgrey)"
							style={{
								marginTop: "40px"
							}}
						>
							{"newsDetail.mainImage"}
						</Text>
						<AddDocument
							id="image_preview"
							list={news.image_preview}
							onChange={(id, list) => {
								news.image_preview = list;
								setNews({...news});
							}}
						/>
						<Text
							size="medium"
							weight="regular"
							color="var(--darkgrey)"
							style={{
								marginTop: "40px"
							}}
						>
							{"newsDetail.gallery"}
						</Text>
						<AddDocument
							id="galleria"
							list={news.galleria}
							multiField
							onChange={(id, list) => {
								news.galleria = list;
								setNews({...news});
							}}
						/>
					</Card>
				</div>
			</Row>

			{news._id ? (
				<BottomBar
					leftFirstButton={{
						show: true,
						title: "newsDetail.deleteArticle",
						onClick: confermaEliminazione,
					}}
					leftSecondButton={{
						show: false,
						title: "",
						onClick: "",
					}}
					leftInfo={{
						show: true,
						text: news.updatedAt ? t("newsDetail.lastUpdate2", {date: moment(news.updatedAt).format("DD/MM/YYYY, HH:mm")}) : "",
					}}
					successButton={{
						show: true,
						title: "newsDetail.update",
						onClick: saveNews,
					}}
					cancelButton={{
						show: true,
						title: "newsDetail.cancel",
						onClick: () => {
							navigate(NEWS);
						},
					}}
				/>
			) : (
				<BottomBar
					
					successButton={{
						show: true,
						title: "newsDetail.createArticle",
						onClick: creaNews,
					}}
					cancelButton={{
						show: true,
						title: "newsDetail.cancel",
						onClick: () => {
							navigate(NEWS);
						},
					}}
				/>
			)
		}
		<ConfirmAlertModal
			show={confirmAlertModalShow}
			headerTitle="newsDetail.confirmAlertModal.headerTitle"
			mainTitle="newsDetail.confirmAlertModal.mainTitle"
			subtitle="newsDetail.confirmAlertModal.subtitle"
			onHide={() => setConfirmAlertModalShow(false)}
			onCancel={() => setConfirmAlertModalShow(false)}
			onSuccess={() =>
				eliminaArticolo()
			}
		/>
		</div>
		
	);
})
