import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Row, Text, TextField } from "../";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const Div = styled.div`
	display: flex;
	align-items: center;
`;

const DivClick = styled(Div)`
	&,
	& > * {
		cursor: pointer;
	}
`;

export const AddSedeSecondaria = withTranslation()((props) => {

	const { t } = props;

	let list = props.list || [];
	const [addItemHover, setAddItemHover] = useState(false);
	const [deleteItemHover, setDeleteItemHover] = useState([]);

	useEffect(() => {
		let index = 0;
		let obj = {};
		while (index < list.length) {
			obj[index] = false;
			index++;
		}
		setDeleteItemHover(obj);
	}, []);

	const addItem = () => {
		const listUpdated = list.concat([
			{
				address: "",
			},
		]);

		setDeleteItemHover({ ...deleteItemHover, [listUpdated.length - 1]: false });

		if (props.onListChange) {
			props.onListChange(listUpdated);
		}
	};

	const updateItem = (index, newItem) => {
		list[index] = newItem;

		if (props.onListChange) {
			props.onListChange(list);
		}
	};

	const removeItem = (index) => {
		const listUpdated = list.filter((item, item_index) => item_index != index);

		if (props.onListChange) {
			props.onListChange(listUpdated);
		}

		let i = 0;
		let obj = {};
		while (i < list.length) {
			obj[i] = false;
			i++;
		}
		setDeleteItemHover(obj);
	};

	return (
		<Wrapper style={props.style}>
			<Row defaultWidth={100}>
				{list.map((el, index) => (
					<Div key={index}>
						<TextField
							id={index}
							type="text"
							label={t("addSedeSecondaria.sedeNumber", {number:(index+1)})}
							value={el.address}
							placeholder={"hint_insert"}
							onChange={(id, address) => {
								updateItem(id, { address });
							}}
							onDelete={(id) => {
								removeItem(id);
							}}
						/>
						<DivClick
							onClick={(e) => {
								removeItem(index);
							}}
							style={{
								alignSelf: "end",
								marginLeft: "16px",
								marginBottom: "6px",
								userSelect: "none",
							}}
							onMouseEnter={() =>
								setDeleteItemHover({ ...deleteItemHover, [index]: true })
							}
							onMouseLeave={() =>
								setDeleteItemHover({ ...deleteItemHover, [index]: false })
							}
						>
							<DeleteIcon
								style={{
									color: deleteItemHover[index]
										? "var(--black)"
										: "var(--grey-2)",
									width: "20px",
									height: "20px",
									marginRight: "8px",
									marginBottom: "3px",
								}}
							/>
							<Text
								size="large"
								weight="medium"
								color={
									deleteItemHover[index] ? "var(--black)" : "var(--grey-2)"
								}
								style={{
									textDecoration: deleteItemHover[index] ? "underline" : "",
								}}
							>
								{"addSedeSecondaria.delete"}
							</Text>
						</DivClick>
					</Div>
				))}
			</Row>
			<DivClick
				onClick={(e) => {
					addItem();
				}}
				style={{
					width: "214px",
					marginTop: "20px",
					userSelect: "none",
				}}
				onMouseEnter={() => setAddItemHover(true)}
				onMouseLeave={() => setAddItemHover(false)}
			>
				<AddIcon
					style={{
						color: addItemHover ? "var(--darkgrey)" : "var(--grey-2)",
						width: "20px",
						height: "20px",
						marginRight: "8px",
						marginBottom: "2px",
					}}
				/>
				<Text
					size="medium"
					weight="medium"
					color={addItemHover ? "var(--darkgrey)" : "var(--grey-2)"}
					style={{
						textDecoration: addItemHover ? "underline" : "",
					}}
				>
					{"addSedeSecondaria.add"}
				</Text>
			</DivClick>
		</Wrapper>
	);
});
