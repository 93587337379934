import { ORDINI, ORDINI_FILTERED, ORDINI_COUNTER } from "./constants";
import { get, post, put } from "./service";

/**
 * Function to fetch all the users.
 */
export const getOrdini = async () => {
  return get(ORDINI());
};

export const getOrdiniFiltered = async (filters, page_number, elements_count) => {
  return post(ORDINI_FILTERED(page_number, elements_count), filters);
};

export const getOrdiniCounter = async () => {
  return get(ORDINI_COUNTER());
};