import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Button, SelectField, Text, TextField } from "../..";
import {
	getListini,
	setDefaultListino
} from "../../../services";
import { ToastContainer, Toast } from "react-bootstrap";
import { setToast } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../..";

export const ListinoDefaultModal = (props) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [listiniList, setListiniList] = useState([]);
	const [listinoSelected, setListinoSelected] = useState();
	const [showToast, setShowToast] = useState({ show: false });
	const toast = useSelector((state) => state.toast || {});
	const user = useSelector((state) => state.user || {});

	useEffect(() => {
		executeGetListini();
	}, []);

	const executeGetListini = () => {
		getListini().then(({ result, error }) => {
			if (error) {
			} else {
				const listinoDefault = result.find((item) => item.is_default) || {};
				setListiniList(result);
				setListinoSelected(listinoDefault);
				setIsLoading(false);
			}
		});
	};

	const saveData = () => {
		setDefaultListino(listinoSelected._id).then(({ result, error }) => {
			if (error || !result || (result && !result.success)) {
				dispatch(
					setToast({
						show: true,
						title: "listini.toastUpdate.error.title",
						description: "listini.toastUpdate.error.description",
						variant: "danger",
					})
				);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "listini.toastUpdate.success.title",
						description: "listini.toastUpdate.success.description",
						variant: "success",
					})
				);
				if(props.onSuccess) {
					props.onSuccess();
				}
			}
		});
	}

	
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Text size="xxlarge" weight="medium" color="var(--black)">
					Imposta listino di default
				</Text>
				<Text color="var(--darkgrey)">
					Scegli il listino di default
				</Text>
				{
					isLoading ?
						<div
							style={
								{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									marginTop: "36px"
								}
							}
						>
							<Loader
								layer={3}
								color="var(--red)"
							/>
						</div>
					:
						<SelectField
							id=""
							required
							type="text"
							label="LISTINO"
							labelColor="var(--darkgrey)"
							style={{
								marginTop: "50px",
							}}
							value={listinoSelected ? listinoSelected._id : "-"}
							options={
								[{ value: "-", text: "Seleziona..." }].concat(
									listiniList.map((item) => ({
										value: item._id,
										text: item.nome
									}))
								)
							}
							onChange={(id, value) => {
								let listino = listiniList.find((item) => item._id == value);
								setListinoSelected(listino);
							}}
						>
						</SelectField>
				}
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row
						style={{
							marginTop: "36px",
						}}
					>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={() => props.onCancel()}>
								<Text size="small" weight="medium">
									ANNULLA
								</Text>
							</Button>
							<Button
								variant="success"
								disabled={!listinoSelected || isLoading}
								onClick={() => saveData()}
							>
								<Text size="small" weight="medium">
									SALVA
								</Text>
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};
