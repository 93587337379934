import { createSlice } from "@reduxjs/toolkit";
import {
	COMMESSA_TYPES
} from "../utils/constants";

const initialState = {
  cd_number_type: COMMESSA_TYPES.COMMESSA,
  search: "",
  statusList: [],
  is_anticipo: false,
  is_gold: false,
  is_attivita: false,
  priority: "-",
  azienda: "-",
  materiale: "-",
  dt_inizio_lavorazione: "",
  dt_fine_lavorazione: "",
  lavorazioni: {
    mat: "-",
    tgm: "-",
    tga: "-",
    coste: "-",
    wjm: "-",
    wja: "-",
    cnc: "-",
    alz: "-",
    acc: "-",
    inc: "-",
    fin: "-",
    imb: "-",
  },
};

export const commesseFiltersSlice = createSlice({
  name: "commesseFilters",
  initialState,
  reducers: {
    setCommesseFilters: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state = action.payload;
      return action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setCommesseFilters } = commesseFiltersSlice.actions;
export default commesseFiltersSlice.reducer;
