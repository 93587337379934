import React, { useState } from "react";
import { Col } from "react-bootstrap";
import {
	LAVORAZIONI, USER_ROLES,
} from "../../utils/constants";
import { Checkbox, TextField, Spacer, Row, BlockNote } from "../";
import { useSelector } from "react-redux";

const workTypes = [
	"lavorazioni_alzatine",
	"lavorazioni_profili",
	"lavorazioni_fori",
	"lavorazioni_varie",
	"accessori",
	"vasche_integrate",
];

/**
 * Servono necessariamente:
 * title: il titolo della sezione
 * active: se è attivo o meno
 * list: [
 * 	{
 * 		value: String, valore,
 * 		note: String, nota da inserire nella textfield
 * 		route: String, identificatore della textfield
 * 	}
 * ]
 * workTypeKey: String, chiave a cui si riferirà sul db per in punteggi_lavorazioni
 * onChange: (id, value) => {} quando cambia il testo
 * onGenericChange: (id, value) => {} quando cambia la nota generica libera (l'ultima)
 */
export const BlockLavorazioniNote = (props) => {
	
	let notes = {};
	let commessa = props.commessa;
	const user = useSelector((state) => state.user || {})

	/**
	 * Recupera le note per ogni materiale e le setta
	 */
	 const getNotes = (materials) => {
		let updatedNotes = {};

		if(materials && materials.length > 0) {
			materials.forEach((material, materialIndex) => {
				if (material) {
					workTypes.forEach((workType) => {
						updatedNotes = getNotesForWorkType(
							workType,
							material,
							materialIndex,
							updatedNotes
						);
					});
				}
			});
			notes = updatedNotes;
		}
	};

	/**
	 * data una tipologia di lavoro, un materiale, l'indice e le note attuali,
	 * crea una struttura di base iniettando un campo notes in ogni lavorazione e popolandolo
	 * con le note corrette a seconda dei punteggi dei materiali. Traccia ogni punteggio/nota.
	 */
	const getNotesForWorkType = (
		workType,
		material,
		materialIndex,
		updatedNotes
	) => {
		if (material[workType] && material[workType].length > 0) {
			material[workType].forEach((work, index) => {
				if (material[workType][index].lavorazione.notes) {
				} else {
					material[workType][index].lavorazione.notes = {};
				}

				LAVORAZIONI.forEach((descriptor) => {
					if (
						work.lavorazione &&
						work.lavorazione[descriptor.value] &&
						work.lavorazione[descriptor.value] !== "" &&
						descriptor.value != "tga" &&
						descriptor.value != "tgm"
					) {
						if (updatedNotes[descriptor.value] === undefined) {
							updatedNotes[descriptor.value] = [];
						}

						if(!(material[workType][index].lavorazione.notes && material[workType][index].lavorazione.notes[descriptor.value])) {
							material[workType][index].lavorazione.notes[descriptor.value] = work.lavorazione.descrizione;
						}

						updatedNotes[descriptor.value].push({
							value: work.lavorazione[descriptor.value],
							note: material[workType][index].lavorazione.notes[
								descriptor.value
							],
							route: `${materialIndex}.${workType}.${index}.${descriptor.value}`,
						});
					}
				});
			});
		}
		return updatedNotes;
	};

	getNotes(commessa.materiali);

	console.log(notes);

	return LAVORAZIONI.map((lavorazione) => (
		<>
			<Spacer height="30px" />
			<BlockNote
				title={lavorazione.noteDescriptor}
				active={
					notes[lavorazione.value] &&
					notes[lavorazione.value].length > 0
				}
				list={notes[lavorazione.value]}
				workTypeKey={lavorazione.value}
				workTypeKeyComment={
					commessa.punteggi_lavorazioni[lavorazione.value].comment
				}
				onChange={(id, value) => {
					let route = id.split(".");
					commessa.materiali[route[0]][route[1]][
						route[2]
					].lavorazione.notes[route[3]] = value;
					if(props.onChange) {
						props.onChange(commessa)
					}
				}}
				onGenericChange={(id, value) => {
					let route = id.split(".");
					commessa[route[0]][route[1]][route[2]] = value;
					if(props.onChange) {
						props.onChange(commessa)
					}
				}}
				colSize={{
					label: 4,
					input: 8,
				}}
			>
				{
					notes[lavorazione.value] ?
						notes[lavorazione.value].map((item, index) => {
							let route = item.route.split(".");

							return (
								<div key={item.route}>
									{
										index > 0 ?
											<Spacer height="10px" />
										:
											""
									}
									<Row>
										{
											index > 0 ?
												<Col
													className="d-md-none d-lg-block"
													lg={4}
												></Col>
											:
											<Col
												md={12}
												lg={4}
												style={{
													display: "flex",
													alignItems: "baseline",
												}}
											>
												<Checkbox
													label={lavorazione.noteDescriptor}
													labelColor={
														notes[lavorazione.value].length > 0
															? "var(--black)"
															: "var(--grey-1)"
													}
													color="var(--black)"
													checked={
														notes[lavorazione.value].length > 0
													}
													style={{
														marginTop: "0.5rem",
													}}
												/>
											</Col>
										}
										<Col md={12} lg={8}>
											<TextField
												id={item.route}
												readOnly={user.cd_privilege != USER_ROLES.ADMIN && user.cd_privilege != USER_ROLES.OPERATOR}
												value={
													commessa.materiali[route[0]][route[1]][
														route[2]
													].lavorazione.notes[route[3]] || ""
												}
												onChange={(id, value) => {
													let route = id.split(".");
													commessa.materiali[route[0]][route[1]][
														route[2]
													].lavorazione.notes[route[3]] = value;
													if(props.onChange) {
														props.onChange(commessa);
													}
												}}
											/>
										</Col>
									</Row>
								</div>
							);

						})
					:
						""
				}
			</BlockNote>
		</>
	));
};
