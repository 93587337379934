import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { AdaptableTextAreaField, Checkbox, TextField, Spacer, Row } from "../";
import { useSelector } from "react-redux";
import {
	USER_ROLES,
} from "../../utils/constants";

/**
 * Servono necessariamente:
 * title: il titolo della sezione
 * active: se è attivo o meno
 * list: [
 * 	{
 * 		value: String, valore,
 * 		note: String, nota da inserire nella textfield
 * 		route: String, identificatore della textfield
 * 	}
 * ]
 * workTypeKey: String, chiave a cui si riferirà sul db per in punteggi_lavorazioni
 * onChange: (id, value) => {} quando cambia il testo
 * onGenericChange: (id, value) => {} quando cambia la nota generica libera (l'ultima)
 */
export const BlockNote = (props) => {
	const defaults = {
		editable: props.active ? props.active : false,
		text: props.active ? "" : "-",
		labelColor: props.active ? "var(--black)" : "var(--grey-1)",
		defaultLabelColSize: props.colSize.label ? props.colSize.label : 4,
		defaultInputColSize: props.colSize.input ? props.colSize.input : 8,
	};

	const [editable, setEditable] = useState(defaults.editable);
	const [labelColor, setLabelColor] = useState(defaults.labelColor);
	const user = useSelector((state) => state.user || {})

	const list = props.list || [];

	return list.length > 0 ? (
		<>
			{props.children}

			<Spacer height="10px" />
			<Row>
				<Col
					className="d-md-none d-lg-block"
					lg={defaults.defaultLabelColSize}
				></Col>
				<Col md={12} lg={defaults.defaultInputColSize}>
					<TextField
						key={`punteggi_lavorazioni.${props.workTypeKey}.comment`}
						id={`punteggi_lavorazioni.${props.workTypeKey}.comment`}
						readOnly={user.cd_privilege != USER_ROLES.ADMIN && user.cd_privilege != USER_ROLES.OPERATOR} 
						value={props.workTypeKeyComment || ""}
						onChange={props.onGenericChange}
					/>
				</Col>
			</Row>
		</>
	) : props.workTypeKey == "tga" || props.workTypeKey == "tgm" ? (
		<Row className="mt-3">
			<Col
				md={12}
				lg={defaults.defaultLabelColSize}
				style={{
					display: "flex",
					alignItems: "baseline",
				}}
			>
				<Checkbox
					id={`punteggi_lavorazioni.${props.workTypeKey}.comment`}
					label={props.title}
					color="var(--black)"
					checked={true}
					style={{
						marginTop: "0.5rem",
					}}
				/>
			</Col>
			<Col md={12} lg={defaults.defaultInputColSize}>
				<TextField
					key={`punteggi_lavorazioni.${props.workTypeKey}.comment`}
					id={`punteggi_lavorazioni.${props.workTypeKey}.comment`}
					readOnly={user.cd_privilege != USER_ROLES.ADMIN && user.cd_privilege != USER_ROLES.OPERATOR} 
					value={props.workTypeKeyComment || ""}
					onChange={props.onGenericChange}
				/>
			</Col>
		</Row>
	) : (
		<Row className="mt-3">
			<Col
				md={12}
				lg={defaults.defaultLabelColSize}
				style={{
					display: "flex",
					alignItems: "baseline",
				}}
			>
				<Checkbox
					id=""
					label={props.title}
					labelColor={labelColor}
					color="var(--black)"
					checked={editable}
					style={{
						marginTop: "0.5rem",
					}}
				/>
			</Col>
			<Col md={12} lg={defaults.defaultInputColSize}>
				<AdaptableTextAreaField editable={editable && user.cd_privilege != USER_ROLES.ADMIN && user.cd_privilege != USER_ROLES.OPERATOR} text="-" />
			</Col>
		</Row>
	);
};
