import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { TextCell } from "../cells/textCell";
import { Dropdown, Text, CreateCommessaModal, ConfirmAlertModal } from "../..";
import {
	FormatListBulleted as FormatListBulletedIcon,
	NoteAdd as NoteAddIcon,
	Print as PrintIcon,
	Delete as DeleteIcon,
} from "@material-ui/icons";

import { PREVENTIVIDETAIL, COMMESSEDETAIL, PREVENTIVI } from "../../../navigation/constants";
import { useNavigate } from "react-router-dom";
import { setToast } from "../../../redux";
import { deletePreventivo } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../../../utils/constants";
import {
	mountMaterialNames
} from "../../../utils/";
import { PDF_PREVENTIVO } from "../../../services/constants";

const Wrapper = styled.div`
	& {
		width: 100%;
		max-width: 100%;
		min-width: 1192px;

		display: flex;
		flex-direction: row;
		align-items: center;

		background-color: var(--white);
		border-bottom: 1px solid var(--grey-1);
	}

	& > .cell-padding {
		padding-left: 8px;
		padding-right: 8px;
	}

	& > .cursor-pointer > * {
		cursor: pointer;
	}
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowPreventivi = (props) => {
	const [data, setData] = useState(props.data);
	const [createCommessaModalShow, setCreateCommessaModalShow] = useState(false);
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);
	const navigate = useNavigate();
	const [showToast, setShowToast] = useState({ show: false });
	const toast = useSelector((state) => state.toast || {});
	const user = useSelector((state) => state.user || {});
	const dispatch = useDispatch();

	const confermaEliminazione = () => {
		setConfirmAlertModalShow(true);
	}

	const eliminaPreventivo = () => {
		
		deletePreventivo(data._id).then(({ result, error }) => {
			if (error) {
				console.log(error);
				dispatch(setToast({
					show: true,
					title: "Attenzione",
					description: "Non è stato possibile eliminare il preventivo.",
					variant:"danger"
				}));
			} else {
				console.log("Salvataggio:");
				
				if(props.onDelete) {
					props.onDelete(data);
				}
				
				
			}
			
		});
	};

	const openPdf = () => {
		window.open(`${process.env.REACT_APP_WS_LINK}${PDF_PREVENTIVO(data._id, user.cd_privilege)}?u=${((user||{}).logged||{})._id || ""}`, '_blank');
	}

	let menuList = [];
	if(data.cd_type == "PREVENTIVO" && user.cd_privilege != USER_ROLES.CLIENT){
		menuList=[
			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
				onClick={() => {
					setCreateCommessaModalShow(true);
				}}
			>
				
				<NoteAddIcon
					style={{
						color: "var(--darkgrey)",
						marginRight: "16px",
					}}
				/>
				<Text size="large" weight="medium">
					{"rowPreventivo.createCommessa"}
				</Text>
				
			</div>,
			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
				onClick={(e) => {
					openPdf();
				}}
			>
				<PrintIcon
					style={{
						color: "var(--darkgrey)",
						marginRight: "16px",
					}}
				/>
				<Text size="large" weight="medium">
					{"rowPreventivo.printPreventivo"}
				</Text>
			</div>,
			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
				onClick={() => {
					confermaEliminazione();
				}}
			>
				<DeleteIcon
					style={{
						color: "var(--darkgrey)",
						marginRight: "16px",
					}}
				/>
				<Text size="large" weight="medium">
					{"rowPreventivo.deletePreventivo"}
				</Text>
			</div>,
		];
	} else {
		menuList=[
			
			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
				onClick={(e) => {
					openPdf();//window.location.href  = `${process.env.REACT_APP_WS_LINK}${PDF_PREVENTIVO(data._id, user.cd_privilege)}`;
				}}
			>
				<a
					target="_blank"
					style={{
						textDecoration: "none"
					}}
				>
					<PrintIcon
						style={{
							color: "var(--darkgrey)",
							marginRight: "16px",
						}}
					/>
					<Text size="large" weight="medium">
						{"rowPreventivo.printPreventivo"}
					</Text>
				</a>
			</div>,
			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
				}}
				onClick={() => {
					confermaEliminazione();
				}}
			>
				<DeleteIcon
					style={{
						color: "var(--darkgrey)",
						marginRight: "16px",
					}}
				/>
				<Text size="large" weight="medium">
					{"rowPreventivo.deletePreventivo"}
				</Text>
			</div>,
		];
	}

	return (
		<Wrapper
			className={props.className}
			key={props.keyCell}
			keyCell={props.keyCell}
			style={props.style}
		>
			<CreateCommessaModal
				show={createCommessaModalShow}
				isPreventivo={true}
				commessaData={data}
				onHide={() => setCreateCommessaModalShow(false)}
				onCancel={() => setCreateCommessaModalShow(false)}
				onSuccess={() => navigate(COMMESSEDETAIL+`?commessaId=${data._id}`)}
			/>
			<TextCell
				className="cell-padding"
				primaryText={
					data.numero_preventivo + " / " + moment(data.createdAt).format("YYYY")
				}
				style={{
					width: "10%",
				}}
			/>

			<TextCell
				className="cell-padding cursor-pointer"
				primaryText={(data.cliente && data.cliente.ragione_sociale) || "-"}
				primaryTextSize="large"
				primaryTextWeight="bold"
				onClick={() => {
					navigate(PREVENTIVIDETAIL+`?commessaId=${data._id}`);
				}}
				style={{
					width: "15%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={data.riferimento}
				style={{
					width: "15%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={data.created_by.fullname}
				style={{
					width: "15%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={mountMaterialNames(data)}
				style={{
					width: "20%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={moment(data.createdAt).format("DD/MM/YYYY")}
				style={{
					width: "10%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={
					data.cd_type != "COMMESSA"
						? "-"
						: data.numero_commessa +
						  " / " +
						  moment(data.dt_creazione_commessa).format("YYYY")
				}
				style={{
					width: "10%",
				}}
			/>

			<div
				className="cell-padding"
				style={{
					width: "5%",
				}}
			>
				<Dropdown
					toggle={
						<FormatListBulletedIcon
							style={{
								color: "var(--darkgrey)",
							}}
						/>
					}
					menu={menuList}
				/>
			</div>
			<ConfirmAlertModal
				show={confirmAlertModalShow}
				headerTitle="rowPreventivo.confirmAlertModal.headerTitle"
				mainTitle="rowPreventivo.confirmAlertModal.mainTitle"
				subtitle="rowPreventivo.confirmAlertModal.subtitle"
				onHide={() => setConfirmAlertModalShow(false)}
				onCancel={() => setConfirmAlertModalShow(false)}
				onSuccess={() =>
					eliminaPreventivo()
				}
			/>
		</Wrapper>
	);
};
