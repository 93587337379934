import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EditorCell } from "../cells/editorCell";
import { ImgCell } from "../cells/imgCell";
import { TextCell } from "../cells/textCell";
import { Close as CloseIcon } from '@material-ui/icons';
import { SelectField } from "../..";
import { calcolaImballaggioPrice, calcolaPrezzoMateriale, calcolaScontoTabellaDimensioni, calcolaTotParziale } from "../../../utils/functionsUtils";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        align-items: normal;

        background-color: var(--white);
        border-bottom: 1px solid var(--grey-1);
        
        
    }

    & > div {
        display: flex;
        justify-content: center;
        border-right: 1px solid var(--grey-1);
        padding-top: 0px;
        padding-bottom: 0px;
    }

    & > .cell-padding {
        padding-left: 16px;
        padding-right: 16px;
    }

    & > div:nth-last-child(1) {
        border-right: 0px;
    }

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowCommesseDimensioni = (props) => {

    const [data, setData] = useState(props.data);
    let materialeData = props.materialeData || {};
    let clienteData = props.clienteData || {};

    const prezzoMq = materialeData.prezzo;
    const prezzo = calcolaPrezzoMateriale(materialeData, data);
    const sconto = calcolaScontoTabellaDimensioni(clienteData,materialeData);

    let maxDimension = parseFloat(data.lunghezza) > parseFloat(data.larghezza) ? (data.lunghezza / 100).toFixed(2) : (data.larghezza / 100).toFixed(2);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const onChange = (id, value, isValid) => {

    }

  return (
        <Wrapper
            className={props.className}
            style={props.style}
        >
            <EditorCell
                id={"data1"}
                value={data.lunghezza}
                lightbackground
                noborder={"true"}
                type="text"
                onChange={(id, value) => {
                    data.lunghezza = value.replace(",", ".");
                    setData({ ...data });
                    if(props.onChange) {
                        props.onChange(data);
                    }
                }}
                style={{
                    width: "13.5%"
                }}
            />

            <EditorCell
                id={"data2"}
                value={data.larghezza}
                lightbackground
                noborder={"true"}
                type="text"
                onChange={(id, value) => {
                    data.larghezza = value.replace(",", ".");
                    setData({ ...data });
                    if(props.onChange) {
                        props.onChange(data);
                    }
                }}
                style={{
                    width: "13.5%"
                }}
            />

            <TextCell
                primaryText={((parseFloat(data.lunghezza)/100) * (parseFloat(data.larghezza)/100)).toFixed(2) }
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "13.5%"
                }}
            />

            <select name="select_imballaggio" id="select_imballaggio" 
                style={{
                    width: "13.5%",
                    border: "none",
                    borderRight: "1px solid var(--grey-1)"
                }}
                value={`${data.imballaggio}`}
                onChange={(e) => {
                    data.imballaggio = e.target.value;
                    setData({ ...data });
                    if(props.onChange) {
                        props.onChange(data);
                    }
                }}
            >
                <option value="true">SI</option>
                <option value="false">NO</option>
            </select>
            {/* <SelectField
                id="select_imballaggio"
                lightbackground
                //className={`no-border`}
                value={`${data.imballaggio}`}
                innerBorderHidden={true}
                options={[
                    {
                        text: "SI",
                        value: "true",
                    },
                    {
                        text: "NO",
                        value: "false",
                    },
                ]}
                onChange={(id, value) => {
                    data.imballaggio = value;
                    setData({ ...data });
                    if(props.onChange) {
                        props.onChange(data);
                    }
                }}
                style={{
                    width: "13.5%"
                }}
            /> */}

            <TextCell
                primaryText={prezzoMq}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "13.5%"
                }}
            />

            <TextCell
                primaryText={sconto}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "13.5%"
                }}
            />

            <TextCell
                primaryText={data.imballaggio == true || data.imballaggio == "true" ? (parseFloat(calcolaTotParziale(prezzo, sconto)) +  parseFloat(calcolaImballaggioPrice(data, clienteData))).toFixed(2) : calcolaTotParziale(prezzo, sconto)}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "13.5%"
                }}
            />

            

            <div
                className="cell-padding"
                style={{
                    width: "5.5%",
                    alignItems: "center"
                }}
            >

                <CloseIcon
                    onClick={() => {
                        if(props.onDelete) {
                            props.onDelete(data);
                        }
                    }}
                    style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer"
                    }}
                />

            </div>

        </Wrapper>
  );
};
