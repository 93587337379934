import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Label as LabelIcon, AssignmentTurnedIn } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Checkbox, ProgressBar, Text, ImgLavorazione } from "../..";
import { AccessoriGreen } from "../../../assets/images/svg";
import { controllaEsistenzaDocumento, updateCommessa } from "../../../services/";
import {
	COMMESSA_STATUS_COLOR,
	COMMESSA_TYPES,
	COMMESSA_STATUS,
	PUNTEGGI_LAVORAZIONI_STATUS,
	USER_ROLES,
	ZONE,
} from "../../../utils/constants";
import { TextCell } from "../cells/textCell";
import { mountMaterialNames, getCommentiLavorazioniFormatted } from "../../../utils/";
import { COMMESSEDETAIL } from "../../../navigation/constants";
import { Link } from "react-router-dom";
import { DOWNLOAD_DOCUMENTI } from "../../../services/constants";

const Wrapper = styled.div`
	& {
		width: 100%;
		max-width: 100%;
		min-width: 1301px;

		display: flex;
		flex-direction: row;
		align-items: center;

		background-color: var(--white);
		border-bottom: 1px solid var(--grey-1);


		.info-cliente-container {
			cursor: pointer;
			& * {
				cursor: pointer;
			}
		}

	}

	& .cell-padding {
		padding-left: 8px;
		padding-right: 8px;
	}
`;

const Img = styled.img`
	width: 24px;
	height: 24px;
	cursor: pointer;

	margin-right: 16px;
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowSpedizioni = (props) => {
	const [data, setData] = useState(props.data);
	const user = useSelector((state) => state.user || {});

	const [documentsState, setDocumentsState] = useState({
		dwg_esecutivo: {
			exist: false,
			filename: ""
		}
	});

	const commenti_lavorazioni_obj = getCommentiLavorazioniFormatted();

	const oraConsegna = data.hour_consegna ? data.hour_consegna : "-";

	useEffect(() => {

		const anno_commessa = moment(data.dt_creazione_commessa).format("YYYY"); //2022
		const numero_commessa = data.numero_commessa; // "76D"

		controllaEsistenzaDocumento("dwg", numero_commessa, anno_commessa).then((response) => {
			if (response.result) {
				documentsState.dwg_esecutivo.exist = response.result.success;
				documentsState.dwg_esecutivo.filename = `${numero_commessa}-${anno_commessa}.dwg`;
				setDocumentsState({...documentsState});
			}
		})
	}, []);

	const saveCommessa = () => {
		updateCommessa(data._id, data).then(({ result, error }) => {
			if (error) {
				console.log(error);
			}
		});
		if (props.onChange) {
			props.onChange(data);
		}
	};

	const getColorFromCommessa = (show_anticipo) => {
		if (data.cd_type != COMMESSA_TYPES.ATTIVITA) {
			if (moment() < moment(data.dt_consegna) && show_anticipo) {
				return "var(--green)";
			} else {
				return COMMESSA_STATUS_COLOR[data.cd_status];
			}
		} else {
			return "";
		}
	};

	const getSumPunteggiLavorazione = (only_green) => {
		let sum = 0;

		Object.keys(data.punteggi_lavorazioni).forEach((key) => {
			if (only_green) {
				if (
					data.punteggi_lavorazioni &&
					data.punteggi_lavorazioni[key] &&
					data.punteggi_lavorazioni[key].status ==
						PUNTEGGI_LAVORAZIONI_STATUS.GREEN
				) {
					sum += parseFloat(data.punteggi_lavorazioni[key].initial_value);
				}
			} else {
				if (
					data.punteggi_lavorazioni[key] &&
					data.punteggi_lavorazioni[key].initial_value
				) {
					sum += parseFloat(data.punteggi_lavorazioni[key].initial_value);
				}
			}
		});

		return sum.toFixed(2);
	};

	return (
		<Wrapper
			className={props.className}
			key={props.keyCell}
			keyCell={props.keyCell}
			style={props.style}
		>
			{data.cd_type == COMMESSA_TYPES.ATTIVITA ? (
				<AssignmentTurnedIn
					className="cell-padding"
					style={{
						width: "3%",
						color: getColorFromCommessa(true),
					}}
				/>
			) : (
				<LabelIcon
					className="cell-padding"
					style={{
						width: "3%",
						color: getColorFromCommessa(true),
					}}
				/>
			)}

			<TextCell
				className="cell-padding"
				primaryText={data.priority || ""}
				style={{
					width: "5%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={oraConsegna}
				style={{
					width: "5%",
				}}
			/>

			<TextCell
				className="cell-padding"
				primaryText={
					data.cliente && data.cliente.zona_consegna != "-"
						? (ZONE.find((item) => data.cliente.zona_consegna == item.value) || {}).text
						: "-"
				}
				style={{
					width: "7%",
				}}
			/>
			<Link
				to={user.cd_privilege == USER_ROLES.ADMIN && data.cd_type != COMMESSA_TYPES.ATTIVITA ? COMMESSEDETAIL+`?commessaId=${data._id}` : ""}
				style={{
					width: "10%",
					textDecoration: "none"
				}}
			>
				<TextCell
					className="cell-padding info-cliente-container"
					lineOnText={data.is_sospesa}
					color={data.cd_type != COMMESSA_TYPES.ATTIVITA ? COMMESSA_STATUS_COLOR[data.cd_status] : "#000000"}
					secondaryColor={data.cd_type != COMMESSA_TYPES.ATTIVITA ? COMMESSA_STATUS_COLOR[data.cd_status] : "#000000"}
					primaryText={
						data.cliente && data.cliente.nomecompleto
							? data.cliente.nomecompleto
							: "-"
					}
					primaryTextClassName="ellipsis"
					primaryTextTitle={
						data.cliente && data.cliente.nomecompleto
							? data.cliente.nomecompleto
							: undefined
					}
					primaryTextSize="large"
					primaryTextWeight="bold"
					secondaryText={
						data.riferimento ? data.riferimento : "-"
					}
					secondaryTextClassName="ellipsis"
					secondaryTextTitle={
						data.riferimento ? data.riferimento : undefined
					}
					style={{
						cursor: "pointer"
					}}
				/>
			</Link>

			{data.cd_type == COMMESSA_TYPES.ATTIVITA ? (
				<>
					<TextCell
						primaryText={data.descrizione_attivita}
						primaryTextClassName="ellipsis"
						primaryTextTitle={data.descrizione_attivita}
						style={{
							width: "70%",
						}}
					/>
				</>
			) : (
				<>
					<TextCell
						className="cell-padding"
						primaryText={mountMaterialNames(data)}
						primaryTextClassName="ellipsis"
						primaryTextTitle={mountMaterialNames(data)}
						style={{
							width: "10%",
						}}
					/>

					<TextCell
						className="cell-padding"
						primaryText={data.commento || "-"}
						primaryTextClassName="ellipsis"
						primaryTextTitle={data.commento}
						style={{
							width: "20%",
						}}
					/>

					<TextCell
						className="cell-padding"
						primaryText={data.note_consegna || "-"}
						primaryTextClassName="ellipsis"
						primaryTextTitle={data.note_consegna}
						style={{
							width: "10%",
						}}
					/>

					<TextCell
						className="cell-padding"
						primaryText={
							data.cliente && data.cliente.note_consegna
								? data.cliente.note_consegna
								: "-"
						}
						primaryTextClassName="ellipsis"
						primaryTextTitle={
							data.cliente && data.cliente.note_consegna
								? data.cliente.note_consegna
								: undefined
						}
						style={{
							width: "10%",
						}}
					/>

					<div
						style={{
							width: "20%",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								width: "100%",
								display: "flex",
							}}
						>
							<div
								className="cell-padding"
								style={{
									display: "flex",
									width: "25%",
								}}
							>
								<Text size="large" weight="regular">
									{data.numero_commessa}
								</Text>
							</div>
							<div
								className="cell-padding"
								style={{
									display: "flex",
									width: "25%",
								}}
							>
								{
									documentsState.dwg_esecutivo.exist ?
										<a
											href={documentsState.dwg_esecutivo.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("dwg", data.numero_commessa, moment(data.dt_creazione_commessa).format("YYYY"))}` : null}
											target="_blank"
											style={{
												textDecoration: "none"
											}}
										>
											<FileDownloadOutlinedIcon
												style={{
													color: "var(--darkgrey)",
													cursor: "pointer",
												}}
											/>
										</a>
									:
										""
								}
							</div>
							<div
								className="cell-padding"
								style={{
									display: "flex",
									width: "50%",
								}}
							>
								{/*<Img src={AccessoriGreen} /* è cliccabile?  />*/}
							<ImgLavorazione
								keyLavorazione="acc"
								lavorazione={data.punteggi_lavorazioni.acc}
								title={commenti_lavorazioni_obj.acc}
								onClick={()=>{}/*(lavorazione) => {
									if(canEdit(user)){
										data.punteggi_lavorazioni.acc = lavorazione;
										setData({ ...data });
										saveCommessa();
									}
								}*/}
							/>
								<Checkbox
									id="chk_qa"
									large
									checked={data.qa && data.qa.is_done ? data.qa.is_done : false}
									onChange={(e) => {
										const checked = e.target.checked;
										if (checked) {
											data.qa = {
												is_done: true,
												user: user.nomecompleto,
												dt_qa: moment().toISOString(),
											};
										} else {
											data.qa = {
												is_done: false,
												user: "",
												dt_qa: "",
											};
										}
										setData({ ...data });
										saveCommessa();
									}}
									style={{
										marginRight: "16px",
									}}
								/>
								<Checkbox
									id="chk_finito"
									large
									checked={data.cd_status == COMMESSA_STATUS.FINITO}
									onChange={(e) => {
										const checked = e.target.checked;
										if (checked) {
											data.cd_status = COMMESSA_STATUS.FINITO;
										} else {
											data.cd_status = COMMESSA_STATUS.CONFERMATO;
										}
										data.status_history[data.cd_status] =
											moment().toISOString();
										setData({ ...data });
										saveCommessa();
									}}
									style={{
										marginRight: "16px",
									}}
								/>
							</div>
						</div>

						<ProgressBar
							now={(
								(getSumPunteggiLavorazione(true) * 100) /
								getSumPunteggiLavorazione(false)
							).toFixed(2)}
							className={"small"}
							color="var(--red)"
							label={`${getSumPunteggiLavorazione(
								true
							)}/${getSumPunteggiLavorazione(false)}`}
							style={{
								maxWidth: "100%",
								width: "100%",
								marginTop: "12px",
							}}
						/>
					</div>
				</>
			)}
		</Wrapper>
	);
};
