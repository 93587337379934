// All user related database operations can be defined here.

import {
	MATERIALI_GET_ALL,
	MATERIALI_SEARCH,
	GET_MATERIALI_BY_LISTINO_ID,
} from "./constants";
import { get, post } from "./service";

/**
 * Function to fetch all the users.
 */
export const getAll = async (listinoId, aziendaId) => {
	return get(MATERIALI_GET_ALL(listinoId, aziendaId));
};

export const search = async (id, nome) => {
	return get(MATERIALI_SEARCH(id, nome));
};

export const getMaterialiByListinoId = async (listinoId) => {
	return get(GET_MATERIALI_BY_LISTINO_ID(listinoId));
};
