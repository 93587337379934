import React from "react";
import styled from "styled-components";
import { TextField } from "../..";

const Wrapper = styled.div`
  & {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-top: 12px;
    padding-left: 8px;
    padding-rigth: 8px;
    padding-bottom: 12px;
    
  }
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */

export const EditorCell = (props) => {
  

  return (
    <Wrapper
      id={props.id}
      className={props.className}
      style={props.style}
    >
      <TextField
        id={props.id}
        defaultValue={props.defaultValue}
        value={props.value}
        required={props.required}
        lightbackground={props.lightbackground}
        noborder={props.noborder}
        type={props.type}
        placeholder={props.placeholder}
        padding={props.padding ? props.padding : undefined}
        onChange={props.onChange}
        style={{
          maxWidth: "100%"
        }}
      /> 
    </Wrapper>
  );
};
