/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to
 * keep your app working for UI changes and
 * make it independent of network requirements.
 *
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 *
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

// Actual endpoints. Uncomment below section to use actual data.
// export const GET_ALL_USERS = () => `https://jsonplaceholder.typicode.com/users`;
// export const GET_USER_DETAILS = (id) => `https://jsonplaceholder.typicode.com/users/${id}`;

// Local endpoints. Uncomment below section to use dummy local data.

export const LOGIN = () => `/api/signin`;
export const LOGINTOKEN = () => `/api/users`;

export const GET_ALL_USERS = () => `/api/data/users`;
export const GET_USER_DETAILS = (id) => `/api/data/user/${id}`;

export const COMMESSE = (page_number, elements_count) => `/api/commesse${elements_count > 0 ? "?page_number=" + page_number + "&elements_count=" + elements_count : ""}`;
export const COMMESSE_FILTERED = (page_number, elements_count) => `/api/commesse/get_list_filtered${elements_count > 0 ? "?page_number=" + page_number + "&elements_count=" + elements_count : ""}`;
export const COMMESSE_DETAIL = (id) => `/api/commesse/${id}`;
export const COMMESSE_LAVORAZIONE_UPDATE_STATUS = (id) => `/api/commesse/${id}/status_lavorazione`;
export const UPDATE_COMMESSE_LIST = () => '/api/commesse/updateList';
export const DELETE_COMMESSA = (id) => `/api/commesse/deleteSoft/${id}`;
export const CONSEGNA_COMMESSA = (id) => `/api/commesse/deleteHard/${id}`;
export const CONFERMA_ORDINE = () => `/api/commesse/send_conferma_ordine_email`;
export const CHECK_DOCUMENTI = (type, numero_commessa, anno_commessa) => `/api/commesse/documenti/exist/${type}/${numero_commessa}/${anno_commessa}`;
export const DOWNLOAD_DOCUMENTI = (type, numero_commessa, anno_commessa) => `/api/commesse/documenti/${type}/${numero_commessa}/${anno_commessa}`;
export const SEND_PREVENTIVO_CLIENTE = () => `/api/commesse/send_preventivo`;

export const PREVENTIVI = () => `/api/preventivi`;
export const PREVENTIVI_FILTERED = (page_number, elements_count) => `/api/preventivi/get_list_filtered${elements_count > 0 ? "?page_number=" + page_number + "&elements_count=" + elements_count : ""}`;
export const PREVENTIVI_DETAIL = (id) => `/api/preventivi/${id}`;
export const ORDINI = () => `/api/ordini`;
export const ORDINI_FILTERED = (page_number, elements_count) => `/api/ordini/get_list_filtered${elements_count > 0 ? "?page_number=" + page_number + "&elements_count=" + elements_count : ""}`;
export const ORDINI_COUNTER = () => `/api/ordini/counter`;
export const SPEDIZIONI_FILTERED = (page_number, elements_count) => `/api/spedizioni/get_list_filtered${elements_count > 0 ? "?page_number=" + page_number + "&elements_count=" + elements_count : ""}`;
export const DELETE_PREVENTIVO = (id) => `/api/preventivi/${id}`;
export const SEND_MAIL_PREVENTIVO = () => `/api/preventivi/send_new_preventivo_email`;

export const ANAGRAFICHE = () => `/api/clients`;
export const ANAGRAFICHE_FILTERED = (cd_privilege, page_number, elements_count) => `/api/clients/getListFiltered?cd_privilege=${cd_privilege}${elements_count > 0 ? "&page_number=" + page_number + "&elements_count=" + elements_count : ""}`;
export const ANAGRAFICHE_TYPE = (cd_privilege) =>
	`/api/clients?cd_privilege=${cd_privilege}`;
export const ANAGRAFICHE_DETAIL = (id) => `/api/clients/${id}`;
export const CREATE_CLIENT = () => "/api/clients";
export const CREATE_OPERATOR = () => "/api/clients";
export const DELETE_CLIENT = (id) => `/api/clients/${id}`;
export const UPDATE_CLIENT = (id) => `/api/clients/${id}`;
export const SEND_CLIENT_CREDENTIALS = () => `/api/clients/send_client_credential`;
export const SEND_OPERATOR_CREDENTIALS = () => `/api/clients/send_operator_credential`;

export const AZIENDE_GET_ALL = () => `/api/aziende/`;

export const MATERIALI_GET_ALL = (listinoId, aziendaId) => `/api/materiali/?${listinoId ? "listino="+listinoId+"&" : ""}${aziendaId ? "azienda_id="+aziendaId : ""}`;
export const MATERIALI_SEARCH = (id, nome) =>
	`/api/materiali?azienda_id=${id}&nome=${nome}`;

export const GET_LAVORAZIONI = () => `/api/lavorazioni/`;

export const GET_LISTINI = () => `/api/listini/`;

export const LISTINO_DEFAULT = (id) => `/api/listini/setDefaultListino/${id}`;

export const GET_LAVORAZIONI_BY_LISTINO_ID = (listinoId) =>
	`/api/lavorazioni?listino=${listinoId}`;

export const GET_AZIENDE_BY_LISTINO_ID = (listinoId) =>
	`/api/aziende?listino=${listinoId}`;

export const GET_MATERIALI_BY_LISTINO_ID = (listinoId) =>
	`/api/materiali?listino=${listinoId}`;

export const SETTINGS = (id) => `/api/settings/${id ? id : ""}`;
export const UPLOAD_FILE = () => `/statics/uploads`;

export const NEWS_GET_ALL = () => "/api/news";
export const GET_NEWS_BY_ID = (id) => `/api/news/${id}`;
export const DELETE_NEWS = (id) => `/api/news/${id}`;
export const UPDATE_NEWS = (id) => `/api/news/${id}`;
export const CREATE_NEWS = () => `/api/news`;


export const IMPORT_LISTINO = () => `/api/import/all`;



export const PDF_COMMESSE_LIST = (page_type) => `/api/pdf/commesseList/${page_type}`;
export const PDF_SPEDIZIONI_LIST = () => `/api/pdf/spedizioniList`;
export const PDF_CONTEGGI = (commessa_id, user_cd_privilege) => `/api/pdf/conteggi/${commessa_id}/${user_cd_privilege}`;
export const PDF_PREVENTIVO = (commessa_id, user_cd_privilege) => `/api/pdf/preventivo/${commessa_id}/${user_cd_privilege}`;
export const PDF_ETICHETTE = (commessa_id) => `/api/pdf/preventivo/${commessa_id}`;
