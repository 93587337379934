import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Add as AddIcon } from "@material-ui/icons";
import {
	Button,
	SelectField,
	Text,
	Spacer,
	TextFieldWithIcon,
	DatePickerComponent,
} from "../index";
import { Row, Col } from "react-bootstrap";
import { useWindowSize } from "../../hooks";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { NewOrderModal } from "../../components";
import { USER_ROLES_PREVENTIVI, USER_ROLES } from "../../utils/constants";
import moment from "moment";

export const BlockFilterPreventivi = (props) => {
	const { width } = useWindowSize();
	const user = useSelector((state) => state.user || {});

	const [filterObj, setFilterObj] = useState({});
	const [typology, setTypology] = useState({});
	const changeTypology = (value) => {
		console.log(value);
		setTypology(value);
	};
	const [count, setCount] = useState({});
	const [dateFrom, setDateFrom] = useState("");
	const [dateTo, setDateTo] = useState("");
	const [preventiveSearch, setPreventiveSearch] = useState("");
	const [newOrderModalShow, setNewOrderModalShow] = useState(false);
	const [filtersDate, setFiltersDate] = useState({
		
		dal: "",
		al: "",
		
	});

	const setDate = (id, value) => {
		props.setFilters({
			...props.filters,
			[id]: value,
		});
	};

	return (
		<>
			<Row
				className="w-100 mx-0"
				style={{
					marginTop: "32px",
				}}
			>
				<Col
					lg={{ span: 4, order: 2 }}
					className="mx-0 px-0 d-flex justify-content-lg-end"
				>
					<TextFieldWithIcon
						id="search"
						className="textfield-md"
						placeholder="blockFilterPreventivi.search"
						value={props.selected.search}
						onChange={(id, value) => props.setFilter("search", value)}
					/>
					<Spacer width="16px" />
					<Button variant="primary" onClick={() => setNewOrderModalShow(true)}>
						<AddIcon
							style={{
								width: "24px",
								height: "24px",
								marginRight: "8px",
							}}
						/>
						<Text size="small" weight="medium">
							{"blockFilterPreventivi.new"}
						</Text>
					</Button>
					<NewOrderModal
						show={newOrderModalShow}
						isPreventivo={true}
						defaultClientId={user.cd_privilege == USER_ROLES.CLIENT ? user._id : null}
						onHide={() => setNewOrderModalShow(false)}
						onCancel={() => setNewOrderModalShow(false)}
					/>
				</Col>

				<Col lg={{ span: 8, order: 1 }} className="mx-0 px-0">
					<Row className="w-100 mx-0 mt-3 mt-lg-0 d-flex align-items-center flex-wrap justify-content-between justify-content-lg-start">
						<Col
							lg={4}
							className={`d-flex ${width < 992 ? "px-0" : "padding-left-0"}`}
						>
							<SelectField
								id=""
								lightbackground
								value={props.selected.typology}
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={USER_ROLES_PREVENTIVI}
								onChange={(id, value) => props.setFilter("typology", value)}
							/>
							<Spacer width="1rem" />
							
						</Col>
						<Col
							lg={4}
							className={`d-flex ${
								width < 992 ? "px-0" : "padding-left-0"
							} justify-content-between justify-content-lg-start mt-2 mt-lg-0`}
						>
							<DatePickerComponent
								id="dal"
								className="date-start"
								//selectedDate={filtersDate.dt_inizio_lavorazione}
								selectedDate={props.filters.dal}
								removeMaxDate={props.filters.al == ""}
								maxDate={props.filters.al}
								backgroundColor="var(--white)"
								placeholderText="blockFilterPreventivi.dtFrom"
								onChange={setDate}
								// onChange={(id, value) => {
								// 	filtersDate.dt_inizio_lavorazione = value;
								// 	props.setFilter("dt_inizio_lavorazione", value);
								// 	setFiltersDate({ ...filtersDate });
								// }}
							/>
							<Spacer width="1rem" />
							<DatePickerComponent
								id="al"
								className="date-end"
								//selectedDate={filtersDate.dt_fine_lavorazione}
								selectedDate={props.filters.al}
								removeMinDate={props.filters.da == ""}
								minDate={props.filters.da}
								backgroundColor="var(--white)"
								placeholderText="blockFilterPreventivi.dtAt"
								onChange={setDate}
								// onChange={(id, value) => {
								// 	filtersDate.dt_fine_lavorazione = value;
								// 	props.setFilter("dt_fine_lavorazione", value);
								// 	setFiltersDate({ ...filtersDate });
								// }}
							/>
						</Col>
						<Col lg={4} className="px-0 mt-2 mt-lg-0">
							<Button
								variant="outline-secondary"
								style={{
									marginRight: "16px",
								}}
								onClick={props.clearFilters}
							>
								<AutorenewOutlinedIcon
									style={{
										width: "20px",
										height: "20px",
										marginRight: "8px",
									}}
								/>
								<Text>
									{"blockFilterPreventivi.clearFilter"}
								</Text>
								
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
