// All user related database operations can be defined here.

import {
	GET_LAVORAZIONI,
	GET_LAVORAZIONI_BY_LISTINO_ID,
} from "./constants";
import { get, post } from "./service";

/**
 * Function to fetch all the users.
 */
export const getLavorazioni = async () => {
	return get(GET_LAVORAZIONI());
};

export const getLavorazioniByListinoId = async (listinoId) => {
	return get(GET_LAVORAZIONI_BY_LISTINO_ID(listinoId));
};
