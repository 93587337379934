import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row as ReactRow, Col as ReactCol } from "react-bootstrap";
import {
	Breadcrumb,
	Row,
	Card,
	Text,
	TextField,
	SelectField,
	Checkbox,
	Button,
	BottomBar,
	Spacer,
	DynamicList,
	NewOrderModal,
	ConfirmAlertModal,
	AddUtenzeCliente,
	AddEmail,
	AddSedeSecondaria,
	Loader,
	TextAreaField
} from "../../components";
import { Add as AddIcon, CloudUpload as UploadIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import {
	getClientDetails,
	getListini,
	getLavorazioniByListinoId,
	getAziendeByListinoId,
	getMaterialiByListinoId,
	createClient,
	updateClient,
	deleteClient
} from "../../services";
import {
	GRUPPI_ANAGRAFICHE,
	ZONE,
	AGENTI,
	PRIORITA,
} from "../../utils/constants";

import moment from "moment";
import { isEmailValid } from "../../utils";
import { useNavigate } from "react-router-dom";
import { ANAGRAFICHE, ANAGRAFICHEDETAIL } from "../../navigation/constants";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux";
import AddDocument from "../../components/modals/commesse/components/addDocument";
import { Col, Row as RowB } from "react-bootstrap";
import { t } from "i18next";
import { withTranslation } from "react-i18next";


const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 40px;
`;

const DivClick = styled.div`
	& {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
	&,
	& > * {
		cursor: pointer;
	}
	& > svg {
		color: var(--grey-2);
		&:hover {
			color: var(--black);
		}
	}
`;

export const AnagraficheDetail = withTranslation()((props) => {
	const { t } = props;
	const location = useLocation();
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);
	const [anagraficaId, setAnagraficaId] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const toast = useSelector((state) => state.toast || {});
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [breadcrumbLevels, setBreadcrumbLevels] = useState([
		{
			text: "anagraficheDetail.breadcrumbTitle",
			link: ANAGRAFICHE,
		},
		{
			text: "anagraficheDetail.breadcrumbSubtitle",
		},
	]);
	const [newOrderModalShow, setNewOrderModalShow] = useState(false);
	const [showCommessaModal, setShowCommessaModal] = useState(false);
	const [anagrafica, setAnagrafica] = useState({});
	const [sediSecondarie, setSediSecondarie] = useState([]);
	const [listino, setListino] = useState({});
	const [listinoLavorazioni, setListinoLavorazioni] = useState([]);
	const [lavorazioniDaScontare, setLavorazioniDaScontare] = useState([
		{ codice: "", text: "-", value: "" },
	]);
	const [lavorazioniDaScontareOptions, setLavorazioniDaScontareOptions] =
		useState([]);
	const [scontiAzienda, setScontiAzienda] = useState([
		{ _id: "", text: "-", value: "" },
	]);
	const [scontiAziendaOptions, setScontiAziendaOptions] = useState([]);
	const [scontiMateriale, setScontiMateriale] = useState([
		{ codice: "", text: "-", value: "" },
	]);
	const [scontiMaterialeOptions, setScontiMaterialeOptions] = useState([]);
	const [isInvioMailOrdineFinito, setIsInvioMailOrdineFinito] = useState(true);
	const [isInvioMailNuovaCommessa, setIsInvioMailNuovaCommessa] =
		useState(true);
	const [isContenzioso, setIsContenzioso] = useState(true);
	const [isImballoObbligatorio, setIsImballoObbligatorio] = useState(true);
	const [isVisualizzaStampaConteggi, setIsVisualizzaStampaConteggi] =
		useState(true);
	const [isAbilitaLogoCliente, setIsAbilitaLogoCliente] = useState(true);

	// =================================================================================
	// Startup
	// =================================================================================
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setAnagraficaId(queryParams.get("anagraficaId"));
	}, []);

	useEffect(() => {
		if(anagraficaId) {
			executeGetAnagraficaDetail();
			setSediSecondarie(getSediSecondarie());
		} else {
			setAnagrafica({
				abilita_logo_cliente: "",
				agente: "-",
				cd_privilege: "CMS-ACCESS-CLIENT",
				cellulare: "",
				citta: "",
				codicefiscale: "",
				contenzioso: true,
				costo_ordine: {descrizione: "", prezzo: null},
				credentials: [],
				email_invio_documenti: "",
				emails: [{ email: "" }],
				gruppo: "-",
				imballo_obbligatorio: true,
				indirizzo: "",
				indirizzo_consegna: "",
				invia_mail_nuova_commessa: "",
				invia_mail_ordine_finito: "",
				lavorazioni_scontate: [],
				listino: "",
				logo_cliente: { file_url: "", filename: "", _id: "" },
				materiali_scontati: [],
				nomecompleto: "",
				note_consegna: "",
				note_contenzioso: "",
				note_esposizione: "",
				note_generali: "",
				p_iva: "",
				priority: "-",
				provincia: "",
				ragione_sociale: "",
				sconto_azienda: [],
				sconto_generale_lavorazioni: "",
				sconto_generale_materiali: "",
				sconto_imballo: "",
				sedi_secondarie: [{ address: "" }],
				telefono: "",
				visualizza_stampa_conteggi: "",
				zipcode: "",
				zona: "-",
				zona_consegna: "-",
				sconto_incondizionato: { primo: "", secondo: "" },
			});

			setIsLoading(false);
		}
	}, [anagraficaId]);


	const executeGetAnagraficaDetail = () => {
		if (anagraficaId) {
			getClientDetails(anagraficaId).then(({ result, error }) => {
				if (error) {
				} else {
					result.sconto_incondizionato = result.sconto_incondizionato || {
						primo: "",
						secondo: "",
					};

					setAnagrafica({...result});

					const lavorazioni_scontate = result.lavorazioni_scontate.map(
						(item) => ({
							codice: item.lavorazione,
							value: item.sconto,
						})
					);

					const sconto_azienda = result.sconto_azienda.map((item) => ({
						_id: item.azienda,
						value: item.sconto,
					}));

					const materiali_scontati = result.materiali_scontati.map((item) => ({
						codice: item.materiale,
						value: item.sconto,
					}));

					setIsInvioMailOrdineFinito(result.invia_mail_ordine_finito);
					setIsInvioMailNuovaCommessa(result.invia_mail_nuova_commessa);
					setIsContenzioso(result.contenzioso);
					setIsImballoObbligatorio(result.imballo_obbligatorio);
					setIsVisualizzaStampaConteggi(result.visualizza_stampa_conteggi);
					setIsAbilitaLogoCliente(result.abilita_logo_cliente);

					if (lavorazioni_scontate && lavorazioni_scontate.length > 0) {
						setLavorazioniDaScontare(lavorazioni_scontate);
					}
					if (sconto_azienda && sconto_azienda.length > 0) {
						setScontiAzienda(sconto_azienda);
					}
					if (materiali_scontati && materiali_scontati.length > 0) {
						setScontiMateriale(materiali_scontati);
					}

					setBreadcrumbLevels([
						{
							text: "anagraficheDetail.breadcrumbTitle",
							link: ANAGRAFICHE,
						},
						{
							text: result.nomecompleto,
						},
					]);
				}

				setIsLoading(false);
			});
		}
	};

	const executeGetListini = () => {
		getListini().then(({ result, error }) => {
			if (error) {
				console.error(error);
			} else {
				setListinoLavorazioni(result);

				if (anagrafica.listino === "" && result.length > 0) {
					const listinoDefault = result.find((item) => item.is_default);
					setListino(listinoDefault);
					setAnagrafica({...anagrafica, listino: listinoDefault._id});

				} else {
					for (let reslistino of result) {
						if (reslistino._id === anagrafica.listino) {
							setListino(reslistino);
						}
					}
				}
			}
		});
	};

	// =================================================================================
	useEffect(() => {
		if(anagrafica._id || !anagraficaId) {
			executeGetListini();
		}
	}, [anagrafica]);

	useEffect(() => {
		if (listino && listino._id) {
			getLavorazioniByListinoId(listino._id).then(({ result, error }) => {
				if (error) {
					console.error(error);
				} else {
					const listFiltered = [];

					result.forEach((lavorazione) => {
						if	(!listFiltered.some((item) => item.codice == lavorazione.codice)){
							listFiltered.push(lavorazione);
						}
					});

					setLavorazioniDaScontareOptions([{ codice: "", descrizione: "-" }, ...listFiltered]);
				}
			});

			getAziendeByListinoId(listino._id).then(({ result, error }) => {
				if (error) {
					console.error(error);
				} else {
					const aziendeScontabiliOptions = [{ _id: "", nome: "-" }, ...result];
					setScontiAziendaOptions(aziendeScontabiliOptions);
				}
			});

			getMaterialiByListinoId(listino._id).then(({ result, error }) => {
				if (error) {
					console.error(error);
				} else {
					const materialiScontabiliOptions = [
						{ codice: "", nome: "-" },
						...result.map((materiale) => {
							return {
								codice: materiale.codice,
								nome: `${materiale.nome} - ${materiale.spessore}`,
							};
						}),
					];
					setScontiMaterialeOptions(materialiScontabiliOptions);
				}
			});
		}
	}, [listino]);

	// =================================================================================
	// onChanges
	// =================================================================================
	const onChange = (id, value, isValid) => {
		const routes = id.split(".");
		if (routes.length > 1) {
			anagrafica[routes[0]][routes[1]] = value;
		} else {
			anagrafica[id] = value;
		}
		setAnagrafica({ ...anagrafica });
	};

	const onChangeNumber = (id, value, isValid) => {
		if (isNaN(value)) return;
		onChange(id, value, isValid);
	};

	const onChangeSales = (id, value, isValid) => {
		if (isNaN(value)) return;
		if (value < 0 || value > 100) return;
		onChange(id, value, isValid);
	};

	// --------------------------------------------------------------------------------
	// Lavorazioni da scontare
	const onChangeLavorazioneDaScontare = (old_codice, codice) => {
		const newLavorazione = {
			codice: "",
			text: "",
			value: "",
		};

		if (codice === "") {
			let newLavorazioni = lavorazioniDaScontare.filter(
				(item) => item.codice !== codice
			);
			newLavorazioni.push(newLavorazione);
			setLavorazioniDaScontare([...newLavorazioni]);
		} else {
			let option = lavorazioniDaScontareOptions.find((e) => e.codice === codice);
			if (option) {
				newLavorazione.codice = option.codice;
				newLavorazione.text = option.descrizione;
			}

			let newLavorazioniList = lavorazioniDaScontare.map((item) =>
				item.codice === old_codice ? newLavorazione : item
			);

			//const found = lavorazioniDaScontare.find((e) => e.codice === old_codice);

			//let newLavorazioni = lavorazioniDaScontare;

			/*if (found) {
				newLavorazioni = lavorazioniDaScontare.map((item) =>
					item.codice === old_codice ? newLavorazione : item
				);
			}*/

			setLavorazioniDaScontare([...newLavorazioniList]);
		}
	};

	const onChangeValueLavorazioneDaScontare = (codice, value, isValid) => {
		if (isNaN(value) || value < 0 || value > 100) return;
		if (codice && codice !== "") {
			let newValues = lavorazioniDaScontare.map((item) =>
				item.codice === codice ? { ...item, value: value } : item
			);
			setLavorazioniDaScontare([...newValues]);
		}
	};

	const addLavorazioneScontata = () => {
		if (
			lavorazioniDaScontare &&
			lavorazioniDaScontare[lavorazioniDaScontare.length - 1] &&
			lavorazioniDaScontare[lavorazioniDaScontare.length - 1].codice !== ""
		) {
			setLavorazioniDaScontare([
				...lavorazioniDaScontare,
				{ codice: "", text: "-", value: "" },
			]);
		}
	};


	const removeItemFromLavorazioniScontate = (codice) => {
		let newLavorazioni = lavorazioniDaScontare.filter(
			(item) => item.codice !== codice
		);
		if (newLavorazioni.length == 0) {
			newLavorazioni = [{ codice: "", text: "-", value: "" }];
		}
		setLavorazioniDaScontare([...newLavorazioni]);
	};

	// --------------------------------------------------------------------------------
	// Sconti Aziende
	const onChangeScontiAziende = (id, value, isValid) => {
		if (value === "") {
			let newSconti = scontiAzienda.filter((item) => item._id !== id);
			newSconti.push({
				_id: "",
				text: "",
				value: "",
			});
			setScontiAzienda([...newSconti]);
		} else {
			const newSconto = {
				_id: "",
				text: "",
				value: "",
			};

			let option = scontiAziendaOptions.find((e) => e._id === value);
			if (option) {
				newSconto._id = option._id;
				newSconto.text = option.descrizione;
			}

			const found = scontiAzienda.find((e) => e._id === id);

			let newSconti = scontiAzienda;
			if (found) {
				newSconti = scontiAzienda.map((item) =>
					item._id === "" ? newSconto : item
				);
			}

			setScontiAzienda([...newSconti]);
		}
	};

	const onChangeValueScontiAzienda = (id, value, isValid) => {
		if (isNaN(value) || value < 0 || value > 100) return;
		if (id && id !== "") {
			let newValues = scontiAzienda.map((item) =>
				item._id === id ? { ...item, value: value } : item
			);
			setScontiAzienda([...newValues]);
		}
	};

	const addScontoAzienda = () => {
		if (
			scontiAzienda &&
			scontiAzienda[scontiAzienda.length - 1] &&
			scontiAzienda[scontiAzienda.length - 1]._id !== ""
		) {
			setScontiAzienda([...scontiAzienda, { _id: "", text: "-", value: "" }]);
		}
	};

	const removeItemFromAziendeScontate = (id) => {
		let newSconti = scontiAzienda.filter(
			(item) => item._id !== id
		);
		if (newSconti.length == 0) {
			newSconti = [{ _id: "", text: "-", value: "" }];
		}
		setScontiAzienda([...newSconti]);
	};

	// --------------------------------------------------------------------------------
	// Sconti Materiale
	const onChangeScontiMateriale = (codice, value, isValid) => {
		if (value === "") {
			let newMateriali = scontiMateriale.filter((item) => item.codice !== codice);
			newMateriali.push({
				codice: "",
				text: "",
				value: "",
			});
			setScontiMateriale([...newMateriali]);
		} else {
			const newMateriale = {
				codice: "",
				text: "",
				value: "",
			};

			let option = scontiMaterialeOptions.find((e) => e.codice === value);

			if (option) {
				newMateriale.codice = option.codice;
				newMateriale.text = `${option.nome || ""} ${option.spessore || ""}`;
			}

			const found = scontiMateriale.find((e) => e.codice === codice);

			let newMateriali = scontiMateriale;
			if (found) {
				newMateriali = scontiMateriale.map((item) =>
					item.codice === "" ? newMateriale : item
				);
			}

			setScontiMateriale([...newMateriali]);
		}
	};

	const onChangeValueScontiMateriale = (codice, value, isValid) => {
		if (isNaN(value) || value < 0 || value > 100) return;
		if (codice && codice !== "") {
			let newValues = scontiMateriale.map((item) =>
				item.codice === codice ? { ...item, value: value } : item
			);
			setScontiMateriale([...newValues]);
		}
	};

	const addScontoMateriale = () => {
		if (
			scontiMateriale &&
			scontiMateriale[scontiMateriale.length - 1] &&
			scontiMateriale[scontiMateriale.length - 1].codice !== ""
		) {
			setScontiMateriale([
				...scontiMateriale,
				{ codice: "", text: "-", value: "" },
			]);
		}
	};

	const removeItemFromMaterialiScontati = (codice) => {
		let newMateriali = scontiMateriale.filter(
			(item) => item.codice !== codice
		);
		if (newMateriali.length == 0) {
			newMateriali = [{ codice: "", text: "-", value: "" }];
		}
		setScontiMateriale([...newMateriali]);
	};

	// ================================================================================
	// Crea / Modifica anagrafica
	// ================================================================================

	const createAnagrafica = () => {
		const { ok, toSave, errors } = validateObject();
		if (ok) {
			createClient(toSave).then(({ result, errors }) => {
				if (errors) {
					console.error(errors);
					dispatch(
						setToast({
							show: true,
							title: "anagraficheDetail.toastCreate.error.title",
							description: "anagraficheDetail.toastCreate.error.description",
							variant: "danger",
						})
					);
				} else if (result === undefined || !result.success || !result._id) {
					console.error("Qualcosa è andato storto");
					dispatch(
						setToast({
							show: true,
							title: "anagraficheDetail.toastCreate.error2.title",
							description: "anagraficheDetail.toastCreate.error2.description",
							variant: "danger",
							delay: 7000
						})
					);
				} else {
					dispatch(
						setToast({
							show: true,
							title: "anagraficheDetail.toastCreate.success.title",
							description: "anagraficheDetail.toastCreate.success.description",
							variant: "success",
						})
					);
					//setAnagraficaId(result._id);
					window.location.href = '/anagrafiche/detail?anagraficaId=' + result._id
					//navigate(ANAGRAFICHEDETAIL+`?anagraficaId=${result._id}`);
					//navigate(ANAGRAFICHE);
					//executeGetAnagraficaDetail();
				}
			});
		} else {
			console.error("Ci sono errori:");
			console.error(errors);
			dispatch(
				setToast({
					show: true,
					title: "anagraficheDetail.toastCreate.error.title",
					description: "anagraficheDetail.toastCreate.error.description",
					variant: "danger",
				})
			);
		}
	};

	/**
	 * Modifica anagrafica
	 */
	const saveAnagrafica = () => {
		const { ok, toSave, errors } = validateObject();
		if (ok) {
			updateClient(toSave._id, toSave).then(({ result, errors }) => {
				if (errors) {
					console.error(errors);
					dispatch(
						setToast({
							show: true,
							title: "anagraficheDetail.toastUpdate.error.title",
							description: "anagraficheDetail.toastUpdate.error.description",
							variant: "danger",
						})
					);
				} else if (result === undefined || !result.success) {
					console.error("Qualcosa è andato storto");
					dispatch(
						setToast({
							show: true,
							title: "anagraficheDetail.toastUpdate.error2.title",
							description: "anagraficheDetail.toastUpdate.error2.description",
							variant: "danger",
						})
					);
				} else {
					dispatch(
						setToast({
							show: true,
							title: "anagraficheDetail.toastUpdate.success.title",
							description: "anagraficheDetail.toastUpdate.success.description",
							variant: "success",
						})
					);
					executeGetAnagraficaDetail();
				}
			});
		} else {
			if (errors.credentials.length > 0) {
				let description = "";
				if (errors.credentials.length > 1) {
					description = `Non è stato possibile aggiornare il cliente: gli utenti ${errors.credentials
						.map((credential) =>
							credential.fullname !== ""
								? credential.fullname
								: credential.telefono
						)
						.join(", ")} devono avere un'email associata`;
				} else {
					description = `Non è stato possibile aggiornare il cliente: l'utente ${errors.credentials.map(
						(credential) =>
							credential.fullname !== ""
								? credential.fullname
								: credential.telefono
					)} deve avere un'email associata`;
				}

				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description: description,
						variant: "danger",
					})
				);
			} else {
				console.error("Ci sono errori:");
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "anagraficheDetail.toastUpdate.error.title",
						description: "anagraficheDetail.toastUpdate.error.description",
						variant: "danger",
					})
				);
			}
		}
	};

	/**
	 * Validazione per creazione o modifica anagrafica
	 */
	const validateObject = () => {
		let allOk = true;
		let errors = {};

		const toSave = anagrafica;

		toSave.listino = listino._id || "";

		// controllo le email
		if (anagrafica.emails && anagrafica.emails.length > 0) {
			anagrafica.emails = anagrafica.emails.filter(
				(email) => email.email !== ""
			);
			for (let email of anagrafica.emails) {
				if (email.email !== "" && !isEmailValid(email.email)) {
					errors.emails = errors.emails || [];
					errors.emails.push(email.email);
					allOk = false;
				}
			}
		}

		// controllo le credenziali
		anagrafica.credentials = anagrafica.credentials.filter(
			(item) =>
				item.fullname !== "" || item.telefono !== "" || item.email !== ""
		);

		for (let credential of anagrafica.credentials) {
			if (
				credential.telefono &&
				credential.telefono !== "" &&
				isNaN(credential.telefono)
			) {
				errors.credentials = errors.credentials || [];
				errors.credentials.push(credential);
				allOk = false;
			}

			if (credential.email === "" || !isEmailValid(credential.email)) {
				errors.credentials = errors.credentials || [];
				errors.credentials.push(credential);
				allOk = false;
			}
		}

		toSave.invia_mail_ordine_finito = isInvioMailOrdineFinito;
		toSave.invia_mail_nuova_commessa = isInvioMailNuovaCommessa;
		toSave.contenzioso = isContenzioso;
		toSave.lavorazioni_scontate = lavorazioniDaScontare
			.filter((item) => item.value != "")
			.map((item) => ({
				lavorazione: item.codice,
				sconto: item.value,
			}));
		toSave.sconto_azienda = scontiAzienda
			.filter((item) => item.value != "")
			.map((item) => ({
				azienda: item._id,
				sconto: item.value,
			}));
		toSave.materiali_scontati = scontiMateriale
			.filter((item) => item.value != "")
			.map((item) => ({
				materiale: item.codice,
				sconto: item.value,
			}));
		toSave.imballo_obbligatorio = isImballoObbligatorio;
		toSave.visualizza_stampa_conteggi = isVisualizzaStampaConteggi;
		toSave.abilita_logo_cliente = isAbilitaLogoCliente;

		toSave.lavorazioni_scontate = toSave.lavorazioni_scontate.filter(
			(item) => item.codice !== ""
		);
		toSave.materiali_scontati = toSave.materiali_scontati.filter(
			(item) => item.codice !== ""
		);
		toSave.sconto_azienda = toSave.sconto_azienda.filter(
			(item) => item._id !== ""
		);
		toSave.sedi_secondarie = toSave.sedi_secondarie.filter(
			(item) => item.address !== ""
		);

		// TODO: add logo
		return { ok: allOk, toSave: toSave, errors: errors };
	};

	// ================================================================================
	// Delete
	// ================================================================================
	const confermaEliminazione = () => {
		setConfirmAlertModalShow(true);
	}

	const del = () => {
		deleteClient(anagrafica._id).then(({ result, errors }) => {
			if (errors) {
				console.error(errors);
				setConfirmAlertModalShow(false)
				dispatch(
					setToast({
						show: true,
						title: "anagraficheDetail.toastDelete.error.title",
						description: "anagraficheDetail.toastDelete.error.description",
						variant: "danger",
					})
				);
			} else {
				setConfirmAlertModalShow(false)
				dispatch(
					setToast({
						show: true,
						title: "anagraficheDetail.toastDelete.success.title",
						description: "anagraficheDetail.toastDelete.success.description",
						variant: "success",
					})
				);
				navigate(ANAGRAFICHE);
			}
		});
	};

	// --------------------------------------------------------------------------------
	const getSediSecondarie = () => {
		let sedi = [];
		if (anagrafica.sedi_secondarie) {
			anagrafica.sedi_secondarie.map((sede) =>
				sedi.push({ value: sede.address })
			);
		}
		sedi.push({ value: "" });
		return sedi;
	};



	return (
		isLoading ?
			<div
				style={
					{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: "56px"
					}
				}
			>
				<Loader
					layer={3}
					color="var(--red)"
				/>
			</div>
		:
		<Wrapper>
			<Breadcrumb
				levels={breadcrumbLevels}
				onClick={() => navigate(ANAGRAFICHE)}
			/>

			<Row
				style={{
					marginTop: "32px",
					alignItems: "flex-start",
				}}
				breakpoints={[
					{
						breakpoint: "10000",
						defaultWidth: 100,
						widthOverride: {
							".first-card": 60,
							".second-card": 40,
						},
					},
					{
						breakpoint: "1000",
						defaultWidth: 100,
					},
				]}
			>
				<div
					className="first-card"
					style={{
						display: "flex",
						flexDirection: "column",
						className: "first-card",
					}}
				>
					{anagrafica._id && (
						<Card
							style={{
								padding: "32px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexDirection: "row",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="xxlarge" weight="bold" color="var(--black)">
									{(anagrafica.ragione_sociale ? anagrafica.ragione_sociale : "-") + " / "+ (anagrafica.nomecompleto ? anagrafica.nomecompleto : "")}
								</Text>

								<Text size="large" weight="regular" color="var(--darkgrey)">
									{t("anagraficheDetail.dataCreazione", {date: anagrafica.createdAt ? moment(anagrafica.createdAt).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY")})}
								</Text>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="large" weight="regular" color="var(--grey-2)">
									{"anagraficheDetail.lastUpdate"}
								</Text>
								<Text
									size="large"
									weight="regular"
									color="var(--grey-2)"
									style={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									{anagrafica.updatedAt
										? moment(anagrafica.updatedAt).format("DD/MM/YYYY, HH:mm")
										: moment().format("DD/MM/YYYY, HH:mm")}
								</Text>
							</div>
						</Card>
					)}

					<Card
						style={{
							padding: !anagrafica._id ? "" : "32px",
							width: "100%",
							marginTop: !anagrafica._id ? "" : "40px",
						}}
					>
						<Text size="large" weight="bold">
							{"anagraficheDetail.title"}
						</Text>

						<Text size="medium" weight="regular">
							{"anagraficheDetail.description"}
						</Text>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "40px",
							}}
						>
							<TextField
								id=""
								className="cursor-default"
								type="text"
								value=""
								label="anagraficheDetail.typologiLabel"
								placeholder="hint_insert"
								onChange={() => {}}
								readOnly={true}
							/>

							<SelectField
								id="gruppo"
								type="text"
								label="anagraficheDetail.groupLabel"
								placeholder="hint_select"
								value={anagrafica.gruppo}
								options={GRUPPI_ANAGRAFICHE}
								onChange={onChange}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "60px",
							}}
						>
							<TextField
								id="ragione_sociale"
								type="text"
								value={anagrafica.ragione_sociale || ""}
								label="anagraficheDetail.ragioneSocialeLabel"
								placeholder="hint_insert"
								onChange={onChange}
							/>

							<TextField
								id="nomecompleto"
								type="text"
								value={anagrafica.nomecompleto || ""}
								label="anagraficheDetail.nameSurname"
								placeholder="hint_insert"
								onChange={onChange}
							/>

							<TextField
								id="p_iva"
								type="text"
								value={anagrafica.p_iva || ""}
								label="anagraficheDetail.pIva"
								placeholder="hint_insert"
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>

							<TextField
								id="codicefiscale"
								type="text"
								value={anagrafica.codicefiscale || ""}
								label="anagraficheDetail.fiscalCode"
								placeholder="hint_insert"
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "60px",
							}}
						>
							<SelectField
								id="zona"
								type="text"
								label="anagraficheDetail.shopZone"
								placeholder="hint_select"
								value={anagrafica.zona}
								options={ZONE}
								onChange={onChange}
							/>

							<TextField
								id="indirizzo"
								type="text"
								value={anagrafica.indirizzo || ""}
								label="anagraficheDetail.address"
								placeholder="hint_insert"
								onChange={onChange}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "40px",
							}}
							breakpoints={[
								{
									breakpoint: "10000",
									defaultWidth: 25,
									widthOverride: {
										".citta": 50,
									},
								},
							]}
						>
							<TextField
								id="citta"
								type="text"
								value={anagrafica.citta || ""}
								label="anagraficheDetail.city"
								placeholder="hint_insert"
								onChange={onChange}
								className="citta"
								style={{
									marginTop: "20px",
								}}
							/>

							<TextField
								id="zipcode"
								type="text"
								value={anagrafica.zipcode || ""}
								label="anagraficheDetail.cap"
								placeholder="hint_insert"
								onChange={onChangeNumber}
								style={{
									marginTop: "20px",
								}}
							/>

							<TextField
								id="provincia"
								type="text"
								value={anagrafica.provincia || ""}
								label="anagraficheDetail.province"
								placeholder="hint_insert"
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>
						</Row>

						<AddSedeSecondaria
							list={anagrafica.sedi_secondarie}
							onListChange={(newList) => {
								anagrafica.sedi_secondarie = newList;
								setAnagrafica({ ...anagrafica });
							}}
							style={{
								marginTop: "20px",
							}}
						/>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "60px",
							}}
						>
							<TextField
								id="telefono"
								type="text"
								value={anagrafica.telefono || ""}
								label="anagraficheDetail.telephone"
								placeholder="hint_insert"
								onChange={onChange}
							/>

							<TextField
								id="cellulare"
								type="text"
								value={anagrafica.cellulare || ""}
								label="anagraficheDetail.phone"
								placeholder="hint_insert"
								onChange={onChange}
							/>
						</Row>

						<AddEmail
							list={anagrafica.emails}
							onListChange={(newList) => {
								anagrafica.emails = newList;
								setAnagrafica({ ...anagrafica });
							}}
							style={{
								marginTop: "20px",
							}}
						/>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "40px",
							}}
						>
							<TextField
								id="email_invio_documenti"
								type="text"
								value={anagrafica.email_invio_documenti || ""}
								label="anagraficheDetail.emailDocuments"
								placeholder="hint_insert"
								onChange={onChange}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "40px",
							}}
						>
							<div
								style={{
									justifyContent: "space-between",
									display: "flex",
								}}
							>
								<Text size="medium" weight="regular" color="var(--black)">
									{"anagraficheDetail.sendMailOrderFinished"}
								</Text>

								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Checkbox
										id="chk_order_ended_yes"
										type="radio"
										name="radioOrderEnded"
										label="anagraficheDetail.checkboxYes"
										checked={isInvioMailOrdineFinito === true}
										onChange={() => setIsInvioMailOrdineFinito(true)}
										style={{
											marginRight: "24px",
										}}
									/>
									<Checkbox
										id="chk_order_ended_no"
										type="radio"
										name="radioOrderEnded"
										label="anagraficheDetail.checkboxNo"
										checked={isInvioMailOrdineFinito === false}
										onChange={() => setIsInvioMailOrdineFinito(false)}
										style={{
											marginRight: "24px",
										}}
									/>
								</div>
							</div>

							<div
								style={{
									justifyContent: "space-between",
									display: "flex",
								}}
							>
								<Text size="medium" weight="regular" color="var(--black)">
									{"anagraficheDetail.sendMailNewCommessa"}
								</Text>

								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Checkbox
										id="chk_new_commessa_yes"
										type="radio"
										name="radioNewCommessa"
										label="anagraficheDetail.checkboxYes"
										checked={isInvioMailNuovaCommessa === true}
										onChange={() => setIsInvioMailNuovaCommessa(true)}
										style={{
											marginRight: "24px",
										}}
									/>
									<Checkbox
										id="chk_new_commessa_no"
										type="radio"
										name="radioNewCommessa"
										label="anagraficheDetail.checkboxNo"
										checked={isInvioMailNuovaCommessa === false}
										onChange={() => setIsInvioMailNuovaCommessa(false)}
										style={{
											marginRight: "24px",
										}}
									/>
								</div>
							</div>
						</Row>

						<Row
							defaultWidth={100}
							style={{
								marginTop: "60px",
							}}
						>
							<TextField
								id="note_generali"
								type="text"
								label="anagraficheDetail.generalNotes"
								placeholder="hint_insert"
								value={anagrafica.note_generali || ""}
								onChange={onChange}
							/>

							<TextField
								id="note_consegna"
								type="text"
								label="anagraficheDetail.deliveryNotes"
								placeholder="hint_insert"
								value={anagrafica.note_consegna || ""}
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "20px",
							}}
						>
							<SelectField
								id="zona_consegna"
								type="text"
								label="anagraficheDetail.deliveryZone"
								placeholder="hint_select"
								value={anagrafica.zona_consegna}
								options={ZONE}
								onChange={onChange}
							/>

							<TextField
								id="indirizzo_consegna"
								type="text"
								label="anagraficheDetail.deliveryAddress"
								placeholder="hint_insert"
								value={anagrafica.indirizzo_consegna || ""}
								onChange={onChange}
							/>
						</Row>

						<Row
							defaultWidth={100}
							style={{
								marginTop: "20px",
							}}
						>
							<TextAreaField
								id="note_esposizione"
								label="anagraficheDetail.expositionNotes"
								labelColor="var(--darkgrey)"
								rows={4}
								placeholder="hint_insert"
								value={anagrafica.note_esposizione || ""}
								onChange={onChange}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "20px",
								alignItems: "center"
							}}
						>
							<div
								style={{
									justifyContent: "space-between",
									display: "flex"
								}}
							>
								<Text size="medium" weight="regular" color="var(--black)">
									{"anagraficheDetail.contenzioso"}
								</Text>

								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Checkbox
										id="chk_contenzioso_yes"
										type="radio"
										name="radioContenzioso"
										label="anagraficheDetail.checkboxYes"
										checked={isContenzioso === true}
										onChange={() => setIsContenzioso(true)}
										style={{
											marginRight: "24px",
										}}
									/>
									<Checkbox
										id="chk_contenzioso_no"
										type="radio"
										name="radioContenzioso"
										label="anagraficheDetail.checkboxNo"
										checked={isContenzioso === false}
										onChange={() => setIsContenzioso(false)}
										style={{
											marginRight: "24px",
										}}
									/>
								</div>
							</div>
							<TextAreaField
								id="note_contenzioso"
								label="anagraficheDetail.contenziosoNotes"
								labelColor="var(--darkgrey)"
								rows={4}
								placeholder="hint_insert"
								value={anagrafica.note_contenzioso || ""}
								onChange={onChange}
							/>
						</Row>
					</Card>
					<Card
						style={{
							padding: "32px",
							width: "100%",
							marginTop: "40px",
						}}
					>
						<Text size="large" weight="bold">
							{"anagraficheDetail.clientUsersTitle"}
						</Text>

						<Text size="medium" weight="regular">
							{"anagraficheDetail.clientUsersDescription"}
						</Text>

						<Text size="medium" weight="regular" color="var(--red)">
							{"anagraficheDetail.clientUsersDescription2"}
						</Text>

						<AddUtenzeCliente
							list={anagrafica.credentials && anagrafica.credentials.length > 0 ? anagrafica.credentials : [{ fullname: "", telefono: "", email: "" }]}
							clientId={anagrafica._id}
							disabledSendMail={anagrafica.modified}
							onListChange={(newList) => {
								anagrafica.credentials = newList;
								anagrafica.modified = true;
								setAnagrafica({ ...anagrafica });
							}}
							style={{
								marginTop: "50px",
							}}
						/>
					</Card>
				</div>
				<div
					className="second-card"
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{anagrafica._id && (
						<Card
							style={{
								padding: "32px",
								width: "100%",
							}}
						>
							<Text size="large" weight="bold">
								{"anagraficheDetail.createPreventivoCommessa"}
							</Text>

							<Text size="medium" weight="regular">
								{"anagraficheDetail.usersDocumentsData"}
							</Text>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<Button
									variant="primary"
									style={{
										marginTop: "40px",
										marginRight: "20px",
									}}
									onClick={() => setNewOrderModalShow(true)}
								>
									<AddIcon
										style={{
											marginRight: "8px",
											marginBottom: "2px",
										}}
									/>
									<Text size="small" weight="medium">
										{"anagraficheDetail.createPreventivo"}
									</Text>
								</Button>
								<Button
									variant="outline-primary"
									style={{
										marginTop: "40px",
									}}
									onClick={() => setShowCommessaModal(true)}
								>
									<AddIcon
										style={{
											marginRight: "8px",
											marginBottom: "2px",
										}}
									/>

									<Text size="small" weight="medium">
										{"anagraficheDetail.createCommessa"}
									</Text>
								</Button>
							</div>
						</Card>
					)}

					<Card
						style={{
							padding: !anagrafica._id ? "" : "32px",
							width: "100%",
							marginTop: !anagrafica._id ? "" : "40px",
						}}
					>
						<Text size="large" weight="bold">
							{"anagraficheDetail.sellSettingsTitle"}
						</Text>

						<Text size="medium" weight="regular">
							{"anagraficheDetail.sellSettingsDescription"}
						</Text>

						<Row
							defaultWidth={100}
							style={{
								marginTop: "40px",
							}}
						>
							<SelectField
								id="agente"
								type="text"
								label="anagraficheDetail.connectedAgent"
								placeholder="hint_select"
								value={anagrafica.agente}
								options={AGENTI}
								onChange={onChange}
							/>

							<SelectField
								id="priority"
								type="text"
								label="anagraficheDetail.priority"
								placeholder="hint_select"
								onChange={onChange}
								value={anagrafica.priority}
								options={PRIORITA}
								style={{
									marginTop: "20px",
								}}
							/>
						</Row>

						<Row
							defaultWidth={100}
							style={{
								marginTop: "60px",
							}}
						>
							<SelectField
								id="listino"
								type="text"
								label="listinoLavorazioni"
								placeholder="hint_select"
								value={anagrafica.listino}
								options={listinoLavorazioni.map((listino) => {
									return {
										value: listino._id,
										text: listino.nome,
									};
								})}
								onChange={(id, value, isValid) => {
									setListino(value);
									onChange(id, value);
								}}
							/>

							<TextField
								id="sconto_generale_lavorazioni"
								type="text"
								value={anagrafica.sconto_generale_lavorazioni || ""}
								label="anagraficheDetail.generalDiscountLavorazioni"
								placeholder="hint_insert"
								onChange={onChangeNumber}
							/>
						</Row>

						<Spacer height="20px" />

						<ReactRow>
							<ReactCol>
								<DynamicList
									addText="anagraficheDetail.addDiscountLavorazione"
									onAddItem={addLavorazioneScontata}
								>
									{lavorazioniDaScontare.map((lavorazioneDaScontare, index) => {
										return (
											<ReactRow
												key={index}
												style={{ marginTop: index === 0 ? "0" : "16px" }}
											>
												<ReactCol sm={8}>
													<SelectField
														id={lavorazioneDaScontare.codice}
														label={index === 0 ? "anagraficheDetail.discountLavorazione" : ""}
														value={lavorazioneDaScontare.codice}
														options={lavorazioniDaScontareOptions
															.filter(
																(item) =>
																	!lavorazioniDaScontare.some(
																		(itemDaScontare) =>
																			item.codice == itemDaScontare.codice &&
																			item.codice != lavorazioneDaScontare.codice
																	)
															)
															.map((lavorazione) => {
																return {
																	value: lavorazione.codice,
																	text:
																		`${lavorazione.descrizione || ""}` || "",
																};
															})}
														onChange={(id, value) => {
															onChangeLavorazioneDaScontare(
																lavorazioneDaScontare.codice,
																value
															);
														}}
													/>
												</ReactCol>
												<ReactCol sm={3}>
													<TextField
														id={lavorazioneDaScontare.codice}
														className="sconto"
														type="text"
														label={index === 0 ? "anagraficheDetail.discountLabel" : ""}
														placeholder="hint_insert"
														value={lavorazioneDaScontare.value}
														onChange={onChangeValueLavorazioneDaScontare}
														style={{
															margin: "0px",
														}}
													/>
												</ReactCol>
												<ReactCol sm={1}>
													<DivClick
														onClick={(e) => {
															removeItemFromLavorazioniScontate(lavorazioneDaScontare.codice);
														}}
														style={{
															alignSelf: "end",
															marginLeft: "16px",
															marginBottom: "6px",
															userSelect: "none",
															marginTop: index == 0 ? "12px" : ""
														}}
													>
														<DeleteIcon
															style={{
																width: "20px",
																height: "20px",
																marginRight: "8px",
																marginBottom: "3px",
															}}
														/>
													</DivClick>
												</ReactCol>
											</ReactRow>
										);
									})}
								</DynamicList>
							</ReactCol>
						</ReactRow>

						<Spacer height="60px" />

						<ReactRow>
							<ReactCol>
								<DynamicList
									addText="anagraficheDetail.addDiscountCompany"
									onAddItem={addScontoAzienda}
								>
									{scontiAzienda.map((scontoAzienda, index) => {
										return (
											<ReactRow
												key={index}
												style={{ marginTop: index === 0 ? "0" : "16px" }}
											>
												<ReactCol sm={8}>
													<SelectField
														id={scontoAzienda._id}
														label={
															index === 0
																? "anagraficheDetail.discountCompany"
																: ""
														}
														value={scontoAzienda._id}
														options={scontiAziendaOptions
															.filter(
																(item) =>
																	!scontiAzienda.some(
																		(itemAzienda) =>
																			item._id == itemAzienda._id &&
																			item._id != scontoAzienda._id
																	)
															)
															.map((sconto) => {
																return {
																	value: sconto._id,
																	text: sconto.nome || "",
																};
															})}
														onChange={(id, value) => {
															onChangeScontiAziende(scontoAzienda._id, value);
														}}
													/>
												</ReactCol>
												<ReactCol sm={3}>
													<TextField
														id={scontoAzienda._id}
														className="sconto"
														type="text"
														label={index === 0 ? "anagraficheDetail.discountLabel" : ""}
														placeholder="hint_insert"
														value={scontoAzienda.value}
														onChange={onChangeValueScontiAzienda}
														style={{
															margin: "0px",
														}}
													/>
												</ReactCol>
												<ReactCol sm={1}>
													<DivClick
														onClick={(e) => {
															removeItemFromAziendeScontate(scontoAzienda._id);
														}}
														style={{
															alignSelf: "end",
															marginLeft: "16px",
															marginBottom: "6px",
															userSelect: "none",
															marginTop: index == 0 ? "12px" : ""
														}}
													>
														<DeleteIcon
															style={{
																width: "20px",
																height: "20px",
																marginRight: "8px",
																marginBottom: "3px",
															}}
														/>
													</DivClick>
												</ReactCol>
											</ReactRow>
										);
									})}
								</DynamicList>
							</ReactCol>
						</ReactRow>

						<Spacer height="60px" />

						<ReactRow>
							<ReactCol>
								<DynamicList
									addText="anagraficheDetail.addDiscountMaterial"
									onAddItem={addScontoMateriale}
								>
									{scontiMateriale.map((scontoMateriale, index) => {
										return (
											<ReactRow
												key={index}
												style={{ marginTop: index === 0 ? "0" : "16px" }}
											>
												<ReactCol sm={8}>
													<SelectField
														id={scontoMateriale.codice}
														label={index === 0 ? "anagraficheDetail.discountMaterial" : ""}
														value={scontoMateriale.codice}
														options={scontiMaterialeOptions
															.filter(
																(item) =>
																	!scontiMateriale.some(
																		(itemMateriale) =>
																			item.codice == itemMateriale.codice &&
																			item.codice != scontoMateriale.codice
																	)
															)
															.map((sconto) => {
																return {
																	value: sconto.codice,
																	text: sconto.nome || "",
																};
															})}
														onChange={(id, value) => {
															onChangeScontiMateriale(
																scontoMateriale.codice,
																value
															);
														}}
													/>
												</ReactCol>
												<ReactCol sm={3}>
													<TextField
														id={scontoMateriale.codice}
														className="sconto"
														type="text"
														label={index === 0 ? "anagraficheDetail.discountLabel" : ""}
														placeholder="hint_insert"
														value={scontoMateriale.value}
														onChange={onChangeValueScontiMateriale}
														style={{
															margin: "0px",
														}}
													/>
												</ReactCol>
												<ReactCol sm={1}>
													<DivClick
														onClick={(e) => {
															removeItemFromMaterialiScontati(scontoMateriale.codice);
														}}
														style={{
															alignSelf: "end",
															marginLeft: "16px",
															marginBottom: "6px",
															userSelect: "none",
															marginTop: index == 0 ? "12px" : ""
														}}
													>
														<DeleteIcon
															style={{
																width: "20px",
																height: "20px",
																marginRight: "8px",
																marginBottom: "3px",
															}}
														/>
													</DivClick>
												</ReactCol>
											</ReactRow>
										);
									})}
								</DynamicList>
							</ReactCol>
						</ReactRow>

						<Row
							defaultWidth={100}
							style={{
								marginTop: "60px",
							}}
						>
							<TextField
								id="costo_ordine.descrizione"
								type="text"
								value={anagrafica.costo_ordine?.descrizione || ""}
								label="anagraficheDetail.descrizioneCostoOrdine"
								placeholder="hint_insert"
								onChange={onChange}
							/>
							<TextField
								id="costo_ordine.prezzo"
								type="number"
								value={anagrafica.costo_ordine?.prezzo || ""}
								label="anagraficheDetail.prezzoCostoOrdine"
								placeholder="hint_insert"
								onChange={onChangeNumber}
							/>
						</Row>

						<Spacer height="64px" />

						<Row defaultWidth={100}>
							<div
								style={{
									width: "100%",
									justifyContent: "space-between",
									display: "flex",
								}}
							>
								<Text size="medium" weight="regular" color="var(--black)">
									{"anagraficheDetail.imballoObbligatorio"}
								</Text>

								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Checkbox
										id="chk_imballo_yes"
										type="radio"
										name="radioImballo"
										label="anagraficheDetail.checkboxYes"
										checked={isImballoObbligatorio === true}
										onChange={() => setIsImballoObbligatorio(true)}
										style={{
											marginRight: "24px",
										}}
									/>
									<Checkbox
										id="chk_imballo_no"
										type="radio"
										name="radioImballo"
										label="anagraficheDetail.checkboxNo"
										checked={isImballoObbligatorio === false}
										onChange={() => setIsImballoObbligatorio(false)}
										style={{
											marginRight: "24px",
										}}
									/>
								</div>
							</div>
						</Row>

						<Spacer height="24px" />

						<Row defaultWidth={100}>
							<TextField
								id="sconto_imballo"
								type="text"
								value={anagrafica.sconto_imballo || ""}
								label="anagraficheDetail.discountImballo"
								placeholder="hint_insert"
								onChange={onChangeNumber}
							/>
						</Row>

						<Spacer height="60px" />

						<Row
							breakpoints={[
								{
									breakpoint: "10000",
									defaultWidth: 45,
									widthOverride: {
										".plus": 10,
									},
								},
							]}
						>
							<TextField
								id="sconto_incondizionato.primo"
								type="text"
								value={
									anagrafica.sconto_incondizionato &&
									anagrafica.sconto_incondizionato.primo
								}
								label="unconditionallyDiscount"
								placeholder="hint_insert"
								onChange={onChangeSales}
								style={{
									alignSelf: "flex-end",
								}}
							/>
							<div
								className="plus"
								style={{
									display: "flex",
									justifyContent: "center",
									alignSelf: "flex-end",
									marginBottom: "10px",
								}}
							>
								<AddIcon
									style={{
										color: "var(--grey-2)",
										width: "20px",
										height: "20px",
									}}
								/>
							</div>
							<TextField
								id="sconto_incondizionato.secondo"
								type="text"
								value={
									anagrafica.sconto_incondizionato &&
									anagrafica.sconto_incondizionato.secondo
								}
								label=""
								placeholder="hint_insert"
								onChange={onChangeSales}
								style={{
									alignSelf: "flex-end",
								}}
							/>
						</Row>

						<Spacer height="64px" />
						<Row defaultWidth={100}>
							<div
								style={{
									width: "100%",
									justifyContent: "space-between",
									display: "flex",
								}}
							>
								<Text size="medium" weight="regular" color="var(--black)">
									{"anagraficheDetail.showConteggiPrint"}
								</Text>

								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Checkbox
										id="chk_stampa_yes"
										type="radio"
										name="radioVisualizzaStampa"
										label="anagraficheDetail.checkboxYes"
										checked={isVisualizzaStampaConteggi === true}
										onChange={() => setIsVisualizzaStampaConteggi(true)}
										style={{
											marginRight: "24px",
										}}
									/>
									<Checkbox
										id="chk_stampa_no"
										type="radio"
										name="radioVisualizzaStampa"
										label="anagraficheDetail.checkboxNo"
										checked={isVisualizzaStampaConteggi === false}
										onChange={() => setIsVisualizzaStampaConteggi(false)}
										style={{
											marginRight: "24px",
										}}
									/>
								</div>
							</div>
						</Row>

						<Spacer height="64px" />
						<Row defaultWidth={100}>
							<div
								style={{
									width: "100%",
									justifyContent: "space-between",
									display: "flex",
								}}
							>
								<Text size="medium" weight="regular" color="var(--black)">
									{"anagraficheDetail.enableClientLogo"}
								</Text>

								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Checkbox
										id="chk_logo_yes"
										type="radio"
										name="radioLogoCliente"
										label="anagraficheDetail.checkboxYes"
										checked={isAbilitaLogoCliente === true}
										onChange={() => setIsAbilitaLogoCliente(true)}
										style={{
											marginRight: "24px",
										}}
									/>
									<Checkbox
										id="chk_logo_no"
										type="radio"
										name="radioLogoCliente"
										label="anagraficheDetail.checkboxNo"
										checked={isAbilitaLogoCliente === false}
										onChange={() => setIsAbilitaLogoCliente(false)}
										style={{
											marginRight: "24px",
										}}
									/>
								</div>
							</div>
						</Row>

						<Spacer height="32px" />
						<RowB>
							<Col lg={12} style={{ marginBottom: "8px"}}>
								<Text size="small" weight={"medium"} color="var(--darkgrey)">
									{"anagraficheDetail.clientLogo"}
								</Text>
								<AddDocument
									id="documento_logo_cliente"
									list={anagrafica.logo_cliente}
									onChange={(id, list) => {
										anagrafica.logo_cliente = list;
										setAnagrafica({...anagrafica});
									}}
								/>
							</Col>

							{/* <TextField
								id=""
								type="text"
								label="LOGO CLIENTE"
								placeholder="Clicca sull'icona per caricare l'immagine..."
								disabled={true}
								lightbackground={true}
								value={
									anagrafica.logo_cliente && anagrafica.logo_cliente.filename
										? anagrafica.logo_cliente.filename
										: ""
								}
								style={{
									alignSelf: "flex-end",
								}}
							/>
							<div
								className="upload"
								style={{
									alignSelf: "flex-end",
									marginBottom: "10px",
								}}
							>
								<UploadIcon
									style={{
										color: "var(--red)",
										width: "28px",
										height: "28px",
										cursor: "pointer",
									}}
									onClick={() => {
										alert("Click");
									}}
								/>
							</div> */}
						</RowB>
					</Card>
				</div>
			</Row>

			<Spacer height="240px" />

			<BottomBar
				leftFirstButton={{
					show: anagrafica._id,
					title: "anagraficheDetail.deleteAnagrafica",
					onClick: confermaEliminazione,
				}}
				leftSecondButton={{
					show: false,
					title: "",
					onClick: "",
				}}
				leftInfo={{
					show: anagrafica._id,
					text: t("anagraficheDetail.lastUpdate2", {date: anagrafica ? moment(anagrafica.updatedAt).format("DD/MM/YYYY, HH:mm") : "-"}),
				}}
				successButton={{
					show: true,
					title: !anagrafica._id ? "anagraficheDetail.createAnagrafica" : "anagraficheDetail.saveAnagrafica",
					onClick: !anagrafica._id ? createAnagrafica : saveAnagrafica,
				}}
				cancelButton={{
					show: true,
					title: "anagraficheDetail.cancel",
					onClick: () => navigate(ANAGRAFICHE),
				}}
			/>

			<NewOrderModal
				show={newOrderModalShow}
				isPreventivo={true}
				defaultClientId={anagraficaId}
				onHide={() => setNewOrderModalShow(false)}
				onCancel={() => setNewOrderModalShow(false)}
			/>

			<NewOrderModal
				show={showCommessaModal}
				isPreventivo={false}
				defaultClientId={anagraficaId}
				onHide={() => setShowCommessaModal(false)}
				onCancel={() => setShowCommessaModal(false)}
			/>
			<ConfirmAlertModal
				show={confirmAlertModalShow}
				headerTitle="anagraficheDetail.confirmAlertModal.headerTitle"
				mainTitle="anagraficheDetail.confirmAlertModal.mainTitle"
				subtitle="anagraficheDetail.confirmAlertModal.subtitle"
				onHide={() => setConfirmAlertModalShow(false)}
				onCancel={() => setConfirmAlertModalShow(false)}
				onSuccess={() =>
					del()
				}
			/>
		</Wrapper>
	);
})
