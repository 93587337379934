import React, {useEffect, useRef} from "react";
import styled from "styled-components";

const GENERAL_SPACE_FREE = 16;

const Wrapper = styled.div`

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${props => props.breakpoints && props.breakpoints.length > 0 ? generateBreakpoints(props) : ""}

`

const getDefaultWidth = ({ width, childrenLength }) => {

  return width ? width : (100 / getColumnCount(childrenLength));

}


const blockDimension = (width, lateralSpace) => {
  return (
    width && width != "" ?
      `
            width: calc(${width + "%"} - ${lateralSpace*2}PX) !important;
            max-width: ${width == 100 ? "100%" : `calc(${width + "%"} - ${lateralSpace*2}px)`} !important;
            flex: calc(${width + "%"} - ${lateralSpace*2}PX) !important;
            margin-right: ${lateralSpace}px !important;
            margin-left: ${lateralSpace}px !important;
        `
      :
      ""
  )
}


const getColumnCount = (childrenLength) => {
  return childrenLength ?
    (childrenLength <= 10 ? childrenLength : 10)
    :
    0;
}


const generateBreakpoints = (props) => {
  const breakpoints = props.breakpoints;
  let mediaQueryCss = '';

  breakpoints.map((item, index) => {

    mediaQueryCss += `
      @media screen and ${generateMediaQueryRange(breakpoints, index)} {
        width: calc(100% + ${GENERAL_SPACE_FREE*2}px);
        max-width: calc(100% + ${GENERAL_SPACE_FREE*2}px);
        margin-left: -${GENERAL_SPACE_FREE}px;
        margin-right: -${GENERAL_SPACE_FREE}px;

        ${generateStyleDiv(props, item)}

      }
    `;

  });

  return mediaQueryCss;
}


const generateMediaQueryRange = (breakpoints, actualBreakpointIndex) => {

  let item = breakpoints[actualBreakpointIndex];

  let range = "(max-width: " + item.breakpoint + "px)";

  if (breakpoints && (breakpoints.length > actualBreakpointIndex + 1)) {

    let nextItem = breakpoints[actualBreakpointIndex + 1];

    range = "(min-width: " + nextItem.breakpoint + "px) and " + range;

  }

  return range;

}

const generateStyleDiv = (props, actualBreakpoint) => {

  let styleCss = "";
  let widthCalculator = 0;
  let rowNumber = 0;
  const children = props.children ? (props.children.length > 0 ? props.children : [props.children]) : [];
  const childrenLength = children ? (children.length ? children.length : 1) : 0;

  const marginValue = getMarginValue(children, actualBreakpoint);

  let lateralSpace = actualBreakpoint.marginLateral ? actualBreakpoint.marginLateral : GENERAL_SPACE_FREE;

  if(actualBreakpoint.columnReverseDirection) {
    styleCss += `flex-direction: column-reverse;`
  }
  if(actualBreakpoint.rowReverseDirection) {
    styleCss += `flex-direction: row-reverse;`
  }

  if (childrenLength > 0) {

    children.forEach((element, index) => {

      let elementKey = elementHasWidthOverride(element, actualBreakpoint);
      let actualWidth = elementKey ? actualBreakpoint.widthOverride[elementKey] : getDefaultWidth({ width: actualBreakpoint.defaultWidth, childrenLength });

      if (parseInt(widthCalculator + actualWidth) > 100) {
        widthCalculator = 0;
        rowNumber++;
      }

      styleCss += `
        & > ${elementKey ? elementKey : `div:nth-child(${index + 1})`} {
          ${blockDimension(actualWidth, lateralSpace)}
          ${rowNumber > 0 ? `margin-top: 20px` : ""}
        }
      `;

      widthCalculator += actualWidth;

    });

  }

  return styleCss;

}


const getMarginValue = (children, actualBreakpoint) => {

  let validItem = 0;

  if (children && children.length > 0) {

    children.forEach((element, index) => {

      let elementKey = elementHasWidthOverride(element, actualBreakpoint);

      let actualWidth = elementKey ? actualBreakpoint.widthOverride[elementKey] : 0;

      if (actualWidth != 100) {
        validItem++;
      }

    });

  }

  return validItem;

}


const elementHasWidthOverride = (element, actualBreakpoint) => {

  const elementClass = element && element.props ? element.props.className : null;

  let containKey = null;

  if (elementClass && actualBreakpoint.widthOverride) {

    Object.keys(actualBreakpoint.widthOverride).forEach((key) => {

      const customKey = key.replace("#", "").replace(".", "");

      if (elementClass.lastIndexOf(customKey) > -1) {
        containKey = key;
      }

    });

  }

  return containKey;

}


export const Row = (props) => {

  const rowRef = useRef();
  
  useEffect(() => {
    if (props.onResize) {
      const handleResize = () => {
        if(rowRef && rowRef.current) {
          props.onResize({
            width: rowRef.current.offsetWidth,
            height: rowRef.current.offsetHeight
          });
        }
      };

      handleResize();

      window.addEventListener("resize", handleResize);
    }

		//return () => window.removeEventListener("resize", handleResize);
	}, []);

  const addDefaultBreakpoint = () => {

    let list = props.breakpoints || [];

    if(props.defaultWidth) {

      return [{
        breakpoint: 100000,
        marginLateral: props.marginLateral ? props.marginLateral : GENERAL_SPACE_FREE,
        defaultWidth: props.defaultWidth
      }].concat(list);

    } else {
      return list;
    }

  }

  const breakpoints = addDefaultBreakpoint();

  return (
    <Wrapper
      ref={rowRef}
      className={props.className}
      defaultWidth={props.defaultWidth}
      breakpoints={breakpoints}
      style={props.style}
    >
      {props.children}
    </Wrapper>
  );
};
