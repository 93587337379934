// All user related database operations can be defined here.

import { NEWS_GET_ALL, GET_NEWS_BY_ID, DELETE_NEWS, CREATE_NEWS, UPDATE_NEWS } from "./constants";
import { get, post, del, put} from "./service";

/**
 * Function to fetch all the users.
 */
export const getNews = async () => {
	return get(NEWS_GET_ALL());
};

export const getNewsById = async (newsId) => {
	return get(GET_NEWS_BY_ID(newsId));
};

export const deleteNews = async (newsId) => {
	return del(DELETE_NEWS(newsId));
};

export const createNews = async (news) => {
	return post(CREATE_NEWS(), news);
};

export const updateNews = async (id, data) => {
	return put(UPDATE_NEWS(id), data);
  };