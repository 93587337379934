import React, { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { withTranslation } from "react-i18next";

const Container = styled(Form.Group)`
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: var(--white);
	border: 1px solid var(--grey-1);
	border-radius: 6px;

	padding: 2px 16px;

	cursor: text;
`;

const Input = styled(Form.Control)`
	background: none;
	border: none;

	&:focus {
		box-shadow: none;
	}
`;

const Icon = styled.div`
	padding-left: 8px;

	color: ${(props) => (props.iconColor ? props.iconColor : "var(--grey-2)")};
`;



function TextFieldWithIcon(props) {

	const { t } = props;

	const inputRef = useRef();
	const [searching, setSearching] = useState(false);


	return (
		<Container className={props.className} style={{ ...props.style }}>
			<Input
				ref={inputRef}
				placeholder={t(props.placeholder)}
				value={props.value}
				onChange={(e) => {
					props.onChange(props.id, e.target.value);
					setSearching(e !== "");
				}}
			/>
			<Icon
				onClick={() => {
					if (searching) {
						props.onChange(props.id, "");
						setSearching(false);
					} else {
						inputRef.current.focus();
					}
				}}
			>
				{searching ? <CloseIcon className="cursor-pointer" /> : <SearchIcon />}
			</Icon>
		</Container>
	);
}

TextFieldWithIcon.defaultProps = {
	placeholder: "Cerca ordini...",
	value: "",
	onChange: (e) => {},
};

export default withTranslation()(TextFieldWithIcon);
