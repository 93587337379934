import React, { useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import * as moment from "moment";
import { Button, Checkbox, Text, TextField, DatePickerComponent, TimePickerComponent } from "../..";
import {
	getZona,
	getPriorita,
	calculateDtLavoration,
} from "../../../utils/functionsUtils";
import { getCommessaDetail, updateCommessa } from "../../../services";

const CreateCommessaModal = (props) => {
	const { t } = props;
	const [commessa,setCommessa] = useState(props.commessaData);

	const creaCommessa = () => {
		if(commessa.dt_consegna && commessa.dt_consegna != ""){
			commessa.cd_type = "COMMESSA";
			if(!commessa.hour_consegna || commessa.hour_consegna == "") {
				commessa.hour_consegna = "08:00";
			}
			updateCommessa(commessa._id, commessa).then(({ result, error }) => {
				if (error) {
					console.log(error);
				} else {
					props.onSuccess();
				}
			});
		}
	};

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<Container>
					<Text size="xxlarge" weight="medium" color="var(--black)">
						{"create_commessa_modal.title"}
					</Text>
					<Text color="var(--darkgrey)">
						{"create_commessa_modal.subtitle"}
					</Text>
					<Row
						
						style={{
							marginTop: "20px",
						}}
					>
						<Col sx={12} md={6}>
							<DatePickerComponent
								id="dt_consegna"
								label="DATA CONSEGNA"
								labelColor="var(--darkgrey)"
								placeholderText="Seleziona data..."
								backgroundColor="var(--grey-field)"
								selectedDate={
									commessa.dt_consegna ? commessa.dt_consegna : moment().add(1, "day").toDate()
								}
								onChange={(id, value) => {
									commessa.dt_consegna = moment(value).set({hour:0,minute:0,second:0,millisecond:0});
									if (commessa.dt_consegna && commessa.punteggi_lavorazioni) {
										commessa.dt_lavorazione = calculateDtLavoration(
											commessa.punteggi_lavorazioni,
											commessa.dt_consegna
										).toISOString();
									}
									setCommessa({ ...commessa });
								}}
							/>
						</Col>
						<Col sx={12} md={6}>
							<TimePickerComponent
								id="dt_time"
								label="ORA CONSEGNA"
								labelColor="var(--darkgrey)"
								placeholderText="Seleziona ora..."
								backgroundColor="var(--grey-field)"
								selectedDate={
									commessa.hour_consegna ? moment().set({hour:commessa.hour_consegna.split(":")[0],minute:commessa.hour_consegna.split(":")[1],second:0,millisecond:0}) : moment().set({hour:8,minute:0,second:0,millisecond:0}).toDate()
								}
								onChange={(id, value) => {
									commessa.hour_consegna = moment(value).format("HH:mm");
									setCommessa({ ...commessa });
								}}
							/>
						</Col>
					</Row>
					
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row
						style={{
							marginTop: "50px",
						}}
					>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={() => props.onCancel()}>
								<Text size="small" weight="medium">
									{"btn_cancel"}
								</Text>
							</Button>
							<Button variant="success" onClick={() => creaCommessa()}>
								<Text size="small" weight="medium">
									{"btn_create"}
								</Text>
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};

CreateCommessaModal.defaultProps = {
	onSuccess: () => {},
	onCancel: () => {},
};

export default CreateCommessaModal;
