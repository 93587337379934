// All user related database operations can be defined here.

import { AZIENDE_GET_ALL, GET_AZIENDE_BY_LISTINO_ID } from "./constants";
import { get, post } from "./service";

/**
 * Function to fetch all the users.
 */
export const getAll = async () => {
	return get(AZIENDE_GET_ALL());
};

export const getAziendeByListinoId = async (listinoId) => {
	return get(GET_AZIENDE_BY_LISTINO_ID(listinoId));
};
