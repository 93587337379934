import { PropTypes } from "prop-types";
import React from "react";
import styled from "styled-components";
import { Text } from "../index";
import { ProgressBar as Progress } from "react-bootstrap";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Dot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--black);
  top: 0px;
  right: 15%;
`;

const ProgressBarCustom = styled(Progress)`

  & > .progress-bar {
    background-color: ${(props) => props.color ? props.color : "var(--red)"}
  }

  & {

    width: 100%;
    margin-right: 8px;

    &.small {
      border-radius: 3px;
      height: 6px;
      & > .progress-bar {
        border-radius: 3px;
      }
    }

    &.normal {
      border-radius: 6px;
      height: 10px;
      & > .progress-bar {
        border-radius: 6px;
      }
    }

    &.large {
      border-radius: 6px;
      height: 12px;
      & > .progress-bar {
        border-radius: 6px;
      }
    }

  }


  background-color: var(--grey-1);
  justify-content: flex-end;

`;

export const ProgressBar = (props) => {
  
  return (
      <Wrapper
        style={props.style}
      >
        <div
          style={{
            width: "100%",
            position: "relative"
          }}
        >
          <ProgressBarCustom
              className={props.className}
              animated={props.animated}
              isChild={props.isChild}
              max={props.max}
              min={props.min}
              now={props.now}
              striped={props.striped}
              variant={props.variant}
              visuallyHidden={props.visuallyHidden}
              color={props.color}
              style={props.progressBarStyle}
          />
          {
            props.dotPercentage || props.dotPercentage === 0 ?
              <Dot
                style={{
                  right: `${props.dotPercentage}%`
                }}
              />
            :
              ""
          }
        </div>
        {
          props.label ?
            <Text
                size="xxsmall"
                weight="medium"
                style={{
                  marginLeft: "8px"
                }}
            >
                {props.label}
            </Text>
          :
          ""
        }
      </Wrapper>
    
  );
};
