import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    getImgFromLavorazioneKey
} from "../../assets/images/svg";

import { PUNTEGGI_LAVORAZIONI_STATUS, PUNTEGGI_LAVORAZIONI_NEXT_STATUS } from "../../utils/constants"

const Img = styled.img`

    width: 24px;
    height: 24px;
    cursor: pointer;

    margin-right: 8px;

`;

export const ImgLavorazione = (props) => {

	const {keyLavorazione, lavorazione, title} = props;

	return (
		<Img
			src={getImgFromLavorazioneKey(keyLavorazione, lavorazione.status)}
			title={title}
			onClick={() => {
				const status = lavorazione.status
				if(status != PUNTEGGI_LAVORAZIONI_STATUS.GREY) {
					//lavorazione.status = PUNTEGGI_LAVORAZIONI_NEXT_STATUS[keyLavorazione][status];
					if(props.onClick) {
						props.onClick(lavorazione);
					}
				}
			}}
		/>
	);
};
