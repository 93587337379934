import {
    PersonOutline as PersonOutlineIcon,
    DescriptionOutlined as DescriptionIcon,
    LocalShippingOutlined as LocalShippingIcon,
    FormatListBulleted as ListiniIcon
} from "@material-ui/icons";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import StickyNote2Icon from "@mui/icons-material/StickyNote2Outlined";
import {ANAGRAFICHE, PREVENTIVI, COMMESSE, ORDINI, SPEDIZIONI, LISTINI, LISTINI_MATERIALI, LISTINI_LAVORAZIONI, NEWS, PROFILODETAIL} from "../navigation/constants";
import { USER_ROLES } from "./constants";

export const getMenuItems = (cd_privilege) => {

    if(cd_privilege == USER_ROLES.ADMIN) {

        return [
            {
                img: GradingOutlinedIcon,
                text: "menu.commesse",
                link: COMMESSE
            },
            {
                img: PersonOutlineIcon,
                text: "menu.anagrafiche",
                link: ANAGRAFICHE
            },
            {
                img: DescriptionIcon,
                text: "menu.preventivi",
                link: PREVENTIVI
            },
            {
                img: ContentPasteIcon,
                text: "menu.ordini",
                link: ORDINI
            },
            {
                img: LocalShippingIcon,
                text: "menu.spedizioni",
                link: SPEDIZIONI
            },
            /*{
                img: StickyNote2Icon,
                text: "menu.listini_materiali",
                link: LISTINI_MATERIALI
            },
            {
                img: StickyNote2Icon,
                text: "menu.listini_lavorazioni",
                link: LISTINI_LAVORAZIONI
            },*/
            {
                img: ListiniIcon,
                text: "menu.listini",
                link: LISTINI
            },
            {
                img: StickyNote2Icon,
                text: "menu.news",
                link: NEWS
            }
        ];

    } else if (cd_privilege == USER_ROLES.OPERATOR) {

        return [
            {
                img: DescriptionIcon,
                text: "menu.commesse",
                link: COMMESSE
            }/*,
            {
                img: StickyNote2Icon,
                text: "menu.listini",
                link: LISTINI
            }*/
        ];

    } else if (cd_privilege == USER_ROLES.CLIENT) {

        return [
            /*{
                img: GradingOutlinedIcon,
                text: "menu.commesse",
                link: COMMESSE
            },*/
            {
                img: DescriptionIcon,
                text: "menu.preventivi",
                link: PREVENTIVI
            },
            {
                img: ContentPasteIcon,
                text: "menu.ordini",
                link: ORDINI
            },
            {
                img: PersonOutlineIcon,
                text: "menu.profilo",
                link: PROFILODETAIL
            },
            {
                img: StickyNote2Icon,
                text: "menu.news",
                link: NEWS
            }
        ];

    } else {
        return [];
    }

}