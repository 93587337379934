// All user related database operations can be defined here.

import axios from "axios";
import { getToken } from "../utils";

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_WS_LINK,
	timeout: 60000,
	headers: {
		Authorization: `Bearer ${getToken()}`,
	},
});

/**
 * Intercept reponse and format
 */
axiosClient.interceptors.response.use(
	function (response) {
		return { result: response.data };
	},
	function (error) {
		return { error };
	}
);

/**
 *
 * @param {*} path
 *
 * @returns { { result: *, error: Error }}
 */
export const get = async (path) => {
	return axiosClient.get(path, {
		headers: {
			Authorization: `Bearer ${getToken()}`,
		}
	});
};

/**
 *
 * @param {*} path
 * @param {*} data
 *
 * @returns { { result: *, error: Error }}
 */
export const post = async (path, data, header) => {
	return axiosClient.post(path, data, {
		headers: {
			Authorization: `Bearer ${getToken()}`,
			...header
		}
	});
};

/**
 *
 * @param {*} path
 * @param {*} data
 *
 * @returns { { result: *, error: Error }}
 */
export const put = async (path, data) => {
	return axiosClient.put(path, data, {
		headers: {
			Authorization: `Bearer ${getToken()}`,
		}
	});
};

/**
 *
 * @param {*} path
 *
 * @returns { { result: *, error: Error }}
 */
export const del = async (path) => {
	return axiosClient.delete(path, {
		headers: {
			Authorization: `Bearer ${getToken()}`,
		}
	});
};
