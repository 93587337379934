import { Divider } from "@mui/material";
import { element } from "prop-types";
import React, {useEffect, useState, useRef, useCallback} from "react";


const DivListener = ({onThresholdReached}) => {

  const sectionRef = useRef(null);
  
  useEffect(() => {
    const callbackFunc = entries => {
      const [entry] = entries;
      if (entry.isIntersecting === true) {
        onThresholdReached();
      }
    }
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1
    }
    const observer = new IntersectionObserver(callbackFunc, options);
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    }
  }, []);

  return (
    <div ref={sectionRef}></div>
  );

}


export const InfiniteScroll = ({isLoading, hasMore, loaderContent, onUpdate, content}) => {

  

  const getElementsList = () => {
    const list = [];

    /*content.forEach((element, index) => {

      if ((content.length-1) == index && hasMore) {
        list.push(
          <DivListener
            onThresholdReached={() => {
              if (hasMore && onUpdate) {
                onUpdate();
              }
            }}
          />
        );
      }

      list.push(element);

    });*/

    if(content && content.length > 0) {

      content.push(
        <DivListener
          onThresholdReached={() => {
            if (hasMore && onUpdate) {
              onUpdate();
            }
          }}
        />
      );

    }

    return content;
  }

  return (
    <div>
      {getElementsList()}
      {isLoading ? loaderContent : ""}
    </div>
  );
};
