import {
  COMMESSE,
  COMMESSE_FILTERED,
  COMMESSE_DETAIL,
  DELETE_COMMESSA,
  CONSEGNA_COMMESSA,
  CONFERMA_ORDINE,
  CHECK_DOCUMENTI,
  DOWNLOAD_DOCUMENTI,
  UPDATE_COMMESSE_LIST,
  COMMESSE_LAVORAZIONE_UPDATE_STATUS,
  SEND_PREVENTIVO_CLIENTE, PREVENTIVI_DETAIL
} from "./constants";
import { get, post, put, del } from "./service";

/**
 * Function to fetch all the users.
 */
export const getCommesse = async (page_number, elements_count) => {
  return get(COMMESSE(page_number, elements_count));
};

export const getCommesseFiltered = async (filters, page_number, elements_count) => {
  return post(COMMESSE_FILTERED(page_number, elements_count), filters);
};

export const getCommessaDetail = async (id) => {
  return get(COMMESSE_DETAIL(id));
};

export const createCommessa = async (data) => {
  return post(COMMESSE(), data);
};

export const updateCommesseList = async (data) => {
  return post(UPDATE_COMMESSE_LIST(), data);
};

export const sendPreventivoCliente = async (data) => {
  return post(SEND_PREVENTIVO_CLIENTE(),data);
};

export const updateCommessa = async (id, data) => {
  return put(COMMESSE_DETAIL(id), data);
};

export const updatePreventivi = async (id, data) => {
  return put(PREVENTIVI_DETAIL(id), data);
};

export const updateStatusLavorazione = async (id, data) => {
  return put(COMMESSE_LAVORAZIONE_UPDATE_STATUS(id), data);
}

export const deleteCommessa = async (id) => {
  return del(DELETE_COMMESSA(id));
};

export const consegnaCommessa = async (id) => {
  return del(CONSEGNA_COMMESSA(id));
};

export const confermaOrdine = async (data) => {
  return post(CONFERMA_ORDINE(), data);
};

export const controllaEsistenzaDocumento = async (type, numero_commessa, anno_commessa) => {
  return get(CHECK_DOCUMENTI(type, numero_commessa, anno_commessa));
};

export const downloadDocumento = async (type, numero_commessa, anno_commessa) => {
  return get(DOWNLOAD_DOCUMENTI(type, numero_commessa, anno_commessa));
};
