import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { EditorCell } from "../cells/editorCell";
import { ImgCell } from "../cells/imgCell";
import { TextCell } from "../cells/textCell";
import { Close as CloseIcon } from '@material-ui/icons';
import { calcolaPrezzoLavorazione, calcolaScontoTabellaLavorazioni, calcolaTotParziale } from "../../../utils/functionsUtils";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        align-items: normal;

        background-color: var(--white);
        border-bottom: 1px solid var(--grey-1);
        
        
    }

    & > div {
        display: flex;
        justify-content: center;
        border-right: 1px solid var(--grey-1);
        padding-top: 0px;
        padding-bottom: 0px;
    }

    & > .cell-padding {
        padding-left: 16px;
        padding-right: 16px;
    }

    & > div:nth-last-child(1) {
        border-right: 0px;
    }

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowCommesseLavorazioni = (props) => {

    const [data, setData] = useState(props.data);
    let clienteData = props.clienteData || {};
    let sconto = calcolaScontoTabellaLavorazioni(clienteData, data.lavorazione);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const onChange = (id, value, isValid) => {

    }

  return (
        <Wrapper
            className={props.className}
            style={props.style}
        >
            <EditorCell
                id={"data1"}
                value={data.lavorazione.ua}
                lightbackground
                noborder={"true"}
                type="text"
                onChange={onChange}
                style={{
                    width: "10%"
                }}
            />

            <EditorCell
                id={"data2"}
                value={data.ua_value}
                lightbackground
                noborder={"true"}
                type="text"
                onChange={(id, value) => {
                    data.ua_value = value;
                    setData({ ...data });
                    if(props.onChange) {
                        props.onChange(data);
                    }
                }}
                style={{
                    width: "10%"
                }}
            />

            <TextCell
                primaryText={data.lavorazione.codice}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%"
                }}
            />

            <TextCell
                primaryText={data.lavorazione.descrizione}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "35%"
                }}
            />

            <TextCell
                primaryText={data.lavorazione ? data.lavorazione.prezzo : ""}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%"
                }}
            />

            <TextCell
                primaryText={sconto}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%"
                }}
            />

            <TextCell
                primaryText={calcolaTotParziale(calcolaPrezzoLavorazione(data.lavorazione.prezzo, data.ua_value), sconto)}
                primaryTextSize="small"
                color="var(--black)"
                className="cell-padding"
                style={{
                    width: "10%"
                }}
            />

            

            <div
                className="cell-padding"
                style={{
                    width: "5%",
                    alignItems: "center"
                }}
            >

                <CloseIcon
                    onClick={() => {
                        if(props.onDelete) {
                            props.onDelete(data);
                        }
                    }}
                    style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer"
                    }}
                />

            </div>

        </Wrapper>
  );
};
