import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row as ReactRow, Col as ReactCol } from "react-bootstrap";
import {
	Breadcrumb,
	Row,
	Card,
	Text,
	TextField,
	SelectField,
	Checkbox,
	Button,
	BottomBar,
	Spacer,
	DynamicList,
	NewOrderModal,
	ConfirmAlertModal,
	AddUtenzeCliente,
	AddEmail,
	AddSedeSecondaria,
	Loader
} from "../../components";
import { Add as AddIcon, CloudUpload as UploadIcon } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import {
	getClientDetails,
	updateClient,
	authenticateWithToken
} from "../../services";
import {
	GRUPPI_ANAGRAFICHE,
	ZONE,
	AGENTI,
	PRIORITA,
} from "../../utils/constants";
import moment from "moment";
import { isEmailValid } from "../../utils";
import { useNavigate } from "react-router-dom";
import { PROFILODETAIL } from "../../navigation/constants";
import { useDispatch, useSelector } from "react-redux";
import { setToast, setUser } from "../../redux";
import AddDocument from "../../components/modals/commesse/components/addDocument";
import { Col, Row as RowB } from "react-bootstrap";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 40px;
`;

export function ProfiloDetail(props) {
	const location = useLocation();
	const user = useSelector((state) => state.user || {});
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);
	const [anagraficaId, setAnagraficaId] = useState(user._id);
	const toast = useSelector((state) => state.toast || {});
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);
	const [breadcrumbLevels, setBreadcrumbLevels] = useState([
		{
			text: "Profilo",
			link: PROFILODETAIL,
		},
		{
			text: "Dettaglio Profilo",
		},
	]);
	const [anagrafica, setAnagrafica] = useState({});
	const [sediSecondarie, setSediSecondarie] = useState([]);
	const [isAbilitaLogoCliente, setIsAbilitaLogoCliente] = useState(true);

	// =================================================================================
	// Startup
	// =================================================================================

	useEffect(() => {
		if(anagraficaId) {
			executeGetAnagraficaDetail();
			setSediSecondarie(getSediSecondarie());
		}
	}, [anagraficaId]);


	const executeAuthenticationWithToken = async () => {

		let { result, error } = await authenticateWithToken();

		if (result && result.user) {
			dispatch(setUser(result.user));
		}

	}
	

	const executeGetAnagraficaDetail = () => {
		if (anagraficaId) {
			getClientDetails(anagraficaId).then(({ result, error }) => {
				if (error) {
				} else {
					result.sconto_incondizionato = result.sconto_incondizionato || {
						primo: "",
						secondo: "",
					};

					setAnagrafica({...result});

					setIsAbilitaLogoCliente(result.abilita_logo_cliente);

					setBreadcrumbLevels([
						{
							text: "profiloDetail.breadcrumbTitle",
							link: PROFILODETAIL,
						},
						{
							text: result.nomecompleto,
						},
					]);
				}

				setIsLoading(false);
			});
		} else {
			setAnagrafica({
				abilita_logo_cliente: "",
				agente: "-",
				cd_privilege: "CMS-ACCESS-CLIENT",
				cellulare: "",
				citta: "",
				codicefiscale: "",
				contenzioso: true,
				credentials: [],
				email_invio_documenti: "",
				emails: [{ email: "" }],
				gruppo: "-",
				imballo_obbligatorio: true,
				indirizzo: "",
				indirizzo_consegna: "",
				invia_mail_nuova_commessa: "",
				invia_mail_ordine_finito: "",
				lavorazioni_scontate: [],
				listino: "",
				logo_cliente: { file_url: "", filename: "", _id: "" },
				materiali_scontati: [],
				nomecompleto: "",
				note_consegna: "",
				note_contenzioso: "",
				note_esposizione: "",
				note_generali: "",
				p_iva: "",
				priority: "-",
				provincia: "",
				ragione_sociale: "",
				sconto_azienda: [],
				sconto_generale_lavorazioni: "",
				sconto_generale_materiali: "",
				sconto_imballo: "",
				sedi_secondarie: [{ address: "" }],
				telefono: "",
				visualizza_stampa_conteggi: "",
				zipcode: "",
				zona: "-",
				zona_consegna: "-",
				sconto_incondizionato: { primo: "", secondo: "" },
			});

			setIsLoading(false);
		}
	};



	// =================================================================================
	// onChanges
	// =================================================================================
	const onChange = (id, value, isValid) => {
		const routes = id.split(".");
		if (routes.length > 1) {
			anagrafica[routes[0]][routes[1]] = value;
		} else {
			anagrafica[id] = value;
		}
		setAnagrafica({ ...anagrafica });
	};

	const onChangeNumber = (id, value, isValid) => {
		if (isNaN(value)) return;
		onChange(id, value, isValid);
	};

	const onChangeSales = (id, value, isValid) => {
		if (isNaN(value)) return;
		if (value < 0 || value > 100) return;
		onChange(id, value, isValid);
	};



	// ================================================================================
	// Crea / Modifica anagrafica
	// ================================================================================

	/**
	 * Modifica anagrafica
	 */
	const saveAnagrafica = () => {
		const { ok, toSave, errors } = validateObject();
		if (ok) {
			updateClient(toSave._id, toSave).then(({ result, errors }) => {
				if (errors) {
					console.error(errors);
					dispatch(
						setToast({
							show: true,
							title: "profiloDetail.toastUpdate.error.title",
							description: "profiloDetail.toastUpdate.error.description",
							variant: "danger",
						})
					);
				} else if (result === undefined) {
					console.error("Qualcosa è andato storto");
					dispatch(
						setToast({
							show: true,
							title: "profiloDetail.toastUpdate.error.title",
							description: "profiloDetail.toastUpdate.error.description",
							variant: "danger",
						})
					);
				} else {
					dispatch(
						setToast({
							show: true,
							title: "profiloDetail.toastUpdate.success.title",
							description: "profiloDetail.toastUpdate.success.description",
							variant: "success",
						})
					);
					executeGetAnagraficaDetail();
					executeAuthenticationWithToken();
				}
			});
		} else {
			if (errors.credentials.length > 0) {
				let description = "";
				if (errors.credentials.length > 1) {
					description = `Non è stato possibile aggiornare il cliente: gli utenti ${errors.credentials
						.map((credential) =>
							credential.fullname !== ""
								? credential.fullname
								: credential.telefono
						)
						.join(", ")} devono avere un'email associata`;
				} else {
					description = `Non è stato possibile aggiornare il cliente: l'utente ${errors.credentials.map(
						(credential) =>
							credential.fullname !== ""
								? credential.fullname
								: credential.telefono
					)} deve avere un'email associata`;
				}

				dispatch(
					setToast({
						show: true,
						title: "Errore",
						description: description,
						variant: "danger",
					})
				);
			} else {
				console.error("Ci sono errori:");
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "profiloDetail.toastUpdate.error.title",
						description: "profiloDetail.toastUpdate.error.description",
						variant: "danger",
					})
				);
			}
		}
	};

	/**
	 * Validazione per creazione o modifica anagrafica
	 */
	const validateObject = () => {
		let allOk = true;
		let errors = {};

		const toSave = anagrafica;

		// controllo le email
		if (anagrafica.emails && anagrafica.emails.length > 0) {
			anagrafica.emails = anagrafica.emails.filter(
				(email) => email.email !== ""
			);
			for (let email of anagrafica.emails) {
				if (email.email !== "" && !isEmailValid(email.email)) {
					errors.emails = errors.emails || [];
					errors.emails.push(email.email);
					allOk = false;
				}
			}
		}

		// controllo le credenziali
		anagrafica.credentials = anagrafica.credentials.filter(
			(item) =>
				item.fullname !== "" || item.telefono !== "" || item.email !== ""
		);

		for (let credential of anagrafica.credentials) {
			if (
				credential.telefono &&
				credential.telefono !== "" &&
				isNaN(credential.telefono)
			) {
				errors.credentials = errors.credentials || [];
				errors.credentials.push(credential);
				allOk = false;
			}

			if (credential.email === "" || !isEmailValid(credential.email)) {
				errors.credentials = errors.credentials || [];
				errors.credentials.push(credential);
				allOk = false;
			}
		}
		console.log(errors);
		
		
		toSave.abilita_logo_cliente = isAbilitaLogoCliente;

		toSave.lavorazioni_scontate = toSave.lavorazioni_scontate.filter(
			(item) => item.codice !== ""
		);
		toSave.materiali_scontati = toSave.materiali_scontati.filter(
			(item) => item.codice !== ""
		);
		toSave.sconto_azienda = toSave.sconto_azienda.filter(
			(item) => item._id !== ""
		);
		toSave.sedi_secondarie = toSave.sedi_secondarie.filter(
			(item) => item.address !== ""
		);

		// TODO: add logo
		return { ok: allOk, toSave: toSave, errors: errors };
	};

	// --------------------------------------------------------------------------------
	const getSediSecondarie = () => {
		let sedi = [];
		if (anagrafica.sedi_secondarie) {
			anagrafica.sedi_secondarie.map((sede) =>
				sedi.push({ value: sede.address })
			);
		}
		sedi.push({ value: "" });
		return sedi;
	};

	
	return (
		isLoading ?
			<div
				style={
					{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: "56px"
					}
				}
			>
				<Loader
					layer={3}
					color="var(--red)"
				/>
			</div>
		:
		<Wrapper>
			<Breadcrumb
				levels={breadcrumbLevels}
				onClick={() => navigate(PROFILODETAIL)}
			/>

			<Row
				style={{
					marginTop: "32px",
					alignItems: "flex-start",
				}}
				breakpoints={[
					{
						breakpoint: "10000",
						defaultWidth: 100,
						widthOverride: {
							".first-card": 100,
							".second-card": 100,
						},
					},
					{
						breakpoint: "1000",
						defaultWidth: 100,
					},
				]}
			>
				<div
					className="first-card"
					style={{
						display: "flex",
						flexDirection: "column",
						className: "first-card",
					}}
				>
					{anagrafica._id && (
						<Card
							style={{
								padding: "32px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexDirection: "row",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="xxlarge" weight="bold" color="var(--black)">
									{(anagrafica.ragione_sociale ? anagrafica.ragione_sociale : "-") + " / "+ (anagrafica.nomecompleto ? anagrafica.nomecompleto : "")}
								</Text>

								<Text size="large" weight="regular" color="var(--darkgrey)">
									{`Data creazione: ${
										anagrafica.createdAt
											? moment(anagrafica.createdAt).format("DD/MM/YYYY")
											: moment().format("DD/MM/YYYY")
									}`}
								</Text>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="large" weight="regular" color="var(--grey-2)">
									Ultimo aggiornamento:
								</Text>
								<Text
									size="large"
									weight="regular"
									color="var(--grey-2)"
									style={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									{anagrafica.updatedAt
										? moment(anagrafica.updatedAt).format("DD/MM/YYYY, HH:mm")
										: moment().format("DD/MM/YYYY, HH:mm")}
								</Text>
							</div>
						</Card>
					)}

					<Card
						style={{
							padding: !anagrafica._id ? "" : "32px",
							width: "100%",
							marginTop: !anagrafica._id ? "" : "40px",
						}}
					>
						<Text size="large" weight="bold">
							PROFILO
						</Text>

						<Text size="medium" weight="regular">
							Modificare i dati nei campi dedicati
						</Text>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "60px",
							}}
						>
							<TextField
								id="ragione_sociale"
								type="text"
								value={anagrafica.ragione_sociale || ""}
								label="RAGIONE SOCIALE"
								placeholder="Inserisci..."
								onChange={onChange}
							/>

							<TextField
								id="p_iva"
								type="text"
								value={anagrafica.p_iva || ""}
								label="PARTITA IVA"
								placeholder="Inserisci..."
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>

							<TextField
								id="codicefiscale"
								type="text"
								value={anagrafica.codicefiscale || ""}
								label="CODICE FISCALE"
								placeholder="Inserisci..."
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "60px",
							}}
						>
							<TextField
								id="indirizzo"
								type="text"
								value={anagrafica.indirizzo || ""}
								label="INDIRIZZO"
								placeholder="Inserisci..."
								onChange={onChange}
							/>
						</Row>

						<Row
							defaultWidth={50}
							style={{
								marginTop: "40px",
							}}
							breakpoints={[
								{
									breakpoint: "10000",
									defaultWidth: 25,
									widthOverride: {
										".citta": 50,
									},
								},
							]}
						>
							<TextField
								id="citta"
								type="text"
								value={anagrafica.citta || ""}
								label="CITTA'"
								placeholder="Inserisci..."
								onChange={onChange}
								className="citta"
								style={{
									marginTop: "20px",
								}}
							/>

							<TextField
								id="zipcode"
								type="text"
								value={anagrafica.zipcode || ""}
								label="CAP"
								placeholder="Inserisci..."
								onChange={onChangeNumber}
								style={{
									marginTop: "20px",
								}}
							/>

							<TextField
								id="provincia"
								type="text"
								value={anagrafica.provincia || ""}
								label="PROVINCIA"
								placeholder="Inserisci..."
								onChange={onChange}
								style={{
									marginTop: "20px",
								}}
							/>
						</Row>

						<AddSedeSecondaria
							list={anagrafica.sedi_secondarie}
							onListChange={(newList) => {
								anagrafica.sedi_secondarie = newList;
								setAnagrafica({ ...anagrafica });
							}}
							style={{
								marginTop: "20px",
							}}
						/>

						<Text 
							size="small"
							weight={"medium"}
							color="var(--darkgrey)"
							style={{
								marginTop: "20px",
							}}
						>
							{"Logo"}
						</Text>
						<AddDocument
							id="documento_logo_cliente"
							list={anagrafica.logo_cliente}
							onChange={(id, list) => {
								anagrafica.logo_cliente = list;
								setAnagrafica({...anagrafica});
							}}
						/>

					</Card>
				</div>
				<div
					className="second-card"
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Card
						style={{
							padding: "32px",
							width: "100%",
							marginTop: "40px",
						}}
					>
						<Text size="large" weight="bold">
							UTENZE CLIENTE
						</Text>

						<Text size="medium" weight="regular">
							Selezionare o inserire i dati nei campi dedicati
						</Text>

						<AddUtenzeCliente
							list={anagrafica.credentials && anagrafica.credentials.length > 0 ? anagrafica.credentials : [{ fullname: "", telefono: "", email: "" }]}
							clientId={anagrafica._id}
							onListChange={(newList) => {
								anagrafica.credentials = newList;
								setAnagrafica({ ...anagrafica });
							}}
							style={{
								marginTop: "50px",
							}}
						/>
					</Card>
				</div>
			</Row>

			<Spacer height="240px" />

			<BottomBar
				leftSecondButton={{
					show: false,
					title: "",
					onClick: "",
				}}
				leftInfo={{
					show: anagrafica._id,
					text: `Ultima modifica: ${
						anagrafica
							? moment(anagrafica.updatedAt).format("DD/MM/YYYY, HH:mm")
							: "-"
					}`,
				}}
				successButton={{
					show: true,
					title: "SALVA MODIFICHE",
					onClick: saveAnagrafica,
				}}
				cancelButton={{
					show: true,
					title: "ANNULLA",
					onClick: () => navigate(PROFILODETAIL),
				}}
			/>

		</Wrapper>
	);
}
