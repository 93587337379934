import {
	Add as AddIcon,
	ArrowForward as ArrowForwardIcon,
	Description as DescriptionIcon,
	Dns as DnsIcon,
	EmailOutlined as EmailIcon,
	Print as PrintIcon,
} from "@material-ui/icons";
import React, { useEffect, useState, useMemo } from "react";
import {
	BlockCommesseMateriali,
	BottomBar,
	Breadcrumb,
	Button,
	Card,
	Checkbox,
	Row,
	Spacer,
	Text,
	TextAreaField,
	TextField,
	NewOrderModal,
	SendQuotationModal,
	CreateCommessaModal,
	ConfirmAlertModal,
	Dropdown,
	Loader,
	SelectField
} from "../../components";
import { ToastContainer, Toast } from "react-bootstrap";
import {
	getCommessaDetail,
	updatePreventivi,
	deletePreventivo,
	sendMailPreventivo, updateCommessa
} from "../../services";
import { sendPreventivoCliente } from "../../services/commesse";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as moment from "moment";
import {
	getZona,
	getPriorita,
	calcolaTotalePreventivo,
} from "../../utils/functionsUtils";
import { COMMESSEDETAIL, PREVENTIVI } from "../../navigation/constants";
import { COMMESSA_TYPES, USER_ROLES } from "../../utils/constants";
import { setToast } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { PDF_CONTEGGI, PDF_ETICHETTE, PDF_PREVENTIVO } from "../../services/constants";
import { withTranslation } from "react-i18next";
import { grey } from "@material-ui/core/colors";

export const PreventiviDetail = withTranslation()((props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const location = useLocation();
	const [id, setId] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [preventivo, setPreventivo] = useState();
	const [commessaId, setCommessaId] = useState();
	const navigate = useNavigate();
	const [newOrderModalShow, setNewOrderModalShow] = useState(false);
	const [sendQuotationModalShow, setSendQuotationModalShow] = useState(false);
	const [createCommessaModalShow, setCreateCommessaModalShow] = useState(false);
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);
	//const [blockListMaterials, setblockListMaterials] = useState();
	const [pdfToOpen, setPdfToOpen] = useState("");
	const toast = useSelector((state) => state.toast || {});
	const user = useSelector((state) => state.user || {});



/*

		let totListino = calcolaTotalePreventivo(data);

		let primoScontoIncondizionato = 0
		if(data.cliente && data.cliente.sconto_incondizionato && data.cliente.sconto_incondizionato.primo != ""){
			primoScontoIncondizionato = parseInt(data.cliente.sconto_incondizionato.primo)
		}
		let secondoScontoIncondizionato = 0
		if(data.cliente && data.cliente.sconto_incondizionato && data.cliente.sconto_incondizionato.secondo != ""){
			secondoScontoIncondizionato = parseInt(data.cliente.sconto_incondizionato.secondo)
		}

		let totFirstScontoIncondizionato = (totListino * primoScontoIncondizionato) / 100

		let scontoIncondizionato = ((totFirstScontoIncondizionato * secondoScontoIncondizionato) / 100) + totFirstScontoIncondizionato

		let totScontoIncondizionato = totListino - scontoIncondizionato
		let scontoAggiuntivo =
		data.sconto_aggiuntivo && data.sconto_aggiuntivo != ""
				? data.sconto_aggiuntivo
				: 0;
		let totScontato = totListino - scontoIncondizionato - ((totListino - scontoIncondizionato) * scontoAggiuntivo) / 100;
		let iva = (totScontato * 22) / 100;

		setTotalePreventivo(totListino);
		setScontoIncondizionato(scontoIncondizionato.toFixed(2));
		setTotWithScontoIncond(totScontoIncondizionato.toFixed(2));
		setTotScontato(totScontato.toFixed(2));
		setIva(iva.toFixed(2));
		setTotComplessivo((totScontato + iva).toFixed(2));

*/

	const totalePreventivo = useMemo(() => {
		if (!preventivo) {
			return 0;
		}
		return calcolaTotalePreventivo(preventivo);
	}, [preventivo]);

	const scontoIncondizionato = useMemo(() => {
		if (!preventivo) {
			return 0;
		}
		let primoScontoIncondizionato = 0
		if(preventivo.cliente && preventivo.cliente.sconto_incondizionato && preventivo.cliente.sconto_incondizionato.primo != ""){
			primoScontoIncondizionato = parseInt(preventivo.cliente.sconto_incondizionato.primo)
		}
		let secondoScontoIncondizionato = 0
		if(preventivo.cliente && preventivo.cliente.sconto_incondizionato && preventivo.cliente.sconto_incondizionato.secondo != ""){
			secondoScontoIncondizionato = parseInt(preventivo.cliente.sconto_incondizionato.secondo)
		}

		let totFirstScontoIncondizionato = (totalePreventivo * primoScontoIncondizionato) / 100

		return (((totFirstScontoIncondizionato * secondoScontoIncondizionato) / 100) + totFirstScontoIncondizionato).toFixed(2);
	}, [preventivo]);

	const totWithScontoIncond = useMemo(() => {
		if (!preventivo) {
			return 0;
		}
		return (totalePreventivo - scontoIncondizionato).toFixed(2);
	}, [preventivo]);//[totWithScontoIncond, setTotWithScontoIncond] = useState();

	const totScontato = useMemo(() => {
		if (!preventivo) {
			return 0;
		}
		let scontoAggiuntivo = preventivo.sconto_aggiuntivo && preventivo.sconto_aggiuntivo != "" ? preventivo.sconto_aggiuntivo : 0;
		return (totalePreventivo - scontoIncondizionato - ((totalePreventivo - scontoIncondizionato) * scontoAggiuntivo) / 100).toFixed(2);
	}, [preventivo]);

	const iva = useMemo(() => {
		if (!preventivo) {
			return 0;
		}
		return ((totScontato * 22) / 100).toFixed(2);
	}, [preventivo]);

	const totComplessivo = useMemo(() => {
		if (!preventivo) {
			return 0;
		}
		return (parseFloat(totScontato) + parseFloat(iva)).toFixed(2);
	}, [preventivo]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setCommessaId(queryParams.get("commessaId"));
	}, []);

	useEffect(() => {
		if(commessaId) {
			executeGetPreventivoDetail();
		}
	}, [commessaId]);

	useEffect(() => {
		if	(pdfToOpen != "") {
			savePreventivo()
		}
	}, [pdfToOpen]);

	const executeGetPreventivoDetail = () => {
		getCommessaDetail(commessaId).then(({ result, error }) => {
			if (error) {
			} else {
				setPreventivo(result);
			}

			setIsLoading(false);
		});
	};

	const savePreventivo = () => {
		updateCommessa(preventivo._id, preventivo).then(({ result, error }) => {
			if (error) {

				dispatch(setToast({
					show: true,
					title: "preventiviDetail.toastUpdate.error.title",
					description: "preventiviDetail.toastUpdate.error.description",
					variant: "danger"
				}));

			} else {

				dispatch(setToast({
                    show: true,
                    title: "preventiviDetail.toastUpdate.success.title",
                    description: "preventiviDetail.toastUpdate.success.description",
                    variant: "success"
				}));

				if (pdfToOpen != "") {
					window.open(pdfToOpen, '_blank');
					setPdfToOpen("");
				}

			}
		});
	};

	const confermaEliminazione = () => {
		setConfirmAlertModalShow(true);
	}

	const eliminaPreventivo = () => {
		deletePreventivo(preventivo._id).then(({ result, error }) => {
			if (error) {

			} else {
				navigate(PREVENTIVI);
			}
		});
	};

	const sendPreventivoToClient = () => {
		if(preventivo.created_by.client_visible){
			let preventivoToSend = {
				commessa_id : preventivo._id
			}

			sendPreventivoCliente(preventivoToSend).then(({ result, errors }) => {
				if (errors) {
					console.error(errors);
					dispatch(
						setToast({
							show: true,
							title: "preventiviDetail.toastSendMail.error.title",
							description: "preventiviDetail.toastSendMail.error.description",
							variant: "danger",
						})
					);
				} else if (result === undefined) {
					console.error("Qualcosa è andato storto");
					dispatch(
						setToast({
							show: true,
							title: "preventiviDetail.toastSendMail.error.title",
							description: "preventiviDetail.toastSendMail.error.description",
							variant: "danger",
						})
					);
				} else {
					dispatch(
						setToast({
							show: true,
							title: "preventiviDetail.toastSendMail.success.title",
							description: "preventiviDetail.toastSendMail.success.description",
							variant: "success",
						})
					);
				}
			});
		}
	}

	const inviaPreventivo = (mailToSend) => {
		let preventivoToSend = {
			preventivo_id : preventivo._id,
			client_email : mailToSend
		}

		sendMailPreventivo(preventivoToSend).then(({ result, errors }) => {
			if (errors) {
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "preventiviDetail.toastSendMail.error.title",
						description: "preventiviDetail.toastSendMail.error.description",
						variant: "danger",
					})
				);
			} else if (result === undefined) {
				console.error("Qualcosa è andato storto");
				dispatch(
					setToast({
						show: true,
						title: "preventiviDetail.toastSendMail.error.title",
						description: "preventiviDetail.toastSendMail.error.description",
						variant: "danger",
					})
				);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "preventiviDetail.toastSendMail.success.title",
						description: "preventiviDetail.toastSendMail.success.description",
						variant: "success",
					})
				);
			}
			setSendQuotationModalShow(false)
		});
	};



	const getUniqueMaterial = () => {
		let list = [];

		preventivo.materiali.forEach((item, index) => {
			if (
				item.materiale &&
				!list.some(
					(materiale) =>
						materiale.materiale &&
						materiale.materiale.nome == item.materiale.nome
				)
			) {
				list.push(item);
			}
		});

		return list;
	};

	if (isLoading) {
		return (
			<div
				style={
					{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: "56px"
					}
				}
			>
				<Loader
					layer={3}
					color="var(--red)"
				/>
			</div>
		);
	} else {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					padding: "20px",
				}}
			>
				<Breadcrumb
					levels={[
						{
							text: "preventiviDetail.breadcrumbTitle",
							link: PREVENTIVI
						},
						{
							text: "preventiviDetail.breadcrumbSubtitle"
						}
					]}
					style={{
						marginTop: "40px",
					}}
				/>
				<Row
					style={{
						marginTop: "32px",
						marginBottom: "100px",
						alignItems: "flex-start",
					}}
					breakpoints={[
						{
							breakpoint: "10000",
							defaultWidth: 100,
							widthOverride: {
								".first-card": 60,
								".second-card": 40,
							},
						},
						{
							breakpoint: "1000",
							defaultWidth: 100,
						},
					]}
				>
					<div
						className="first-card"
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Card
							style={{
								padding: "32px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexDirection: "row",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="xxlarge" weight="bold" color="var(--black)">
									{t("preventiviDetail.preventivoNumber", { number: preventivo.numero_preventivo, year: moment(preventivo.createdAt).format("YYYY") })}
								</Text>

								<Text size="large" weight="regular" color="var(--darkgrey)">
									{t("preventiviDetail.creationDate", { date: moment(preventivo.createdAt).format("YYYY") })}
								</Text>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="large" weight="regular" color="var(--grey-2)">
									{"preventiviDetail.dtLastUpdate"}
								</Text>
								<Text
									size="large"
									weight="regular"
									color="var(--grey-2)"
									style={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									{moment(preventivo.updatedAt).format("DD/MM/YYYY, HH:mm")}
								</Text>
							</div>
						</Card>

						{preventivo.materiali.map((item, index) => (
							<BlockCommesseMateriali
								key={item._id || item.key}
								listino={preventivo.listino._id}
								commessaMateriale={item}
								commessaCliente={preventivo.cliente}
								materialiSelected={preventivo.materiali.filter((item) => item.materiale && item.materiale._id).map((item) => item.materiale._id)}
								materialeName={item.materialeName || ""}
								aziendaId={item.aziendaId || ""}
								onChange={(item) => {
									if (preventivo.materiali.length > index) {
										preventivo.materiali[index] = item;
									} else {
										preventivo.materiali.push(item);
									}

									setPreventivo({ ...preventivo });
								}}
								onDelete={(item) => {
									preventivo.materiali = preventivo.materiali.filter((el, _index) => { return _index != index; });
									//preventivo.materiali.filter(function(el) { return el._id != item._id; });
									setPreventivo({ ...preventivo });
								}}
							/>
						))}

						<Row
							style={{
								display: "flex",
								flexDirection: "row",
								padding: "20px",
							}}
						>
							<Button
								variant="primary"
								style={{
									marginTop: "40px",
									marginRight: "20px",
								}}
								onClick={(e) => {
									preventivo.materiali.push({
										key: id + 1,
										accessori: [],
										altre_lavorazioni: [],
										dimensioni: [],
										lavorazioni_alzatine: [],
										lavorazioni_fori: [],
										lavorazioni_profili: [],
										lavorazioni_varie: [],
										vasche_integrate: [],
									});
									setId(id+1);
									setPreventivo({ ...preventivo });
								}}
							>
								<AddIcon
									style={{
										marginRight: "8px",
									}}
								/>
								<Text size="small" weight="medium">
									{"preventiviDetail.addMaterial"}
								</Text>
							</Button>
							{preventivo.materiali && preventivo.materiali.some((item) => !!item.materiale && !!item.materiale._id) ? (
								<Dropdown
									style={{
										marginTop: "40px",
									}}
									toggle={
										<Button
											variant="outline-primary"

											// onClick={(e) => {
											// 	let materialCopy = JSON.parse(JSON.stringify(preventivo.materiali[preventivo.materiali.length - 1].materiale)) ;
											// 	materialCopy.spessore = "";
											// 	preventivo.materiali.push({
											// 		materiale: materialCopy,
											// 		accessori: [],
											// 		altre_lavorazioni: [],
											// 		dimensioni: [],
											// 		lavorazioni_alzatine: [],
											// 		lavorazioni_fori: [],
											// 		lavorazioni_profili: [],
											// 		lavorazioni_varie: [],
											// 		vasche_integrate: []
											// 	})
											// 	setPreventivo({...preventivo});
											// }}
										>
											<AddIcon
												style={{
													marginRight: "8px",
												}}
											/>
											<Text size="small" weight="medium">
												{"preventiviDetail.addSpessore"}
											</Text>
										</Button>
									}
									menu={getUniqueMaterial().map((item, index) => {
										return (
											<div
												style={{
													width: "100%",
													display: "flex",
													alignItems: "center",
												}}
												onClick={(e) => {
													preventivo.materiali.push({
														key: id+1,
														materiale: null,
														materialeName: item.materiale.nome,
														aziendaId: item.materiale.azienda ? item.materiale.azienda._id || "" : "",
														accessori: [],
														altre_lavorazioni: [],
														dimensioni: [],
														lavorazioni_alzatine: [],
														lavorazioni_fori: [],
														lavorazioni_profili: [],
														lavorazioni_varie: [],
														vasche_integrate: [],
													});
													setId(id+1);
													setPreventivo({ ...preventivo });
												}}
											>
												<Text size="large" weight="medium">
													{item.materiale.nome}
												</Text>
											</div>
										);
									})}
								/>
							) : (
								""
							)}
						</Row>
						<Spacer height="100px" />
					</div>

					<div
						className="second-card"
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						{preventivo.cd_type == "PREVENTIVO" && user.cd_privilege != USER_ROLES.CLIENT ? (
							<Card
								style={{
									padding: "32px",
									width: "100%",
								}}
							>
								<Text size="large" weight="bold">
									{"preventiviDetail.commessaLinked.title"}
								</Text>

								<Text size="medium" weight="regular">
									{"preventiviDetail.commessaLinked.description"}
								</Text>

								<Row
									style={{
										display: "flex",
										flexDirection: "row",
										padding: "20px 0px 0px 0px"
									}}
								>
									<Button
										variant="primary"
										style={{
											marginTop: "20px",
											marginRight: "20px",
											alignItems: "center",
											justifyContent: "center",
											display: "flex",
										}}
										onClick={() => {
											setCreateCommessaModalShow(true);
										}}
									>
										<AddIcon
											style={{
												width: "20px",
												height: "20px",
												marginRight: "8px",
											}}
										/>
										<Text size="small" weight="medium">
											{"preventiviDetail.createCommessa"}
										</Text>
									</Button>
									<CreateCommessaModal
										show={createCommessaModalShow}
										isPreventivo={true}
										commessaData={preventivo}
										onHide={() => setCreateCommessaModalShow(false)}
										onCancel={() => setCreateCommessaModalShow(false)}
										onSuccess={() =>
											navigate(COMMESSEDETAIL+`?commessaId=${preventivo._id}`)
										}
									/>
								</Row>
							</Card>
						) : (
							<div></div>
						)}
						{preventivo.cd_type != "PREVENTIVO" && user.cd_privilege != USER_ROLES.CLIENT ?
							(
							<Card
								style={{
									padding: "32px",
									width: "100%",
								}}
							>
								<Text size="large" weight="bold">
									{"preventiviDetail.commessaLinked.title"}
								</Text>

								<Text size="medium" weight="regular">
									{"preventiviDetail.commessaLinked.description2"}
								</Text>
								<Row
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										flexDirection: "row",
										marginTop: "40px",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "column",
										}}
									>
										<Text size="small" weight="medium" color="var(--darkgrey)">
											{"preventiviDetail.commessaNumber"}
										</Text>
										<Text
											size="small"
											weight="medium"
											color="var(--darkgrey)"
											style={{
												marginTop: "17px",
											}}
										>
											{"preventiviDetail.creationDate2"}
										</Text>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "flex-end",
											flexDirection: "column",
										}}
									>
										<Text size="large" weight="regular" color="var(--black)">
											{preventivo.numero_commessa +
												" / " +
												+moment(preventivo.dt_creazione_commessa).format(
													"YYYY"
												)}
										</Text>
										<Text
											size="large"
											weight="regular"
											color="var(--black)"
											style={{
												marginTop: "12px",
											}}
										>
											{moment(preventivo.dt_creazione_commessa).format(
												"DD/MM/YYYY"
											)}
										</Text>
									</div>
								</Row>
								<Row
									style={{
										display: "flex",
										flexDirection: "row",
										paddingTop: "20px",
									}}
								>
									<Button
										variant="secondary"
										style={{
											marginTop: "40px",
											marginRight: "20px",
										}}
										onClick={() => {
											navigate(COMMESSEDETAIL+`?commessaId=${preventivo._id}`);
										}}
									>
										<Text size="small" weight="medium">
											{"preventiviDetail.goToCommessa"}
										</Text>
										<ArrowForwardIcon
											style={{
												width: "20px",
												height: "20px",
												marginLeft: "8px",
											}}
										/>
									</Button>
								</Row>
							</Card>
							) : (
								<div></div>
							)
						}
						<Card
							style={{
								padding: "32px",
								width: "100%",
								marginTop: user.cd_privilege == USER_ROLES.ADMIN ? "40px" : "0px",
							}}
						>
							<Text size="large" weight="bold">
								{"preventiviDetail.anagrafica.title"}
							</Text>

							<Text size="medium" weight="regular">
								{"preventiviDetail.anagrafica.description"}
							</Text>
							<TextField
								id=""

								type="text"
								label={"preventiviDetail.clientName"}
								lightbackground
								readOnly
								value={
									preventivo.cliente && preventivo.cliente.nomecompleto
										? preventivo.cliente.nomecompleto
										: "-"
								}
								style={{
									marginTop: "20px",
								}}
							/>
							{
								user.cd_privilege != USER_ROLES.CLIENT ?
									(
										<>
											<Row
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													flexDirection: "row",
													marginTop: "20px",
												}}
											>
												<div
													style={{
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
													}}
												>
													<Text size="small" weight="medium" color="var(--darkgrey)">
														{"preventiviDetail.telephone"}
													</Text>
													<Text
														size="small"
														weight="medium"
														color="var(--darkgrey)"
														style={{
															marginTop: "17px",
														}}
													>
														{"preventiviDetail.city"}
													</Text>
													<Text
														size="small"
														weight="medium"
														color="var(--darkgrey)"
														style={{
															marginTop: "17px",
														}}
													>
														{"preventiviDetail.zone"}
													</Text>
													<Text
														size="small"
														weight="medium"
														color="var(--darkgrey)"
														style={{
															marginTop: "17px",
														}}
													>
														{"preventiviDetail.agent"}
													</Text>
													<Text
														size="small"
														weight="medium"
														color="var(--darkgrey)"
														style={{
															marginTop: "17px",
														}}
													>
														{"preventiviDetail.priority"}
													</Text>
													<Text
														size="small"
														weight="medium"
														color="var(--darkgrey)"
														style={{
															marginTop: "17px",
														}}
													>
														{"preventiviDetail.contenzioso"}
													</Text>
													<Text
														size="small"
														weight="medium"
														color="var(--darkgrey)"
														style={{
															marginTop: "17px",
														}}
													>
														{"preventiviDetail.imballo"}
													</Text>
												</div>

												<div
													style={{
														display: "flex",
														justifyContent: "flex-end",
														alignItems: "flex-end",
														flexDirection: "column",
													}}
												>
													<Text size="large" weight="regular" color="var(--black)">
														{preventivo.cliente && preventivo.cliente.telefono
															? preventivo.cliente.telefono
															: "-"}
													</Text>
													<Text
														size="large"
														weight="regular"
														color="var(--black)"
														style={{
															marginTop: "12px",
														}}
													>
														{preventivo.cliente && preventivo.cliente.citta
															? preventivo.cliente.citta
															: "-"}
													</Text>
													<Text
														size="large"
														weight="regular"
														color="var(--black)"
														style={{
															marginTop: "12px",
														}}
													>
														{preventivo.cliente && preventivo.cliente.zona
															? getZona(preventivo.cliente.zona)
															: "-"}
													</Text>
													<Text
														size="large"
														weight="regular"
														color="var(--black)"
														style={{
															marginTop: "12px",
														}}
													>
														{preventivo.cliente && preventivo.cliente.agente
															? preventivo.cliente.agente
															: "-"}
													</Text>
													<Text
														size="large"
														weight="regular"
														color="var(--black)"
														style={{
															marginTop: "12px",
														}}
													>
														{preventivo.cliente && preventivo.cliente.priority
															? getPriorita(preventivo.cliente.priority)
															: "-"}
													</Text>
													<Text
														size="large"
														weight="regular"
														color="var(--black)"
														style={{
															marginTop: "12px",
														}}
													>
														{preventivo.cliente && preventivo.cliente.contenzioso
															? "SI"
															: "NO"}
													</Text>
													<Text
														size="large"
														weight="regular"
														color="var(--black)"
														style={{
															marginTop: "12px",
														}}
													>
														{preventivo.cliente &&
														preventivo.cliente.imballo_obbligatorio
															? "SI"
															: "NO"}
													</Text>
												</div>
											</Row>
										</>
									)
								:
									""
							}
							<TextField
								id=""
								required
								type="text"
								label={"commesseDetail.listinoLavorazioni"}
								defaultValue={
									preventivo.listino && preventivo.listino.nome
										? preventivo.listino.nome
										: ""
								}
								readOnly
								lightbackground
								style={{
									marginTop: "40px",
								}}
							/>
							<TextField
								id=""

								type="text"
								label={"preventiviDetail.riferimento"}
								placeholder={"hint_insert"}
								defaultValue={preventivo.riferimento ? preventivo.riferimento : "-"}
								onChange={(id, value) => {
									preventivo.riferimento = value;

									setPreventivo({ ...preventivo });
								}}

								style={{
									marginTop: "20px",
								}}
							/>
							{user.cd_privilege == USER_ROLES.CLIENT ?
								<>
									<TextField
										id=""
										required
										type="text"
										label={"preventiviDetail.attentionOf"}
										defaultValue={preventivo.cliente.credentials.filter((item) => item._id === user.logged._id) ? preventivo.cliente.credentials.filter((item) => item._id === user.logged._id)[0].fullname : "-"}
										readOnly
										lightbackground
										style={{
											marginTop: "40px",
										}}
									/>
									<Checkbox
										id="chk_visibile_cliente"
										checked={preventivo.created_by.client_visible}
										label={"preventiviDetail.visibileCliente"}
										color="var(--black)"
										disabled
										onChange={() => {
											preventivo.created_by.client_visible = !preventivo.created_by.client_visible;
											setPreventivo({ ...preventivo });
										}}
										style={{
											marginRight: "24px",
											marginTop: "20px",
										}}
									/>
								</>
								:
								<>
									<SelectField
										id=""
										required
										type="text"
										labelColor="var(--darkgrey)"
										label={"preventiviDetail.attentionOf"}
										value={preventivo.attenzione_di}
										style={{
											marginTop: "20px"

										}}
										options={[{ value: "-", text: "Seleziona..." }].concat(
											preventivo.cliente.credentials.map((item) => ({
												value: item.email,
												text: item.fullname,
											}))
										)}
										onChange={(id, value) => {
											preventivo.attenzione_di = value;
											setPreventivo({ ...preventivo });
										}}
										lightbackground
									></SelectField>
									<Checkbox
										id="chk_visibile_cliente"
										checked={preventivo.created_by.client_visible}
										label={"preventiviDetail.visibileCliente"}
										color="var(--black)"
										onChange={() => {
											preventivo.created_by.client_visible = !preventivo.created_by.client_visible;
											setPreventivo({ ...preventivo });
										}}
										style={{
											marginRight: "24px",
											marginTop: "20px",
										}}
									/>


									<div
										onClick={sendPreventivoToClient}
										style={{
											cursor: "pointer",
											marginTop: "8px",
											marginBottom: "16px"
										}}
									>
										<DescriptionIcon
											style={{
												color: preventivo.created_by.client_visible ? "var(--black)" : "var(--grey-2)",
												width: "24px",
												height: "24px",
												marginRight: "8px",
												marginLeft: "-3px"
											}}
										></DescriptionIcon>

										<Text size="small" weight="medium" color={preventivo.created_by.client_visible ? "var(--black)" : "var(--grey-2)"}>
											{"preventiviDetail.sendPreventivoCliente"}
										</Text>
									</div>


								</>
							}
							{/* <TextField
								id=""
								type="text"
								label={"preventiviDetail.attentionOf"}
								defaultValue={
									(preventivo.attenzione_di && preventivo.cliente.credentials.find((e) => e.email === preventivo.attenzione_di)
										? preventivo.cliente.credentials.find((e) => e.email === preventivo.attenzione_di).fullname
										: "-")
								}

								lightbackground
								style={{
									marginTop: "20px",
								}}
							/> */}
							{user.cd_privilege != USER_ROLES.CLIENT ? (
								<>
								<TextField
									id=""
									type="text"
									label={"preventiviDetail.additionalDiscount"}
									value={preventivo.sconto_aggiuntivo}
									placeholder={"hint_insert"}
									onChange={(id, value) => {
										preventivo.sconto_aggiuntivo = value;

										/*let scontoAggiuntivo = value != "" ? value : 0;
										let totScontato = totalePreventivo - scontoIncondizionato - ((totalePreventivo - scontoIncondizionato) * scontoAggiuntivo) / 100;
										let iva = (totScontato * 22) / 100;
										preventivo.sconto_aggiuntivo = scontoAggiuntivo;

										setTotScontato(totScontato.toFixed(2));
										setIva(iva.toFixed(2));
										setTotComplessivo((totScontato + iva).toFixed(2));*/

										setPreventivo({ ...preventivo });
									}}
									style={{
										marginTop: "20px",
									}}
								/>
								<Checkbox
									id="chk_stampa_news"
									checked={preventivo.stampa_news}
									label={"preventiviDetail.printNews"}
									color="var(--black)"
									onChange={() => {
										preventivo.stampa_news = !preventivo.stampa_news;
										setPreventivo({ ...preventivo });
									}}
									style={{
										marginRight: "24px",
										marginTop: "20px",
									}}
								/>
								</>)
								:
								(<div></div>)
							}
							<TextAreaField
								label="preventiviDetail.comments"
								value={preventivo.commento_preventivo}
								onChange={(value) => {
									preventivo.commento_preventivo = value;
									setPreventivo({ ...preventivo });
								}}
								style={{
									marginTop: "20px",
								}}
							/>
							{user.cd_privilege != USER_ROLES.CLIENT ?
								<div>
									<Button
										variant="outline-secondary"
										style={{
											marginTop: "40px",
										}}
										onClick={() => {
											setSendQuotationModalShow(true);
										}}
									>
										<EmailIcon
											style={{
												color: "var(--grey-2)",
												width: "20px",
												height: "20px",
												marginRight: "8px",
											}}
										/>
										<Text size="small" weight="medium">
											{"preventiviDetail.sendMail"}
										</Text>
									</Button>
									<SendQuotationModal
										show={sendQuotationModalShow}
										isPreventivo={true}
										data={preventivo}
										onSuccess={(mailToSend) => inviaPreventivo(mailToSend)}
										onHide={() => setSendQuotationModalShow(false)}
										onCancel={() => setSendQuotationModalShow(false)}
									/>
								</div>
								:
								(<div></div>)
							}
						</Card>
						<Card
							style={{
								padding: "32px",
								width: "100%",
								marginTop: "40px",
							}}
						>
							<Text size="large" weight="bold">
								{"preventiviDetail.total.title"}
							</Text>

							<Text size="medium" weight="regular">
								{"preventiviDetail.total.description"}
							</Text>
							<div
								style={{
									marginTop: "40px",
									display: "grid",
									gridTemplateColumns: "auto auto auto",
									gridGap: "0px",
									backgroundColor: "var(--white)",
								}}
							>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
										borderRadius: "5px 0px 0px 0px",
									}}
								>
									<Text size="xsmall" weight="regular" color="var(--darkgrey)">
										{"preventiviDetail.listinoTotal"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="small" weight="regular" color="var(--black)">
										{""}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										borderRight: "1px solid black",
										textAlign: "right",
										padding: "12px",
										borderRadius: "0px 5px 0px 0px",
									}}
								>
									<Text size="medium" weight="regular" color="var(--black)">
										{totalePreventivo + " €"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="xsmall" weight="regular" color="var(--darkgrey)">
										{"preventiviDetail.scontoIncodizionato"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="small" weight="regular" color="var(--black)">
										{(preventivo.cliente &&
										preventivo.cliente.sconto_incondizionato && preventivo.cliente.sconto_incondizionato.primo != "" && preventivo.cliente.sconto_incondizionato.primo != "0" && preventivo.cliente.sconto_incondizionato.secondo != "" && preventivo.cliente.sconto_incondizionato.secondo != "0"
											? parseInt(preventivo.cliente.sconto_incondizionato.primo) + "% + " +
											  parseInt(preventivo.cliente.sconto_incondizionato.secondo) + "%"
											: (preventivo.cliente.sconto_incondizionato && preventivo.cliente.sconto_incondizionato.primo != "" ? parseInt(preventivo.cliente.sconto_incondizionato.primo) + "%" : "0%")) }
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										borderRight: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="medium" weight="regular" color="var(--black)">
										{scontoIncondizionato + " €"}
									</Text>
								</div>

								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="xsmall" weight="regular" color="var(--darkgrey)">
										{"preventiviDetail.restano"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="small" weight="regular" color="var(--black)">
										{t("preventiviDetail.additionalDiscount2", {sconto: (preventivo.sconto_aggiuntivo ? preventivo.sconto_aggiuntivo : 0)})}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										borderRight: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="medium" weight="regular" color="var(--black)">
										{totScontato + " €"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="xsmall" weight="regular" color="var(--darkgrey)">
										{"preventiviDetail.iva"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="small" weight="regular" color="var(--black)">
										{"22 %"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										borderRight: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="medium" weight="regular" color="var(--black)">
										{iva + " €"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										borderBottom: "1px solid black",
										textAlign: "right",
										padding: "12px",
										borderRadius: "0px 0px 0px 5px",
									}}
								>
									<Text size="xsmall" weight="regular" color="var(--darkgrey)">
										{"preventiviDetail.totaleComplessivo"}
									</Text>
								</div>
								<div
									style={{
										borderTop: "1px solid black",
										borderLeft: "1px solid black",
										borderBottom: "1px solid black",
										textAlign: "right",
										padding: "12px",
									}}
								>
									<Text size="small" weight="regular" color="var(--black)">
										{""}
									</Text>
								</div>
								<div
									style={{
										border: "1px solid black",
										textAlign: "right",
										padding: "12px",
										borderRadius: "0px 0px 5px 0px",
									}}
								>
									<Text size="medium" weight="medium" color="var(--red)">
										{totComplessivo + " €"}
									</Text>
								</div>
							</div>
							{user.cd_privilege != USER_ROLES.CLIENT ? (
								<>
								<Row
									style={{
										marginTop: "40px",
									}}
								>
									<TextField
										id=""
										type="text"
										label={"preventiviDetail.totaleCorpoLabel"}
										placeholder={"hint_insert"}
										value={
											preventivo.totale_a_corpo ? preventivo.totale_a_corpo : ""
										}
										onChange={(id, value) => {
											preventivo.totale_a_corpo = value;
											setPreventivo({ ...preventivo });
										}}
									/>
								</Row>
								</>)
								:
								(<div></div>)
							}
						</Card>

						<Card
							style={{
								padding: "32px",
								width: "100%",
								marginTop: "40px",
							}}
						>
							<Text size="large" weight="bold">
								{"preventiviDetail.printOptions.title"}
							</Text>

							<Text size="medium" weight="regular">
								{"preventiviDetail.printOptions.description1"}
							</Text>
							<Text size="medium" weight="regular">
								{"preventiviDetail.printOptions.description2"}
							</Text>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "baseline",
									flexDirection: "column",
									marginTop: "40px",
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										width: "100% !important",
										maxWidth: "100% !important",
									}}
								>
									<div
										onClick={() => {
											setPdfToOpen(`${process.env.REACT_APP_WS_LINK}${PDF_PREVENTIVO(preventivo._id, user.cd_privilege)}?u=${((user||{}).logged||{})._id || ""}`);
										}}
										style={{
											cursor: "pointer"
										}}
									>
										<DescriptionIcon
											style={{
												color: "var(--black)",
												width: "24px",
												height: "24px",
												marginRight: "8px",
											}}
										></DescriptionIcon>

										<Text size="small" weight="medium" color="var(--black)">
											{"preventiviDetail.printPreventivoCompleto"}
										</Text>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										marginTop: "20px",
										width: "100% !important",
										maxWidth: "100% !important",
									}}
								>
									<div
										onClick={() => {
											setPdfToOpen(`${process.env.REACT_APP_WS_LINK}${PDF_CONTEGGI(preventivo._id, user.cd_privilege)}`);
										}}
										style={{
											cursor: "pointer"
										}}
									>
										<PrintIcon
											style={{
												color: "var(--black)",
												width: "24px",
												height: "24px",
												marginRight: "8px",
											}}
										></PrintIcon>

										<Text size="small" weight="medium" color="var(--black)">
											{"preventiviDetail.printConteggi"}
										</Text>
									</div>
								</div>
								{ user.cd_privilege != USER_ROLES.CLIENT ?
									<>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												marginTop: "20px",
												width: "100% !important",
												maxWidth: "100% !important",
											}}
										>
											<div
												onClick={() => {
													setPdfToOpen(`${process.env.REACT_APP_WS_LINK}${PDF_PREVENTIVO(preventivo._id, "CMS-ACCESS-CLIENT")}?u=${((user||{}).logged||{})._id || ""}`);
												}}
												style={{
													cursor: "pointer"
												}}
											>
												<PrintIcon
													style={{
														color: "var(--black)",
														width: "24px",
														height: "24px",
														marginRight: "8px",
													}}
												></PrintIcon>

												<Text size="small" weight="medium" color="var(--black)">
													{"preventiviDetail.printPdfLogo"}
												</Text>
											</div>
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												marginTop: "20px",
												width: "100% !important",
												maxWidth: "100% !important",
											}}
										>
											<div
												onClick={() => {
													setPdfToOpen(`${process.env.REACT_APP_WS_LINK}${PDF_ETICHETTE(preventivo._id)}`);
												}}
												style={{
													cursor: "pointer"
												}}
											>
												<DnsIcon
													style={{
														color: "var(--black)",
														width: "24px",
														height: "24px",
														marginRight: "8px",
													}}
												></DnsIcon>

												<Text size="small" weight="medium" color="var(--black)">
													{"preventiviDetail.printEtichette"}
												</Text>
											</div>
										</div>
									</>
									:
									""
								}
							</div>
						</Card>
					</div>
				</Row>
				{preventivo.cd_type == "PRE-PREVENTIVO" ? (
					<BottomBar
						successButton={{
							show: true,
							title: "preventiviDetail.createPreventivo",
							onClick: "",
						}}
						cancelButton={{
							show: true,
							title: "preventiviDetail.cancel",
							onClick: "",
						}}
					/>
				) : (
					<BottomBar
						leftFirstButton={{
							show: true,
							title: "preventiviDetail.deletePreventivo",
							onClick: confermaEliminazione,
						}}
						leftSecondButton={{
							show: false,
							title: "",
							onClick: "",
						}}
						leftInfo={{
							show: true,
							text: t("preventiviDetail.dtLastUpdate2", {date: moment(preventivo.updatedAt).format("DD/MM/YYYY, HH:mm")}),
						}}
						successButton={{
							show: true,
							title: "preventiviDetail.updatePreventivo",
							onClick: savePreventivo,
						}}
						cancelButton={{
							show: true,
							title: "preventiviDetail.cancel",
							onClick: () => {
								navigate(PREVENTIVI);
							},
						}}
					/>

				)}
				<ConfirmAlertModal
					show={confirmAlertModalShow}
					headerTitle="preventiviDetail.confirmAlertModal.headerTitle"
					mainTitle="preventiviDetail.confirmAlertModal.mainTitle"
					subtitle="preventiviDetail.confirmAlertModal.subtitle"
					onHide={() => setConfirmAlertModalShow(false)}
					onCancel={() => setConfirmAlertModalShow(false)}
					onSuccess={() =>
						eliminaPreventivo()
					}
				/>
			</div>

		);
	}
})
