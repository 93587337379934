import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Text } from "../index";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`
	& {
		display: flex;
		align-items: center;
	}
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */

export const Breadcrumb = withTranslation()((props) => {
	const { t } = props;
	const levels = props.levels || [];
	const navigate = useNavigate();

	return (
		<Wrapper
			className={props.className}
			keyCell={props.keyCell}
			style={props.style}
		>
			<Text
				size="xxlarge"
				weight="medium"
				style={{
					marginRight: "16px",
					userSelect: "none",
					cursor: props.onClick ? "pointer" : "default",
				}}
				onClick={() => {
					if(levels && levels.length > 0 && levels[0].link) {
						navigate(levels[0].link, {});
					}
				}}
			>
				{levels && levels.length > 0 ? levels[0].text : "breadcrumb.projectTitleShort"}
			</Text>
			<Text
				size="medium"
				style={{
					marginRight: "16px",
					userSelect: "none",
					cursor: props.onClick ? "pointer" : "default",
				}}
			>
				|
			</Text>
			<Text
				size="medium"
				style={{
					marginRight: "8px",
					userSelect: "none",
					cursor: props.onClick ? "pointer" : "default",
				}}
				onClick={() => {
					if(levels && levels.length > 0 && levels[0].link) {
						navigate(levels[0].link, {});
					}
				}}
			>
				{"breadcrumb.projectTitle"}
			</Text>
			{levels.map((level, index) => (
				<Text
					key={index}
					size="medium"
					weight={levels.length - 1 == index ? "bold" : "regular"}
					style={{
						marginRight: "8px",
						userSelect: "none",
						cursor: level.link ? "pointer" : "default",
					}}
					onClick={() => {
						if(level.link) {
							navigate(level.link, {});
						}
					}}
				>
					{"/ " + t(level.text)}
				</Text>
			))}
		</Wrapper>
	);
});
