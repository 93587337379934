import React, { Fragment } from "react";
import { Dropdown as DropdownBootstrap } from "react-bootstrap";
import styled from "styled-components";

const DropdownCustom = styled(DropdownBootstrap)`
	& > .dropdown-toggle {
		padding: 0px;
		border: none !important;
		background: transparent !important;
		box-shadow: none !important;

		&:after {
			display: none !important;
		}

		&:hover > svg,
		&:focus > svg {
			color: var(--black) !important;
		}
	}

	& > .dropdown-menu {
		padding: 0px;
		border-radius: 6px;
		min-width: 240px;
		border: 2px solid var(--grey-1);
		background-color: var(--white);
		z-index: 100000000;

		& > a {
			padding: 15px 20px 15px 20px;
			background-color: var(--white);
			border-bottom: 2px solid var(--grey-1);
			cursor: pointer;

			& * {
				cursor: pointer;
			}
		}

		& > a:first-child {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}

		& > a:last-child {
			border-bottom: none;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}
	}
`;

const DropdownItemCustom = styled(DropdownCustom.Item)`
	& {
		padding: 0px !important;
	}
	& > * {
		padding: 15px 20px 15px 20px;
	}
`;

export const Dropdown = (props) => {
	return (
		<DropdownCustom className={props.className} style={props.style}>
			<DropdownCustom.Toggle id="dropdown-basic">
				{props.toggle}
			</DropdownCustom.Toggle>
			<DropdownCustom.Menu>
				{props.menu
					? props.menu.map((item, index) => (
							<DropdownItemCustom key={index}>{item}</DropdownItemCustom>
					  ))
					: ""}
			</DropdownCustom.Menu>
		</DropdownCustom>
	);
};
