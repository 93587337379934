import React from "react";
import styled from "styled-components";
import { HeaderCell } from "./headerCell";

const Wrapper = styled.div`
  & {
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;
    align-items: normal;

    background-color: var(--grey-field);
    border-bottom: 1px solid var(--grey-1);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  & > div:nth-child(1) {
    border-top-left-radius: 6px;
  }
  & > div:nth-last-child(1) {
    border-right: 0px;
    border-top-right-radius: 6px;
  }
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const Header = (props) => {

  const HeaderCellCustom = props.headerCellComponent ? props.headerCellComponent : HeaderCell;

  const returnOrderingObj = (key) => {
    const obj = {};

    const ordering = props.ordering;

    if (ordering && ordering.key == key) {
      obj.key = key;
      if (ordering.order == "desc") {
        obj.order = "asc";
      } else {
        obj.order = "desc";
      }
    } else {
      obj.key = key;
      obj.order = "desc";
    }

    return obj;
  };

  return <Wrapper
          className={props.className}
          style={props.style}
          >
            {
              props.elements ? 
                props.elements.map((elem, index) => (
                  <HeaderCellCustom
                    key={index}
                    text={elem.text}
                    textType={"label"}
                    isOrderable={elem.isOrderable}
                    isOrdered={
                      props.ordering && props.ordering[elem.key] ? props.ordering[elem.key] : null
                    }
                    style={{
                      width: elem.width ? elem.width : "",
                    }}
                    orderingListener={(key) => {
                      if (props.orderingListener) {
                        const obj = returnOrderingObj(key);
                        props.orderingListener(obj);
                      }
                    }}
                  />
                ))
              :
              ""
            }
          </Wrapper>;
};
