import {
	FormatListBulleted as FormatListBulletedIcon,
	NoteAdd as NoteAddIcon,
} from "@material-ui/icons";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../../hooks";
import { USER_ROLES } from "../../../utils/constants";
import {
	Dropdown,
	NewOrderModal,
	OperatorModal,
	Spacer,
	Text,
} from "../../index";
import { TextCell } from "../cells/textCell";

const Wrapper = styled.div`
	& {
		width: 100%;
		max-width: 100%;
		min-width: 1318px;

		display: flex;
		flex-direction: column;
		align-items: center;

		background-color: var(--white);
		border-bottom: 1px solid var(--grey-1);
	}

	& > .cell-padding {
		padding-left: 8px;
		padding-right: 8px;
	}
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowListini = (props) => {
	
	const navigate = useNavigate();
	const { width } = useWindowSize();

	const data = props.data;

	function generateRuleText(listino, rule){

		let string = "";

		if(rule == 'std'){
			return `STD: larghezza > ${listino.rules.std} cm + 20%`;
		}else if(rule == 'corian'){
			return `CORIAN: larg > ${listino.rules.corian} cm o lung > ${listino.rules.corian} cm + 20%`;
		}else if(rule == 'mitex'){
			return `MTEX: larg > ${listino.rules.mitex} cm o lung > ${listino.rules.mitex} cm + 20%`;
		}else if(rule == 'arredanet'){
			return `ARREDANET: lung/larg < ${listino.rules.arredanet.lunghezza_1} * 65% o lung/larg < ${listino.rules.arredanet.lunghezza_2} * 90%`;
		}

		return "";
	}

	return (
		<Wrapper
			className={props.className}
			key={props.keyCell}
			keyCell={props.keyCell}
			style={props.style}
		>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					background: data.contenzioso === true ? "rgba(210, 42, 22, .1)" : "rgba(255, 255, 255, 1)"
					
				}}
			>
				<TextCell
					className="cell-padding"
					primaryText={data.nome}
					primaryTextWeight="bold"
					style={{
						width: "20%"
					}}
				/>

				<TextCell
					className="cell-padding"
					primaryText={data.anno}
					style={{
						width: "5%"
					}}
				/>

				<TextCell
					className="cell-padding"
					primaryText={data.file_id}
					onClick={() => {
						window.open("https://docs.google.com/spreadsheets/d/" + data.file_id, "_blank");
					}}
					style={{
						width: "35%",
						cursor: "pointer"
					}}
				/>

				<TextCell
					className="cell-padding"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>

				{/*
				<TextCell
					className="cell-padding"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>
				<TextCell
					className="cell-paddin g"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>
				<TextCell
					className="cell-padding"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>
				<TextCell
					className="cell-padding"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>
				<TextCell
					className="cell-padding"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>
				<TextCell
					className="cell-padding"
					primaryText={data.is_default ? "SI" : ""}
					style={{
						width: "5%"
					}}
				/>
				*/}

				<div style={{
					width: "35%"
				}}>
					<TextCell
						className="cell-padding"
						primaryText={generateRuleText(data, "std")}
					/>
					<TextCell
						className="cell-padding"
						primaryText={generateRuleText(data, "corian")}
					/>
					<TextCell
						className="cell-padding"
						primaryText={generateRuleText(data, "mitex")}
					/>
					<TextCell
						className="cell-padding"
						primaryText={generateRuleText(data, "arredanet")}
					/>
				</div>

				
			</div>
		</Wrapper>
	);
};
