import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "./menu";
import { Text, Dropdown } from "../index";
import { setUser, setToken, setToast } from "../../redux";

import { USER_ROLES } from "../../utils/constants";

import {
	Menu as MenuIcon,
	ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import LogoutIcon from "@mui/icons-material/Logout";
import { LogoHeader2X } from "../../assets/images/png/index";
import { ToastContainer, Toast } from "react-bootstrap";
import { saveToken } from "../../utils";
import { withTranslation } from "react-i18next";

const Container = styled.div`
	width: 100%;
	max-width: 100%;
	height: 70px;
	min-height: 70px;
	position: sticky;
	top: 0;
	z-index: 1000;

	background: var(--white);

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	padding-left: 40px;
	padding-right: 40px;

	border-bottom: 1px solid var(--grey-1);
`;

const MenuToggle = styled.div`
	width: 32px;
	height: 32px;
	cursor: pointer;
	margin-right: 36px;
`;

export const Navbar = withTranslation()((props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const token = useSelector((state) => state.token || "");
	const user = useSelector((state) => state.user || {});
	const toast = useSelector((state) => state.toast || {});
	const [showMenu, setShowMenu] = useState(false);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	return (
		<>
			<Menu
				showMenu={showMenu}
				user={user}
				onMenuClose={() => {
					setShowMenu(false);
				}}
			/>

			<Routes>
				<Route
					path="/*"
					element={
						<Container
							className={props.className}
							showMenu={props.showMenu}
							isMenuOpen={props.isMenuOpen}
						>
							<ToastContainer position="top-end" className="p-3">
								<Toast
									onClose={() =>
										dispatch(
											setToast({
												show: false,
												title: "",
												description: "",
												variant: toast.variant,
											})
										)
									}
									show={toast.show || false}
									delay={toast.delay || 5000}
									autohide={toast.autohide || true}
									bg={toast.variant || "primary"}
								>
									<Toast.Header>
										<strong className="me-auto">{t(toast.title) || ""}</strong>
									</Toast.Header>
									<Toast.Body className="text-white">
										{t(toast.description) || ""}
									</Toast.Body>
								</Toast>
							</ToastContainer>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								{
									user && user._id ? (
										<MenuToggle onClick={toggleMenu}>
											<MenuIcon
												style={{
													color: "var(--darkgrey)",
													width: "32px",
													height: "32px",
												}}
											/>
										</MenuToggle>
									) : (
										""
									)
								}

								<img
									src={LogoHeader2X}
									style={{
										width: "120px",
										height: "55px",
									}}
								/>
							</div>
							<div>
								{
									user && user._id ? (
										<Dropdown
											toggle={
												<div
													style={{
														display: "flex",
														alignItems: "center",
													}}
												>
													<Text
														size="small"
														weight="medium"
														style={{
															marginRight: "20px",
														}}
													>
														{user.cd_privilege == USER_ROLES.ADMIN
															? user.nomecompleto
															: user.cd_privilege == USER_ROLES.OPERATOR
															? `${user.nome_operatore} ${user.cognome_operatore}`
															: user.cd_privilege == USER_ROLES.CLIENT
															? user.logged.fullname
															: ""}
													</Text>
													<ArrowDropDownIcon
														style={{
															color: "var(--grey-2)",
														}}
													/>
												</div>
											}
											menu={[
												<div
													style={{
														width: "100%",
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}
												>
													<Text
														size="medium"
														weight="medium"
														color="var(--black)"
													>
														{user.cd_privilege == USER_ROLES.ADMIN
															? user.nomecompleto
															: user.cd_privilege == USER_ROLES.OPERATOR
															? `${user.nomecompleto}`
															: user.cd_privilege == USER_ROLES.CLIENT
															? user.logged.fullname
															: ""}
													</Text>
													<Text size="medium" weight="medium">
														{user.cd_privilege == USER_ROLES.ADMIN
															? user.email
															: user.cd_privilege == USER_ROLES.OPERATOR
															? user.email_operatore
															: (user.logged || {}).email}
													</Text>
												</div>,
												<div
													style={{
														width: "100%",
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
													onClick={() => {
														dispatch(setToken(""));
														dispatch(setUser(null));
														saveToken("");
													}}
												>
													<LogoutIcon
														style={{
															color: "var(--red)",
															marginRight: "16px",
														}}
													/>
													<Text size="small" weight="medium" color="var(--red)">
														{"navbar.logout"}
													</Text>
												</div>,
											]}
										/>
									) : (
										""
									)
								}
							</div>
						</Container>
					}
				/>
			</Routes>
		</>
	);
});

Navbar.propTypes = {
	showMenu: PropTypes.bool,
	isMenuOpen: PropTypes.bool,
};

Navbar.defaultProps = {
	showMenu: false,
	isMenuOpen: false,
};
