import {
	GET_LISTINI,
    LISTINO_DEFAULT
} from "./constants";
import { get, post } from "./service";

export const getListini = async () => {
	return get(GET_LISTINI());
};

export const setDefaultListino = async (id) => {
    return post(LISTINO_DEFAULT(id), {});
  };