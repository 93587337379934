import React from "react";
import styled from "styled-components";
import { HeaderCell } from "./headerCell";

const HeaderCellCustom = styled(HeaderCell)`
  border-right: 1px solid var(--grey-1);
  padding-left: 16px;
  padding-right: 16px;
`;

export const HeaderCellCommesse = (props) => {

  return (
    <HeaderCellCustom
      {...props}
      style={{
        ...props.style
      }}
    />
  );
};
