import {
	Add as AddIcon,
	ArrowForward as ArrowForwardIcon,
	AutorenewOutlined as AutorenewIcon,
	PrintOutlined as PrintIcon,
	Save as SaveIcon
} from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
//import { Link, useLocation } from "react-router-dom";
import {
	BlockCommesse,
	Breadcrumb,
	Button,
	Checkbox,
	DatePickerComponent,
	Dropdown,
	Loader,
	NewActivityModal,
	NewOrderModal,
	Row as RowWrapper,
	SelectField,
	Text,
	TextFieldWithIcon,
	ImportListinoModal,
	InfiniteScroll
} from "../../components";
import { COMMESSE, SPEDIZIONI } from "../../navigation/constants";
import {
	getAllAziende,
	getAllMateriali,
	getCommesseFiltered,
	searchMaterialiByAziendaId,
	updateCommesseList
} from "../../services";
import {
	COMMESSA_STATUS,
	COMMESSA_TYPES,
	PRIORITA as PRIORITA_LIST,
	USER_ROLES
} from "../../utils/constants";
import {
	getOptionsForSelectAcc,
	getOptionsForSelectAlz,
	getOptionsForSelectCnc,
	getOptionsForSelectCoste,
	getOptionsForSelectFin,
	getOptionsForSelectImb,
	getOptionsForSelectInc,
	getOptionsForSelectMat,
	getOptionsForSelectTg,
	getOptionsForSelectWj,
} from "../../utils/index";
import { useSelector, useDispatch } from "react-redux";
import { PDF_COMMESSE_LIST } from "../../services/constants";
import { useDebounce } from "../../hooks";
import { setToast, setCommesseFilters } from "../../redux";
import { withTranslation } from "react-i18next";
import { Fragment } from "react";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;

const FloatingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: var(--red);
	position: fixed;
	right: 60px;
	bottom: 60px;
	z-index: 10000;
	cursor: pointer;
	& > svg {
		color: var(--white);
	}
`;

const ELEMENTS_COUNT = 100000000;

export const Commesse = withTranslation()((props) => {
	const { t } = props;
	const EMPTY_FILTERS = {
		cd_number_type: COMMESSA_TYPES.COMMESSA,
		search: "",
		statusList: [],
		is_anticipo: false,
		is_gold: false,
		is_attivita: false,
		priority: "-",
		azienda: "-",
		materiale: "-",
		dt_inizio_lavorazione: "",
		dt_fine_lavorazione: "",
		lavorazioni: {
			mat: "-",
			tgm: "-",
			tga: "-",
			coste: "-",
			wjm: "-",
			wja: "-",
			cnc: "-",
			alz: "-",
			acc: "-",
			inc: "-",
			fin: "-",
			imb: "-",
		},
	};

	const [pageNumber, setPageNumber] = useState(0);
	const ELEMENTS_COUNT = 15;
	const [updateList, setUpdateList] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const debounce = useDebounce();

	const dispatch = useDispatch();
	const [commesseList, setCommesseList] = useState([]);
	const [commesseFilteredList, setCommesseFilteredList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [reloadData, setReloadData] = useState(false);
	const [showCommessaModal, setShowCommessaModel] = useState(false);
	const [showAttivitaModal, setShowAttivitaModel] = useState(false);
	const [showImportListinoModal, setShowImportListinoModal] = useState(false);
	const [aziendaList, setAziendeList] = useState([]);
	const [materialiList, setMaterialiList] = useState([]);
	const readOnlyCommesseFilters = useSelector((state) => state.commesseFilters || {});
	const filters = useMemo(() => {
		return JSON.parse(JSON.stringify(readOnlyCommesseFilters));
	}, [readOnlyCommesseFilters]);
	const user = useSelector((state) => state.user || {});

	const navigate = useNavigate();

	useEffect(() => {
		executeGetAziende();
	}, []);

	useEffect(() => {
		if(reloadData) {
			executeGetCommesse();
			executeGetAziende();
			setReloadData(false);
		}
	}, [reloadData]);


	useEffect(() => {
		executeGetMateriali();
		filters.materiale = "-";
		dispatch(setCommesseFilters({ ...filters }));
	}, [filters.azienda]);

	useEffect(() => {
		setPageNumber(0);
		executeGetCommesse(0);
	}, [filters]);

	useEffect(() => {
		setCommesseFilteredList(getCommesseDayList());
	}, [commesseList]);

	useEffect(() => {
		if	(updateList && hasMore) {
			executeGetCommesse(pageNumber+1);
			setPageNumber(pageNumber+1);
		}
	}, [updateList]);

	const executeGetAziende = () => {
		//setIsLoading(true);

		getAllAziende().then(({ result, error }) => {
			if (error) {
			} else {
				setAziendeList(result);
			}

			//setIsLoading(false);
		});
	};

	const executeGetMateriali = () => {
		if (filters.azienda != "-") {
			searchMaterialiByAziendaId(filters.azienda, "").then(
				({ result, error }) => {
					if (error) {
					} else {
						setMaterialiList(result);
					}
				}
			);
		} else {
			getAllMateriali().then(({ result, error }) => {
				if (error) {
				} else {
					setMaterialiList(result);
				}
			});
		}
	};

	const executeGetCommesse = (page_number = 0, elements_count = ELEMENTS_COUNT) => {
		debounce(() => {
			setIsLoading(true);

			getCommesseFiltered(filters, page_number, elements_count).then(({ result, error }) => {
				if (error) {
				} else {
					setHasMore(result.length == ELEMENTS_COUNT);

					if(page_number > 0) {
						setCommesseList([...commesseList, ...result]);
					} else {
						setCommesseList([...result]);
					}
				}
				setUpdateList(false);
				setIsLoading(false);
			});
		});
		
	};

	const getCommesseDayList = () => {
		let list = [];
		
		commesseList.forEach((commessa, index) => {
			const commesseDay = list.find(
				(item) =>
					moment(item.dt_lavorazione).format("DD/MM/YYYY") ==
					moment(
						commessa.cd_type == COMMESSA_TYPES.ATTIVITA
							? commessa.dt_consegna
							: commessa.dt_lavorazione
					).format("DD/MM/YYYY")
			);

			if (
				commesseDay &&
				commessa.dt_consegna != ""
			) {
				commesseDay.list.push(commessa);
			} else if (commessa.dt_consegna != "") {
				list.push({
					dt_lavorazione:
						commessa.cd_type == COMMESSA_TYPES.ATTIVITA
							? commessa.dt_consegna
							: commessa.dt_lavorazione,
					list: [commessa],
				});
			}
		});

		return list.sort((item1, item2) => new Date(item1.dt_lavorazione) - new Date(item2.dt_lavorazione));
	};


	const saveCommessa = () => {
		let listToUpdate = [];
		commesseList.forEach((item) => {
			if (item.modified) {
				listToUpdate.push({
					_id: item._id,
					commento: item.commento,
					punteggi_lavorazioni: item.punteggi_lavorazioni,
					qa: item.qa,
					cd_status: item.cd_status,
					status_history: item.status_history
				});
			}
		});
		setIsSaving(true);
		updateCommesseList({list: listToUpdate}).then(({ result, error }) => {
			setIsSaving(false);
			if (result.success) {
				if(result.errors && result.errors.length == 0) {

					dispatch(
						setToast({
							show: true,
							title: "commesse.toastUpdate.success.title",
							description: "commesse.toastUpdate.success.description",
							variant: "success",
						})
					);

				} else {

					dispatch(
						setToast({
							show: true,
							title: "commesse.toastUpdate.error2.title",
							description: t("commesse.toastUpdate.error2.description", {errors: result.errors.join(", N.comm ")}),
							variant: "danger",
							delay: 7000
						})
					);

				}

				console.log(result);
			} else if((result && !result.success) || error) {

				dispatch(
					setToast({
						show: true,
						title: "commesse.toastUpdate.error.title",
						description: "commesse.toastUpdate.error.description",
						variant: "danger",
						delay: 7000
					})
				);

			}
		});
	};

	return (
		<Wrapper>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<FloatingButton
					style={{display:'none'}}
					onClick={!isSaving ? saveCommessa : null}
				>
					{
						!isSaving ?
							<SaveIcon/>
						:
							<Fragment>
								<Loader
									layer={1}
									size={"sm"}
									color="var(--white)"
								/>
							</Fragment>
					}

				</FloatingButton>
				<Breadcrumb
					levels={[
						{
							text: "commesse.breadcrumbTitle",
							link: COMMESSE,
						},
					]}
				/>
				{user.cd_privilege == USER_ROLES.ADMIN ? (
					<div
						style={{
							display: "flex"
						}}
					>
						
						<Button
							variant="secondary"
							onClick={() => {
								navigate(SPEDIZIONI);
							}}
						>
							<Text size="small" weight="medium">
								{"commesse.goToSpedizioni"}
							</Text>
							<ArrowForwardIcon
								style={{
									width: "20px",
									height: "20px",
									marginLeft: "8px",
								}}
							/>
						</Button>
					</div>
				) : (
					<div></div>
				)}
			</div>

			<div
				className="d-flex align-items-center flex-wrap space-between"
				style={{
					width: "100%",
					marginTop: "16px",
				}}
			>
				<RowWrapper
					className="d-flex flex-wrap space-children-24"
					breakpoints={[
						{
							breakpoint: 10000,
							defaultWidth: 50,
							widthOverride: {
								".filterContainer": 70,
								".searchContainer": 30,
							},
						},
						{
							breakpoint: 1415,
							defaultWidth: 100,
							columnReverseDirection: true,
						},
					]}
					style={{
						alignItems: "normal"
					}}
				>
					<div
						className="filterContainer"
						style={{
							display: "flex",
							flexDirection: "column",
							marginTop: "32px",
						}}
					>
						<RowWrapper
							className="d-flex align-items-center flex-wrap space-children-24"
							breakpoints={[
								{
									breakpoint: 1025,
									defaultWidth: 20,
								},
								{
									breakpoint: 860,
									defaultWidth: 25,
								},
								{
									breakpoint: 670,
									defaultWidth: 50,
								},
							]}
						>
							<Checkbox
								id="chk_prenotazione"
								label={"commesse.prenotazioneLabel"}
								color="var(--purple)"
								checked={filters.statusList.includes(COMMESSA_STATUS.PRENOTAZIONE)}
								onChange={(e) => {
									const value = e.target.checked;
									if (value) {
										filters.statusList.push(COMMESSA_STATUS.PRENOTAZIONE);
									} else {
										filters.statusList = filters.statusList.filter(
											(item) => item != COMMESSA_STATUS.PRENOTAZIONE
										);
									}
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>

							<Checkbox
								id="chk_preordine"
								label={"commesse.preordineLabel"}
								color="var(--blue)"
								checked={filters.statusList.includes(COMMESSA_STATUS.PREORDINE)}
								onChange={(e) => {
									const value = e.target.checked;
									if (value) {
										filters.statusList.push(COMMESSA_STATUS.PREORDINE);
									} else {
										filters.statusList = filters.statusList.filter(
											(item) => item != COMMESSA_STATUS.PREORDINE
										);
									}
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>

							<Checkbox
								id="chk_attesa"
								label={"commesse.waitingLabel"}
								color="var(--red)"
								checked={filters.statusList.includes(COMMESSA_STATUS.IN_ATTESA)}
								onChange={(e) => {
									const value = e.target.checked;
									if (value) {
										filters.statusList.push(COMMESSA_STATUS.IN_ATTESA);
									} else {
										filters.statusList = filters.statusList.filter(
											(item) => item != COMMESSA_STATUS.IN_ATTESA
										);
									}
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>

							<Checkbox
								id="chk_ordine"
								label={"commesse.ordineLabel"}
								checked={filters.statusList.includes(COMMESSA_STATUS.CONFERMATO)}
								onChange={(e) => {
									const value = e.target.checked;
									if (value) {
										filters.statusList.push(COMMESSA_STATUS.CONFERMATO);
									} else {
										filters.statusList = filters.statusList.filter(
											(item) => item != COMMESSA_STATUS.CONFERMATO
										);
									}
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>

							<Checkbox
								id="chk_finito"
								label={"commesse.finitoLabel"}
								color="var(--darkgrey)"
								checked={filters.statusList.includes(COMMESSA_STATUS.FINITO)}
								onChange={(e) => {
									const value = e.target.checked;
									if (value) {
										filters.statusList.push(COMMESSA_STATUS.FINITO);
									} else {
										filters.statusList = filters.statusList.filter(
											(item) => item != COMMESSA_STATUS.FINITO
										);
									}
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>

							<Checkbox
								id="chk_gold"
								label={"commesse.goldLabel"}
								color="var(--yellow)"
								checked={filters.statusList.is_gold}
								onChange={(e) => {
									const value = e.target.checked;
									filters.is_gold = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>

							<Checkbox
								id="chk_attivita"
								label={"commesse.attivitaLabel"}
								checked={filters.statusList.is_attivita}
								onChange={(e) => {
									const value = e.target.checked;
									filters.is_attivita = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>
						</RowWrapper>
						<RowWrapper
							className="d-flex align-items-center flex-wrap space-children-12 margin-top-children-16"
							breakpoints={[
								{
									breakpoint: 1400,
									defaultWidth: 100 / 3,
									marginLateral: 12,
									widthOverride: {
										".date-start": 50,
										".date-end": 50,
									},
								},
								{
									breakpoint: 670,
									marginLateral: 12,
									defaultWidth: 50,
								},
							]}
						>
							<div>
								<SelectField
									id="select_priority"
									lightbackground
									value={filters.priority}
									options={PRIORITA_LIST}
									onChange={(id, value) => {
										filters.priority = value;
										dispatch(setCommesseFilters({ ...filters }));
									}}
									style={{
										minWidth: "150px"
									}}
								/>
							</div>
							<div>
								<SelectField
									id="select_azienda"
									lightbackground
									value={filters.azienda}
									options={[{ value: "-", text: "commesse.allCompanies" }].concat(
										aziendaList.map((item) => ({
											value: item._id,
											text: item.nome,
										}))
									)}
									onChange={(id, value) => {
										filters.azienda = value;
										dispatch(setCommesseFilters({ ...filters }));
									}}
									style={{
										minWidth: "150px"
									}}
								/>
							</div>
							<div>
								<SelectField
									id="select_materiale"
									lightbackground
									value={filters.materiale}
									options={[{ value: "-", text: "commesse.allMaterials" }].concat(
										materialiList.map((item) => ({
											value: item.codice,
											text: item.nome + " " + item.spessore,
										}))
									)}
									onChange={(id, value) => {
										filters.materiale = value;
										dispatch(setCommesseFilters({ ...filters }));
									}}
									style={{
										minWidth: "150px"
									}}
								/>
							</div>
						</RowWrapper>
						<RowWrapper
							className="d-flex align-items-center flex-wrap space-children-12 margin-top-children-16"
							breakpoints={[
								{
									breakpoint: 1400,
									defaultWidth: 100 / 3,
									marginLateral: 12,
									widthOverride: {
										".date-start": 50,
										".date-end": 50,
									},
								},
								{
									breakpoint: 670,
									marginLateral: 12,
									defaultWidth: 50,
								},
							]}
						>
							<DatePickerComponent
								className="date-start"
								placeholderText="commesse.from"
								selectedDate={filters.dt_inizio_lavorazione}
								removeMaxDate={filters.dt_fine_lavorazione == ""}
								maxDate={filters.dt_fine_lavorazione}
								backgroundColor="var(--white)"
								removeMinDate
								onChange={(id, value) => {
									filters.dt_inizio_lavorazione = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>
							<DatePickerComponent
								className="date-end"
								placeholderText="commesse.at"
								backgroundColor="var(--white)"
								selectedDate={filters.dt_fine_lavorazione}
								removeMinDate={filters.dt_inizio_lavorazione == ""}
								minDate={filters.dt_inizio_lavorazione}
								onChange={(id, value) => {
									filters.dt_fine_lavorazione = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
							/>
						</RowWrapper>
						<RowWrapper
							className="space-children-12"
							breakpoints={[
								{
									breakpoint: 10000,
									defaultWidth: 100 / 6,
								},
								{
									breakpoint: 1400,
									marginLateral: 12,
									defaultWidth: 25,
								},
								{
									breakpoint: 670,
									marginLateral: 12,
									defaultWidth: 50,
								},
							]}
							style={{
								marginTop: "16px",
							}}
						>
							<SelectField
								id="select_lavorazioni_mat"
								lightbackground
								value={filters.lavorazioni.mat}
								options={getOptionsForSelectMat()}
								onChange={(id, value) => {
									filters.lavorazioni.mat = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_tg"
								lightbackground
								value={
									filters.lavorazioni.tga != "-"
										? `tga_${filters.lavorazioni.tga}`
										: `tgm_${filters.lavorazioni.tgm}`
								}
								options={getOptionsForSelectTg()}
								onChange={(id, value) => {
									if (value.lastIndexOf("tgm_") > -1) {
										filters.lavorazioni.tgm = value.replace("tgm_", "");
										filters.lavorazioni.tga = "-";
									} else if (value.lastIndexOf("tga_") > -1) {
										filters.lavorazioni.tgm = "-";
										filters.lavorazioni.tga = value.replace("tga_", "");
									} else {
										filters.lavorazioni.tgm = "-";
										filters.lavorazioni.tga = "-";
									}

									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_coste"
								lightbackground
								value={filters.lavorazioni.coste}
								options={getOptionsForSelectCoste()}
								onChange={(id, value) => {
									filters.lavorazioni.coste = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_wj"
								lightbackground
								value={
									filters.lavorazioni.wja != "-"
										? `wja_${filters.lavorazioni.wja}`
										: `wjm_${filters.lavorazioni.wjm}`
								}
								options={getOptionsForSelectWj()}
								onChange={(id, value) => {
									if (value.lastIndexOf("wjm_") > -1) {
										filters.lavorazioni.wjm = value.replace("wjm_", "");
										filters.lavorazioni.wja = "-";
									} else if (value.lastIndexOf("wja_") > -1) {
										filters.lavorazioni.wjm = "-";
										filters.lavorazioni.wja = value.replace("wja_", "");
									} else {
										filters.lavorazioni.wjm = "-";
										filters.lavorazioni.wja = "-";
									}

									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_cnc"
								lightbackground
								value={filters.lavorazioni.cnc}
								options={getOptionsForSelectCnc()}
								onChange={(id, value) => {
									filters.lavorazioni.cnc = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_alz"
								lightbackground
								value={filters.lavorazioni.alz}
								options={getOptionsForSelectAlz()}
								onChange={(id, value) => {
									filters.lavorazioni.alz = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_acc"
								lightbackground
								value={filters.lavorazioni.acc}
								options={getOptionsForSelectAcc()}
								onChange={(id, value) => {
									filters.lavorazioni.acc = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_inc"
								lightbackground
								value={filters.lavorazioni.inc}
								options={getOptionsForSelectInc()}
								onChange={(id, value) => {
									filters.lavorazioni.inc = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>

							<SelectField
								id="select_lavorazioni_fin"
								lightbackground
								value={filters.lavorazioni.fin}
								options={getOptionsForSelectFin()}
								onChange={(id, value) => {
									filters.lavorazioni.fin = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
								}}
							/>
							<SelectField
								id="select_lavorazioni_imb"
								lightbackground
								value={filters.lavorazioni.imb}
								options={getOptionsForSelectImb()}
								onChange={(id, value) => {
									filters.lavorazioni.imb = value;
									dispatch(setCommesseFilters({ ...filters }));
								}}
								style={{
									maxWidth: "150px",
									marginRight: "32px",
								}}
							/>
						</RowWrapper>
					</div>
					<div
						className="searchContainer"
						style={{
							display: "flex",
							flexDirection: "column",
							marginTop: "32px",
						}}
					>
						<RowWrapper
							className="d-flex flex-wrap space-children-24"
							breakpoints={[
								{
									breakpoint: 10000,
									defaultWidth: 100,
								},
								{
									breakpoint: 1415,
									defaultWidth: 50,
									rowReverseDirection: true,
								},
								{
									breakpoint: 920,
									defaultWidth: 100,
								},
							]}
						>
							<div
								className="d-flex align-items-center"
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<TextFieldWithIcon
									id="search"
									type="text"
									lightbackground
									placeholder={"commesse.searchCommessa"}
									value={filters.search}
									onChange={(id, value) => {
										filters.search = value.toLowerCase();
										dispatch(setCommesseFilters({ ...filters }));
									}}
									style={{
										maxWidth: "500px",
										marginRight: user.cd_privilege == USER_ROLES.ADMIN ? "16px" : "0px",
										width: "100%"
									}}
								/>

								{user.cd_privilege == USER_ROLES.ADMIN ? (
									<Dropdown
										toggle={
											<Button variant="primary">
												<AddIcon
													style={{
														marginRight: "8px",
													}}
												/>
												<Text size="small" weight="medium">
													{"commesse.new"}
												</Text>
											</Button>
										}
										menu={[
											<div
												style={{
													width: "100%",
													display: "flex",
													alignItems: "center",
												}}
												onClick={() => {
													setShowCommessaModel(true);
												}}
											>
												<AddIcon
													style={{
														color: "var(--darkgrey)",
														marginRight: "16px",
													}}
												/>
												<Text size="large" weight="medium">
													{"commesse.newCommessa"}
												</Text>
											</div>,
											<div
												style={{
													width: "100%",
													display: "flex",
													alignItems: "center",
												}}
												onClick={() => {
													setShowAttivitaModel(true);
												}}
											>
												<AddIcon
													style={{
														color: "var(--darkgrey)",
														marginRight: "16px",
													}}
												/>
												<Text size="large" weight="medium">
													{"commesse.newAttivita"}
												</Text>
											</div>,
										]}
									/>
								) : (
									<div></div>
								)
							}
							</div>
							<div
								className="d-flex align-items-center"
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<Button
									variant="outline-secondary"
									onClick={() => {
										dispatch(setCommesseFilters({ ...EMPTY_FILTERS }));
									}}
									style={{
										marginRight: "16px",
									}}
								>
									<AutorenewIcon
										style={{
											marginRight: "8px",
										}}
									/>
									<Text size="small" weight="medium">
										{"commesse.clearFilters"}
									</Text>
								</Button>

								<Button
									variant="outline-primary"
									href={`${process.env.REACT_APP_WS_LINK}${PDF_COMMESSE_LIST("singola")}`}
									style={{
										marginRight: "16px",
									}}
								>
									<PrintIcon
										style={{
											marginRight: "8px",
										}}
									/>
									<Text size="small" weight="medium">
										{"commesse.single"}
									</Text>
								</Button>

								<Button
									variant="outline-primary"
									href={`${process.env.REACT_APP_WS_LINK}${PDF_COMMESSE_LIST("unita")}`}
								>
									<PrintIcon
										style={{
											marginRight: "8px",
										}}
									/>
									<Text size="small" weight="medium">
										{"commesse.united"}
									</Text>
								</Button>
							</div>
						</RowWrapper>
					</div>
				</RowWrapper>
			</div>
			<InfiniteScroll
				isLoading={isLoading}
				hasMore={hasMore}
				loaderContent={
					(
						<div
							style={
								{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									marginTop: "56px"
								}
							}
						>
							<Loader
								layer={3}
								color="var(--red)"
							/>
						</div>
					)
				}
				onUpdate={() => {
					setUpdateList(true);
				}}
				content={
					commesseFilteredList.map((item, index) => (
						<BlockCommesse
							data={item}
							key={item.dt_lavorazione}
							aziende={aziendaList}
							onChange={(newCommessa) => {
								const commessaIndex = commesseList.findIndex(
									(commessa) => commessa._id == newCommessa._id
								);
								commesseList[commessaIndex] = newCommessa;
								setCommesseList(commesseList);
							}}
							onDelete={(oldCommessa) => {
								setCommesseList([...commesseList.filter((item) => item._id != oldCommessa._id)]);
							}}
							style={{
								marginTop: index == 0 ? "52px" : "36px",
							}}
						/>
					)
			)}
			/>

			<NewOrderModal
				show={showCommessaModal}
				onHide={() => setShowCommessaModel(false)}
				onCancel={() => setShowCommessaModel(false)}
			/>
			<NewActivityModal
				show={showAttivitaModal}
				onHide={() => setShowAttivitaModel(false)}
				onCancel={() => setShowAttivitaModel(false)}
				onSuccess={(newCommessa) => {
					commesseList.push(newCommessa);
					setCommesseList([...commesseList]);
					setShowAttivitaModel(false);
				}}
			/>
			<ImportListinoModal
				show={showImportListinoModal}
				onHide={() => setShowImportListinoModal(false)}
				onCancel={() => setShowImportListinoModal(false)}
				onSuccess={() => {
					setShowImportListinoModal(false)
					setReloadData(true);
				}}
			/>
		</Wrapper>
	);
});
