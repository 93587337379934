import React, { useState } from "react";
import styled from "styled-components";
import { ImgCell } from "../cells/imgCell";
import { TextCell } from "../cells/textCell";
import * as moment from "moment";
import { NEWSDETAIL } from "../../../navigation/constants";
import { useNavigate } from "react-router-dom";
import { getDescriptionNewsWithoutHtml } from "../../../utils";
import { PlaceholderImgNews } from "../../../assets/images/svg";


const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--white);
        border-bottom: 1px solid var(--grey-1);
        padding-left: 8px;
        padding-right: 8px;
    }

    & > .cursor-pointer > * {
		cursor: pointer;
	}

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */
export const RowNews = (props) => {

    const [data, setData] = useState(props.data);
    const navigate = useNavigate();

    const onChange = (id, value, isValid) => {

    }

    console.log(data)
  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >
            <ImgCell
                src={data.image_preview && data.image_preview[0] && data.image_preview[0].file_url ? data.image_preview[0].file_url : PlaceholderImgNews}
                style={{
                    width: "5%"
                }}
            />

            <TextCell
                className="cursor-pointer"
                primaryText={data.titolo ? data.titolo : "-"}
                primaryTextSize="large"
                primaryTextWeight="bold"
                color="var(--black)"
                onClick={() => {
                    navigate(NEWSDETAIL+`?newsId=${data._id}`);
                }}
                style={{
                    width: "20%"
                }}
            />

            <TextCell
                primaryText={data.descrizione ? getDescriptionNewsWithoutHtml(data.descrizione) : "-"}
                primaryTextSize="large"
                primaryTextWeight="regular"
                color="var(--black)"
                style={{
                    width: "60%"
                }}
            />

            <TextCell
                primaryText={data.data_pubblicazione && data.data_pubblicazione ? moment(data.data_pubblicazione).format("DD/MM/YYYY, HH:mm") : ""}
                primaryTextSize="large"
                primaryTextWeight="regular"
                style={{
                    width: "15%"
                }}
            />

        </Wrapper>
  );
};
