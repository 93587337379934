import { Add as AddIcon, NoteAdd as NoteAddIcon } from "@material-ui/icons";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useWindowSize } from "../../hooks";
import {
	Button,
	SelectField,
	Spacer,
	Text,
	TextFieldWithIcon,
	Dropdown,
	OperatorModal,
} from "../index";
import { useNavigate } from "react-router-dom";
import { ANAGRAFICHEDETAIL } from "../../navigation/constants";

const Wrapper = styled.div`
	& {
		width: 100%;
		max-width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const BlockFilterAnagrafiche = (props) => {
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const [showOperatorModal, setShowOperatorModal] = useState(false);

	return (
		<Wrapper
			className={props.className}
			key={props.keyCell}
			keyCell={props.keyCell}
			style={props.style}
		>
			<OperatorModal
				show={showOperatorModal}
				onHide={() => setShowOperatorModal(false)}
				onCancel={() => setShowOperatorModal(false)}
				onSuccess={props.onCreateOperatorSuccess}
			/>
			<Row
				className="w-100 mx-0"
				style={{
					marginTop: "32px",
				}}
			>
				<Col lg={9} className="mx-0 px-0">
					<Row className="w-100 mx-0 d-flex align-items-center flex-wrap justify-content-between justify-content-lg-start">
						<Col
							lg={4}
							className={`d-flex ${width < 992 ? "px-0" : "padding-left-0"}`}
						>
							<SelectField
								id="typologyFilter"
								lightbackground
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={props.typologies || []}
								value={props.selected.typology}
								onChange={(id, value) => props.setFilter("typology", value)}
							/>
							<Spacer width="1rem" />
							<SelectField
								id="groupFilter"
								lightbackground
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={props.groups || []}
								value={props.selected.group}
								onChange={(id, value) => props.setFilter("group", value)}
							/>
						</Col>
						<Col
							lg={4}
							className={`d-flex ${width < 992 ? "px-0" : "padding-left-0"}`}
						>
							<SelectField
								id="agentFilter"
								lightbackground
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={props.agents || []}
								value={props.selected.agent}
								onChange={(id, value) => props.setFilter("agent", value)}
							/>
							<Spacer width="1rem" />
							<SelectField
								id="shopZoneFilter"
								lightbackground
								className={`${width < 992 ? "w-90" : "w-100"}`}
								options={props.shopZones || []}
								value={props.selected.shopZone}
								onChange={(id, value) => props.setFilter("shopZone", value)}
							/>
						</Col>
						<Col
							sm={6}
							lg={2}
							className={`d-flex ${width < 992 ? "px-0" : "padding-left-0"}`}
						>
							<SelectField
								id="deliveryZoneFilter"
								lightbackground
								className={`${width < 992 ? "w-100" : "w-100"}`}
								options={props.deliveryZones || []}
								value={props.selected.deliveryZone}
								onChange={(id, value) => props.setFilter("deliveryZone", value)}
							/>
						</Col>

						<Col lg={2} className="px-0 mt-2 mt-lg-0">
							<Button
								variant="link"
								style={{
									padding: "0px 8px",
								}}
								onClick={props.clearFilters}
							>
								<AutorenewOutlinedIcon
									style={{
										width: "20px",
										height: "20px",
										marginRight: "8px",
									}}
								/>
								<Text>
									{"blockFilterAnagrafiche.clearFilter"}
								</Text>
								
							</Button>
						</Col>
					</Row>
				</Col>
				<Col
					lg={3}
					className="mt-2 mt-lg-0 d-flex justify-content-lg-end align-items-center"
					style={{
						paddingLeft: "0",
						paddingRight: "0",
					}}
				>
					<TextFieldWithIcon
						id="search"
						placeholder="blockFilterAnagrafiche.search"
						value={props.selected.search}
						onChange={(id, value) => props.setFilter("search", value)}
						style={{
							width: "20vw",
							maxWidth: "500px",
							marginRight: "16px",
						}}
					/>

					<Dropdown
						toggle={
							<Button variant="primary">
								<AddIcon
									style={{
										marginRight: "8px",
									}}
								/>
								<Text size="small" weight="medium">
									{"blockFilterAnagrafiche.new"}
								</Text>
							</Button>
						}
						menu={[
							<div
								style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
								}}
								onClick={() => {
									navigate(ANAGRAFICHEDETAIL);
								}}
							>
								<NoteAddIcon
									style={{
										color: "var(--darkgrey)",
										marginRight: "16px",
									}}
								/>
								<Text size="large" weight="medium">
									{"blockFilterAnagrafiche.createUser"}
								</Text>
							</div>,
							<div
								style={{
									width: "100%",
									display: "flex",
									alignItems: "center",
								}}
								onClick={() => setShowOperatorModal(true)}
							>
								<NoteAddIcon
									style={{
										color: "var(--darkgrey)",
										marginRight: "16px",
									}}
								/>
								<Text size="large" weight="medium">
									{"blockFilterAnagrafiche.createOperator"}
								</Text>
							</div>,
						]}
					/>
				</Col>
			</Row>
		</Wrapper>
	);
};
