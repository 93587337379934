import React, { useState } from "react";
import { Add as AddIcon } from "@material-ui/icons";
import styled from "styled-components";
import { Row, Checkbox, TextField, SelectField, Button, Text, TextFieldWithIcon, Spacer } from "../index";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NEWSDETAIL } from "../../navigation/constants";
import { USER_ROLES } from "../../utils/constants";

const Wrapper = styled.div`

    & {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

`;


export const BlockFilterNews = (props) => {

    const [filterObj, setFilterObj] = useState({

    });

    const navigate = useNavigate();
    const user = useSelector((state) => state.user || {});

    const onChange = (id, value) => {
      console.log("Id -> " + id + " - Value -> " + value);
    }

    const setValue = (value) => {
      props.setFilters({ ...props.filters, search: value });
    };

  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >
              
              
              <TextFieldWithIcon
                id="search"
                className="textfield-md"
                placeholder="blockFilterNews.search"
                value={props.filters.search || ""}
                onChange={(id, value) => props.setFilter("search", value)}
                
            />
            <Spacer width="16px" />

          {
            user.cd_privilege == USER_ROLES.ADMIN ?
            (
              <Button
                variant="primary"
                onClick={() => {
                  navigate(NEWSDETAIL);
                }}
              >
                <AddIcon
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                  }}
                />
                <Text
                  size="small"
                  weight="medium"
                >
                  {"blockFilterNews.new"}
                </Text>
              </Button>
            )
            :
            ""
          }
          
            

        </Wrapper>
  );
};
