import AccessoriGreen from "./accessori_green.svg";
import AccessoriGrey from "./accessori_grey.svg";
import AccessoriRed from "./accessori_red.svg";
import AccessoriYellow from "./accessori_yellow.svg";

import AlzatineGreen from "./alzatine_green.svg";
import AlzatineGrey from "./alzatine_grey.svg";
import AlzatineYellowGreen from "./alzatine_yellow_green.svg";
import AlzatineYellow from "./alzatine_yellow.svg";

import CncGrey from "./cnc_grey.svg";
import CncRed from "./cnc_red.svg";
import CncYellow from "./cnc_yellow.svg";
import CncYellowGreen from "./cnc_yellow_green.svg";
import CncGreen from "./cnc_green.svg";

import FinitoreGreen from "./finitore_green.svg";
import FinitoreGrey from "./finitore_grey.svg";
import FinitoreYellow from "./finitore_yellow.svg";

import ImballoGreen from "./imballo_green.svg";
import ImballoGrey from "./imballo_grey.svg";
import ImballoYellow from "./imballo_yellow.svg";

import IncollaggioGreen from "./incollaggio_green.svg";
import IncollaggioGrey from "./incollaggio_grey.svg";
import IncollaggioYellow from "./incollaggio_yellow.svg";

import LavorazioneCosteGreen from "./lavorazione_coste_green.svg";
import LavorazioneCosteGrey from "./lavorazione_coste_grey.svg";
import LavorazioneCosteYellow from "./lavorazione_coste_yellow.svg";
import LavorazioneCosteYellowGreen from "./lavorazione_coste_yellow_green.svg";

import MaterialeGreen from "./materiale_green.svg";
import MaterialeGrey from "./materiale_grey.svg";
import MaterialeRed from "./materiale_red.svg";
import MaterialeYellow from "./materiale_yellow.svg";

import TaglioAutomaticoGreen from "./taglio_automatico_green.svg";
import TaglioAutomaticoGrey from "./taglio_automatico_grey.svg";
import TaglioAutomaticoRed from "./taglio_automatico_red.svg";
import TaglioAutomaticoYellowGreen from "./taglio_automatico_yellow_green.svg";
import TaglioAutomaticoYellow from "./taglio_automatico_yellow.svg";

import TaglioManualeGreen from "./taglio_manuale_green.svg";
import TaglioManualeGrey from "./taglio_manuale_grey.svg";
import TaglioManualeYellow from "./taglio_manuale_yellow.svg";

import TaglioGrey from "./taglio_grey.svg";

import WjAutomaticoGreen from "./wj_automatico_green.svg";
import WjAutomaticoGrey from "./wj_automatico_grey.svg";
import WjAutomaticoRed from "./wj_automatico_red.svg";
import WjAutomaticoYellowGreen from "./wj_automatico_yellow_green.svg";
import WjAutomaticoYellow from "./wj_automatico_yellow.svg";

import WjManualeGreen from "./wj_manuale_green.svg";
import WjManualeGrey from "./wj_manuale_grey.svg";
import WjManualeYellowGreen from "./wj_manuale_yellow_green.svg";
import WjManualeYellow from "./wj_manuale_yellow.svg";

import StatusCommessaRedYellow from "./status_commessa_red_yellow.svg";

import WjGrey from "./wj_grey.svg";

import ArrowDrowpDownCircle from "./arrow_drop_down_circle.svg";

import PlaceholderImgNews from "./placeholder_new_img_news.svg";

export { AccessoriGreen };
export { AccessoriGrey };
export { AccessoriRed };
export { AccessoriYellow };

export { AlzatineGreen };
export { AlzatineGrey };
export { AlzatineYellowGreen };
export { AlzatineYellow };

export { CncGrey };
export { CncRed };
export { CncYellow };
export { CncYellowGreen };
export { CncGreen };

export { FinitoreGreen };
export { FinitoreGrey };
export { FinitoreYellow };

export { ImballoGreen };
export { ImballoGrey };
export { ImballoYellow };

export { IncollaggioGreen };
export { IncollaggioGrey };
export { IncollaggioYellow };

export { LavorazioneCosteGreen };
export { LavorazioneCosteGrey };
export { LavorazioneCosteYellow };
export { LavorazioneCosteYellowGreen };

export { MaterialeGreen };
export { MaterialeGrey };
export { MaterialeRed };
export { MaterialeYellow };

export { TaglioAutomaticoGreen };
export { TaglioAutomaticoGrey };
export { TaglioAutomaticoRed };
export { TaglioAutomaticoYellowGreen };
export { TaglioAutomaticoYellow };

export { TaglioManualeGreen };
export { TaglioManualeGrey };
export { TaglioManualeYellow };

export { TaglioGrey };

export { WjAutomaticoGreen };
export { WjAutomaticoGrey };
export { WjAutomaticoRed };
export { WjAutomaticoYellowGreen };
export { WjAutomaticoYellow };

export { WjManualeGreen };
export { WjManualeGrey };
export { WjManualeYellowGreen };
export { WjManualeYellow };

export { WjGrey };

export {StatusCommessaRedYellow};

export { ArrowDrowpDownCircle };

export { PlaceholderImgNews };

const mapLavorazioniImg = {
	mat: {
		GREY: MaterialeGrey,
		RED: MaterialeRed,
		YELLOW: MaterialeYellow,
		GREEN: MaterialeGreen,
	},
	tgm: {
		GREY: TaglioManualeGrey,
		YELLOW: TaglioManualeYellow,
		GREEN: TaglioManualeGreen,
	},
	tga: {
		GREY: TaglioAutomaticoGrey,
		RED: TaglioAutomaticoRed,
		YELLOW: TaglioAutomaticoYellow,
		YELLOWGREEN: TaglioAutomaticoYellowGreen,
		GREEN: TaglioAutomaticoGreen,
	},
	coste: {
		GREY: LavorazioneCosteGrey,
		YELLOW: LavorazioneCosteYellow,
		YELLOWGREEN: LavorazioneCosteYellowGreen,
		GREEN: LavorazioneCosteGreen,
	},
	wjm: {
		GREY: WjManualeGrey,
		YELLOW: WjManualeYellow,
		YELLOWGREEN: WjManualeYellowGreen,
		GREEN: WjManualeGreen,
	},
	wja: {
		GREY: WjAutomaticoGrey,
		RED: WjAutomaticoRed,
		YELLOW: WjAutomaticoYellow,
		YELLOWGREEN: WjAutomaticoYellowGreen,
		GREEN: WjAutomaticoGreen,
	},
	cnc: {
		GREY: CncGrey,
		RED: CncRed,
		YELLOW: CncYellow,
		YELLOWGREEN: CncYellowGreen,
		GREEN: CncGreen,
	},
	alz: {
		GREY: AlzatineGrey,
		YELLOW: AlzatineYellow,
		YELLOWGREEN: AlzatineYellowGreen,
		GREEN: AlzatineGreen,
	},
	inc: {
		GREY: IncollaggioGrey,
		YELLOW: IncollaggioYellow,
		GREEN: IncollaggioGreen,
	},
	fin: {
		GREY: FinitoreGrey,
		YELLOW: FinitoreYellow,
		GREEN: FinitoreGreen,
	},
	imb: {
		GREY: ImballoGrey,
		YELLOW: ImballoYellow,
		GREEN: ImballoGreen,
	},
	acc: {
		GREY: AccessoriGrey,
		RED: AccessoriRed,
		YELLOW: AccessoriYellow,
		GREEN: AccessoriGreen,
	},
};

export const getImgFromLavorazioneKey = (lavorazione, status) => {
	return mapLavorazioniImg[lavorazione]
		? mapLavorazioniImg[lavorazione][status]
			? mapLavorazioniImg[lavorazione][status]
			: null
		: null;
};
