import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Add as AddIcon } from "@material-ui/icons";
import { Text, TextField, SelectField, Button, Table, RowCommesseDimensioni, HeaderCellCommesse } from "../index";
import { calcolaTotaleDimensioni, calcolaTotaleLavorazioni } from "../../utils/functionsUtils";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: column;
    }

`;


const Footer = styled.div`

    & {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        background-color: var(--grey-field);

        border-left: 1px solid var(--grey-1);
        border-right: 1px solid var(--grey-1);
        border-bottom: 1px solid var(--grey-1);

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        padding: 12px 20px 12px 20px;
        
    }

`;


const COLUMNS = [
  {
    key: "ua",
    text: "tableCommesseDimensioni.column1",
    width: "13.5%",
  },
  {
    key: "data1",
    text: "tableCommesseDimensioni.column2",
    width: "13.5%",
  },
  {
    key: "data2",
    text: "tableCommesseDimensioni.column3",
    width: "13.5%",
  },
  {
    key: "data3",
    text: "tableCommesseDimensioni.column4",
    width: "13.5%",
  },
  {
    key: "data4",
    text: "tableCommesseDimensioni.column5",
    width: "13.5%",
  },
  {
      key: "data1",
      text: "tableCommesseDimensioni.column6",
      width: "13.5%",
    },
    {
      key: "data2",
      text: "tableCommesseDimensioni.column7",
      width: "13.5%",
    },
    {
      key: "data3",
      text: "tableCommesseDimensioni.column8",
      width: "5.5%",
    }
];

const MAX_VALUE = 2000;
const MIN_VALUE = 10;


export const TableCommesseDimensioni = withTranslation()((props) => {

  const { t } = props;

    let tableData = props.tableData || [];
    let materialeData = props.materialeData || [];
    let clienteData = props.clienteData || [];
    let imballo = props.imballo || {};
    const [lunghezza, setLunghezza] = useState("");
    const [larghezza, setLarghezza] = useState("");
    const user = useSelector((state) => state.user || {});
    const imballaggio = clienteData.imballo_obbligatorio;
    let scontoImballaggio = clienteData.sconto_imballo && clienteData.sconto_imballo != "" ? clienteData.sconto_imballo : 0
    let prezzo_regola_imb = lunghezza > larghezza ? imballo * lunghezza : imballo * larghezza
    let imballaggio_price = imballo
    //clienteData.sconto_imballo ? prezzo_regola_imb - ((scontoImballaggio * prezzo_regola_imb) / 100) : 29;


    const areFieldsValid = lunghezza != "" && larghezza != "";

    const addRowItem = () => {

      let itemToAdd = {
        lunghezza,
        larghezza,
        imballaggio,
        imballaggio_price
      };

      tableData.push(itemToAdd);

      if(props.onChange) {
        props.onChange(tableData);
      }
      
      setLunghezza("");
      setLarghezza("");

    }

    const updateRowItem = (item, index) => {
      tableData[index] = item;

      if(props.onChange) {
        props.onChange(tableData);
      }

    }

    const deleteRowItem = (index) => {

      tableData = tableData.filter((item, itemIndex) => itemIndex != index);

      if(props.onChange) {
        props.onChange(tableData);
      }

    }

  return (
        <Wrapper
          className={props.className}
          key={props.keyCell}
          keyCell={props.keyCell}
          style={props.style}
        >
            
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <Text
                    size="medium"
                    weight="medium"
                    color="var(--red)"
                    style={{
                      marginRight: "12px"
                    }}
                >
                    {props.title}
                </Text>
                <Text
                    size="xsmall"
                    weight="regular"
                    color="var(--grey-2)"
                >
                    {"tableCommesseDimensioni.description"}
                </Text>
            </div>
            
            <div
              style={{
                width: "100%",
                display: "flex",
                marginTop: "24px"
              }}
            >
              <TextField
                id={"lunghezza"}
                value={lunghezza}
                type={"text"}
                label={"tableCommesseDimensioni.lunghezza"}
                placeholder={"hint_insert"}
                style={{
                  maxWidth: "150px",
                  marginRight: "20px"
                }}
                onChange={(id, value) => {
                  if(user.cd_privilege == USER_ROLES.CLIENT) {
                    if(parseFloat(value) > MAX_VALUE) {
                      value = `${MAX_VALUE}`;
                    } /*else if(parseFloat(value) < MIN_VALUE) {
                      value = `${MIN_VALUE}`;
                    }*/
                  }
                  setLunghezza(value.replace(",", "."));
                }}
                onKeyPress={(event) => {
                  if (event.charCode === 13) {
                    if(areFieldsValid) {
                      addRowItem();
                    }
                  }
                }}
              />
              <TextField
                id={"larghezza"}
                value={larghezza}
                type={"text"}
                label={"tableCommesseDimensioni.larghezza"}
                placeholder={"hint_insert"}
                style={{
                  maxWidth: "150px",
                  marginRight: "20px"
                }}
                onChange={(id, value) => {
                  if(user.cd_privilege == USER_ROLES.CLIENT) {
                    if(parseFloat(value) > MAX_VALUE) {
                      value = `${MAX_VALUE}`;
                    } /*else if(parseFloat(value) < MIN_VALUE) {
                      value = `${MIN_VALUE}`;
                    }*/
                  }
                  setLarghezza(value.replace(",", "."));
                }}
                onKeyPress={(event) => {
                  if (event.charCode === 13) {
                    if(areFieldsValid) {
                      addRowItem();
                    }
                  }
                }}
              />
              <Button
                variant="outlined-darkgrey"
                disabled={!areFieldsValid}
                style={{
                  alignSelf: "flex-end",
                  padding: "8px 16px 8px 16px"
                }}
                onClick={() => {
                  addRowItem();
                }}
              >
                <AddIcon/>
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
                overflowX: "scroll"
              }}
            >
              <Table
                className="min-width-742"
                columns={COLUMNS}
                headerCellComponent={HeaderCellCommesse}
                data={tableData}
                materialeData={materialeData}
                clienteData={clienteData}
                hasborder
                rowComponent={RowCommesseDimensioni}
                onChange={(item, index) => {
                  updateRowItem(item, index);
                }}
                onDelete={(item, index) => {
                  deleteRowItem(index);
                }}
              />
              <Footer
                className="min-width-742"
              >
                {/* <Text
                  size="xsmall"
                  weight="medium"
                  color={"var(--darkgrey)"}
                >
                  {`TOTALE COMPLESSIVO: -`}
                </Text>
                <Text
                  size="xsmall"
                  weight="medium"
                  color={"var(--darkgrey)"}
                >
                  {`TOTALE REGOLA: -`}
                </Text> */}
                <Text
                  size="xsmall"
                  weight="medium"
                  color={"var(--darkgrey)"}
                >
                  {t("tableCommesseDimensioni.tot", {tot: calcolaTotaleDimensioni(tableData, clienteData, materialeData)})}
                </Text>
              </Footer>
            </div>
            

        </Wrapper>
  );
});
