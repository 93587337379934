import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
	Breadcrumb,
	Button,
	Card,
	CardCounter,
	RowOrdini,
	RowOrdiniCliente,
	Table,
	Text,
	SelectField,
	Checkbox,
	TextField,
	Row as RowWrapper,
	DatePickerComponent,
	Loader
} from "../../components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Add as AddIcon, PrintOutlined as PrintIcon, AutorenewOutlined as AutorenewIcon } from "@material-ui/icons";
import "./Ordini.css";
import { COMMESSE, ORDINI } from "../../navigation/constants";
import {
	COMMESSA_STATUS,
	PRIORITA as PRIORITA_LIST,
	ZONE,
	COMMESSA_TYPES,
	USER_ROLES,
	STATUS
} from "../../utils/constants";
import {
	getOrdiniFiltered,
	getOrdiniCounter
} from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "../../hooks";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;

const COLUMNS = [
	{
		key: "data1",
		text: "ordini.column1",
		isOrderable: true,
		width: "3%",
	},
	{
		key: "data2",
		text: "ordini.column2",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data3",
		text: "ordini.column3",
		width: "7%",
	},
	{
		key: "data3",
		text: "ordini.column.status.client",
		width: "10%",
	},
	{
		key: "data4",
		text: "ordini.column4",
		isOrderable: true,
		width: "15%",
	},
	{
		key: "data1",
		text: "ordini.column5",
		isOrderable: true,
		width: "30%",
	},
	{
		key: "data2",
		text: "ordini.column6",
		isOrderable: true,
		width: "15%",
	},
	{
		key: "data3",
		text: "ordini.column7",
		width: "10%",
	},
	{
		key: "data3",
		text: "ordini.column9",
		width: "5%",
	},
	{
		key: "data3",
		text: "ordini.column8",
		width: "5%",
	},
	{
		key: "data3",
		text: "ordini.column10",
		width: "5%",
	},
];

const CLIENT_COLUMNS = [
	
	{
		key: "data3",
		text: "ordini.column.status.client",
		width: "15%",
	},
	{
		key: "data4",
		text: "ordini.column4",
		isOrderable: true,
		width: "20%",
	},
	{
		key: "data1",
		text: "ordini.column5.client",
		isOrderable: true,
		width: "25%",
	},
	{
		key: "data2",
		text: "ordini.column6",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data3",
		text: "ordini.column7",
		width: "10%",
	},
	{
		key: "data3",
		text: "ordini.column9",
		width: "5%",
	},
	{
		key: "data3",
		text: "ordini.column8",
		width: "5%",
	},
	{
		key: "data3",
		text: "ordini.column10",
		width: "5%",
	},
	{
		key: "data3",
		text: "PREV.",
		width: "5%"
	},
	{
		key: "data3",
		text: "CONT.",
		width: "5%"
	}
];

const DEFAULT_COUNTER = {
	prenotazioni: {
        tutti: "-",
        sospesi: "-"
      },
      preordini: {
        tutti: "-",
        sospesi: "-"
      },
      in_attesa: {
        tutti: "-",
        sospesi: "-"
      },
      ordini: {
        tutti: "-",
        sospesi: "-"
      },
      finiti: {
        tutti: "-",
        sospesi: "-"
      },
      anticipo: {
        tutti: "-",
        sospesi: "-"
      }
}


export function Ordini() {
	const EMPTY_FILTERS = {
		search: "",
		statusList: [],
		is_anticipo: false,
		is_gold: false,
		is_attivita: false,
		priority: "-",
		zonaOrdini: "-",
		dt_inizio_lavorazione: "",
		dt_fine_lavorazione: "",
		is_anticipo: ""
	};

	const [pageNumber, setPageNumber] = useState(0);
	const ELEMENTS_COUNT = 30;
	const [updateList, setUpdateList] = useState(false);
	const [hasMore, setHasMore] = useState(true);

  	const debounce = useDebounce();

	const [isLoading, setIsLoading] = useState(true);
	const [filters, setFilters] = useState(EMPTY_FILTERS);
	const [ordiniList, setOrdiniList] = useState([]);
	const [counter, setCounter] = useState(DEFAULT_COUNTER);
	const navigate = useNavigate();
	const user = useSelector((state) => state.user || {});

	useEffect(() => {
		executeGetCounter();
	}, []);

	useEffect(() => {
		setPageNumber(0);
		executeGetCommesse(0);
	}, [filters]);

	useEffect(() => {
		if	(updateList && hasMore) {
      		setPageNumber(pageNumber+1);
			executeGetCommesse(pageNumber+1);
		}
	}, [updateList]);

	const executeGetCounter = () => {

		getOrdiniCounter().then(({ result, error }) => {
			if (error) {
			} else {
				setCounter(result);
			}
		});

	}


	const executeGetCommesse = (page_number = 0, elements_count = ELEMENTS_COUNT) => {

		debounce(()  => {
			setIsLoading(true);
			
			getOrdiniFiltered(filters, page_number, elements_count).then(({ result, error }) => {
				if (error) {
				} else {
					setHasMore(result.length == ELEMENTS_COUNT);

					if(page_number > 0) {
						setOrdiniList([...ordiniList, ...result]);
					} else {
						setOrdiniList([...result]);
					}
				}
				setUpdateList(false);
				setIsLoading(false);
			});
		  });
	};

	return (
		<Wrapper>
			<Row className="d-flex align-items-center">
				<Col md={8}>
					<Breadcrumb levels={[
						{
							text: "ordini.breadcrumbTitle",
							link: ORDINI
						}
					]} />
				</Col>
				<Col md={4} className="mt-3 mt-md-0 d-flex justify-content-md-end">
					{user.cd_privilege != USER_ROLES.CLIENT ? (
						<Button variant="secondary" onClick={() => {
								navigate(COMMESSE)
							}}>
							
							<Text size="small" weight="medium">
								{"ordini.goToCommesse"}
							</Text>
							<ArrowForwardIcon
								style={{
									width: "20px",
									height: "20px",
									marginLeft: "8px",
								}}
							/>
						</Button>
						) : (
							""
						)
					}
				</Col>
			</Row>
			{user.cd_privilege == USER_ROLES.ADMIN ? (
				<Row
					style={{
						marginTop: "34px"
					}}
				>
					<Col sm={6} lg={2} className="col-1480-3">
						<CardCounter
							primaryText="ordini.prenotazioniLabel"
							primaryCounter={counter.prenotazioni.tutti || 0}
							secondaryText="ordini.sospese"
							secondaryCounter={counter.prenotazioni.sospesi || 0}
							color="var(--purple)"
						/>
					</Col>
					<Col sm={6} lg={2} className="mt-3 mt-sm-0 col-1480-3">
						<CardCounter
							primaryText="ordini.preordiniLabel"
							primaryCounter={counter.preordini.tutti || 0}
							secondaryText="ordini.sospesi"
							secondaryCounter={counter.preordini.sospesi || 0}
							color="var(--blue)"
						/>
					</Col>
					<Col sm={6} lg={2} className="mt-3 mt-lg-0 col-1480-3">
						<CardCounter
							primaryText="ordini.waitingLabel"
							primaryCounter={counter.in_attesa.tutti || 0}
							secondaryText="ordini.sospese"
							secondaryCounter={counter.in_attesa.sospesi || 0}
							color="var(--red)"
						/>
					</Col>
					<Col sm={6} lg={2} className="mt-3 mt-lg-0 col-1480-3 mt-1480-16">
						<CardCounter
							primaryText="ordini.ordiniLabel"
							primaryCounter={counter.ordini.tutti || 0}
							secondaryText="ordini.sospesi"
							secondaryCounter={counter.ordini.sospesi || 0}
						/>
					</Col>
					<Col sm={6} lg={2} className="mt-3 mt-lg-0 col-1480-3 mt-1480-16">
						<CardCounter
							primaryText="ordini.endedLabel"
							primaryCounter={counter.finiti.tutti || 0}
							secondaryText="ordini.sospesi"
							secondaryCounter={counter.finiti.sospesi || 0}
							color="var(--darkgrey)"
						/>
					</Col>
					<Col sm={6} lg={2} className="mt-3 mt-lg-0 col-1480-3 mt-1480-16">
						
					</Col>
				</Row>
			) : (
				<div></div>
			)
			}
			<RowWrapper
				className="d-flex flex-wrap space-children-24"
				breakpoints={[
					{
						breakpoint: 10000,
						defaultWidth: 50,
						widthOverride: {
							".filterContainer": 70,
							".searchContainer": 30
						}
					},
					{
						breakpoint: 1415,
						defaultWidth: 100,
						columnReverseDirection: true
					}
				]}
				style={{
					alignItems: "normal",
				}}
			>
				<div
					className="filterContainer"
					style={{
						display: "flex",
						flexDirection: "column",
						marginTop: "32px"
					}}
				>
					<RowWrapper
						className="d-flex align-items-center flex-wrap space-children-24"
						breakpoints={[
							{
								breakpoint: 1025,
								defaultWidth: 20,
							},
							{
								breakpoint: 860,
								defaultWidth: 25,
							},
							{
								breakpoint: 670,
								defaultWidth: 50,
							},
						]}
						style={{
							marginTop: "6px"
						}}
					>
						<Checkbox
							id="chk_prenotazione"
							label={"ordini.prenotazioneLabel"}
							color="var(--purple)"
							onChange={(e) => {
								const value = e.target.checked;
								if (value) {
									filters.statusList.push(COMMESSA_STATUS.PRENOTAZIONE);
								} else {
									filters.statusList = filters.statusList.filter(
										(item) => item != COMMESSA_STATUS.PRENOTAZIONE
									);
								}
								setFilters({ ...filters });
							}}
						/>

						<Checkbox
							id="chk_preordine"
							label={"ordini.preordineLabel"}
							color="var(--blue)"
							onChange={(e) => {
								const value = e.target.checked;
								if (value) {
									filters.statusList.push(COMMESSA_STATUS.PREORDINE);
								} else {
									filters.statusList = filters.statusList.filter(
										(item) => item != COMMESSA_STATUS.PREORDINE
									);
								}
								setFilters({ ...filters });
							}}
						/>

						<Checkbox
							id="chk_attesa"
							label={"ordini.waitingLabel2"}
							color="var(--red)"
							onChange={(e) => {
								const value = e.target.checked;
								if (value) {
									filters.statusList.push(COMMESSA_STATUS.IN_ATTESA);
								} else {
									filters.statusList = filters.statusList.filter(
										(item) => item != COMMESSA_STATUS.IN_ATTESA
									);
								}
								setFilters({ ...filters });
							}}
						/>

						<Checkbox
							id="chk_ordine"
							label={"ordini.ordineLabel"}
							onChange={(e) => {
								const value = e.target.checked;
								if (value) {
									filters.statusList.push(COMMESSA_STATUS.CONFERMATO);
								} else {
									filters.statusList = filters.statusList.filter(
										(item) => item != COMMESSA_STATUS.CONFERMATO
									);
								}
								setFilters({ ...filters });
							}}
						/>

						<Checkbox
							id="chk_finito"
							label={"ordini.finitoLabel"}
							color="var(--darkgrey)"
							onChange={(e) => {
								const value = e.target.checked;
								if (value) {
									filters.statusList.push(COMMESSA_STATUS.FINITO);
								} else {
									filters.statusList = filters.statusList.filter(
										(item) => item != COMMESSA_STATUS.FINITO
									);
								}
								setFilters({ ...filters });
							}}
						/>

						{user.cd_privilege != USER_ROLES.CLIENT ? (
							""
							// <Checkbox
							// 	id="chk_anticipo"
							// 	label={"ordini.anticipoLabel"}
							// 	color="var(--green)"
							// 	onChange={(e) => {
							// 		const value = e.target.checked;
							// 		filters.is_anticipo = value;
							// 		setFilters({ ...filters });
							// 	}}
							// />
							) : (
								""
							)
						}

						{user.cd_privilege != USER_ROLES.CLIENT ? (
							<Checkbox
								id="chk_gold"
								label={"ordini.goldLabel"}
								color="var(--yellow)"
								onChange={(e) => {
									const value = e.target.checked;
									filters.is_gold = value;
									setFilters({ ...filters });
								}}
							/>
							) : (
								""
							)
						}

						{user.cd_privilege != USER_ROLES.CLIENT ? (
							<Checkbox
								id="chk_attivita"
								label={"ordini.attivitaLabel"}
								onChange={(e) => {
									const value = e.target.checked;
									filters.is_attivita = value;
									setFilters({ ...filters });
								}}
							/>
							) : (
								""
							)
						}
							
							
					</RowWrapper>
					<RowWrapper
						className="d-flex align-items-center flex-wrap space-children-12 margin-top-children-16"
						breakpoints={[
							{
								breakpoint: 1400,
								defaultWidth: 100 / 4,
								marginLateral: 12,
							},
							{
								breakpoint: 770,
								marginLateral: 12,
								defaultWidth: 50
							},
						]}
					>
						{user.cd_privilege != USER_ROLES.CLIENT ? (
							<SelectField
								id="select_priority"
								className="select-priority"
								lightbackground
								value={filters.priority}
								options={PRIORITA_LIST}
								onChange={(id, value) => {
									filters.priority = value;
									setFilters({ ...filters });
								}}
								style={{
									minWidth: "150px"
								}}
							/>
							) : (
								""
							)
						}

						{user.cd_privilege != USER_ROLES.CLIENT ? (
							<SelectField
								id="select_zone"
								className="select-zone"
								lightbackground
								value={filters.zonaOrdini}
								options={ZONE}
								onChange={(id, value) => {
									filters.zonaOrdini = value;
									setFilters({ ...filters });
								}}
								style={{
									minWidth: "150px"
								}}
							/>
							) : (
								""
							)
						}

						<DatePickerComponent
							className="date-start"
							placeholderText="ordini.dateFrom"
							selectedDate={filters.dt_inizio_consegna}
							backgroundColor="var(--white)"
							removeMaxDate={filters.dt_fine_consegna == ""}
							maxDate={filters.dt_fine_consegna}
							onChange={(id, value) => {
								filters.dt_inizio_consegna = value;
								setFilters({ ...filters });
							}}
						/>
						<DatePickerComponent
							className="date-end"
							placeholderText="ordini.dateAt"
							selectedDate={filters.dt_fine_consegna}
							backgroundColor="var(--white)"
							removeMinDate={filters.dt_inizio_consegna == ""}
							minDate={filters.dt_inizio_consegna}
							onChange={(id, value) => {
								filters.dt_fine_consegna = value;
								setFilters({ ...filters });
							}}
						/>

						<div>
							<Button
								variant="outline-secondary"
								onClick={() => {
									setFilters({...EMPTY_FILTERS});
								}}
							>
								<AutorenewIcon
									style={{
										marginRight: "8px"
									}}
								/>
								<Text size="small" weight="medium">
									{"ordini.cleanFilters"}
								</Text>
							</Button>
						</div>
						
					</RowWrapper>
				</div>
				<div
					className="searchContainer"
					style={{
						display: "flex",
						flexDirection: "column",
						marginTop: "32px"
					}}
				>
					<RowWrapper
						className="d-flex flex-wrap space-children-24"
						breakpoints={[
							{
								breakpoint: 10000,
								defaultWidth: 100
							},
							{
								breakpoint: 1415,
								defaultWidth: 50,
							},
							{
								breakpoint: 920,
								defaultWidth: 100
							}
						]}
					>
						<TextField
							id="search"
							type="text"
							lightbackground
							placeholder={"ordini.searchOrders"}
							onChange={(id, value) => {
								filters.search = value.toLowerCase();
								setFilters({...filters});
							}}
							style={{
								maxWidth: "500px",
								marginRight: "16px"
							}}
						/>
					</RowWrapper>
				</div>
			</RowWrapper>
			<Card
				style={{
					marginTop: "32px",
					padding: "32px 20px 32px 20px",
				}}
			>
				<Table
					infiniteScrollOptions={{
						enabled: true,
						onUpdate: () => {
						setUpdateList(true);
						},
						hasMore,
						isLoading,
						loaderContent: (
							<div
								style={
								{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									marginTop: "56px"
								}
								}
							>
								<Loader
								layer={3}
								color="var(--red)"
								/>
							</div>
						)
					}}
					className={"scroll-1330"}
					classNameHeader={"min-width-1192"}
					columns={user.cd_privilege == USER_ROLES.CLIENT ? CLIENT_COLUMNS : COLUMNS}
					data={ordiniList}
					rowComponent={user.cd_privilege == USER_ROLES.CLIENT ? RowOrdiniCliente : RowOrdini}
				/>
			</Card>
		</Wrapper>
	);
}
