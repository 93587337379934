import { SETTINGS } from "./constants";
import { get, post, put, del } from "./service";

/**
 * Function to fetch all the users.
 */
export const getSettings = async () => {
  return get(SETTINGS());
};

export const setSettings = async (id, data) => {
  return put(SETTINGS(id), data);
};