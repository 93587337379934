// All user related database operations can be defined here.

import { LOGIN, LOGINTOKEN } from "./constants";
import { get, post } from "./service";

/**
 * Function to fetch all the users.
 */
export const authenticate = async (data) => {
  return post(LOGIN(), data);
};


export const authenticateWithToken = async (data) => {
  return get(LOGINTOKEN());
};