import { createSlice } from "@reduxjs/toolkit";
import { getToken, saveToken } from "../utils";

const initialState = getToken();

export const tokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state = action.payload;
      const newState = action.payload ? action.payload :  "";
      return action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setToken } = tokenSlice.actions;
export default tokenSlice.reducer;