import { Delete as DeleteIcon, Email as EmailIcon } from "@material-ui/icons";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Text, TextField } from "../";
import { useWindowSize } from "../../hooks";
import {
	sendClientCredentials
} from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../redux";

const Div = styled.div`
	display: flex;
	align-items: center;
`;

const DivClick = styled(Div)`
	&,
	& > * {
		cursor: pointer;
	}
`;

export const UtenzeCliente = (props) => {
	let data = props.data;
	let client = props.client
	const [rowWidth, setRowWidth] = useState();
	const [sendHover, setSendHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const toast = useSelector((state) => state.toast || {});
	const dispatch = useDispatch();


	const sendCredential = () => {
		let credentials = {
			email : data.email,
			client_id : client
		}
		
		sendClientCredentials(credentials).then(({ result, errors }) => {
			if (errors) {
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "utenzeCliente.toast.errorTitle",
						description: "utenzeCliente.toast.errorDescription",
						variant: "danger",
					})
				);
			} else if (result === undefined) {
				console.error("Qualcosa è andato storto");
				dispatch(
					setToast({
						show: true,
						title: "utenzeCliente.toast.errorTitle",
						description: "utenzeCliente.toast.errorDescription",
						variant: "danger",
					})
				);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "utenzeCliente.toast.successTitle",
						description: "utenzeCliente.toast.successDescription",
						variant: "success",
					})
				);
			}
		});
	};

	const onChangeListener = (id, value, isValid) => {
		if (props.onChange) {
			data[id] = value;
			props.onChange(data);
		}
	};

	return (
		<Row
			defaultWidth={25}
			onResize={(size) => {
				setRowWidth(size.width);
			}}
			style={props.style}
		>
			<TextField
				id="fullname"
				value={data.fullname}
				type="text"
				label={"utenzeCliente.fullname"}
				placeholder={"hint_insert"}
				onChange={onChangeListener}
			/>

			<TextField
				id="telefono"
				value={data.telefono}
				type="text"
				label={"utenzeCliente.phone"}
				placeholder={"hint_insert"}
				onChange={onChangeListener}
			/>

			<TextField
				id="email"
				value={data.email}
				required
				autoFocus
				type="text"
				label={"utenzeCliente.email"}
				placeholder={"hint_insert"}
				onChange={onChangeListener}
			/>

			<Div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignSelf: "flex-end",
					marginBottom: rowWidth > 1779 ? "" : "6px",
					userSelect: "none",
				}}
			>
				{data._id && !props.disabledSendMail ? (
					<DivClick
						onClick={(e) => {
							sendCredential();
						}}
						onMouseEnter={() => setSendHover(true)}
						onMouseLeave={() => setSendHover(false)}
					>
						<EmailIcon
							style={{
								color: sendHover ? "var(--black)" : "var(--red)",
								width: "20px",
								height: "20px",
								marginRight: "8px",
								marginBottom: "2px",
							}}
						/>
						<Text
							size="medium"
							weight="medium"
							color={sendHover ? "var(--black)" : "var(--red)"}
							style={{
								textDecoration: sendHover ? "underline" : "",
							}}
						>
							{
								rowWidth > 1100 ? "utenzeCliente.sendLong" : "utenzeCliente.sendShort"
							}
						</Text>
					</DivClick>
				) : (
					""
				)}

				<DivClick
					onClick={(e) => {
						if (props.onDelete) {
							props.onDelete(data.id);
						}
					}}
					onMouseEnter={() => setDeleteHover(true)}
					onMouseLeave={() => setDeleteHover(false)}
				>
					<DeleteIcon
						style={{
							color: deleteHover ? "var(--black)" : "var(--grey-2)",
							width: "20px",
							height: "20px",
							marginRight: "8px",
							marginBottom: "2px",
						}}
					/>
					<Text
						size="medium"
						weight="medium"
						color={deleteHover ? "var(--black)" : "var(--grey-2)"}
						style={{
							textDecoration: deleteHover ? "underline" : "",
						}}
					>
						{"utenzeCliente.delete"}
					</Text>
				</DivClick>
			</Div>
		</Row>
	);
};
