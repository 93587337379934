import React, { useRef } from "react";
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { MenuItem } from "../";
import { getMenuItems } from "../../utils";
import {
	Close as CloseIcon
} from "@material-ui/icons";
import {LogoSidebarMenu} from "../../assets/images/png/index";

const Container = styled.div`
  
  width: 100%;
  max-width: 300px;
  height: 100vh;
  top: 0px;
  position: fixed;
  transform: translateX(${props => props.showMenu ? "0px" : "-300px"});
  transition: transform 0.3s ease;
  z-index: 10000;

  background: var(--black);
  border: 1px solid var(--);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 14px;
  padding-bottom: 50px;

`;

let keyCounter = 0;

export const Menu = (props) => {

    const location = useLocation();
    const ref = useRef();
    const user = props.user || {};
    const menuItems = getMenuItems(user.cd_privilege);

    keyCounter++;

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && props.showMenu && props.onMenuClose) {
            props.onMenuClose();
        }
    };
    document.addEventListener('click', handleClickOutside, true);

  return (
    <Container
      ref={ref}
      className={props.className}
      showMenu={props.showMenu}
      style={props.style}
    >
      <div
        style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "40px",
            paddingRight: "24px",
            marginBottom: "124px"
        }}
      >
          <img
            src={LogoSidebarMenu}
          />

          <CloseIcon
            style={{
              cursor: "pointer",
              color: "var(--grey-2)"
            }}
            onClick={props.onMenuClose}
          />
      </div>
      {
          menuItems.map((item, index) => (
            <MenuItem
              key={index/*"key_" + keyCounter + "_" + */}
              item={item}
              selected={location.pathname.lastIndexOf(item.link) > -1}
              onMenuItemSelected={() => {
                if(props.onMenuClose) {
                  props.onMenuClose();
                }
              }}
            />
          ))
      }
    </Container>
  );
};
