import {
	Add as AddIcon,
	ArrowForward as ArrowForwardIcon,
	EmailOutlined as EmailIcon,
	Print as PrintIcon,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { Col, Row as RowB } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
	BlockCommesseMateriali,
	BlockLavorazioniNote,
	BottomBar,
	Breadcrumb,
	Button,
	Card,
	Checkbox,
	Row,
	SelectField,
	Dropdown,
	TableCommessePunteggi,
	Text,
	TextField,
	DatePickerComponent,
	TimePickerComponent,
	Spacer,
	AdaptableTextAreaField,
	ConfirmAlertModal,
	DocumentField,
	Loader
} from "../../components";
import AddDocument from "../../components/modals/commesse/components/addDocument";
import AddDocumentSingleAction from "../../components/modals/commesse/components/addDocumentSingleAction";
import {
	getCommessaDetail,
	updateCommessa,
	deleteCommessa,
	consegnaCommessa,
	confermaOrdine,
	controllaEsistenzaDocumento
} from "../../services";
import { useLocation } from "react-router-dom";
import * as moment from "moment";
import {
	getZona,
	getPriorita,
	calculateDtLavoration,
} from "../../utils/functionsUtils";
import { PREVENTIVIDETAIL, COMMESSE } from "../../navigation/constants";
import { ToastContainer, Toast } from "react-bootstrap";

import {
	ZONE,
	PRIORITA,
	AGENTI,
	GRUPPI_ANAGRAFICHE,
	COMMESSA_STATUS,
	COMMESSA_TYPES,
	LAVORAZIONI,
} from "../../utils/constants";

import { useNavigate } from "react-router-dom";
import { setToast } from "../../redux";
import { DOWNLOAD_DOCUMENTI, PDF_PREVENTIVO, PDF_CONTEGGI } from "../../services/constants";
import { withTranslation } from "react-i18next";

const workTypes = [
	"lavorazioni_alzatine",
	"lavorazioni_profili",
	"lavorazioni_fori",
	"lavorazioni_varie",
	"accessori",
	"vasche_integrate",
];

export const CommesseDetail = withTranslation()((props) => {
	const { t } = props;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user || {});
	const location = useLocation();
	const [id, setId] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [commessa, setCommessa] = useState();
	const [commessaId, setCommessaId] = useState();
	const [notes, setNotes] = useState({});
	const [confirmAlertModalShow, setConfirmAlertModalShow] = useState(false);
	const [confirmAlert, setConfirmAlert] = useState({});
	const [saveLoading, setSaveLoading] = useState(false);
	const toast = useSelector((state) => state.toast || {});
	const navigate = useNavigate();
	const [documentsState, setDocumentsState] = useState({
		dwg_esecutivo: {
			exist: false,
			filename: ""
		},
		pdf_esecutivo: {
			exist: false,
			filename: ""
		},
		pdf_cliente: {
			exist: false,
			filename: ""
		},
		pdf_conferma: {
			exist: false,
			filename: ""
		}
	});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setCommessaId(queryParams.get("commessaId"));
	}, []);

	useEffect(() => {
		if(commessaId) {
			executeGetCommessaDetail();
		}
	}, [commessaId]);

	const saveCommessa = () => {

		if (saveLoading) {
			return;
		}

		setSaveLoading(true);

		commessa.status_history[commessa.cd_status] = moment().toISOString();
		if(commessa.dt_consegna && commessa.dt_consegna != ""){
			commessa.cd_type = "COMMESSA"
			updateCommessa(commessa._id, commessa).then(({ result, error }) => {
				setSaveLoading(false);
				if (error) {
					console.log(error);
					dispatch(setToast({
						show: true,
						title: "commesseDetail.toastUpdate.error.title",
						description: "commesseDetail.toastUpdate.error.description",
						variant: "danger"
					}));
				} else {
					dispatch(setToast({
						show: true,
						title: "commesseDetail.toastUpdate.success.title",
						description: "commesseDetail.toastUpdate.success.description",
						variant: "success"
					}));
					executeGetCommessaDetail();
				}
			});
		} else {
			setSaveLoading(false);
			dispatch(setToast({
				show: true,
				title: "commesseDetail.toastUpdate.error2.title",
				description: "commesseDetail.toastUpdate.error2.description",
				variant: "danger"
			}));
		}
	};

	const executeGetCommessaDetail = () => {
		getCommessaDetail(commessaId).then(({ result, error }) => {
			if (error) {
			} else {
				if (result/* && result.materiali && result.materiali.length > 0*/) {
					setCommessa({...result});
					const anno_commessa = moment(result.dt_creazione_commessa).format("YYYY"); //2022
					const numero_commessa = result.numero_commessa; // "76D"

					documentsState.dwg_esecutivo.exist = result.dwg_esecutivo.exist;
					documentsState.dwg_esecutivo.filename = `${numero_commessa}-${anno_commessa}.dwg`;

					documentsState.pdf_esecutivo.exist = result.pdf_esecutivo.exist;
					documentsState.pdf_esecutivo.filename = `${numero_commessa}-${anno_commessa}.pdf`;

					documentsState.pdf_cliente.exist = result.pdf_cliente.exist;
					documentsState.pdf_cliente.filename = `${numero_commessa}-${anno_commessa}-E.pdf`;

					documentsState.pdf_conferma.exist = result.pdf_conferma.exist;
					documentsState.pdf_conferma.filename = `${numero_commessa}-${anno_commessa}-D.pdf`;

					setDocumentsState({...documentsState});


				} else {
					setNotes({});
				}
			}

			console.log("Commessa:");
			console.log(result);
			setIsLoading(false);
		});
	};

	const confermaEliminazione = () => {
		confirmAlert.headerTitle = "commesseDetail.confirmDelete.headerTitle";
		confirmAlert.mainTitle = "commesseDetail.confirmDelete.mainTitle";
		confirmAlert.subtitle = "commesseDetail.confirmDelete.subtitle";
		confirmAlert.actionButtonTitle = "commesseDetail.confirmDelete.button";
		confirmAlert.isDelete = "elimina";
		setConfirmAlert(confirmAlert);
		setConfirmAlertModalShow(true);
	}

	const confermaConsegnaCommessa = () => {
		confirmAlert.headerTitle = "commesseDetail.confirmDelivery.headerTitle";
		confirmAlert.mainTitle = "commesseDetail.confirmDelivery.mainTitle";
		confirmAlert.subtitle = "commesseDetail.confirmDelivery.subtitle";
		confirmAlert.actionButtonTitle = "commesseDetail.confirmDelivery.button";
		confirmAlert.isDelete = "consegna";
		setConfirmAlert(confirmAlert);
		setConfirmAlertModalShow(true);
	}

	const eliminaCommessa = () => {
		deleteCommessa(commessa._id).then(({ result, error }) => {
			if (error) {
				console.log(error);
			} else {
				console.log("Salvataggio:");
				navigate(COMMESSE);
			}
		});
	};

	const fineCommessa = () => {
		consegnaCommessa(commessa._id).then(({ result, error }) => {
			if (error) {
				console.log(error);
			} else {
				console.log("Salvataggio:");
				navigate(COMMESSE);
			}
		});
	};

	const inviaOrdine = () => {
		let orderToSend = {
			commessa_id :  commessa._id
		}

		console.log("CONFERMA ORDINE ------> " + orderToSend)

		confermaOrdine(orderToSend).then(({ result, errors }) => {
			if (errors || (result && !result.success)) {
				console.error(errors);
				dispatch(
					setToast({
						show: true,
						title: "commesseDetail.toastOrdine.error.title",
						description: "commesseDetail.toastOrdine.error.description",
						variant: "danger",
					})
				);
			} else if (result === undefined) {
				console.error("Qualcosa è andato storto");
				dispatch(
					setToast({
						show: true,
						title: "commesseDetail.toastOrdine.error.title",
						description: "commesseDetail.toastOrdine.error.description",
						variant: "danger",
					})
				);
			} else {
				dispatch(
					setToast({
						show: true,
						title: "commesseDetail.toastOrdine.success.title",
						description: "commesseDetail.toastOrdine.success.description",
						variant: "success",
					})
				);
			}

		});
	};

	const getUniqueMaterial = () => {
		let list = [];

		commessa.materiali.forEach((item, index) => {
			if (
				item.materiale &&
				!list.some(
					(materiale) =>
						materiale.materiale &&
						materiale.materiale.nome == item.materiale.nome
				)
			) {
				list.push(item);
			}
		});

		console.log(list);

		return list;
	};

	console.log(commessa);

	if (isLoading) {
		return (
			<div
				style={
					{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: "56px"
					}
				}
			>
				<Loader
					layer={3}
					color="var(--red)"
				/>
			</div>
		);
	} else {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					padding: "20px",
				}}
			>
				<Breadcrumb
					levels={[
						{
							text: "commesseDetail.breadcrumbTitle",
							link: COMMESSE
						},
						{
							text: "commesseDetail.breadcrumbSubtitle"
						}
					]}
					style={{
						marginTop: "40px",
					}}
				/>
				<Row
					style={{
						width: "100%",
						marginTop: "32px",
						alignItems: "flex-start",
						marginBottom: "100px",
					}}
					breakpoints={[
						{
							breakpoint: "10000",
							defaultWidth: 100,
							widthOverride: {
								".first-card": 60,
								".second-card": 40,
							},
						},
						{
							breakpoint: "1000",
							defaultWidth: 100,
						},
					]}
				>
					<div
						className="first-card"
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Card
							style={{
								padding: "32px",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								flexDirection: "row",
								width: "100%",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="xxlarge" weight="bold" color="var(--black)">
									{commessa.numero_commessa +
										" / " +
										moment(commessa.dt_creazione_commessa).format("YYYY")}
								</Text>

								<Text size="large" weight="regular" color="var(--darkgrey)">
									{t("commesseDetail.creationDate", {date: moment(commessa.dt_creazione_commessa).format("DD/MM/YYYY")})}
								</Text>
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Text size="large" weight="regular" color="var(--grey-2)">
									{"commesseDetail.lastUpdate"}
								</Text>
								<Text
									size="large"
									weight="regular"
									color="var(--grey-2)"
									style={{
										display: "flex",
										justifyContent: "end",
									}}
								>
									{moment(commessa.updatedAt).format("DD/MM/YYYY, HH:mm")}
								</Text>
							</div>
						</Card>
						<Card
							style={{
								padding: "32px",
								width: "100%",
								marginTop: "40px",
							}}
						>
							<Text size="large" weight="bold">
								{"commesseDetail.statusCommessa.title"}
							</Text>

							<Text size="medium" weight="regular">
								{"commesseDetail.statusCommessa.description"}
							</Text>
							<Row
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline",
								}}
							>
								<div
									style={{
										display: "flex",
										paddingRight: "10px",
										flexDirection: "column",
										marginTop: "20px",
										width: "50%",
										borderRight: "1px solid var(--grey-2)",
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "end",
										}}
									>
										<Checkbox
											id="chk_prenotazione"
											type="radio"
											name="radioStatus"
											label={"commesseDetail.prenotazioneLabel"}
											checked={
												commessa.cd_status == COMMESSA_STATUS.PRENOTAZIONE
											}
											color="var(--purple)"
											onChange={() => {
												commessa.cd_status = COMMESSA_STATUS.PRENOTAZIONE;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
										<div
											style={{
												display: "flex",
												alignItems: "end",
												justifyContent: "flex-end",
											}}
										>
											<Text size="medium" weight="regular">
												{commessa.status_history["0"] != ""
													? moment(commessa.status_history["0"]).format(
															"DD/MM/YYYY"
													  )
													: ""}
											</Text>
										</div>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "end",
										}}
									>
										<Checkbox
											id="chk_preordine"
											type="radio"
											name="radioStatus"
											label={"commesseDetail.preordineLabel"}
											checked={commessa.cd_status == COMMESSA_STATUS.PREORDINE}
											color="var(--purple)"
											onChange={() => {
												commessa.cd_status = COMMESSA_STATUS.PREORDINE;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
										<div
											style={{
												display: "flex",
												alignItems: "end",
												justifyContent: "flex-end",
											}}
										>
											<Text size="medium" weight="regular">
												{commessa.status_history["50"] != ""
													? moment(commessa.status_history["50"]).format(
															"DD/MM/YYYY"
													  )
													: ""}
											</Text>
										</div>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "end",
										}}
									>
										<Checkbox
											id="chk_attesa_conferma"
											type="radio"
											name="radioStatus"
											label={"commesseDetail.waitingLabel"}
											checked={commessa.cd_status == COMMESSA_STATUS.IN_ATTESA}
											color="var(--purple)"
											onChange={() => {
												commessa.cd_status = COMMESSA_STATUS.IN_ATTESA;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
										<div
											style={{
												display: "flex",
												alignItems: "end",
												justifyContent: "flex-end",
											}}
										>
											<Text size="medium" weight="regular">
												{commessa.status_history["100"] != ""
													? moment(commessa.status_history["100"]).format(
															"DD/MM/YYYY"
													  )
													: ""}
											</Text>
										</div>
									</div>

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "end",
										}}
									>
										<Checkbox
											id="chk_confermato"
											type="radio"
											name="radioStatus"
											label={"commesseDetail.ordineLabel"}
											checked={commessa.cd_status == COMMESSA_STATUS.CONFERMATO}
											color="var(--purple)"
											onChange={() => {
												commessa.cd_status = COMMESSA_STATUS.CONFERMATO;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
										<div
											style={{
												display: "flex",
												alignItems: "end",
												justifyContent: "flex-end",
											}}
										>
											<Text size="medium" weight="regular">
												{commessa.status_history["200"] != ""
													? moment(commessa.status_history["200"]).format(
															"DD/MM/YYYY"
													  )
													: ""}
											</Text>
										</div>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "end",
										}}
									>
										<Checkbox
											id="chk_finito"
											type="radio"
											name="radioStatus"
											label={"commesseDetail.finishLabel"}
											checked={commessa.cd_status == COMMESSA_STATUS.FINITO}
											color="var(--purple)"
											onChange={() => {
												commessa.cd_status = COMMESSA_STATUS.FINITO;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
										<div
											style={{
												display: "flex",
												alignItems: "end",
												justifyContent: "flex-end",
											}}
										>
											<Text size="medium" weight="regular">
												{commessa.status_history["500"] != ""
													? moment(commessa.status_history["500"]).format(
															"DD/MM/YYYY"
													  )
													: ""}
											</Text>
										</div>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "flex-start",
										flexDirection: "column",
										marginTop: "20px",
										width: "48%",
										marginLeft: "10px",
									}}
								>
									<Row
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between"
										}}
									>
										<Checkbox
											id="chk_sospendi_commessa"
											label={"commesseDetail.suspendLabel"}
											color="var(--purple)"
											checked={commessa.is_sospesa}
											onChange={() => {
												commessa.is_sospesa = !commessa.is_sospesa;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
									</Row>

									<Row
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between"
										}}
									>
										<Checkbox
											id="chk_commessa_gold"
											label={"commesseDetail.goldLabel"}
											color="var(--yellow)"
											checked={commessa.is_gold}
											onChange={() => {
												commessa.is_gold = !commessa.is_gold;
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
												marginTop: "16px",
											}}
										/>
									</Row>
									<Row
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											marginTop: "56px",
											width: "100%",
										}}
									>
										<Checkbox
											id="chk_controllo_qualita"
											label={"commesseDetail.qualityLabel"}
											color="var(--black)"
											checked={commessa.qa.is_done}
											onChange={(e) => {
												const checked = e.target.checked;
												if (checked) {
													commessa.qa = {
														is_done: true,
														user: user.nomecompleto,
														dt_qa: moment().toISOString(),
													};
												} else {
													commessa.qa = {
														is_done: false,
														user: "",
														dt_qa: "",
													};
												}
												setCommessa({ ...commessa });
											}}
											style={{
												marginRight: "24px",
											}}
										/>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												textAlign: "right",
											}}
										>
											<Text size="large" weight="regular" color="var(--black)">
												{commessa.qa && commessa.qa.user
													? commessa.qa.user
													: ""}
											</Text>
											<Text
												size="large"
												weight="regular"
												color="var(--black)"
												style={{
													display: "flex",
													justifyContent: "end",
												}}
											>
												{commessa.qa && commessa.qa.dt_qa
													? moment(commessa.qa.dt_qa).format(
															"DD/MM/YYYY, HH:mm"
													  )
													: ""}
											</Text>
										</div>
									</Row>
								</div>
							</Row>
							<Row
								defaultWidth={50}
								style={{
									marginTop: "40px",
								}}
							>
								<SelectField
									id=""
									required
									type="text"
									label={"commesseDetail.priorityLabel"}
									placeholder={"hint_select"}
									value={commessa.priority}
									options={PRIORITA}
									onChange={(id, value) => {
										commessa.priority = value;
										setCommessa({ ...commessa });
									}}
								/>

								<TextField
									id=""
									label={"commesseDetail.zoneLabel"}
									placeholder={"hint_insert"}
									readOnly
									value={commessa.cliente && commessa.cliente.zona_consegna != "-" ? (ZONE.find((item) => commessa.cliente.zona_consegna == item.value) || {}).text : "-"}
									options={ZONE}
									onChange={(id, value) => {
										commessa.zona = value;
										setCommessa({ ...commessa });
									}}
								/>
							</Row>
							<Row
								defaultWidth={25}
								style={{
									marginTop: "20px",
								}}
							>
								<DatePickerComponent
									id="dt_consegna"
									label="commesseDetail.dateDeliveryLabel"
									labelColor="var(--darkgrey)"
									placeholderText="Seleziona data..."
									backgroundColor="var(--grey-field)"
									selectedDate={
										commessa.dt_consegna ? commessa.dt_consegna : ""//moment().add(1, "day").toDate()
									}
									onChange={(id, value) => {
										commessa.dt_consegna = moment(value).set({hour:0,minute:0,second:0,millisecond:0});
										if (commessa.dt_consegna && commessa.punteggi_lavorazioni) {
											commessa.dt_lavorazione = calculateDtLavoration(
												commessa.punteggi_lavorazioni,
												commessa.dt_consegna
											).toISOString();
										}
										setCommessa({ ...commessa });
									}}
								/>

								<TimePickerComponent
									id="dt_time"
									label="commesseDetail.hourDeliveryLabel"
									labelColor="var(--darkgrey)"
									backgroundColor="var(--grey-field)"
									selectedDate={
										commessa.hour_consegna ? moment().set({hour:commessa.hour_consegna.split(":")[0],minute:commessa.hour_consegna.split(":")[1],second:0,millisecond:0}) : ""//moment().set({hour:9,minute:0,second:0,millisecond:0}).toDate()
									}
									onChange={(id, value) => {
										commessa.hour_consegna = moment(value).format("HH:mm");
										setCommessa({ ...commessa });
									}}
								/>

								<TextField
									id=""
									type="text"
									label={"commesseDetail.dateStartLavorazioniLabel"}
									value={
										commessa.dt_lavorazione
											? moment(commessa.dt_lavorazione).format("DD/MM/YYYY")
											: ""
									}
									readOnly
									lightbackground
								/>
								{/* <SelectField
									id=""
									required
									type="text"
									label={"DATA INIZIO LAVORAZIONE"}
									placeholder={"Seleziona..."}
									value={calculateDtLavoration(commessa.punteggi_lavorazioni, commessa.createdAt)}
									onChange={onChange}
								/> */}
							</Row>
							<Row
								defaultWidth={100}
								style={{
									marginTop: "20px",
								}}
							>
								<TextField
									id=""
									type="text"
									label={"commesseDetail.deliveryNotesLabel"}
									placeholder={"hint_insert"}
									value={commessa.note_consegna ? commessa.note_consegna : ""}
									onChange={(id, value) => {
										commessa.note_consegna = value;

										setCommessa({ ...commessa });
									}}
									style={{
										marginTop: "20px",
									}}
								/>
							</Row>
							<Row
								defaultWidth={100}
								style={{
									marginTop: "40px",
								}}
							>
								<TextField
									id=""
									type="text"
									label={"commesseDetail.commessaNotes"}
									placeholder={"hint_insert"}
									value={commessa.commento ? commessa.commento : ""}
									onChange={(id, value) => {
										commessa.commento = value;

										setCommessa({ ...commessa });
									}}
									style={{
										marginTop: "20px",
									}}
								/>
							</Row>
						</Card>

						{commessa.cd_type == "PREVENTIVO" ? (
							<>
								{commessa.materiali &&
									commessa.materiali.map((item, index) => (
										<BlockCommesseMateriali
											key={item._id || item.key}
											listino={commessa.listino._id}
											commessaMateriale={item}
											commessaCliente={commessa.cliente}
											materialiSelected={commessa.materiali.filter((item) => item.materiale && item.materiale._id).map((item) => item.materiale._id)}
											materialeName={item.materialeName || ""}
											aziendaId={item.aziendaId || ""}
											onChange={(item) => {
												if (commessa.materiali.length > index) {
													commessa.materiali[index] = item;
												} else {
													commessa.materiali.push(item);
												}
												setCommessa({ ...commessa });
											}}
											onDelete={(item) => {
												commessa.materiali = commessa.materiali.filter((el, _index) => { return _index != index; });
												//preventivo.materiali.filter(function(el) { return el._id != item._id; });
												setCommessa({ ...commessa });
											}}
										/>
									))}
								<Row
									style={{
										display: "flex",
										flexDirection: "row",
										padding: "20px",
									}}
								>
									<Button
										variant="primary"
										style={{
											marginTop: "40px",
											marginRight: "20px",
										}}
										onClick={(e) => {
											commessa.materiali.push({
												key: id + 1,
												accessori: [],
												altre_lavorazioni: [],
												dimensioni: [],
												lavorazioni_alzatine: [],
												lavorazioni_fori: [],
												lavorazioni_profili: [],
												lavorazioni_varie: [],
												vasche_integrate: [],
											});
											setId(id+1);
											setCommessa({ ...commessa });
										}}
									>
										<AddIcon
											style={{
												marginRight: "8px",
											}}
										/>
										<Text size="small" weight="medium">
											{"commesseDetail.addMaterial"}
										</Text>
									</Button>
									{commessa.materiali && commessa.materiali.some((item) => !!item.materiale && !!item.materiale._id) ? (
										<Dropdown
											style={{
												marginTop: "40px",
											}}
											toggle={
												<Button
													variant="outline-primary"

													// onClick={(e) => {
													// 	let materialCopy = JSON.parse(JSON.stringify(preventivo.materiali[preventivo.materiali.length - 1].materiale)) ;
													// 	materialCopy.spessore = "";
													// 	preventivo.materiali.push({
													// 		materiale: materialCopy,
													// 		accessori: [],
													// 		altre_lavorazioni: [],
													// 		dimensioni: [],
													// 		lavorazioni_alzatine: [],
													// 		lavorazioni_fori: [],
													// 		lavorazioni_profili: [],
													// 		lavorazioni_varie: [],
													// 		vasche_integrate: []
													// 	})
													// 	setPreventivo({...preventivo});
													// }}
												>
													<AddIcon
														style={{
															marginRight: "8px",
														}}
													/>
													<Text size="small" weight="medium">
														{"commesseDetail.addSpessore"}
													</Text>
												</Button>
											}
											menu={getUniqueMaterial().map((item, index) => {
												return (
													<div
														style={{
															width: "100%",
															display: "flex",
															alignItems: "center",
														}}
														onClick={(e) => {
															commessa.materiali.push({
																key: id+1,
																materiale: null,
																materialeName: item.materiale.nome,
																aziendaId: item.materiale.azienda ? item.materiale.azienda._id || "" : "",
																accessori: [],
																altre_lavorazioni: [],
																dimensioni: [],
																lavorazioni_alzatine: [],
																lavorazioni_fori: [],
																lavorazioni_profili: [],
																lavorazioni_varie: [],
																vasche_integrate: [],
															});
															setId(id+1);
															setCommessa({ ...commessa });
														}}
													>
														<Text size="large" weight="medium">
															{item.materiale.nome}
														</Text>
													</div>
												);
											})}
										/>
									) : (
										""
									)}
								</Row>
							</>
						) : (
							<Card
								style={{
									padding: "32px",
									width: "100%",
									marginTop: "40px",
								}}
							>
								<Text size="large" weight="bold">
									{"commesseDetail.materiale.title"}
								</Text>

								<Text size="medium" weight="regular">
									{
										"commesseDetail.materiale.description"
									}
								</Text>
								{
									commessa.materiali.some((item) => item.materiale && item.materiale.nome && item.materiale.nome != "") ?
										<RowB
											style={{
												marginTop: "20px",
											}}
										>
											<Col xs={9} sm={9} md={9} lg={9}>
												<Text size={"xsmall"} weight={"medium"}>
													{"commesseDetail.materialeLabel"}
												</Text>
											</Col>

											<Col xs={3} sm={3} md={3} lg={3}>
												<Text size="xsmall" weight="medium">
													{"commesseDetail.lottoTaglioLabel"}
												</Text>
											</Col>
										</RowB>
									:
										""
								}
								{commessa.materiali.map((material) =>
									material.materiale ? (
										<RowB>
											<Col xs={9} sm={9} md={9} lg={9}>
												<TextField
													id=""
													type="text"
													lightbackground
													readOnly
													color="var(--red)"
													value={
														(material.materiale.azienda &&
															material.materiale.azienda.nome +
																" " +
																material.materiale.nome +
																" - Sp. " +
																material.materiale.spessore) ||
														material.materiale.nome +
															" - Sp. " +
															material.materiale.spessore
													}
													style={{
														marginTop: "8px",
													}}
												/>
											</Col>

											<Col xs={3} sm={3} md={3} lg={3}>
												<TextField
													id=""
													type="text"
													placeholder={"hint_insert"}
													value={material.materiale.lotto_taglio}
													onChange={(id, value) => {
														commessa.materiali.forEach(item => {
															if(item.materiale._id == material.materiale._id){
																item.materiale.lotto_taglio = value;
															}
														});

														setCommessa({ ...commessa });
													}}
													style={{
														marginTop: "8px",
													}}
												/>
											</Col>
										</RowB>
									) : (
										""
									)
								)}

								<BlockLavorazioniNote
									commessa={commessa}
									onChange={(newCommessa) => {
										setCommessa({ ...newCommessa });
									}}
								/>
							</Card>
						)}
						<Spacer height="200px" />
					</div>

					<div
						className="second-card"
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						{commessa.cd_type == COMMESSA_TYPES.COMMESSA && (
							<Card
								style={{
									padding: "32px",
									width: "100%",
								}}
							>
								<Text size="large" weight="bold">
									{"commesseDetail.preventivoLinked.title"}
								</Text>

								<Text size="medium" weight="regular">
									{"commesseDetail.preventivoLinked.description"}
								</Text>
								<Row
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										flexDirection: "row",
										marginTop: "40px",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "column",
										}}
									>
										<Text size="small" weight="medium" color="var(--darkgrey)">
											{"commesseDetail.preventivoNumber"}
										</Text>
										<Text
											size="small"
											weight="medium"
											color="var(--darkgrey)"
											style={{
												marginTop: "17px",
											}}
										>
											{"commesseDetail.preventivoCreationDate"}
										</Text>
									</div>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "flex-end",
											flexDirection: "column",
										}}
									>
										<Text size="large" weight="regular" color="var(--black)">
											{commessa.numero_preventivo}
										</Text>
										<Text
											size="large"
											weight="regular"
											color="var(--black)"
											style={{
												marginTop: "12px",
											}}
										>
											{moment(commessa.createdAt).format("DD/MM/YYYY")}
										</Text>
									</div>
								</Row>
								<Row
									style={{
										display: "flex",
										flexDirection: "row",
										padding: "20px 0px 0px 0px",
									}}
								>
									<Button
										variant="secondary"
										style={{
											marginTop: "40px",
											marginRight: "20px",
										}}
										onClick={() => {
											navigate(PREVENTIVIDETAIL+`?commessaId=${commessa._id}`);
										}}
									>
										<Text size="small" weight="medium">
											{"commesseDetail.goToPreventivo"}
										</Text>
										<ArrowForwardIcon
											style={{
												marginLeft: "8px",
											}}
										/>
									</Button>
									<Button
										variant="outline-secondary"
										href={`${process.env.REACT_APP_WS_LINK}${PDF_PREVENTIVO(commessa._id, user.cd_privilege)}`}
										target="_blank"
										style={{
											marginTop: "40px",
										}}
									>
										<PrintIcon
											style={{
												marginRight: "8px",
											}}
										/>
										<Text size="small" weight="medium">
											{"commesseDetail.print"}
										</Text>
									</Button>
								</Row>
							</Card>
						)}
						<Card
							style={{
								padding: "32px",
								width: "100%",
								marginTop:  commessa.cd_type == "COMMESSA" ? "40px" : "0px",
							}}
						>
							<Text size="large" weight="bold">
								{"commesseDetail.clientData.title"}
							</Text>

							<Text size="medium" weight="regular">
								{"commesseDetail.clientData.description"}
							</Text>
							<TextField
								id=""
								required
								type="text"
								label={"commesseDetail.clientName"}
								lightbackground
								readOnly
								value={
									commessa.cliente && commessa.cliente.nomecompleto
										? commessa.cliente.nomecompleto
										: ""
								}
								style={{
									marginTop: "20px",
								}}
							/>
							<Row
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									flexDirection: "row",
									marginTop: "20px",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-start",
										flexDirection: "column",
									}}
								>
									<Text size="small" weight="medium" color="var(--darkgrey)">
										{"commesseDetail.telephone"}
									</Text>
									<Text
										size="small"
										weight="medium"
										color="var(--darkgrey)"
										style={{
											marginTop: "17px",
										}}
									>
										{"commesseDetail.city"}
									</Text>
									<Text
										size="small"
										weight="medium"
										color="var(--darkgrey)"
										style={{
											marginTop: "17px",
										}}
									>
										{"commesseDetail.zone"}
									</Text>
									<Text
										size="small"
										weight="medium"
										color="var(--darkgrey)"
										style={{
											marginTop: "17px",
										}}
									>
										{"commesseDetail.agent"}
									</Text>
									<Text
										size="small"
										weight="medium"
										color="var(--darkgrey)"
										style={{
											marginTop: "17px",
										}}
									>
										{"commesseDetail.priority"}
									</Text>
									<Text
										size="small"
										weight="medium"
										color="var(--darkgrey)"
										style={{
											marginTop: "17px",
										}}
									>
										{"commesseDetail.contenzioso"}
									</Text>
									<Text
										size="small"
										weight="medium"
										color="var(--darkgrey)"
										style={{
											marginTop: "17px",
										}}
									>
										{"commesseDetail.imballo"}
									</Text>
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "flex-end",
										flexDirection: "column",
									}}
								>
									<Text size="large" weight="regular" color="var(--black)">
										{commessa.cliente && commessa.cliente.telefono
											? commessa.cliente.telefono
											: "-"}
									</Text>
									<Text
										size="large"
										weight="regular"
										color="var(--black)"
										style={{
											marginTop: "12px",
										}}
									>
										{commessa.cliente && commessa.cliente.citta
											? commessa.cliente.citta
											: "-"}
									</Text>
									<Text
										size="large"
										weight="regular"
										color="var(--black)"
										style={{
											marginTop: "12px",
										}}
									>
										{getZona(
											commessa.cliente && commessa.cliente.zona
												? commessa.cliente.zona
												: ""
										)}
									</Text>
									<Text
										size="large"
										weight="regular"
										color="var(--black)"
										style={{
											marginTop: "12px",
										}}
									>
										{commessa.cliente && commessa.cliente.agente
											? commessa.cliente.agente
											: "-"}
									</Text>
									<Text
										size="large"
										weight="regular"
										color="var(--black)"
										style={{
											marginTop: "12px",
										}}
									>
										{getPriorita(
											commessa.cliente && commessa.cliente.priority
												? commessa.cliente.priority
												: ""
										)}
									</Text>
									<Text
										size="large"
										weight="regular"
										color="var(--black)"
										style={{
											marginTop: "12px",
										}}
									>
										{commessa.cliente && commessa.cliente.contenzioso
											? "SI"
											: "NO"}
									</Text>
									<Text
										size="large"
										weight="regular"
										color="var(--black)"
										style={{
											marginTop: "12px",
										}}
									>
										{commessa.cliente && commessa.cliente.imballo_obbligatorio
											? "SI"
											: "NO"}
									</Text>
								</div>
							</Row>
							<Row
								style={{
									marginTop: "20px",
									width: "100%",

									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<Text size="small" weight="medium" color="var(--darkgrey)">
									{"commesseDetail.generalClientNotes"}
								</Text>
								<Text size="large" weight="regular" color="var(--black)">
									{commessa.cliente && commessa.cliente.note_contenzioso
										? commessa.cliente.note_contenzioso
										: ""}
								</Text>
							</Row>
							<TextField
								id=""
								required
								type="text"
								label={"commesseDetail.listinoLavorazioni"}
								defaultValue={
									commessa.listino && commessa.listino.nome
										? commessa.listino.nome
										: ""
								}
								readOnly
								lightbackground
								style={{
									marginTop: "40px",
								}}
							/>
							<TextField
								id=""
								required
								type="text"
								label={"commesseDetail.riferimento"}
								placeholder={"hint_insert"}
								defaultValue={commessa.riferimento}
								style={{
									marginTop: "20px",
								}}
								onChange={(id, value) => {
									commessa.riferimento = value;

									setCommessa({ ...commessa });
								}}
							/>
							<SelectField
								id=""
								required
								type="text"
								labelColor="var(--darkgrey)"
								label={"commesseDetail.attenzioneDi"}
								value={commessa.attenzione_di}
								style={{
									marginTop: "20px"

								}}
								options={[{ value: "-", text: "Seleziona..." }].concat(
									commessa.cliente.credentials.map((item) => ({
										value: item.email,
										text: item.fullname,
									}))
								)}
								onChange={(id, value) => {
									commessa.attenzione_di = value;
									setCommessa({ ...commessa });
								}}
								lightbackground
							></SelectField>
							{/* <TextField
								id=""
								required
								type="text"
								label={"commesseDetail.attenzioneDi"}
								placeholder={"hint_insert"}
								defaultValue={
									(commessa.attenzione_id &&
									commessa.attenzione_di.nome_operatore
										? commessa.attenzione_di.nome_operatore + " "
										: "") +
									(commessa.attenzione_di &&
									commessa.attenzione_di.cognome_operatore
										? commessa.attenzione_di.cognome_operatore
										: "")
								}
								readOnly
								lightbackground
								style={{
									marginTop: "20px",
								}}
							/> */}
						</Card>
						<Card
							style={{
								padding: "32px",
								width: "100%",
								marginTop: "40px",
							}}
						>
							<Text size="large" weight="bold">
								{"commesseDetail.documents.title"}
							</Text>

							<Text size="medium" weight="regular">
								{"commesseDetail.documents.description"}
							</Text>
							<DocumentField
								label="commesseDetail.dwgLabel"
								value={documentsState.dwg_esecutivo.exist ? documentsState.dwg_esecutivo.filename : ""}
								iconLink={documentsState.dwg_esecutivo.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("dwg", commessa.numero_commessa, moment(commessa.dt_creazione_commessa).format("YYYY"))}` : null}
								style={{
									marginTop: "40px",
								}}
							/>
							<DocumentField
								label="commesseDetail.pdfEsecLabel"
								value={documentsState.pdf_esecutivo.exist ? documentsState.pdf_esecutivo.filename : ""}
								iconLink={documentsState.pdf_esecutivo.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("pdf", commessa.numero_commessa, moment(commessa.dt_creazione_commessa).format("YYYY"))}` : null}
							/>
							<DocumentField
								label="commesseDetail.pdfClientLabel"
								value={documentsState.pdf_cliente.exist ? documentsState.pdf_cliente.filename : ""}
								iconLink={documentsState.pdf_cliente.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("pdf", commessa.numero_commessa, `${moment(commessa.dt_creazione_commessa).format("YYYY")}-E`)}` : null}
							/>
							<DocumentField
								label="commesseDetail.pdfConfirmLabel"
								value={documentsState.pdf_conferma.exist ? documentsState.pdf_conferma.filename : ""}
								iconLink={documentsState.pdf_conferma.exist ? `${process.env.REACT_APP_WS_LINK}${DOWNLOAD_DOCUMENTI("pdf", commessa.numero_commessa, `${moment(commessa.dt_creazione_commessa).format("YYYY")}-D`)}` : null}
							/>
							<DocumentField
								label="commesseDetail.pdfContLabel"
								value={"conteggio.pdf"}
								iconLink={`${process.env.REACT_APP_WS_LINK}${PDF_CONTEGGI(commessa._id, user.cd_privilege)}`}
							/>
							<DocumentField
								label="commesseDetail.pdfPrevLabel"
								value={"preventivo.pdf"}
								iconLink={`${process.env.REACT_APP_WS_LINK}${PDF_PREVENTIVO(commessa._id, user.cd_privilege)}`}
							/>
							<Row
								style={{
									marginTop: "40px",
								}}
							>
								<Col xs={12} style={{ marginBottom: "8px" }}>
									<Text size="small" weight={"medium"} color="var(--darkgrey)">
										{"commesseDetail.uploadImages"}
									</Text>
								</Col>
							</Row>
							<AddDocument
								id="immagini"
								multiField
								list={commessa.immagini}
								onChange={(id, list) => {
									commessa.immagini = list;
									setCommessa({...commessa});
								}}
							/>
							<div>
								<Button
									variant="outline-secondary"
									style={{
										marginTop: "40px",
									}}
									onClick={() => inviaOrdine()}
								>
									<EmailIcon
										style={{
											color: "var(--grey-2)",
											width: "20px",
											height: "20px",
											marginRight: "8px",
										}}
									/>
									<Text size="small" weight="medium">
										{"commesseDetail.sendOrdine"}
									</Text>
								</Button>
							</div>
						</Card>
					</div>
				</Row>

				{commessa.cd_type == "PREVENTIVO" ? (
					<BottomBar
						successButton={{
							show: true,
							title: saveLoading ? "commesseDetail.waiting" : "commesseDetail.saveCommessa",
							onClick: saveCommessa,
						}}
						cancelButton={{
							show: true,
							title: "commesseDetail.cancel",
							onClick: () => {
								navigate(COMMESSE);
							},
						}}
					/>
				) : (
					<BottomBar
						leftFirstButton={{
							show: true,
							title: "commesseDetail.deleteCommessa",
							onClick: confermaEliminazione,
						}}
						leftSecondButton={{
							show: true,
							title: "commesseDetail.commessaDelivered",
							onClick: confermaConsegnaCommessa,
						}}
						leftInfo={{
							show: true,
							text: t("commesseDetail.lastUpdate2", {date: moment(commessa.updatedAt).format("DD/MM/YYYY, HH:mm")}),
						}}
						successButton={{
							show: true,
							title: saveLoading ? "commesseDetail.waiting" : "commesseDetail.updateCommessa",
							onClick: saveCommessa,
						}}
						cancelButton={{
							show: true,
							title: "commesseDetail.cancel",
							onClick: () => {
								navigate(COMMESSE);
							},
						}}
					/>
				)}
				<ConfirmAlertModal
					show={confirmAlertModalShow}
					headerTitle={confirmAlert.headerTitle}
					mainTitle={confirmAlert.mainTitle}
					subtitle={confirmAlert.subtitle}
					actionButtonTitle={confirmAlert.actionButtonTitle ? confirmAlert.actionButtonTitle : "commesseDetail.delete"}
					onHide={() => setConfirmAlertModalShow(false)}
					onCancel={() => setConfirmAlertModalShow(false)}
					onSuccess={() =>
						{
							setConfirmAlertModalShow(false);
							confirmAlert.isDelete == "consegna" ? fineCommessa() : eliminaCommessa();
						}

					}
				/>

			</div>
		);
	}
})
