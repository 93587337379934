import { PDF_COMMESSE_LIST, PDF_SPEDIZIONI_LIST, PDF_CONTEGGI, PDF_PREVENTIVO, PDF_ETICHETTE } from "./constants";
import { get, post, put, del } from "./service";

export const getPdfCommesseList = async (page_type) => {
  return get(PDF_COMMESSE_LIST(page_type));
};

export const getPdfSpedizioniList = async (filters) => {
  return post(PDF_SPEDIZIONI_LIST(), filters, {responseType: "blob"});
};

export const getPdfConteggi = async (commessa_id, user_cd_privilege) => {
  return get(PDF_CONTEGGI(commessa_id, user_cd_privilege));
};

export const getPdfPreventivo = async (commessa_id, user_cd_privilege) => {
  return get(PDF_PREVENTIVO(commessa_id, user_cd_privilege));
};

export const getPdfEtichette = async (commessa_id) => {
  return get(PDF_ETICHETTE(commessa_id));
};