import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Anagrafiche,
	AnagraficheDetail,
	Preventivi,
	Commesse,
	CommesseDetail,
	Listini,
	Ordini,
	Spedizioni,
	News,
	PreventiviDetail,
	ListiniMateriali,
	ListiniLavorazioni,
	NewsDetail,
	NewsDetailClient,
	ProfiloDetail
} from "../features";
import Login from "./Auth/login";
import { PrivateRoute } from "./Auth/privateRoute";
import {
	ROOT,
	LOGIN,
	ANAGRAFICHE,
	ANAGRAFICHEDETAIL,
	PREVENTIVI,
	PREVENTIVIDETAIL,
	COMMESSE,
	COMMESSEDETAIL,
	ORDINI,
	SPEDIZIONI,
	NEWS,
	NEWSDETAIL,
	UNAUTHORIZED,
	LISTINI,
	LISTINI_MATERIALI,
	LISTINI_LAVORAZIONI,
	PROFILODETAIL,
} from "./constants";
import { USER_ROLES } from "../utils/constants";
//import Dashboard from "./features/Dashboard";
import { NotFound } from "./notFound";

export const RouterConfig = () => {
	const user = useSelector((state) => state.user || {});
	return (
		<Routes>
			{/* List all public routes here */}
			<Route path={LOGIN} element={<Login />} />

			{/* List all private/auth routes here */}

			<Route
				path={ANAGRAFICHE}
				element={<PrivateRoute allowedRoles={[USER_ROLES.ADMIN]} />}
			>
				<Route index element={<Anagrafiche />} />
				<Route exact path={ANAGRAFICHEDETAIL} element={<AnagraficheDetail />} />
			</Route>

			<Route
				path={PREVENTIVI}
				element={
					<PrivateRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.CLIENT]} />
				}
			>
				<Route index element={<Preventivi />} />
				<Route exact path={PREVENTIVIDETAIL} element={<PreventiviDetail />} />
			</Route>

			<Route path={COMMESSE} element={<PrivateRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.OPERATOR]}/>}>
				<Route index exact element={<Commesse />} />
				<Route exact path={COMMESSEDETAIL} element={<CommesseDetail />} />
			</Route>

			<Route path={LISTINI} element={<PrivateRoute allowedRoles={[USER_ROLES.ADMIN]}/>}>
				<Route index exact element={<Listini />} />
			</Route>

			<Route
				path={ORDINI}
				element={
					<PrivateRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.CLIENT]} />
				}
			>
				<Route index element={<Ordini />} />
			</Route>

			<Route path={SPEDIZIONI} element={<PrivateRoute allowedRoles={[]} />}>
				<Route index element={<Spedizioni />} />
			</Route>

			{/*<Route path={LISTINI_MATERIALI} element={<PrivateRoute allowedRoles={[]} />}>
				<Route index element={<ListiniMateriali />} />
			</Route>

			<Route path={LISTINI_LAVORAZIONI} element={<PrivateRoute allowedRoles={[]} />}>
				<Route index element={<ListiniLavorazioni />} />
			</Route>*/}

			<Route
				path={NEWS}
				element={
					<PrivateRoute allowedRoles={[USER_ROLES.ADMIN, USER_ROLES.CLIENT]} />
				}
			>
				<Route index element={<News />} />
				<Route exact path={NEWSDETAIL} element={user.cd_privilege == USER_ROLES.ADMIN ? <NewsDetail /> : <NewsDetailClient />} />
			</Route>

			<Route
				path={PROFILODETAIL}
				element={<PrivateRoute allowedRoles={[USER_ROLES.CLIENT]} />}
			>
				<Route exact path={PROFILODETAIL} element={<ProfiloDetail />} />
			</Route>

			{/* List a generic 404-Not Found route here */}
			<Route path={UNAUTHORIZED} element={<NotFound />} />
			<Route path="*" element={<Login />} />
		</Routes>
	);
};
