import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Text } from "../";

const Container = styled.div`
	width: 100%;
`;

const AddItem = styled.div`
	margin-top: 20px;

	cursor: pointer;

	& > * {
		cursor: pointer;
	}
`;

export const DynamicList = (props) => {
	let addItemColor = props.addItemColor || "var(--grey-2)";
	const [addItemHover, setAddItemHover] = useState(false);

	return (
		<Container>
			<Row>
				<Col>{props.children}</Col>
			</Row>
			<Row>
				<Col>
					<AddItem
						onClick={props.onAddItem}
						onMouseEnter={() => setAddItemHover(true)}
						onMouseLeave={() => setAddItemHover(false)}
					>
						<AddIcon
							style={{
								color: addItemHover ? "var(--darkgrey)" : addItemColor,
								width: "20px",
								height: "20px",
								marginRight: "8px",
								marginBottom: "3px",
							}}
						/>
						<Text
							size="large"
							weight="medium"
							color={addItemHover ? "var(--darkgrey)" : addItemColor}
							style={{
								userSelect: "none",
								textDecoration: addItemHover ? "underline" : "",
							}}
						>
							{props.addText}
						</Text>
					</AddItem>
				</Col>
			</Row>
		</Container>
	);
};
