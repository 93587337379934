import React, { Fragment } from "react";
import styled from "styled-components";
import { Text } from "../../index";

const Wrapper = styled.div`
	& {
		display: flex;
		flex-direction: column;

		padding-top: 12px;
		padding-right: 8px;
		padding-left: 8px;
		padding-bottom: 12px;
	}

	& > .cursor-pointer {
		cursor: pointer;
	}

	& > {
		-webkit-text-decoration-line: line-through; /* Safari */
		text-decoration-line: line-through; 
	 }

`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */

export const TextCell = (props) => {
	return (
		<Wrapper
			keyCell={props.keyCell}
			className={props.className}
			style={props.style}
			onClick={props.onClick}
		>
			{props.secondaryText ? (
				<Fragment>
					<Text
						size={props.primaryTextSize ? props.primaryTextSize : "large"}
						weight={props.primaryTextWeight ? props.primaryTextWeight : "bold"}
						color={props.color}
						className={props.primaryTextClassName}
						title={props.primaryTextTitle}
						style={{
							"-webkit-text-decoration-line: line-through": props.lineOnText ? "line-through" : "",
							"text-decoration-line": props.lineOnText ? "line-through" : ""
						}}
					>
						{props.primaryText}
					</Text>
					<Text
						size={props.secondaryTextSize ? props.secondaryTextSize : "large"}
						weight={
							props.secondaryTextWeight ? props.secondaryTextWeight : "regular"
						}
						color={props.secondaryColor}
						className={props.secondaryTextClassName}
						title={props.secondaryTextTitle}
						style={{
							marginTop: "4px",
							"-webkit-text-decoration-line: line-through": props.lineOnText ? "line-through" : "",
							"text-decoration-line": props.lineOnText ? "line-through" : ""
						}}
					>
						{props.secondaryText}
					</Text>
				</Fragment>
			) : (
				<Text
					size={props.primaryTextSize ? props.primaryTextSize : "large"}
					weight={props.primaryTextWeight ? props.primaryTextWeight : "regular"}
					color={props.color}
					className={props.primaryTextClassName}
					title={props.primaryTextTitle}
				>
					{props.primaryText}
				</Text>
			)}
		</Wrapper>
	);
};
