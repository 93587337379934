import { filter } from "draft-js/lib/DefaultDraftBlockRenderMap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BlockFilterPreventivi, Breadcrumb, Card, RowPreventivi, Table, Loader } from "../../components";
import { PREVENTIVI } from "../../navigation/constants";
import { getPreventiviFiltered, deletePreventivo } from "../../services";
import { setToast } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../hooks";
import { COMMESSA_TYPES } from "../../utils/constants";

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
`;

const COLUMNS = [
  
  {
    key: "data1",
    text: "preventivi.column1",
    isOrderable: true,
    width: "10%",
  },
  {
    key: "data2",
    text: "preventivi.column2",
    isOrderable: true,
    width: "15%",
  },
  {
    key: "data3",
    text: "preventivi.column3",
    width: "15%",
  },
  {
    key: "data8",
    text: "preventivi.column8",
    isOrderable: true,
    width: "15%",
  },
  {
    key: "data4",
    text: "preventivi.column4",
    isOrderable: true,
    width: "20%",
  },
  {
      key: "data1",
      text: "preventivi.column5",
      isOrderable: true,
      width: "10%",
    },
    {
      key: "data2",
      text: "preventivi.column6",
      isOrderable: true,
      width: "10%",
    },
    {
      key: "data3",
      text: "preventivi.column7",
      width: "5%",
    }
];

const DEFAULTS = {
	TYPOLOGIES: "-",
  DAL: "",
	AL: "",
	SEARCH: "",
};

export function Preventivi() {
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(0);
	const ELEMENTS_COUNT = 30;
	const [updateList, setUpdateList] = useState(false);
	const [hasMore, setHasMore] = useState(true);

  const debounce = useDebounce();

  const [isLoading, setIsLoading] = useState(true);
  const [preventivi, setPreventivi] = useState([]);
  const [typologies, setTypologies] = useState([{ text: "", value: "-" }]);
  const [filters, setFilters] = useState({});
  const toast = useSelector((state) => state.toast || {});

  useEffect(() => {
    resetFilters();
	}, []);

  useEffect(() => {
    setPageNumber(0);
		executeGetPreventivi(0);
	}, [filters]);

  useEffect(() => {
		if	(updateList && hasMore) {
      setPageNumber(pageNumber+1);
			executeGetPreventivi(pageNumber+1);
		}
	}, [updateList])

	const executeGetPreventivi = (page_number = 0, elements_count = ELEMENTS_COUNT) => {

    debounce(()  => {
      setIsLoading(true);
      
      getPreventiviFiltered(filters, page_number, elements_count).then(({ result, error }) => {
				if (error) {
				} else {
					setHasMore(result.length == ELEMENTS_COUNT);

					if(page_number > 0) {
						setPreventivi([...preventivi, ...result]);
					} else {
						setPreventivi([...result]);
					}
				}
				setUpdateList(false);
				setIsLoading(false);
			});
    });
		
	};

  const setFilter = (type, value) => {
		console.log(`${type} -> ${value}`);
		setFilters({ ...filters, [type]: value });
	};

  const resetFilters = () => {
    clearFilters();
  }

	const clearFilters = () => {
    
		setFilters({
      cd_number_type: COMMESSA_TYPES.PREVENTIVO,
			typology: DEFAULTS.TYPOLOGIES,
			dal: DEFAULTS.DAL,
		  al: DEFAULTS.AL,
			search: DEFAULTS.SEARCH,
		});
    // setPreventivi(original);
		// setFilters({
		// 	...DEFAULTS,
		// });
	};



  return (
    <Wrapper>
      <Breadcrumb
        levels={[
          {
            text: "preventivi.breadcrumbTitle",
            link: PREVENTIVI
          }
        ]}
        style={{
          marginTop: "32px"
        }}
      />
      
      <BlockFilterPreventivi
        style={{
          marginTop: "32px"
        }}
        selected={filters}
        filters={filters}
				setFilters={setFilters}
        setFilter={setFilter}
				clearFilters={clearFilters}
      />

      <Card
        style={{
          marginTop: "32px",
          padding: "32px 20px 32px 20px"
        }}
      >
        <Table
          infiniteScrollOptions={{
            enabled: true,
            onUpdate: () => {
              setUpdateList(true);
            },
            hasMore,
            isLoading,
            loaderContent: (
              <div
                style={
                  {
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "56px"
                  }
                }
              >
                <Loader
                  layer={3}
                  color="var(--red)"
                />
              </div>
            )
          }}
          columns={COLUMNS}
          data={preventivi}
          rowComponent={RowPreventivi}
          className={"scroll-1330"}
          classNameHeader={"min-width-1192"}
          onDelete={(preventivo, index) => {
            dispatch(setToast({
              show: true,
              title: "preventivi.toastUpdate.success.title",
              description: "preventivi.toastUpdate.success.description",
              variant: "success"
            }));
            let newPreventivi = preventivi.filter((preventivo, itemIndex) => itemIndex != index);
            console.log(newPreventivi)
            setPreventivi(newPreventivi);
          }}
        />
      </Card>
      
    </Wrapper>
  );
}
