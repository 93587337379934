import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
// Redux
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { store } from "./app/store";
import { Wrapper } from "./features";
import { ProvideAuth } from "./navigation/Auth/provideAuth";

function App() {

  let persistor = persistStore(store);

  return (
    <>
      <div>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ProvideAuth>
              <Wrapper />
            </ProvideAuth>
          </PersistGate>
        </Provider>
      </div>
    </>
  );
}

export default withTranslation()(App);
