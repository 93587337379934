import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state = action.payload;
      return action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setToast } = toastSlice.actions;
export default toastSlice.reducer;
