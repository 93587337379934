export const ROOT = "/";
export const LOGIN = "/login";
export const ANAGRAFICHE = "/anagrafiche";
export const ANAGRAFICHEDETAIL = "/anagrafiche/detail";
export const PROFILODETAIL = "/profilo/detail";
export const PREVENTIVI = "/preventivi";
export const PREVENTIVIDETAIL = "/preventivi/detail";
export const COMMESSE = "/commesse";
export const COMMESSEDETAIL = "/commesse/detail";
export const ORDINI = "/ordini";
export const SPEDIZIONI = "/spedizioni";
export const LISTINI = "/listini";
export const LISTINI_MATERIALI = "/listini_materiali";
export const LISTINI_LAVORAZIONI = "/listini_lavorazioni";
export const NEWS = "/news";
export const NEWSDETAIL = "/news/detail";
export const UNAUTHORIZED = "/unauthorized";