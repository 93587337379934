import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
	BlockFilterSpedizioni,
	Breadcrumb,
	Button,
	Card,
	CommentListModal,
	RowSpedizioni,
	Spacer,
	Table,
	Text,
	Loader,
	InfiniteScroll
} from "../../components";
import { COMMESSE, SPEDIZIONI } from "../../navigation/constants";
import { getSpedizioniFiltered } from "../../services";
import { COMMESSA_STATUS, COMMESSA_TYPES } from "../../utils/constants";
import "./Spedizioni.css";
import { getSettings, setSettings } from "../../services";
import { useDispatch } from "react-redux";
import { setToast } from "../../redux";
import { withTranslation } from "react-i18next";
import { useDebounce } from "../../hooks";

const Wrapper = styled.div`
	width: 100%;
	padding: 40px;
`;

const COLUMNS = [
	{
		key: "data1",
		text: "spedizioni.column1",
		isOrderable: true,
		width: "3%",
	},
	{
		key: "data2",
		text: "spedizioni.column2",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data2",
		text: "spedizioni.column3",
		isOrderable: true,
		width: "5%",
	},
	{
		key: "data3",
		text: "spedizioni.column4",
		isOrderable: true,
		width: "7%",
	},
	{
		key: "data4",
		text: "spedizioni.column5",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data4",
		text: "spedizioni.column6",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data1",
		text: "spedizioni.column7",
		isOrderable: true,
		width: "20%",
	},
	{
		key: "data2",
		text: "spedizioni.column8",
		isOrderable: true,
		width: "10%",
	},
	{
		key: "data3",
		text: "spedizioni.column9",
		width: "10%",
	},
	{
		key: "data3",
		text: "spedizioni.column11",
		width: "5%",
	},
	{
		key: "data3",
		text: "spedizioni.column12",
		width: "5%",
	},
	{
		key: "data3",
		text: "spedizioni.column13",
		width: "10%",
	},
];

/*const DEFAULTS = {
	prenotazione: false, //STATUS_LIST
	preordine: true, //STATUS_LIST
	in_attesa: true, //STATUS_LIST
	ordine: true, //STATUS_LIST
	finito: true, //STATUS_LIST
	anticipo: false,  //IS_ANTICIPO
	gold: false, //IS_GOLD
	attivita: true, //IS_ATTIVITA
	priorita: "-", //PRIORITY
	zona: "-", //ZONA
	da: "", //dt_inizio_consegna
	al: "", //dt_fine_consegna
};*/

const DEFAULTS = {
	cd_number_type: COMMESSA_TYPES.COMMESSA,
	search: "",
	statusList: [COMMESSA_STATUS.PREORDINE, COMMESSA_STATUS.IN_ATTESA, COMMESSA_STATUS.CONFERMATO, COMMESSA_STATUS.FINITO],
	is_anticipo: false,
	is_gold: false,
	is_attivita: true,//true, LO ABBIAMO COMMENTATO PERCHè INIZIALIZZARLO A TRUE NON HA SENSO
	priority: "NOI",
	zona: "-",
	dt_inizio_consegna: "",
	dt_fine_consegna: "",
};

export const Spedizioni = withTranslation()((props) => {
	const { t } = props;
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(0);
	const ELEMENTS_COUNT = 15;
	const [updateList, setUpdateList] = useState(false);
	const [hasMore, setHasMore] = useState(true);

  	const debounce = useDebounce();

	const [isLoading, setIsLoading] = useState(true);
	const [spedizioniList, setSpedizioniList] = useState([]);
	const [showModal, setShowModal] = useState({ show: false, value: "" });
	const [localSettings, setLocalSettings] = useState({});
	const navigate = useNavigate();

	const toast = useSelector((state) => state.toast || {});

	const [filters, setFilters] = useState({
		...DEFAULTS,
	});

	// ============================================================================================
	// Sort
	// ============================================================================================
	const sortDeliveries = (list) => {
		const preordini = [];
		const inAttesa = [];
		const ordini = [];
		const finiti = [];
		const attivita = [];
		const altro = [];

		for (let spedizione of list) {
			switch (spedizione.cd_status) {
				case COMMESSA_TYPES.PREORDINE:
					preordini.push(spedizione);
					break;

				case COMMESSA_TYPES.IN_ATTESA:
					inAttesa.push(spedizione);
					break;

				case COMMESSA_TYPES.ORDINE:
					ordini.push(spedizione);
					break;

				case COMMESSA_TYPES.FINITO:
					finiti.push(spedizione);
					break;

				case COMMESSA_TYPES.ATTIVITA:
					attivita.push(spedizione);
					break;

				default:
					altro.push(spedizione);
			}
		}

		const sorted = [];

		preordini.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		inAttesa.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		ordini.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		finiti.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		attivita.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));
		altro.sort((a, b) => new Date(a.dt_consegna) - new Date(b.dt_consegna));

		sorted.pushAll(preordini);
		sorted.pushAll(inAttesa);
		sorted.pushAll(ordini);
		sorted.pushAll(finiti);
		sorted.pushAll(attivita);
		sorted.pushAll(altro);

		return sorted;
	};

	/**
	 * Raggruppa per giorni in formato
	 * {
	 * 	"giorno1": [item1_giorno1, item2_giorno1],
	 * 	"giorno2": [item1_giorno2, item2_giorno2]
	 * }
	 */
	const groupByDay = (list) => {
		const byDay = {};

		for (let item of list) {
			let day = moment(item.dt_consegna).format("DD/MM/YYYY");
			if (byDay[day]) {
				byDay[day].push(item);
			} else {
				byDay[day] = [];
				byDay[day].push(item);
			}
		}

		return byDay;
	};

	const spedizioneComponentList = useMemo(() => {
		let sorted = sortDeliveries(spedizioniList) || {};
		sorted = groupByDay(sorted);
		return Object.keys(sorted).map((day) => {
					if (sorted[day].length > 0) {
						return (
							<>
								<Spacer height="36px" />
								<Text size="xxlarge" weight="medium">
									{t("spedizioni.consegna", {
										weekDay: moment(sorted[day][0].dt_consegna).format("dddd"),
										date: day
									})}
								</Text>
								<Card
									style={{
										marginTop: "20px",
										padding: "32px 20px 32px 20px",
									}}
								>
									<Table
										className="scroll-1440"
										classNameHeader={"min-width-1301"}
										columns={COLUMNS}
										data={sorted[day] || []}
										rowComponent={RowSpedizioni}
									/>
								</Card>
							</>
						);
					} else {
						return "";
					}
				})
	}, [spedizioniList]);

	useEffect(() => {

		getSettings().then(({ result, error }) => {
			if (error) {
				console.error(error);
			} else {
				const settings = result && result.length > 0 ? result[0] : {};
				setLocalSettings(settings);
				setShowModal({ ...showModal, value: settings.commento_spedizioni });
			}
		});
	}, []);

	useEffect(() => {
		setPageNumber(0);
		executeGetCommesse(0);
	}, [filters]);

	useEffect(() => {
		if	(updateList && hasMore) {
			executeGetCommesse(pageNumber+1);
			setPageNumber(pageNumber+1);
		}
	}, [updateList]);

	const executeGetCommesse = (_pageNumber = 0, _elementsCount = ELEMENTS_COUNT) => {
		debounce(()  => {
			setIsLoading(true);
			getSpedizioniFiltered(filters, _pageNumber, _elementsCount).then(({ result, error }) => {
				if (error) {
					console.error(error);
					dispatch(
						setToast({
							show: true,
							title: "spedizioni.toastFetch.error.title",
							description: "spedizioni.toastFetch.error.description",
							variant: "danger",
						})
					);
				} else {
					
					setHasMore(result.length == ELEMENTS_COUNT);
		
					if(_pageNumber > 0) {
						setSpedizioniList([...spedizioniList, ...result]);
					} else {
						setSpedizioniList([...result]);
					}
					//setFilters({ ...DEFAULTS });
				}
				setUpdateList(false);
				setIsLoading(false);
			});
		});

	};

	const clearFilters = () => {
		setFilters({
			...DEFAULTS,
		});
	};

	

	// ==============================================================================
	// Modal
	// ==============================================================================
	const saveComments = () => {
		setSettings(localSettings._id, {
			...localSettings,
			commento_spedizioni: showModal.value,
		}).then(({ result, error }) => {
			if (error) {
				console.error(error);
				dispatch(
					setToast({
						show: true,
						title: "spedizioni.toastupdate.error.title",
						description: "spedizioni.toastupdate.error.description",
						variant: "danger",
					})
				);
			} else {
				setLocalSettings({
					...localSettings,
					commento_spedizioni: showModal.value,
				});
				dispatch(
					setToast({
						show: true,
						title: "spedizioni.toastupdate.success.title",
						description: "spedizioni.toastupdate.success.description",
						variant: "success",
					})
				);
				setShowModal({ ...showModal, show: false });
			}
		});
	};

	return (
		<Wrapper>
			<CommentListModal
				show={showModal.show}
				onHide={() => setShowModal({ ...showModal, show: false })}
				onCancel={() => setShowModal({ ...showModal, show: false })}
				value={showModal.value}
				onChange={(text) => setShowModal({ ...showModal, value: text })}
				onSuccess={saveComments}
			/>

			<Row className="d-flex align-items-center">
				<Col md={8}>
					<Breadcrumb
						levels={[
							{
								text: "spedizioni.breadcrumbTitle",
								link: SPEDIZIONI,
							},
						]}
					/>
				</Col>
				<Col md={4} className="mt-3 mt-md-0 d-flex justify-content-md-end">
					<Button
						variant="secondary"
						onClick={() => {
							navigate(COMMESSE);
						}}
					>
						<Text size="small" weight="medium">
							{"spedizioni.goToCommesse"}
						</Text>
						<ArrowForwardIcon
							style={{
								width: "20px",
								height: "20px",
								marginLeft: "8px",
							}}
						/>
					</Button>
				</Col>
			</Row>

			<BlockFilterSpedizioni
				style={{
					marginTop: "32px",
				}}
				onCommentListOpen={() => setShowModal({ ...showModal, show: true })}
				filters={filters}
				setFilters={setFilters}
				clearFilters={clearFilters}
			/>

			<InfiniteScroll
				isLoading={isLoading}
				hasMore={hasMore}
				loaderContent={
					(
						<div
							style={
								{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									marginTop: "56px"
								}
							}
						>
							<Loader
								layer={3}
								color="var(--red)"
							/>
						</div>
					)
				}
				onUpdate={() => {
					setUpdateList(true);
				}}
				content={
					spedizioneComponentList
				}
			/>
			<Spacer height="120px" />
		</Wrapper>
	);
})
