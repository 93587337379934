import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, SelectField, Text, TextField } from "../../";

export const ProductImageModal = (props) => {
	
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			onCancel={props.onCancel}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			dialogClassName="modal-footer-no-border-top modal-padding"
		>
			<Modal.Body>
				<img
					src={`https://media.etihub.it/campramarmi/${props.azienda.toLowerCase().replaceAll(" ", "_")}_${props.materiale.toLowerCase().replaceAll(" ", "_")}.jpg`}
					style={{
						width:"100%"
					}}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Container>
					<Row
						style={{
							marginTop: "50px",
						}}
					>
						<Col
							style={{
								display: "flex",
								justifyContent: "end",
								flexWrap: "wrap",
								gap: "16px",
							}}
						>
							<Button variant="secondary" onClick={() => props.onCancel()}>
								<Text size="small" weight="medium">
									ANNULLA
								</Text>
							</Button>
						</Col>
					</Row>
				</Container>
			</Modal.Footer>
		</Modal>
	);
};
