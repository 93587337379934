import {
	CloudUpload as UploadIcon,
	DeleteOutline as DeleteIcon,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
	BottomBar,
	Breadcrumb,
	Card,
	Row,
	Text,
	Button,
	Loader
} from "../../components";
import { NEWS } from "../../navigation/constants";
import { useLocation } from "react-router-dom";
import {
	getNewsById,
	createNews,
	updateNews,
	deleteNews
} from "../../services";
import * as moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { create } from "draft-js/lib/CharacterMetadata";
import { Col, Row as RowB } from "react-bootstrap";
import AddDocument from "../../components/modals/commesse/components/addDocument";
import { setToast } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export function NewsDetailClient() {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [news, setNews] = useState({});
	const [newsId, setNewsId] = useState();
	const location = useLocation();
	const navigate = useNavigate();

	const canShowImage = !((!news.image_preview || !news.image_preview[0] || !news.image_preview[0].file_url || news.image_preview[0].file_url == "") && (!news.galleria || news.galleria.length == 0));

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setNewsId(queryParams.get("newsId"));
	}, []);

	useEffect(() => {
		if(newsId) {
			executeGetNewsDetail();
		}
	}, [newsId]);

	const executeGetNewsDetail = () => {
		if (newsId) {
			getNewsById(newsId).then(({ result, error }) => {
				if (error) {
					console.lof(error)
				} else {
					setNews({...result});
				}
				setIsLoading(false);
			});
		};
	};

	console.log(news)

	return (
		isLoading ?
			<div
				style={
					{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						marginTop: "56px"
					}
				}
			>
				<Loader
					layer={3}
					color="var(--red)"
				/>
			</div>
		:
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				padding: "20px",
			}}
		>
			<Breadcrumb
				levels={[
					{
						text: "newsDetail.breadcrumbTitle",
						link: NEWS
					},
					{
						text: "Dettaglio news"
					}
				]}
				style={{
					marginTop: "40px",
				}}
			/>
			<Row
				style={{
					width: "100%",
					marginTop: "32px",
					marginBottom: "100px",
					alignItems: "flex-start",
				}}
				breakpoints={[
					{
						breakpoint: "10000",
						defaultWidth: 100,
						widthOverride: canShowImage ? {
							".first-card": 60,
							".second-card": 40,
						} : {
							".first-card": 100
						},
					},
					{
						breakpoint: "1000",
						defaultWidth: 100,
					},
				]}
			>
				<div
					className="first-card"
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Card
						style={{
							padding: "32px",
							width: "100%"
						}}
					>
						<Text
							size="xxlarge"
							weight="bold"
							color="var(--black)"
						>
							{news.titolo}
						</Text>
						<div
							dangerouslySetInnerHTML={{ __html: news.descrizione }}
							style={{
								marginTop: "16px"
							}}
						/>
						{
							news.allegato && news.allegato[0] && news.allegato[0].file_url && news.allegato[0].file_url !== "" ?
							(
								<RowB>
									<Col lg={6} xs={12} style={{ marginTop: "24px"}}>
										<Text size="small" weight={"medium"} color="var(--darkgrey)">
											{"newsDetail.download"}
										</Text>
										<AddDocument
											id="documento_logo_cliente"
											isReadonly
											block={true}
											list={news.allegato}
										/>
									</Col>
								</RowB>
							)
							:
							""
						}
						{
							news.testo_bottone !== "" && news.link_bottone !== "" ?
							(
								<Button
									variant="primary"
									onClick={() => {
										if(news.link_bottone.includes("https://")){
											window.open(news.link_bottone, '_blank');
										} else {
											window.open('https://' + news.link_bottone, '_blank');
										}
										
									}}
									style={{
										width: "max-content",
										marginTop: "24px"
									}}
								>
									<Text size="small" weight="medium">
										{news.testo_bottone}
									</Text>
								</Button>
							)
							:
							""
						}
					</Card>
				</div>
				{
					canShowImage ?
					(
						<div
							className="second-card"
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Card
								style={{
									padding: "32px",
									width: "100%",
								}}
							>
								{
									news.image_preview && news.image_preview[0] && news.image_preview[0].file_url && news.image_preview[0].file_url != "" ?
									(
										<img
											src={news.image_preview[0].file_url}
											style={{
												width: "100%",
												maxHeight: "300px",
												objectFit: "contain"
											}}
										/>
									)
									:
									""
								}
								{
									news.galleria && news.galleria.length > 0 ?
									<RowB
										style={{
											marginTop: "40px"
										}}
									>
										{
											news.galleria.map((item) => (
												<Col lg={4} md={6} xs={6}>
													<img
														src={item.file_url}
														style={{
															width: "100%"
														}}
													/>
												</Col>
											))
										}
									</RowB>
									:
									""
								}
							</Card>
						</div>
					)
					:
					""
				}
			</Row>

			<BottomBar
				successButton={{
					show: false,
					title: ""
				}}
				cancelButton={{
					show: true,
					title: "newsDetail.cancel",
					onClick: () => {
						navigate(NEWS);
					},
				}}
			/>
		</div>
		
	);
}
