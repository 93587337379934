import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  & {

    display: flex;
    flex-direction: column;

    padding-top: 12px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 12px;
    
  }
`;

/**
 *
 * @param {} elements
 * @param {} orderingListener
 * @returns
 */

export const ImgCell = (props) => {
  

  return (
    <Wrapper
      keyCell={props.keyCell}
      className={props.className}
      style={props.style}
    >
      {
        props.src ?
          (
            <img
              src={props.src}
              onClick={props.onClick}
              style={{
                maxWidth: "calc(100% - 8px)"
              }}
            />
          )
        :
          ""
      }
    </Wrapper>
  );
};
