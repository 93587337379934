import { Today as TodayIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "../index";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from 'date-fns/locale/it'

registerLocale('it', it)


const Container = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 6px 12px 6px 12px !important;
	height: 40px !important;
	width: 100%;

	input {
		width: 100%;
	}

	&,
	&:focus {
		box-shadow: none !important;
		border: 1px solid var(--grey-1);
		border-radius: 6px;
		outline: none;
	}

	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: var(--grey-2);
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: var(--grey-2);
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		color: var(--grey-2);
	}

	& svg {
		color: var(--grey-2);
		width: 20px;
		height: 20px;
	}
`;

const DatePickerCustom = styled(DatePicker)`
	&,
	&:focus {
		background-color: transparent;
		border: none;
		outline: none;
	}
`;

export const DatePickerComponent = withTranslation()((props) => {
	const { t } = props;
	let startDate = props.selectedDate ? new Date(props.selectedDate) : "";
	
	return (
		<Form.Group className={props.className} style={props.style}>
			{props.label ? (
				<Text size="small" weight={"medium"} color={props.labelColor}>
					{props.label}
				</Text>
			) : (
				""
			)}

			<Container
				style={{
					backgroundColor: props.backgroundColor
						? props.backgroundColor
						: "var(--white) !important",
				}}
			>
				<DatePickerCustom
					wrapperClassName="datePicker"
					selected={startDate}
					placeholderText={t(props.placeholderText) || t("picker.select")}
					locale="it"
					onChange={(date) => {
						if (props.onChange) {
							props.onChange(props.id, date);
						}
					}}
					/*showTimeSelect*/
					minDate={
						props.removeMinDate ? null : props.minDate ? props.minDate : null
					}
					maxDate={
						props.removeMaxDate ? null : props.maxDate ? props.maxDate : null
					}
					/*minTime={minTime}
          maxTime={new Date(0, 0, 0, 17)}*/ // 5:00pm
					dateFormat="dd/MM/yyyy"
					/*timeFormat="hh:mm a"*/
				/>
				<TodayIcon className="cursor-pointer" />
			</Container>
		</Form.Group>
	);
});
