import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { BlockFilterNews, Breadcrumb, Card, RowNews, Table, Text, Loader } from "../../components";
import {
  getNews
} from "../../services";
import {
  USER_ROLES
} from "../../utils/constants";
import {
  getDescriptionNewsWithoutHtml
} from "../../utils";
import { NEWSDETAIL } from "../../navigation/constants";
import moment from "moment";
import { PlaceholderImgNews } from "../../assets/images/svg";
import { LogoHeader, LogoHeader2X } from "../../assets/images/png";

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
`;

const COLUMNS = [
  {
    key: "data1",
    text: "news.column1",
    isOrderable: true,
    width: "5%",
  },
  {
    key: "data2",
    text: "news.column2",
    isOrderable: true,
    width: "20%",
  },
  {
    key: "data3",
    text: "news.column3",
    isOrderable: true,
    width: "60%",
  },
  {
    key: "data4",
    text: "news.column4",
    width: "15%",
  }
];

const DEFAULTS = {
	search: "",
};

export function News() {
  const [isLoading, setIsLoading] = useState(true);
  const [newsList, setNewsList] = useState([]);
  const user = useSelector((state) => state.user || {});
  const navigate = useNavigate();
  const [original, setOriginal] = useState({});
  const [filters, setFilters] = useState({
		...DEFAULTS,
	});
	

  useEffect(() => {
		if (
			filters.search === DEFAULTS.search 
		) {
			setNewsList(original);
		} else {
			let filtered = original.filter(
				(news) =>
					includesText(news, filters.search) 
			);

			console.log(filtered);

			setNewsList(filtered);
		}
	}, [filters]);

  useEffect(() => {
		  executeGetNews();
	}, []);

  const executeGetNews = () => {

		getNews().then(({ result, error }) => {
			if (error) {
        console.log(error)
			} else {
				console.log(result);
        if(user.cd_privilege == USER_ROLES.CLIENT) {
          result = result.filter((item) => moment() >= moment(item.data_pubblicazione));
        }
        setOriginal(result);
        setNewsList(result);
			}

			setIsLoading(false);
		});
	};

  const setFilter = (type, value) => {
		console.log(`${type} -> ${value}`);
		setFilters({ ...filters, [type]: value });
	};

  const includesText = (rowData, text) => {
		if (text === "") return true;

		let value = new RegExp(text, "ig");

		return (
			
			(rowData &&
				rowData.titolo !== "" &&
				value.test(rowData.titolo)) ||
      	(rowData &&
          rowData.descrizione !== "" &&
          value.test(rowData.descrizione))
			
		);
	};

  return (
    <Wrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems : "center",
          marginTop: "32px",
          justifyContent: "space-between"
        }}
      >
        <Breadcrumb
          levels={[
            {
              text: "news.breadcrumbTitle"
            }
          ]}
        />
        
        <BlockFilterNews
          filters={filters}
          setFilters={setFilters}
          setFilter={setFilter}
        />
      </div>
      
      {
        isLoading ? 
          <div
            style={
              {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "56px"
              }
            }
          >
            <Loader
              layer={3}
              color="var(--red)"
            />
          </div>
          :
          user.cd_privilege == USER_ROLES.ADMIN ?
          (
            <Card
              style={{
                marginTop: "32px",
                padding: "32px 20px 32px 20px"
              }}
            >
              <Table
                columns={COLUMNS}
                data={newsList}
                rowComponent={RowNews}
                className={"scroll-1330"}
						    classNameHeader={"min-width-1192"}
              />
            </Card>
          )
          :
          <Row
            style={{
              marginTop: "16px"
            }}
          >
            {
              newsList.map((news) => (
                <Col
                  lg="3"
                  md="4"
                  xs="6"
                  style={{
                    display: "flex",
                    marginTop: "16px"
                  }}
                >
                  <Card
                    onClick={() => {
                      navigate(NEWSDETAIL+`?newsId=${news._id}`);   
                    }}
                    style={{
                      padding: "0px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={news.image_preview && news.image_preview[0] && news.image_preview[0].file_url ? news.image_preview[0].file_url : PlaceholderImgNews}
                      style={{
                        width: "100%",
                        maxHeight: "300px",
                        marginBottom: "24px",
                        borderRadius: "6px",
                        alignItems: "center",
                        objectFit: "contain"
                      }}
                    />
                    <Text
                      size="xxlarge"
                      weight="bold"
                      color="var(--black)"
                      style={{
                        marginLeft: "24px",
                        marginRight: "24px"
                      }}
                    >
                      {news.titolo}
                    </Text>
                    <Text
                      size="large"
                      weight="regular"
                      color="var(--darkgrey)"
                      style={{
                        marginLeft: "24px",
                        marginRight: "24px",
                        marginBottom: "24px"
                      }}
                    >
                      {news.descrizione ? getDescriptionNewsWithoutHtml(news.descrizione).replaceAll("&nbsp;", "") : ""}
                    </Text>
                  </Card>
                </Col>
              ))
            }
          </Row>
      }
      
      
    </Wrapper>
  );
}
