import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Button, Text } from "../";
import "./bottomBar.css";

const BarLayout = styled.div`
	background-color: var(--white);
	border: 1px solid var(--grey-1);
	padding: 32px 40px;
`;

function BottomBar(props) {
	let leftFirstButton = props.leftFirstButton
		? props.leftFirstButton
		: {
				show: false,
				title: "default",
				onClick: "",
		  };

	let leftSecondButton = props.leftSecondButton
		? props.leftSecondButton
		: {
				show: false,
				title: "default",
				onClick: "",
		  };

	let info = props.leftInfo
		? props.leftInfo
		: {
				show: false,
				text: "",
		  };

	let leftColSize = 0;
	if (leftFirstButton.show) leftColSize += 1;
	if (leftSecondButton.show) leftColSize += 1;
	if (info.show) leftColSize += 1;

	let successButton = props.successButton
		? props.successButton
		: {
				show: false,
				title: "default",
				onClick: "",
		  };

	let cancelButton = props.cancelButton
		? props.cancelButton
		: {
				show: false,
				title: "default",
				onClick: "",
		  };

	return (
		<BarLayout className="fixed-bottom">
			<Container fluid>
				<Row>
					<Col
						md={12}
						lg={7}
						style={{
							display: "flex",
							justifyContent: "start",
							alignItems: "center",
							gap: "1rem",
							bsGutterX: "none",
						}}
					>
						{/* Parte destra della toolbar */}

						{/* schermo largo */}
						<Row className="controls-large-left w-100 g-0 gx-md-3">
							<Col
								xs={12}
								className="d-flex justify-content-start align-items-center"
							>
								{leftFirstButton.show ? (
									<>
										<Button
											variant="outline-darkgrey"
											className="bottombar-btn-resizable-left d-flex justify-content-center"
											onClick={leftFirstButton.onClick}
										>
											<DeleteOutlineOutlinedIcon
												style={{
													height: "20px",
													width: "20px",
													marginRight: "8px",
													marginBottom: "3px",
												}}
											/>
											<Text size="small" weight="medium">
												{leftFirstButton.title}
											</Text>
										</Button>
										<div
											style={{
												width: "16px",
											}}
										/>
									</>
								) : null}

								{leftSecondButton.show ? (
									<>
										<Button
											variant="outline-primary"
											className="bottombar-btn-resizable-left d-flex justify-content-center mr-3"
											onClick={leftSecondButton.onClick}
										>
											<InventoryOutlinedIcon
												style={{
													height: "20px",
													width: "20px",
													marginRight: "8px",
													marginBottom: "3px",
												}}
											/>
											<Text size="small" weight="medium">
												{leftSecondButton.title}
											</Text>
										</Button>
										<div
											style={{
												width: "16px",
											}}
										/>
									</>
								) : null}

								{info.show ? (
									<Text
										size="xsmall"
										weight="medium"
										style={{ cursor: "default", userSelect: "none" }}
									>
										{info.text}
									</Text>
								) : null}
							</Col>
						</Row>

						{/* schermo piccolo */}
						<Row className="controls-small-left w-100 g-0 gx-md-3">
							{leftFirstButton.show ? (
								<Col xs={12} md={12 / leftColSize} className="w-100">
									<Button
										variant="outline-darkgrey"
										className="bottombar-btn-resizable d-flex justify-content-center"
										onClick={leftFirstButton.onClick}
									>
										<DeleteOutlineOutlinedIcon
											style={{
												height: "20px",
												width: "20px",
												marginRight: "8px",
												marginBottom: "3px",
											}}
										/>
										<Text size="small" weight="medium">
											{leftFirstButton.title}
										</Text>
									</Button>
								</Col>
							) : null}

							{leftSecondButton.show ? (
								<Col
									xs={12}
									md={12 / leftColSize}
									className="w-100 mt-2 mt-lg-0"
								>
									<Button
										variant="outline-primary"
										className="bottombar-btn-resizable d-flex justify-content-center"
										onClick={leftSecondButton.onClick}
									>
										<InventoryOutlinedIcon
											style={{
												height: "20px",
												width: "20px",
												marginRight: "8px",
												marginBottom: "3px",
											}}
										/>
										<Text size="small" weight="medium">
											{leftSecondButton.title}
										</Text>
									</Button>
								</Col>
							) : null}

							{info.show ? (
								<Col
									xs={12}
									md={12 / leftColSize}
									className="w-100 mt-2 mt-lg-0 d-flex align-items-center"
								>
									<Text size="xsmall" weight="medium">
										{info.text}
									</Text>
								</Col>
							) : null}
						</Row>
					</Col>

					{/* Parte destra della toolbar */}
					<Col
						md={12}
						lg={5}
						className={`${
							leftFirstButton.show || leftSecondButton.show || info.show
								? "mt-4"
								: ""
						} mt-lg-0 d-flex justify-content-start justify-content-lg-end`}
					>
						{/* schermo largo */}
						<Row className="controls-large w-100 g-0 gx-md-3">
							{successButton.show || cancelButton.show ? (
								<Col className="d-flex justify-content-end">
									{
										cancelButton.show ?
										(
											<Button
												variant="secondary"
												className="bottombar-btn-resizable d-flex justify-content-center"
												onClick={cancelButton.onClick}
											>
												<Text size="small" weight="medium">
													{cancelButton.title}
												</Text>
											</Button>
										)
										:
										""
									}
									{
										successButton.show ?
										(
											<Button
												variant="success"
												className="bottombar-btn-resizable d-flex justify-content-center"
												onClick={successButton.onClick}
												style={{
													marginLeft: "16px"
												}}
											>
												<Text size="small" weight="medium">
													{successButton.title}
												</Text>
											</Button>
										)
										:
										""
									}
								</Col>
							) : null}
						</Row>

						{/* schermo piccolo */}
						<Row className="controls-small w-100 g-0 gx-md-3">
							{successButton.show ? (
								<Col sm={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
									<Button
										variant="success"
										className="bottombar-btn-resizable d-flex justify-content-center"
										onClick={successButton.onClick}
									>
										<Text size="small" weight="medium">
											{successButton.title}
										</Text>
									</Button>
								</Col>
							) : null}

							{cancelButton.show ? (
								<Col
									sm={{ span: 12, order: 2 }}
									md={{ span: 6, order: 1 }}
									className="mt-2 mt-md-0 d-flex justify-content-end"
								>
									<Button
										variant="secondary"
										className="bottombar-btn-resizable d-flex justify-content-center"
										onClick={cancelButton.onClick}
									>
										<Text size="small" weight="medium">
											{cancelButton.title}
										</Text>
									</Button>
								</Col>
							) : null}
						</Row>
					</Col>
				</Row>
			</Container>
		</BarLayout>
	);
}

export default BottomBar;
