import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Add as AddIcon } from "@material-ui/icons";
import { Text, TextField, SelectField, Button, Table, RowCommesseAltreLavorazioni, HeaderCellCommesse } from "../index";
import { calcolaTotaleDimensioni, calcolaTotaleLavorazioni } from "../../utils/functionsUtils";
import { withTranslation } from "react-i18next";

const Wrapper = styled.div`

    & {
      width: 100%;
      max-width: 100%;

      display: flex;
      flex-direction: column;
    }

`;


const Footer = styled.div`

    & {
      width: 100%;
      max-width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      background-color: var(--grey-field);

      border-left: 1px solid var(--grey-1);
      border-right: 1px solid var(--grey-1);
      border-bottom: 1px solid var(--grey-1);

      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      padding: 12px 20px 12px 20px;
        
    }

`;

const DivClick = styled.div`
  display: flex;
  align-items: center;  
  cursor: pointer;
`;


const COLUMNS = [
  {
    key: "ua",
    text: "tableCommesseAltreLavorazioni.column1",
    width: "5.956%",
  },
  {
    key: "data1",
    text: "tableCommesseAltreLavorazioni.column2",
    width: "8.956%",
  },
  {
    key: "data2",
    text: "tableCommesseAltreLavorazioni.column3",
    width: "29.912%",
  },
  // {
  //   key: "data3",
  //   text: "tableCommesseAltreLavorazioni.column4",
  //   width: "6.956%",
  // },
  {
    key: "data4",
    text: "tableCommesseAltreLavorazioni.column5",
    width: "6.956%",
  },
  {
    key: "data1",
    text: "tableCommesseAltreLavorazioni.column6",
    width: "23.956%",
  },
  {
    key: "data2",
    text: "tableCommesseAltreLavorazioni.column7",
    width: "8.956%",
  },
  // {
  //   key: "data3",
  //   text: "tableCommesseAltreLavorazioni.column8",
  //   width: "8.956%",
  // },
  {
    key: "data3",
    text: "tableCommesseAltreLavorazioni.column9",
    width: "8.956%",
  },
  {
    key: "data3",
    text: "tableCommesseAltreLavorazioni.column10",
    width: "4.35%",
  }
];

export const TableCommesseAltreLavorazioni = withTranslation()((props) => {

    const { t } = props;

    let tableData = props.tableData || [];
    let clienteData = props.clienteData || [];

    const addRowItem = () => {

      let itemToAdd = {
        "ua" : "",
        "ua_value" : "",
        "descrizione" : "",
        "mat" : "",
        "suppl" : true,
        "note" : "",
        "prezzo" : "",
        "sc" : "0",
        "parziale" : ""
      };

      tableData.push(itemToAdd)

      if(props.onChange) {
        props.onChange(tableData);
      }

    }

    const updateRowItem = (item, index) => {
      tableData[index] = item;

      if(props.onChange) {
        props.onChange(tableData);
      }

    }

    const deleteRowItem = (index) => {

      tableData = tableData.filter((item, itemIndex) => itemIndex != index);

      if(props.onChange) {
        props.onChange(tableData);
      }

    }
    
  return (
        <Wrapper
            className={props.className}
            key={props.keyCell}
            keyCell={props.keyCell}
            style={props.style}
        >
            
            <div style={{
                width: "100%",
                display: "flex",
                alignItems: "center"
            }}>
                <Text
                    size="medium"
                    weight="medium"
                    color="var(--red)"
                    style={{
                      marginRight: "12px"
                    }}
                >
                    {props.title}
                </Text>
                <Text
                    size="xsmall"
                    weight="regular"
                    color="var(--grey-2)"
                >
                    {"tableCommesseAltreLavorazioni.description"}
                </Text>
            </div>
            
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "24px",
                overflowX: "scroll"
              }}
            >
              <Table
                className="min-width-742"
                columns={COLUMNS}
                headerCellComponent={HeaderCellCommesse}
                data={tableData}
                hasborder
                rowComponent={RowCommesseAltreLavorazioni}
                onChange={(item, index) => {
                  updateRowItem(item, index);
                }}
                onDelete={(item, index) => {
                  deleteRowItem(index);
                }}
              />
              <Footer
                className="min-width-742"
              >
                <DivClick
                  onClick={() => {
                    addRowItem();
                  }}
                >
                  <AddIcon
                    style={{
                      color: "var(--darkgey)"
                    }}
                  />
                  <Text
                    size="small"
                    weight="regular"
                    color="var(--darkgey)"
                  >
                    {"tableCommesseAltreLavorazioni.newRow"}
                  </Text>
                </DivClick>
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  {/* <Text
                    size="xsmall"
                    weight="medium"
                    color={"var(--darkgrey)"}
                  >
                    {`TOTALE COMPLESSIVO: -`}
                  </Text>
                  <Text
                    size="xsmall"
                    weight="medium"
                    color={"var(--darkgrey)"}
                  >
                    {`TOTALE REGOLA: -`}
                  </Text> */}
                  <Text
                    size="xsmall"
                    weight="medium"
                    color={"var(--darkgrey)"}
                  >
                    {t("tableCommesseAltreLavorazioni.tot", {tot: calcolaTotaleLavorazioni(tableData, clienteData)})}
                  </Text>
                </div>
              </Footer>
            </div>
            

        </Wrapper>
  );
});
