import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { Navbar } from "../../components";
import { RouterConfig } from "../../navigation/routerConfig";

import { authenticateWithToken } from "../../services";
import { setUser, setToken } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../utils";
import { USER_ROLES } from "../../utils/constants";


const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  background-color: var(--grey-background);
`;

const Body = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--grey-background);
`;

export const Wrapper = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token || "");
  const user = useSelector((state) => state.user || {});
  
  useEffect(() => {
    if(getToken() && getToken() != "" && (!user || !user._id)) {
      executeAuthenticationWithToken();
    }
  }, []);

  const executeAuthenticationWithToken = async () => {

    let { result, error } = await authenticateWithToken();

    if (error) {
      dispatch(setToken(""));
    } else {
      if (result && result.user) {
        dispatch(setToken(getToken()));
        dispatch(setUser(result.user));
      }
    }

  }

  return (
    <BrowserRouter>
      <Container>
        <Navbar/>
        <Body>
          <RouterConfig />
        </Body>
      </Container>
    </BrowserRouter>
  );
};
